import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Pressable,
    Text
} from 'react-native'
import LineChart from '../ChartsDesktop/LineChart';
import PanelsText from '../Components/PanelsText';
import FiltersEnhancedSingle from '../ComponentsEnhanced/FiltersEnhancedSingle';
import BarChart from '../ChartsDesktop/BarChart';
import MixedChart from '../ChartsDesktop/MixedChart';
import Switch from '@mui/material/Switch';
import WebFont from 'webfontloader';

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

const AllChannelDailyPanels = (props) => {

    const client = props.client
    const agg_daily_result = props.agg_daily
    const agg_brand_result = props.agg_brand
    const dataProp = props.dataProp
    const uniqueFilter1 = dataProp.uniqueFilter1
    const uniqueFilter2 = dataProp.uniqueFilter2
    const uniqueFilter3 = dataProp.uniqueFilter3
    const currentMetrics = props.currentMetrics
    const filterParams = props.filterParams
    const dateLabels = props.dateLabels
    const currentData = props.agg_daily
    const timeFrame = props.timeFrame
    const [selectedComponent, setSelectedComponent] = useState("Row2_Mixed1");
    const theme = props.theme
    const handleStateChange = props.handleStateChange
    const propObject = props.propObject
    const barBrands = props.barBrands
    const filterList = props.filterList
    const setFilterList = props.setFilterList
    const [checked, setChecked] = React.useState(false);
    const [isCumulative, setIsCumulative] = useState(false)

    const calcCumulative = (data) => {
        let cumuImps = 0, cumuClicks = 0, cumuSpend = 0, cumuRegs = 0, cumuFtds = 0;

        return data.map(item => {
            cumuImps += item.impressions;
            cumuClicks += item.clicks;
            cumuSpend += item.spend;
            cumuRegs += item.regs;
            cumuFtds += item.ftds

            return {
                date: item.date,
                impressions: cumuImps,
                clicks: cumuClicks,
                spend: cumuSpend,
                regs: cumuRegs,
                ftds: cumuFtds,
                cpc: cumuImps > 0 ? cumuClicks / cumuImps : 0,
                cpa: cumuFtds > 0 ? cumuSpend / cumuFtds : 0
            }
        })
    }

    const cumuData = calcCumulative(currentData)

    let dynamicData;

    if (isCumulative) {
        dynamicData = cumuData
    } else {
        dynamicData = currentData
    }

    const handleChange = (event) => {
        setChecked(event.target.checked);
        setIsCumulative(event.target.checked)
        
      };


    const handleComponentClick = (componentName) => {
        setSelectedComponent(componentName);
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    const renderComponentContent = () => {
        if (selectedComponent === 'Row1_Line1') {
            return <Row1Line1 style={panelMain} />;
        } else if (selectedComponent === 'Row1_Line2') {
            return <Row1Line2 style={panelMain} />;
        } else if (selectedComponent === 'Row1_Line3') {
            return <Row1Line3 style={panelMain} />;
        } else if (selectedComponent === 'Row2_Bar1') {
            return <Row2Bar1 style={panelMain} />;
        } else if (selectedComponent === 'Row2_Mixed1') {
            return <Row2Mixed1 style={panelMain} />;
        } else if (selectedComponent === 'Row2_Bar2') {
            return <Row2Bar2 style={panelMain} />;
        } else {
            return null;
        }
    };


    const panelMain = {

        width: '66.666666666%',
        height: windowHeight * 0.65,
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 40,
        fontFamily: 'Electrolize',     

        
    }

    const panelChild = {
        flex: 1,
        height: '100%',
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 20,
        fontFamily: 'Electrolize',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2))',
    }

    const lineChart = {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
    }

    let topValue, rightValue;

    if (windowWidth > 1800) {
        topValue = "8%"
        rightValue = "1%"
    } else {
        topValue = "12.5%"
        rightValue = "1%"
    }


    const headerToggleContainer = {
        position: 'absolute',
        right: rightValue,
        top: topValue,
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 100000,
        flexDirection: 'row',
        fontFamily: 'Electrolize',
    }

    const cumuText = {
        fontFamily: 'Electrolize',
    }


    const Row1Line1 = () => {

        return (
            <View style={lineChart}>
                <BarChart
                    dataProp={dynamicData}
                    labels={dateLabels}
                    theme={theme}
                    metric={'spend'}
                    timeFrame={timeFrame}
                    client={client}
                    currentMetrics={currentMetrics}
                />
            </View>)
    }

    const Row1Line2 = () => {

        return (
            <View style={lineChart}>
                <BarChart
                    dataProp={dynamicData}
                    labels={dateLabels}
                    theme={theme}
                    metric={'ftds'}
                    timeFrame={timeFrame}
                    client={client}
                    currentMetrics={currentMetrics}
                />
            </View>)
    }


    const Row1Line3 = () => {

        return (
            <View style={lineChart}>
                <LineChart
                    agg_daily_result={dynamicData}
                    dateLabels={dateLabels}
                    theme={theme}
                    metric={"cpa"}
                    timeFrame={timeFrame}
                    client={client}
                    currentMetrics={currentMetrics}
                />
            </View>)
    }

    const Row2Bar1 = () => {
        return (
            <View style={lineChart}>
                <LineChart
                        agg_daily_result={dynamicData}
                        dateLabels={dateLabels}
                        theme={theme}
                        metric={"regs"}
                        timeFrame={timeFrame}
                        client={client}
                        currentMetrics={currentMetrics}
                    />
            </View>)
    }

    const Row2Mixed1 = () => {
        return (
            <View style={lineChart}>
                <MixedChart
                    dataProp={dynamicData}
                    labels={barBrands}
                    theme={theme}
                    agg_daily_result={dynamicData}
                    dateLabels={dateLabels}
                    metric1={'spend'}
                    metric2={'ftds'}
                    timeFrame={timeFrame}
                    client={client}
                    currentMetrics={currentMetrics}
                />
            </View>)
    }

    const Row2Bar2 = () => {
        return (
            <View style={lineChart}>
                <MixedChart
                    dataProp={dynamicData}
                    labels={barBrands}
                    theme={theme}
                    agg_daily_result={dynamicData}
                    dateLabels={dateLabels}
                    metric1={'cpc'}
                    metric2={'cpa'}
                    timeFrame={timeFrame}
                    client={client}
                    currentMetrics={currentMetrics}
                />
            </View>)
    }

    return (
        <View style={styles.container}>
            <View style={{ flexDirection: 'row' }}>
                <View style={panelMain}>
                    <View style={{ width: '100%', flexDirection: 'flex-start', fontFamily: 'Electrolize' }}>
                        <FiltersEnhancedSingle
                            style={{
                                height: '10%',
                                borderColor: 'red',
                                borderWidth: 0,
                                width: '100%',
                                fontFamily: 'Electrolize'
                            }}
                            uniqueFilter1={uniqueFilter1}
                            uniqueFilter2={uniqueFilter2}
                            uniqueFilter3={uniqueFilter3}
                            handleStateChange={handleStateChange}
                            propObject={propObject}
                            filterParams={filterParams}
                            theme={theme}
                            setFilterList={setFilterList}
                            filterList={filterList}
                            currentMetrics={currentMetrics}
                        />
                    </View>
                    <View style={headerToggleContainer}>
                    <Text style={cumuText}>Cumulative:</Text>
                    <Switch
                        checked={checked}
                        onChange={handleChange}
                        sx={{
                            color: 'red'
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                        />

                    </View>

                    {renderComponentContent()}
                </View>
                <PanelsText
                    style={{ width: '100%' }}
                    theme={theme}
                    dataProp={agg_daily_result}
                    client={client}
                    currentMetrics={currentMetrics}
                />
            </View>
            <View style={{ flexDirection: 'row', flex: 1 }}>
                <Pressable style={panelChild} onPress={() => handleComponentClick('Row1_Line1')}>
                    <View style={styles.lineChart}>
                        <BarChart
                            dataProp={dynamicData}
                            labels={dateLabels}
                            theme={theme}
                            metric={'spend'}
                            timeFrame={timeFrame}
                            client={client}
                            currentMetrics={currentMetrics}
                        />
                    </View>
                </Pressable>
                <Pressable style={panelChild} onPress={() => handleComponentClick('Row1_Line2')}>
                    <View style={styles.lineChart}>
                        <BarChart
                            dataProp={dynamicData}
                            labels={dateLabels}
                            theme={theme}
                            metric={'ftds'}
                            timeFrame={timeFrame}
                            client={client}
                            currentMetrics={currentMetrics}
                        />
                    </View>
                </Pressable>
                <Pressable style={panelChild} onPress={() => handleComponentClick('Row1_Line3')}>
                    <View style={styles.lineChart}>
                        <LineChart
                            agg_daily_result={dynamicData}
                            dateLabels={dateLabels}
                            theme={theme}
                            metric={"cpa"}
                            timeFrame={timeFrame}
                            client={client}
                            currentMetrics={currentMetrics}
                        />
                    </View>
                </Pressable>

            </View>

            <View style={{ flexDirection: 'row', flex: 1 }}>
                <Pressable style={panelChild} onPress={() => handleComponentClick('Row2_Bar1')}>
                    <LineChart
                        agg_daily_result={dynamicData}
                        dateLabels={dateLabels}
                        theme={theme}
                        metric={"regs"}
                        timeFrame={timeFrame}
                        client={client}
                        currentMetrics={currentMetrics}
                    />
                </Pressable>
                <Pressable style={panelChild} onPress={() => handleComponentClick('Row2_Mixed1')}>
                    <MixedChart
                        dataProp={dynamicData}
                        labels={barBrands}
                        theme={theme}
                        agg_daily_result={dynamicData}
                        dateLabels={dateLabels}
                        metric1={'spend'}
                        metric2={'ftds'}
                        timeFrame={timeFrame}
                        client={client}
                        currentMetrics={currentMetrics}
                    />
                </Pressable>
                <Pressable style={panelChild} onPress={() => handleComponentClick('Row2_Bar2')}>
                    <MixedChart
                        dataProp={dynamicData}
                        labels={barBrands}
                        theme={theme}
                        agg_daily_result={dynamicData}
                        dateLabels={dateLabels}
                        metric1={'cpc'}
                        metric2={'cpa'}
                        timeFrame={timeFrame}
                        client={client}
                        currentMetrics={currentMetrics}
                    />
                </Pressable>

            </View>

        </View>

    )

}

export default AllChannelDailyPanels

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: (windowHeight * 0.93) * 1.3,
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',


    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize',
        
    }
})