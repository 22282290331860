export default function DailySummary(cubeFilters, table, start, end) {

    return {
        "timeDimensions": [
            {
                "dimension": `${table}.timestamp`,
                "granularity" : 'day',
                "dateRange": [
                    start,
                    end
                ]
            }
        ],
        "order": [
            [
                `${table}.date`,
                "desc"
            ]
        ],
        "measures": [
            `${table}.impressions`,
            `${table}.clicks`,
            `${table}.spend`,
            `${table}.regs`,
            `${table}.ftds`,
        ],
        "dimensions": [
            `${table}.brand`,
            `${table}.channel`,
            `${table}.kw_type`,
            `${table}.date`
        ],
        "limit": 20000,
        "filters": cubeFilters
    }

}