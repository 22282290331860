import React, { useEffect } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import LineChart from '../Charts/LineChart';
import BarChart from '../Charts/BarChart';
import Table from '../Charts/Table'
import MultipleLineChart from '../ChatGPT/ChatGPTCharts/MultipleLineChart';
import PieChart from '../ChartsDesktop/PieChart';
import WebFont from 'webfontloader';
import LoadingComponent from './Loader';
import ChatGptLoader from '../ChatGPT/ChatGptLoader';


const ChatComponent = (props) => {

  const dataArray = props.convoHistory
  const textView = JSON.stringify(dataArray)
  const theme = props.theme
  const client = props.clientLower
  const isLoading = props.isLoading
  //const isLoading = true
  //console.log(isLoading)

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Droid Sans', 'Electrolize']
      }
    });
  }, []);

  const questionStyle = { backgroundColor: theme.color }

  const container = { flex: 1, paddingLeft: 30, paddingRight: 30, justifyContent: 'flex-start', backgroundColor: theme.backgroundColor, borderColor: 'blue', borderWidth: 2, backgroundColor: theme.backgroundColor }
  // styling more message 'bubbles'

  return (
    <div style={container}>
      {dataArray.map((item, index) => {
          
        const isLastItem = index === dataArray.length - 1;

        if (isLastItem && item.question && isLoading) {
            // This ensures we're only considering the last item
            return <LoadComponent theme={theme}/>;
        }
        
        if (item.question) {
          return <TextComponent key={index} text={item.question.question} theme={theme} />;
        } else if (item.answer.response_type === 'mixed_chart') {
          return <AnswerMixed key={index}
            dataProp={item.answer.value1Array}
            labels={item.answer.dateArray}
            theme={theme}
            metric1={item.answer.keyNames[1]}
            metric2={item.answer.keyNames[2]}
            agg_daily_result={item.answer.value2Array}
            client={client}
          />;
        } else if (item.answer.response_type === 'line_chart') {
          return <AnswerLine key={index} dataProp={item.answer.value1Array} labels={item.answer.dateArray} theme={theme} />;
        } else if (item.answer.response_type === 'pie_chart') {
          return <AnswerPie key={index} dataProp={item.answer.value1Array} labels={item.answer.dateArray} theme={theme} />;
        } else if (item.answer.response_type === 'bar_chart') {
          return <AnswerBar key={index} dataProp={item.answer.value1Array} labels={item.answer.dateArray} theme={theme} />;
        } else if (item.answer.response_type === 'single_value') {
          return <ViewComponent key={index} answer={item.answer.response_obj_value} question={item.answer.response_question} theme={theme} />
        } else if (item.answer.response_type === 'table') {
          return <AnswerTable key={index} dataProp={item.answer.parsedJSON} theme={theme} />
        } else if (!item.question || !item.answer) {
          return null
        }

        return null; // If the object doesn't have 'question' or 'answer' property
      })}
    </div>
  );
};

const LoadComponent = ({ theme }) => {

  const questionTextStyle = {
    color: theme.backgroundColor,
    borderWidth: 1,
    padding: '1%', // Adding padding for some space around the text
    borderRadius: 10, // Optional: rounded corners
    display: 'inline-block', // This makes the div only as wide as its content
    textAlign: 'center',
    fontSize: 30,
    fontFamily: 'Electrolize',
  };

  return <View style={[styles.loader, {borderColor: theme.backgroundColor2, borderWidth: 0, borderRadius: 10}]}>
      <ChatGptLoader style={questionTextStyle} theme={theme}/>
    </View>

}
const TextComponent = ({ text, theme }) => {

  const questionTextStyle = {
    color: theme.backgroundColor,
    borderWidth: 1,
    padding: '1%', // Adding padding for some space around the text
    borderRadius: 10, // Optional: rounded corners
    display: 'inline-block', // This makes the div only as wide as its content
    textAlign: 'left',
    fontSize: 30,
    fontFamily: 'Electrolize',
    backgroundColor: theme.color
  };

  return <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
      <div style={questionTextStyle}>
        {text}
      </div>
    </div>
};

const ViewComponent = ({ answer, question, theme }) => {

  const answerTextStyle = {
    color: theme.backgroundColor,
    borderWidth: 1,
    padding: '1%', // Adding padding for some space around the text
    borderRadius: 10, // Optional: rounded corners
    display: 'inline-block', // This makes the div only as wide as its content
    textAlign: 'right',
    fontSize: 50,
    fontFamily: 'Electrolize',
    backgroundColor: theme.backgroundColor2
  };
  let formattedAnswer = answer;
  if (question.includes('cost') || question.includes('cpa') || question.includes('spend')) {
    formattedAnswer = `€${answer.toFixed(2)}`;
  } else if (question.includes('rate')) {
    formattedAnswer = `${answer}%`;
  }

  if (question.includes('cost') || question.includes('cpa')) {
    return <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
      <div style={answerTextStyle}>
        {formattedAnswer}
      </div>
    </div>
  }

  else if (question.includes('rate')) {
    return <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
      <div style={answerTextStyle}>
        {formattedAnswer}%
      </div>
    </div>
  }

  return <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
  <div style={answerTextStyle}>
    {formattedAnswer}
  </div>
</div>
};

const AnswerLine = ({ dataProp, labels, theme }) => {

  return (
    <View style={[styles.lineChart, {borderColor: theme.backgroundColor2, borderWidth: 4, borderRadius: 10}]}>
      <LineChart
        dataProp={dataProp}
        labels={labels}
        theme={theme}
      />
    </View>)
};

const AnswerMixed = ({ agg_daily_result, dataProp, labels, theme, metric1, metric2, client }) => {

  return (
    <View style={[styles.lineChart, {borderColor: theme.backgroundColor2, borderWidth: 4, borderRadius: 10}]}>
      <MultipleLineChart
        metric1={metric1}
        metric2={metric2}
        agg_daily_result={agg_daily_result}
        dataProp={dataProp}
        labels={labels}
        theme={theme}
        client={client}
      />
    </View>)
};

const AnswerBar = ({ dataProp, labels, theme }) => {

  return (
    <View style={[styles.lineChart, {borderColor: theme.backgroundColor2, borderWidth: 4, borderRadius: 10}]}>
      <BarChart
        dataProp={dataProp}
        labels={labels}
        theme={theme}
      />
    </View>)
};

const AnswerPie = ({ dataProp, labels, theme }) => {

  return (
    <View style={[styles.lineChart, {borderColor: theme.backgroundColor2, borderWidth: 4, borderRadius: 10, alignItems: 'center'}]}>
      <PieChart
        dataProp={dataProp}
        labels={labels}
        theme={theme}
      />
    </View>)
};

const AnswerTable = ({ dataProp, theme }) => {

  const labels = Object.keys(dataProp[0]);

  return (
    <View style={[styles.table, {borderColor: theme.backgroundColor2, borderWidth: 4, borderRadius: 10}]}>

      <Table
        dataProp={dataProp}
        labels={labels}
        theme={theme}
      />
    </View>
  )

}

const styles = StyleSheet.create({
  container: { flex: 1, paddingLeft: 30, paddingRight: 30, justifyContent: 'flex-start', borderColor: 'blue', borderWidth: 2 },
  textBox: { width: '100%', borderColor: 'red', borderWidth: 1, flexDirection: 'column', alignItems: 'center' },
  head: { height: 44, backgroundColor: 'darkblue' },
  message: { width: '100%', color: 'black', borderWidth: 1, flexDirection: 'column', alignItems: 'center', fontSize: 30, textAlign: 'left', fontFamily: 'Electrolize' },
  answer: { width: '100%', color: 'blue', borderWidth: 1, flexDirection: 'column', alignItems: 'center', fontSize: 50, textAlign: 'right', fontFamily: 'Electrolize' },
  headText: { fontSize: 10, fontWeight: 'bold', textAlign: 'center', color: 'white' },
  text: { margin: 6, fontSize: 25, fontWeight: 'bold', textAlign: 'center' },
  lineChart: { margin: 6, fontSize: 10, width: 1000, height: 500, marginLeft: 'auto', marginRight: 0 },
  table: { fontSize: 10, flex: 1, margin: '1%', fontFamily: 'Electrolize', },
  loader: { margin: 6, fontSize: 10, width: 500, height: 250, marginLeft: 'auto', marginRight: 0 },

})

export default ChatComponent