import { View, Pressable, Text, Dimensions } from 'react-native';
import React, { useState } from 'react';
import SummaryFAB from './SummaryFAB';
import BarChart from '../../ChartsDesktop/BarChart';

const windowWidth = Dimensions.get('window').width;

const SummaryPanel = (props) => {

    const theme = props.theme
    const agg_daily = props.agg_daily
    const dateLabels= props.dateLabels
    const currentMetrics = props.currentMetrics
    const [currentMetric, setCurrentMetric] = useState('ftds')

    const isComponentSelected = (componentId) => {
        return currentMetric === componentId;
      };

    function clickHandler (prop) {
        setCurrentMetric(prop)
    }


    const container = {
        height: '100%',
        width: '100%',
        borderColor: theme.color,
        borderWidth: 0,
        flexDirection: 'column',
    }

    const chartContainer = {
        height: '85%',
        width: '100%',
        borderColor: theme.backgroundColor2,
        borderWidth: 0,
        flexDirection: 'column',
        padding: '2%',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4))',

    }

    const selectContainer = {
        height: '15%',
        width: '100%',
        borderColor: theme.color,
        borderWidth: 0,
        flexDirection: 'column',
    }

    const selectRow = {
        height: '50%',
        width: '100%',
        flexDirection: 'row',
        borderColor: theme.backgroundColor2,
        borderWidth: 0,
        padding: '1%',
        justifyContent: 'space-between'
         
    }

    const selectItem = {
        height: '100%',
        width: '32%',
        borderColor: theme.color,
        borderWidth: 1,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 10,
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2))',
    }

    const selectedItem = {
        height: '100%',
        width: '32%',
        borderColor: theme.backgroundColor2,
        backgroundColor: theme.backgroundColor2,
        borderWidth: 1,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 10,
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2))',
    }

    const text = {
        fontSize : 30,
        color: theme.backgroundColor2,
        fontFamily: 'Electrolize',
    }

    const selectedText = {
        fontSize : 30,
        color: theme.backgroundColor,
        fontFamily: 'Electrolize',
    }

    return (
        <View style={container}>
            <View style={chartContainer}>
                <BarChart
                        dataProp={agg_daily}
                        labels={dateLabels}
                        theme={theme}
                        metric={currentMetric}
                        timeFrame={'Daily'}
                        currentMetrics={currentMetrics}
                    />
            </View>
            <View style={selectContainer}>
                <View style={selectRow}>
                    <SummaryFAB
                        theme={theme}
                        buttonText={currentMetrics.primaryMetric}
                        graphHandler={clickHandler}
                        currentMetrics={currentMetrics}
                    />
                    <SummaryFAB
                        theme={theme}
                        buttonText={'spend'}
                        graphHandler={clickHandler}
                        currentMetrics={currentMetrics}
                    />
                    <SummaryFAB
                        theme={theme}
                        buttonText={'cpa'}
                        graphHandler={clickHandler}
                        currentMetrics={currentMetrics}
                    />
                </View>
                <View style={selectRow}>
                <SummaryFAB
                        theme={theme}
                        buttonText={currentMetrics.secondaryMetric}
                        graphHandler={clickHandler}
                        currentMetrics={currentMetrics}
                    />
                    <SummaryFAB
                        theme={theme}
                        buttonText={'clicks'}
                        graphHandler={clickHandler}
                        currentMetrics={currentMetrics}
                    />
                    <SummaryFAB
                        theme={theme}
                        buttonText={'cpc'}
                        graphHandler={clickHandler}
                        currentMetrics={currentMetrics}
                    />
                </View>

            </View>
            

        </View>
    )

}

export default SummaryPanel