export default function Dimensions_4 (table, start, end, dim1, dim2, dim3, currentChannel, filter2Operator) {

    return {
        "order": {
            [`${table}.cost`]: "desc",
        },
        "limit": 1000,
        "dimensions": [
        `${table}.${dim1}`,
        `${table}.${dim2}`,
        `${table}.${dim3}`,
        `${table}.updated_at`,
        ],
        "filters": [ 
            { 
              "member": `${table}.${dim2}`, 
              "operator": `${filter2Operator}`, 
              "values": 
                currentChannel 
              
            }
        ],
        "timeDimensions": [
          {
            "dimension": `${table}.timestamp`,
            "dateRange": [
              start,
              end
          ]
          }
        ]
      }


}