import React, { useState } from 'react';
import { Modal, 
    View, 
    StyleSheet, 
    Dimensions, 
    Text,
    Pressable } from 'react-native';
import ModalType from './Modals/ModalType';
import ModalDate from './Modals/ModalDate';
import CloseIcon from '@mui/icons-material/Close';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const DocTemplateModal = (props) => {

    const visible = props.visible
    const setVisible = props.setTemplateVisible
    const dateVisible = props.dateVisible
    const setDateVisible = props.setDateVisible
    const config = props.config
    const theme = props.theme
    const uniqueBrands = props.uniqueBrands;
    const uniqueChannels = props.uniqueChannels;
    const uniqueTypes = props.uniqueTypes;
    const chartTypes = props.chartTypes
    const metrics = props.metrics
    const updateSlide = props.updateSlide
    const updateDate = props.updateDate
    const currentMetrics = props.currentMetrics

    const [modalTypeVisible, setModalTypeVisible] = useState(false)

    const modalPanel = {
        height: '90%',
        width: '100%',
        flexDirection: 'column',
        borderColor: theme.color,
        borderWidth: 1,
        
    }

    const modalPanelRow = {
        height: '25%',
        width: '100%',
        flexDirection: 'row',
    }

    const panelRowItem = {
        height: '100%',
        width: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1%'
    }

    const panelChild = {
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.backgroundColor2,
        borderColor: theme.color,
        borderWidth: 1,
        borderRadius: 10,
        padding: '0.5%'
    }

    const text = {
        textAlign: 'center',
        fontFamily: 'Electrolize',
        fontSize: 20,
        color: theme.color2,
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
    }

    const axes = ['date', 'week', 'month', 'year', 'brand', 'channel', 'kw_type']

    const [modalState, setModalState] = useState(chartTypes)

    function modalHandler (title, data) {

        const update = {
            title,
            data
        }

        console.log(update)

        setModalState(update)
        setModalTypeVisible(true)
    }

    

    return (
        <Modal
            visible={visible}
            style={styles.modalParent}
            transparent={true}
            config={config}
            >
        <Pressable 
            style={styles.modalContainer}
            onPress={() => setVisible(false)}
            >
            <View style={[styles.modalWindow , {
                backgroundColor : theme.backgroundColor,
                borderColor: theme.color,
                borderWidth: 2,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
            }]}>
            <Pressable
                    onPress={() => setVisible(false)}
                    style={{
                        borderColor: theme.backgroundColor2,
                        borderWidth: 0,
                        position: 'absolute',
                        top: '1%',
                        right: '1.5%',
                        fontSize: 10,
                        }}
                >
                <CloseIcon
                    style={{
                        
                        
                        color: theme.backgroundColor2,
                        borderRadius: 5
                        
                    }}
                />
                </Pressable> 
                <View style={modalPanel} >
                 
                    <View style={modalPanelRow} >
                        <View style={panelRowItem}>
                            <Pressable 
                                style={panelChild}
                                onPress={() => modalHandler('Chart Type', chartTypes)}>
                                <Text style={text}>
                                    Chart Type
                                </Text>
                                <ModalType
                                    visible={modalTypeVisible}
                                    setVisible={setModalTypeVisible}
                                    config={config}
                                    theme={theme}
                                    updateSlide={updateSlide}
                                    title={'Chart Type'}
                                    modalState={modalState}
                                    modalHandler={modalHandler}
                                    currentMetrics={currentMetrics}
                                />
                                
                            </Pressable>

                        </View>
                        <View style={panelRowItem}>
                            <Pressable style={panelChild}
                            onPress={() => setDateVisible(true)}>
                                <Text style={text}>
                                    Date Range
                                </Text>
                                <ModalDate
                                    visible={dateVisible}
                                    setVisible={setDateVisible}
                                    config={config}
                                    theme={theme}
                                    updateSlide={updateSlide}
                                    updateDate={updateDate}
                                    
                                />
                            </Pressable>

                        </View>
                    </View>
                    <View style={modalPanelRow} >
                        <View style={panelRowItem}>
                            <Pressable style={panelChild}
                            onPress={() => modalHandler('xAxis', axes)}>
                                <Text style={text}>
                                    xAxis
                                </Text>
                                <ModalType
                                    visible={modalTypeVisible}
                                    setVisible={setModalTypeVisible}
                                    config={config}
                                    theme={theme}
                                    updateSlide={updateSlide}
                                    modalState={modalState}
                                    currentMetrics={currentMetrics}
                                />
                            </Pressable>

                        </View>
                        <View style={panelRowItem}>
                            <Pressable style={panelChild}
                            onPress={() => modalHandler('Filter: Brand', uniqueBrands)}>
                                <Text style={text}>
                                    Filter: Brand
                                </Text>
                                <ModalType
                                    visible={modalTypeVisible}
                                    setVisible={setModalTypeVisible}
                                    config={config}
                                    theme={theme}
                                    updateSlide={updateSlide}
                                    modalState={modalState}
                                    currentMetrics={currentMetrics}
                                />
                            </Pressable>

                        </View>
                    </View>
                    <View style={modalPanelRow} >
                        <View style={panelRowItem}>
                            <Pressable style={panelChild}
                            onPress={() => modalHandler('Filter: Channel', uniqueChannels)}>
                                <Text style={text}>
                                    Filter: Channel
                                </Text>
                                <ModalType
                                    visible={modalTypeVisible}
                                    setVisible={setModalTypeVisible}
                                    config={config}
                                    theme={theme}
                                    updateSlide={updateSlide}
                                    modalState={modalState}
                                    currentMetrics={currentMetrics}
                                />
                            </Pressable>

                        </View>
                        <View style={panelRowItem}>
                            <Pressable style={panelChild}
                            onPress={() => modalHandler('Filter: Type', uniqueTypes)}>
                                <Text style={text}>
                                    Filter: Type
                                </Text>
                                <ModalType
                                    visible={modalTypeVisible}
                                    setVisible={setModalTypeVisible}
                                    config={config}
                                    theme={theme}
                                    updateSlide={updateSlide}
                                    modalState={modalState}
                                    currentMetrics={currentMetrics}
                                />
                            </Pressable>

                        </View>
                    </View>
                    <View style={modalPanelRow} >
                        <View style={panelRowItem}>
                            <Pressable style={panelChild}
                            onPress={() => modalHandler('Metric 1', metrics)}>
                                <Text style={text}>
                                    Metric 1
                                </Text>
                                <ModalType
                                    visible={modalTypeVisible}
                                    setVisible={setModalTypeVisible}
                                    config={config}
                                    theme={theme}
                                    updateSlide={updateSlide}
                                    modalState={modalState}
                                    currentMetrics={currentMetrics}
                                />
                            </Pressable>

                        </View>
                        <View style={panelRowItem}>
                            <Pressable style={panelChild}
                            onPress={() => modalHandler('Metric 2', metrics)}>
                                <Text style={text}>
                                    Metric 2
                                </Text>
                                <ModalType
                                    visible={modalTypeVisible}
                                    setVisible={setModalTypeVisible}
                                    config={config}
                                    theme={theme}
                                    updateSlide={updateSlide}
                                    modalState={modalState}
                                    currentMetrics={currentMetrics}
                                />
                            </Pressable>

                        </View>
                    </View>

                </View>

            </View>


        </Pressable>


        </Modal>
    )

}

const styles = StyleSheet.create({
    modalParent: {
        padding: 20,
        borderRadius: 20,
    },
    modalContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        width: windowWidth * 1,
        height: windowHeight * 1,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        justiftContent: 'center',
        
    },
    modalWindow: {
        height: '50%',
        width: '50%',
        borderRadius: 15,
        padding: '1%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    }
});


export default DocTemplateModal