import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';

const TextBox = (props) => {

  const inputHandler = props.inputHandler
  const [inputText, setinputText] = useState('');
  const backupTheme = {
    backgroundGradient: ['#ffffff', '#ffffff'],
    backgroundColor: 'white',
    backgroundColor2: '#006dee',
    color: '#0a2342',
    color2: 'white',
    color3: '#0a2342',
    borderColor: '#0a2342',
    borderColor2: '#0a2342',
    panelBackground: 'rgba(0, 109, 238, 0.5)',
    panelBackground2: 'rgba(0, 109, 238, 0.9)',
    panelTextColor: '#0a2342',
    panelTextShadow: '#fff',
    comboStopColor1: 'rgb(5, 65, 244)',
    comboStopColor2: 'rgb(66, 194, 244)',
    comboStopColor1ARRAY: [5, 65, 244],
    comboStopColor2ARRAY: [66, 194, 244],
    comboStroke: '#0a2342',
    buttonColor: 'rgba(255, 255, 255, 0.15)',
    buttonTextColor: '#0a2342',
    areaStopColor1: 'rgb(5, 65, 244)',
    areaStopColor2: 'rgb(66, 194, 244)',
    blockColor: '#0a2342',
    pieArray: ['#0541f4', '#0069ff', '#0087ff', '#00acfe', '#42c2f4'],
    selectedBackground: '#0a2342',
    selectedColor: 'white',
    selectedBorder: 'white',
    gradc10: '#2253f5',
    gradc20: '#0069fc',
    gradc30: '#007bff',
    gradc40: '#008cff',
    gradc50: '#009cfe',
    gradc60: '#3cabfa',
    gradc70: '#61b9f6',
    gradc80: '#83c6f1',
    gradc90: '#a3d2ed',
    gradc100: '#a3d2ed'

  }
  const theme = props.theme || backupTheme


  return (
<FormControl sx={{ margin: 5, color: 'white' }} variant="outlined">
      <InputLabel 
        sx={{color: 'white'}}
        htmlFor="outlined-adornment-password">
        Ask your question here...</InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        value={inputText}
        onChange={e => setinputText(e.target.value)}

        sx={{color: 'white', backgroundColor: theme.backgroundColor2}}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={() => inputHandler(inputText)} // Changed from onPress to onClick for web
              // Passing the theme and other props directly to SendIcon
              icon={props => (
                <SendIcon {...props} sx={{ color: theme.color }} />
              )}
            >
              <SendIcon sx={{ color: 'white', fontSize: 35 }} />
            </IconButton>
          </InputAdornment>
        }

      />
      
    </FormControl>
  )
};

export default TextBox;