import React from 'react';
import { View, StyleSheet } from 'react-native'
import LineChart from '../ChartsDesktop/LineChart';
import BarChart from '../ChartsDesktop/BarChart';
import MixedChart from '../ChartsDesktop/MixedChart';

const ChartPanelLarge = (props) => {

    const chartConfig = props.chartConfig
    const currentMetrics = props.currentMetrics
    const chartType = chartConfig.type
    const theme = props.theme
    const xAxis = chartConfig.xAxis
    const xAxisLower = xAxis.toLowerCase()
    const metric1 = chartConfig.metric1
    const metric2 = chartConfig.metric2
    const metric1Lower = metric1.toLowerCase()
    const metric2Lower = metric2.toLowerCase()
    const dataset = props.dataset
    const agg_daily_result = dataset.agg_daily_result
    const agg_monthly_result = dataset.agg_daily_result
    const agg_brand_result = dataset.agg_daily_result
    const agg_channel_result = dataset.agg_daily_result
    const agg_type_result = dataset.agg_daily_result
    const uniqueBrands = props.uniqueBrands

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
      };
    
      const labels = getUniqueValues(agg_daily_result, xAxisLower)

      const renderChartLarge = () => {
        if (chartType === 'Daily Line') {
            return <LineChart
            agg_daily_result={agg_daily_result}
            dateLabels={labels}
            theme={theme}
            metric={metric1Lower}
            timeFrame={'Daily'}
            currentMetrics={currentMetrics}
        />
        } else if  (chartType === 'Daily Bar') {
            return <BarChart 
            dataProp={agg_daily_result}
            labels={labels}
            theme={theme}
            metric={metric1Lower}
            timeFrame={'Daily'}
            currentMetrics={currentMetrics}
        />
        } else if  (chartType === 'Monthly Line') {
            return <LineChart
            agg_daily_result={agg_monthly_result}
            dateLabels={labels}
            theme={theme}
            metric={metric1Lower}
            timeFrame={'Monthly'}
            currentMetrics={currentMetrics}
        />
        } else if  (chartType === 'Monthly Bar') {
            return <BarChart 
            dataProp={agg_monthly_result}
            labels={labels}
            theme={theme}
            metric={metric1Lower}
            timeFrame={'Monthly'}
            currentMetrics={currentMetrics}
        />
        } else if  (chartType === 'Grouped Bar' && xAxis === 'Brand') {
            return <BarChart 
            dataProp={agg_brand_result}
            labels={labels}
            theme={theme}
            metric={metric1Lower}
            timeFrame={'Monthly'}
            currentMetrics={currentMetrics}
        />
        } else if  (chartType === 'Grouped Bar' && xAxis === 'Channel') {
            return <BarChart 
            dataProp={agg_channel_result}
            labels={labels}
            theme={theme}
            metric={metric1Lower}
            timeFrame={'Monthly'}
            currentMetrics={currentMetrics}
        />
        } else if  (chartType === 'Grouped Bar' && xAxis === 'KW_Type') {
            return <BarChart 
            dataProp={agg_type_result}
            labels={labels}
            theme={theme}
            metric={metric1Lower}
            timeFrame={'Monthly'}
            currentMetrics={currentMetrics}
        />
        } else if  (chartType === 'Grouped Bar' && xAxis === 'Time') {
            return <BarChart 
            dataProp={agg_daily_result}
            labels={labels}
            theme={theme}
            metric={metric1Lower}
            timeFrame={'Monthly'}
            currentMetrics={currentMetrics}
        />
        } else if  (chartType === 'Grouped Bar') {
            return <BarChart 
            dataProp={agg_channel_result}
            labels={labels}
            theme={theme}
            metric={metric1Lower}
            timeFrame={'Monthly'}
            currentMetrics={currentMetrics}
        />
        }
        
        else if  (chartType === 'Mixed' && xAxis === 'Channel') {
            return <MixedChart 
            dataProp={agg_brand_result}
            labels={labels}
            theme={theme}
            agg_daily_result={agg_channel_result}
            dateLabels={labels}
            metric1={metric1Lower}
            metric2={metric2Lower}
            timeFrame={'Daily'}
            currentMetrics={currentMetrics}
        />
         } else if  (chartType === 'Mixed' && xAxis === 'Brand') {
            return <MixedChart 
            dataProp={agg_brand_result}
            labels={labels}
            theme={theme}
            agg_daily_result={agg_brand_result}
            dateLabels={uniqueBrands}
            metric1={metric1Lower}
            metric2={metric2Lower}
            timeFrame={'Daily'}
            currentMetrics={currentMetrics}
        />
        } else if  (chartType === 'Mixed' && xAxis === 'KW_Type') {
            return <MixedChart 
            dataProp={agg_brand_result}
            labels={labels}
            theme={theme}
            agg_daily_result={agg_type_result}
            dateLabels={labels}
            metric1={metric1Lower}
            metric2={metric2Lower}
            timeFrame={'Daily'}
            currentMetrics={currentMetrics}
        />
        } else if  (chartType === 'Mixed' && xAxis === 'Time') {
            return <MixedChart 
            dataProp={agg_brand_result}
            labels={labels}
            theme={theme}
            agg_daily_result={agg_daily_result}
            dateLabels={labels}
            metric1={metric1Lower}
            metric2={metric2Lower}
            timeFrame={'Daily'}
            currentMetrics={currentMetrics}
        />
        } else if  (chartType === 'Mixed') {
            return <MixedChart 
            dataProp={agg_brand_result}
            labels={labels}
            theme={theme}
            agg_daily_result={agg_daily_result}
            dateLabels={labels}
            metric1={metric1Lower}
            metric2={metric2Lower}
            timeFrame={'Daily'}
            currentMetrics={currentMetrics}
        />
        }
        return null
      }




    return (
        <View style={styles.container}>
        {renderChartLarge()}
        </View>
    )

}

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'grey',
        paddingTop: 0,
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        padding: '2%',
        alignItems: 'center',
        justfiyContent: 'center',
    },
})

export default ChartPanelLarge