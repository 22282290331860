import { memo } from 'react'
import { useDrag } from 'react-dnd'
import { Dimensions, Text, View } from 'react-native'

const windowWidth = Dimensions.get('window').width;

export const Box = memo(function Box({ name, type, link, isDropped, parent, theme }) {

    function boxStyle (parent) {
        if (parent === 'Summaries') {          
            return theme.gradc10
        } else if (parent === 'Budget') {
            return theme.gradc50
        } else if (parent === 'PPC' || parent === 'Paid Social') {
          return theme.gradc70
        } else if (parent === 'Display') {
          return theme.gradc60
        }
        
         else if (parent === 'GA4') {
            return theme.color3
        } else if (parent === 'Enhanced Attribution') {
          return theme.gradc40
        } else if (parent === 'Programmatic') {
          return theme.panelBackground
        } else {
            return 'purple'
        }
    }

    let dynamicMargin;

    if (windowWidth > 2000) {
      dynamicMargin = '2.5rem'
    } else if (windowWidth >= 1400) {
      dynamicMargin = '1.75rem'
    } else if (windowWidth < 1400) {
      dynamicMargin = '0.75rem'
    }

    let dynamicText;

    if (windowWidth > 2000) {
      dynamicText = windowWidth * 0.0075
    } else if (windowWidth >= 1400) {
      dynamicText = windowWidth * 0.0075
    } else if (windowWidth < 1400) {
      dynamicText = windowWidth * 0.0075
    }
    
    const style = {
        backgroundColor: boxStyle(parent),
        color: 'white',
        cursor: 'move',
        padding: '0.5%',
        width: windowWidth * 0.15,
        height: windowWidth * 0.03,
        borderRadius: 10,
        marginBottom: dynamicMargin,
        cursor: 'move',
        float: 'left',
        textAlign: 'center',
        fontSize: dynamicText * 1.1,
        border: '2px solid white',
        alignItems: 'center',
        justifyContent: 'center',
        
      }

    const textStyle = {
      fontFamily: 'Electrolize',
      fontSize: dynamicText * 1.1,
      color: 'white',
      borderColor: 'red',
      borderWidth: 0
    }

    const containerView = {
      width: '100%', 
      height: '100%', 
      borderColor: 'red', 
      borderWidth: 0,
      alignItems: 'center',
      justifyContent: 'center',
    }

  const [{ opacity }, drag] = useDrag(
    () => ({
      type,
      item: { name },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [name, type],
  )
  return (
    <div ref={drag} style={{ ...style, opacity }} data-testid="box">
      <View style={containerView}>
      <Text style={textStyle}>
      {parent}: {link}
      </Text>
      </View>
    </div>
  )
})