import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutButton from '../Auth0/AuthLogout';
import { Dimensions } from 'react-native'
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import routemap from '../routes.json'
import DrawerIcons from './DrawerIcons';


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function TemporaryDrawer(props) {

  const anchor = props.anchor
  const state = props.state
  const toggleDrawer = props.toggleDrawer
  const theme = props.theme
  const [open, setOpen] = useState({});
  const clientID = props.clientID
  const currentRoutes = routemap[clientID]['config']
  
  let responseText;

  if (windowWidth < 1281) {
    responseText = windowHeight * 0.025
  } else if (windowWidth > 2000) {
    responseText = windowHeight * 0.015
  } else {responseText = windowHeight * 0.017}

  const excludeRoutes = ['Home', 'Login', 'TESTING', 'SignedUp']


  const filterList = currentRoutes.filter((item) => {
    return !excludeRoutes.includes(item.text);
  });
  
  let responseDrawer;

  if (windowWidth < 1281) {
    responseDrawer = windowWidth * 0.2
  } else if (windowWidth > 2000) {
    responseDrawer = windowWidth * 0.15
  } else {responseDrawer = windowWidth * 0.17}

    
  const list = (anchor) => (
    <Box
      sx={{ 
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : responseDrawer,
        backgroundColor: theme.backgroundColor, 
        color: theme.color, 
        borderColor: 'red',
        borderWidth: 0,
        minHeight: '97.1%'

         }}
      role="presentation"
      //onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <NestedList
        currentRoutes={filterList}
        DrawerIcons={DrawerIcons}
        theme={theme}
        responseText={responseText}
        open={open}
        setOpen={setOpen}
        toggleDrawer={toggleDrawer}
        anchor={anchor}
      />
      <Box sx={{ alignItems: 'center', 
                justifyContent: 'center',
                display: 'flex',
                width: '100%',
                backgroundColor: theme.backgroundColor, 
                paddingLeft: '0%'}}>

        <LogoutButton
          //sx={{ height: '10%' }}
          theme={theme}
        />

      </Box>
    </Box>
  );

  return (
    <div>

      <React.Fragment key={anchor}>
        <Drawer
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
        >
          {list(anchor)}
        </Drawer>
      </React.Fragment>

    </div>
  );
}

function NestedList({ DrawerIcons, currentRoutes, theme, responseText, open, setOpen, toggleDrawer, anchor }) {
  // State to track open/closed collapsible sections
  

  // Function to handle click on parent
  const handleClick = (parent) => {
    setOpen(prevOpen => ({
      ...prevOpen,
      [parent]: !prevOpen[parent],
    }));
  };

  const handleMenu  = () => {
    toggleDrawer(anchor, false)
  };

  // Assuming 'combinedList' is already structured or you structure it as needed
  const parentGroups = currentRoutes.reduce((acc, item) => {
    acc[item.parent] = acc[item.parent] || [];
    acc[item.parent].push(item);
    return acc;
  }, {});

  return (
    <List sx={{ height: '98.7%', backgroundColor: theme.backgroundColor }}>
      {Object.entries(parentGroups).map(([parent, items]) => (
        
        <React.Fragment key={parent}>
          <ListItem key={`parent-${parent}`} sx={{ paddingLeft: '1%' }} button onClick={() => handleClick(parent)}>
            <ListItemText
              disableTypography
              primary={<Typography style={{ fontSize: responseText, color: theme.color, fontFamily : 'Electrolize' }}>{parent}</Typography>}
            />
            {open[parent] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open[parent] || false} timeout="auto" unmountOnExit>
            {items.map((item, index) => {
              const IconComponent = DrawerIcons[item.icon]
              return (
              <ListItem key={`${parent}-${item.text}-${index}`}
                disablePadding
                button
                component="a"
                href={item.path}
                sx={{ pl: 4 }}>
                <ListItemButton onClick={handleMenu}>
                  <ListItemIcon>{
                    <IconComponent
                      theme={theme}
                      sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
                  />}

                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={<Typography style={{ fontSize: responseText, color: theme.color, fontFamily : 'Electrolize' }}>{item.text}</Typography>}
                  />
                </ListItemButton>
              </ListItem>
            )}
            )}
          </Collapse>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  );
}
