export default function ParseCohorts(cubeFilters, table, start, end) {

    return {
        "timeDimensions": [
            {
                "dimension": `${table}.timestamp`,
                "granularity" : 'month',
            }
        ],
        "order": [
            [
                `${table}.monthsfromftd`,
                "asc"
            ]
        ],
        "dimensions": [ 
            `${table}.cohort_month`, 
            `${table}.monthsfromftd`
          ],
        "measures": [
            `${table}.ngr`,
            `${table}.cost`,
            `${table}.ftds`
        ],
        "limit": 1000,
        "filters": cubeFilters
    }

}