import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport
} from '@mui/x-data-grid';
import { Dimensions } from 'react-native'
import WebFont from 'webfontloader';
import {
  DataGridPremium,
  GRID_AGGREGATION_FUNCTIONS,
} from '@mui/x-data-grid-premium';
import AttModels from '../AttModels';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function AttEnhancedTableDataGrid(props) {

  const dataProp = props.dataProp
  const enhancedTotals = props.enhancedTotals
  const models = props.models
  const columnWidth = props.columnWidth
  const theme = props.theme
  const currentMetrics = props.currentMetrics
  const model1 = models.model1
  const model2 = models.model2
  const model3 = models.model3
  const dimension = models.dimension
  const metric = models.metric
  const filterParams = props.filterParams



  const [aggregationModel, setAggregationModel] = useState({
    [dimension]: 'total',
    impressions: 'sum',
    clicks: 'sum',
    ctr: 'ctr',
    spend: 'sum',
    cpc: 'cpc',
    regs: 'sum',
    [`cpa_${model1}`]: 'firstColumn',
    [`cpa_${model2}`]: 'secondColumn',
    [`cpa_${model3}`]: 'thirdColumn',
    [`ftds_${model1}`]: 'firstColumn',
    [`ftds_${model2}`]: 'secondColumn',
    [`ftds_${model3}`]: 'thirdColumn',
    [`ngr_${model1}`]: 'firstColumn',
    [`ngr_${model2}`]: 'secondColumn',
    [`ngr_${model3}`]: 'thirdColumn',
    [`roas_${model1}`]: 'firstColumn',
    [`roas_${model2}`]: 'secondColumn',
    [`roas_${model3}`]: 'thirdColumn',
    [`arpu_${model1}`]: 'firstColumn',
    [`arpu_${model2}`]: 'secondColumn',
    [`arpu_${model3}`]: 'thirdColumn',
  });

  let responseText;

  if (windowWidth < 1281) {
    responseText = windowHeight * 0.025
  } else if (windowWidth > 2000) {
    responseText = windowHeight * 0.018
  } else { responseText = windowHeight * 0.017 }

  let responseRow;

  if (windowWidth < 1281) {
    responseRow = windowHeight * 0.05
  } else if (windowWidth > 2000) {
    responseRow = windowHeight * 0.05
  } else { responseRow = windowHeight * 0.04 }


  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
    [dimension]: true,
    impressions: true,
    clicks: true,
    ctr: true,
    spend: true,
    cpc: true,
    regs: true,
    [model1]: true,
    [model2]: true,
    [model3]: true,
  });

  const valueFormat = (value, field) => {
    if (field === dimension || field === dimension || field === 'impressions' || field === 'clicks' || field === 'regs')  {
      return value
    } else if (field === 'cpa' || field === 'spend' || field.includes("ngr") || field.includes("arpu") || field === 'cpc') {
      return `€${value}`;
    } else if (field === 'ctr' || metric.includes('roas')) {
      const percent = (value); // Convert to percent and round to 2 decimal places
      return `${percent}%`;
    } else if (metric.includes('cpa') || metric.includes('spend') || metric.includes("ngr") || metric.includes("arpu")) {
      return `€${value}`;
    } else if (field === 'spend_rate') {
      const percent = (value * 100).toFixed(0); // Convert to percent and round to 2 decimal places
      return `${percent}%`;
    }
    return value;
  };

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Electrolize']
      }
    });
  }, []);

  let uniqueIdCounter = 1;

  // Use forEach to add a unique ID to each record
  dataProp.forEach((record) => {
    record.id = uniqueIdCounter++;
  });

  const labelObject = dataProp[0] || []

  const labels = Object.keys(labelObject).map((key, index) => {

    let newLabel;

    if (key.includes('ftd')) {
      newLabel = key.replace('ftds', currentMetrics.primaryMetric)
    } else if (key.includes('reg')) {
      newLabel = key.replace('regs', currentMetrics.secondaryMetric)
    } else {newLabel = key}

    return {
      field: key,
      headerName: newLabel,
      //minWidth: windowWidth * columnWidth * 1.25,
      flex: 1,
      headerAlign: 'center',
      headerClassName: 'table-header',
      align: 'center',
      type: 'number',
      renderCell: (params) => {
        const { value, field } = params;
        const heatmapColor = calculateHeatmapColor(value, { field });
        if (index < 1) {
          return (
            <div
              className="heatmap-cell"
              style={{
                backgroundColor: heatmapColor,
                padding: '7%',
                color: theme.color,
                width: '100%',
                fontSize: responseText * 0.8
              }}
            >
              {valueFormat(params.formattedValue, params.field)}
            </div>
          );
        } else if (params.aggregation) {
          return (
            <div
              className="heatmap-cell-aggregated"
              style={{
                fontWeight: 'bold', // Add your custom font weight
                display: 'flex', // Apply flex styles to center content vertically/horizontally
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%', // Ensure the cell takes up the full height
                width: '100%',
                color: theme.color,
                paddingLeft: 0,
                fontFamily: 'Electrolize',
                fontSize: responseText * 0.8,
              }}
            >
              {valueFormat(params.formattedValue, params.field)}
            </div>
          );
        } else return (
          <div
            className="heatmap-cell"
            style={{
              backgroundColor: heatmapColor,
              padding: '10%',
              width: '100%',
              fontSize: responseText * 0.8

            }}
          >
            {valueFormat(params.formattedValue, params.field)}
          </div>
        );
      },

    };
  });

  const columnRanges = {};
  Object.keys(labelObject).forEach((column) => {
    const values = dataProp.map((record) => record[column]);
    const min = Math.min(...values);
    const max = Math.max(...values);
    columnRanges[column] = { min, max };
  });

  function calculateHeatmapColor(value, column) {
    const { min, max } = columnRanges[column.field];
    const range = max + 1 - min;

    // Define input colors for the heatmap in RGB format
    const fromColor = { r: theme.comboStopColor1ARRAY[0], g: theme.comboStopColor1ARRAY[1], b: theme.comboStopColor1ARRAY[2] };
    const toColor = { r: theme.comboStopColor2ARRAY[0], g: theme.comboStopColor2ARRAY[1], b: theme.comboStopColor2ARRAY[2] };;

    const relativeValue = (value - min) / range;
    const red = Math.round((1 - relativeValue) * fromColor.r + relativeValue * toColor.r);
    const green = Math.round((1 - relativeValue) * fromColor.g + relativeValue * toColor.g);
    const blue = Math.round((1 - relativeValue) * fromColor.b + relativeValue * toColor.b);

    return `rgba(${red},${green},${blue},0.9)`;
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ color: theme.color }}>
        <GridToolbarColumnsButton sx={{ color: theme.color }} />
        <GridToolbarFilterButton sx={{ color: theme.color }} />
        <GridToolbarDensitySelector sx={{ color: theme.color }} />
        <GridToolbarExport sx={{ color: theme.color }} />
      </GridToolbarContainer>
    );
  }

  function calculateCPC() {
    const cpc = enhancedTotals.spend / enhancedTotals.clicks
    return parseFloat(cpc.toFixed(2))
  }

  function calculateCTR() {
    const ctr = (enhancedTotals.clicks / enhancedTotals.impressions)
    console.log(ctr)
    return ctr * 100
  }


  function calculateTotal(spend, ftd) {
    return "TOTAL"
  }


  const aggFirstColumn = (model1, enhancedTotals, metric) => {

    const model1Value = `${metric}_${model1}`
    const ftdValue = `ftds_${model1}`
    const ngrValue = `ngr_${model1}`

    if (metric === 'ftds' || metric === 'ngr') {
      return enhancedTotals[model1Value]
    } else if (metric === 'cpa') {
      const cpa = enhancedTotals.spend / enhancedTotals[ftdValue]
      return cpa
    } else if (metric === 'roas') {
      const roas = enhancedTotals[ngrValue] / enhancedTotals.spend
      return roas * 100
    } else if (metric === 'arpu') {
      const arpu = enhancedTotals[ngrValue] / enhancedTotals[ftdValue]
      return arpu
    } else {
      return enhancedTotals[model1Value]
    }
  }

  const aggSecondColumn = (model2, enhancedTotals, metric) => {

    const model2Value = `${metric}_${model2}`
    const ftdValue = `ftds_${model2}`
    const ngrValue = `ngr_${model2}`

    if (metric === 'ftds' || metric === 'ngr') {
      return enhancedTotals[model2Value]
    } else if (metric === 'cpa') {
      const cpa = enhancedTotals.spend / enhancedTotals[ftdValue]
      return cpa
    } else if (metric === 'roas') {
      const roas = enhancedTotals[ngrValue] / enhancedTotals.spend
      return roas * 100
    } else if (metric === 'arpu') {
      const arpu = enhancedTotals[ngrValue] / enhancedTotals[ftdValue]
      return arpu
    } else {
      return enhancedTotals[model2Value]
    }
  }

  const aggThirdColumn = (model3, enhancedTotals, metric) => {

    const model3Value = `${metric}_${model3}`
    const ftdValue = `ftds_${model3}`
    const ngrValue = `ngr_${model3}`

    if (metric === 'ftds' || metric === 'ngr') {
      return enhancedTotals[model3Value]
    } else if (metric === 'cpa') {
      const cpa = enhancedTotals.spend / enhancedTotals[ftdValue]
      return cpa
    } else if (metric === 'roas') {
      const roas = enhancedTotals[ngrValue] / enhancedTotals.spend
      return roas * 100
    } else if (metric === 'arpu') {
      const arpu = enhancedTotals[ngrValue] / enhancedTotals[ftdValue]
      return arpu
    } else {
      return enhancedTotals[model3Value]
    }
  }

  const cpc = {
    label: '',
    getCellValue: ({ row }) => ({ spend: row.spend, ftd: row.total_conv }),
    apply: ({ values }) => {

      const returnValue = calculateCPC()

      return returnValue;
    },
    columnTypes: ['number'],
  };

  const ctr = {
    label: '',
    getCellValue: ({ row }) => ({ spend: row.spend, ftd: row.total_conv }),
    apply: ({ values }) => {

      const returnValue = calculateCTR()

      return returnValue;
    },
    columnTypes: ['number'],
  };
  
  const firstColumn = {
    label: '',
    getCellValue: ({ row }) => ({ spend: row.spend, ftd: row.total_conv }),
    apply: ({ values }) => {

      const returnValue = aggFirstColumn(model1, enhancedTotals, metric);


      return returnValue;
    },
    columnTypes: ['number'],
  };

  const secondColumn = {
    label: '',
    getCellValue: ({ row }) => ({ spend: row.spend, ftd: row.total_conv }),
    apply: ({ values }) => {

      const returnValue = aggSecondColumn(model2, enhancedTotals, metric);

      return returnValue;
    },
    columnTypes: ['number'],
  };

  const thirdColumn = {
    label: '',
    getCellValue: ({ row }) => ({ spend: row.spend, ftd: row.total_conv }),
    apply: ({ values }) => {

      const returnValue = aggThirdColumn(model3, enhancedTotals, metric);

      return returnValue;
    },
    columnTypes: ['number'],
  };



  const total = {
    label: '',
    getCellValue: ({ row }) => ({ spend: row.spend, ftd: row.ftds }),
    apply: ({ values }) => {
      let spend = 0;
      let ftd = 0;
      values.forEach((value) => {
        if (value) {
          spend += value.spend || 0; // Adds 0 if value.spend is null or NaN
          ftd += value.ftd || 0;
        }
      });
      return calculateTotal(spend, ftd);
    },
    columnTypes: ['number'],
  };

  const largeRows = ((windowHeight / 60).toFixed(0))

  return (
    <Box
      style={{
        width: '100%',
        height: windowHeight * 0.82,
        fontSize: 50,
        color: 'black',
        fontFamily: 'Electrolize',


      }}
      sx={{
        '& .table-header': {
          headerAlign: 'center',
          align: 'center',
          fontFamily: 'Electrolize',
          color: theme.color,
          fontSize: responseText,
        },
        '& .MuiDataGrid-row': { backgroundColor: theme.backgroundColor },
        '& .MuiTablePagination-root': { color: theme.color },
        width: '100%'
      }}
    >
      <DataGridPremium
        rows={dataProp}
        columns={labels}
        rowHeight={responseRow}
        slots={{ toolbar: CustomToolbar }}
        pagination
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        aggregationModel={aggregationModel}
        onAggregationModelChange={(newModel) => setAggregationModel(newModel)}
        aggregationFunctions={{
          ...GRID_AGGREGATION_FUNCTIONS,
          ctr,
          cpc,
          total,
          firstColumn,
          secondColumn,
          thirdColumn
        }}
        initialState={{
          pagination: {
            paginationModel: { pageSize: parseInt(largeRows) }
          },
          sorting: {
            sortModel: [{ field: 'spend', sort: 'desc' }],
          },
        }}

      />
    </Box>
  );
}