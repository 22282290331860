import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
        } from 'react-native'
import FilterPanel from '../../Components/FilterPanel';
import DashGraph from './DashGraph';
import DashMetrics from './DashMetrics';
import DashGraphChild from './DashGraphChild';


const windowWidth = Dimensions.get('window').width;
const responsiveTextSmall = windowWidth * 0.01

const DashTemplate3 = (props) => {

    const theme = props.theme
    const [metricVisible, setMetricVisible] = useState(false)
    const [updateState, setUpdateState] = useState(null)
    const currentMetrics = props.currentMetrics
    const dashConfig = props.dashConfig
    const setDashConfig = props.setDashConfig
    const uniqueBrands = props.uniqueBrands
    const uniqueChannels = props.uniqueChannels
    const uniqueDimensions = props.uniqueDimensions
    const uniqueMetrics = props.uniqueMetrics
    const uniqueTypes = props.uniqueTypes
    const propObject = props.propObject
    const handleStateChange = props.handleStateChange
    const currentSettings = props.currentSettings
    const childGraphHandler = props.childGraphHandler
    const mainGraphHandler = props.mainGraphHandler
    const modalVisible = props.modalVisible
    const setModalVisible = props.setModalVisible
    const currentDash = props.currentDash
    const reportIndex = props.reportIndex
    const mainAgg = props.mainAgg
    const childAgg1 = props.childAgg1
    const childAgg2 = props.childAgg2
    const childAgg3 = props.childAgg3
    const childAgg5 = props.childAgg5
    const childAgg6 = props.childAgg6
    const panelAgg1 = props.panelAgg1
    const panelAgg2 = props.panelAgg2
    const panelAgg3 = props.panelAgg3
    const panelAgg4 = props.panelAgg4
    const panelAgg5 = props.panelAgg5
    const panelAgg6 = props.panelAgg6
    const panel1 = dashConfig[reportIndex][currentDash].panel1
    const panel2 = dashConfig[reportIndex][currentDash].panel2
    const panel3 = dashConfig[reportIndex][currentDash].panel3
    const panel4 = dashConfig[reportIndex][currentDash].panel4
    const panel5 = dashConfig[reportIndex][currentDash].panel5
    const panel6 = dashConfig[reportIndex][currentDash].panel6

    return (
        <View style={styles.container}>
            <View style={styles.topRow}>
                <View style={styles.graphTopRow}>
                    <View style={{ width: '100%', flexDirection: 'flex-start', fontFamily: 'Electrolize' }}>
                        <FilterPanel
                            style={{
                                height: '10%',
                                borderColor: 'red',
                                borderWidth: 0,
                                width: '100%',
                                fontFamily: 'Electrolize'
                            }}
                            uniqueBrands={uniqueBrands}
                            uniqueChannels={uniqueChannels}
                            uniqueTypes={uniqueTypes}
                            handleStateChange={handleStateChange}
                            propObject={propObject}
                            theme={theme}
                            setModalVisible={setModalVisible}
                            modalVisible={modalVisible}
                        />
                    </View>
                    <DashGraph
                        theme={theme}
                        setDashConfig={setDashConfig}
                        mainAgg={mainAgg}
                        currentSettings={currentSettings}
                        childGraphHandler={childGraphHandler}
                        mainGraphHandler={mainGraphHandler}
                        currentDash={currentDash}
                        reportIndex={reportIndex}
                        dashConfig={dashConfig}
                        uniqueDimensions={uniqueDimensions}
                        uniqueMetrics={uniqueMetrics}
                        currentMetrics={currentMetrics}

                        />

                </View>
                <View style={styles.panelContainer}>
                    <View style={styles.panelContainerRow}>
                    <DashGraphChild
                        theme={theme}
                        setDashConfig={setDashConfig}
                        mainAgg={childAgg5}
                        currentChild={'5'}
                        currentSettings={currentSettings}
                        childGraphHandler={childGraphHandler}
                        mainGraphHandler={mainGraphHandler}
                        currentDash={currentDash}
                        reportIndex={reportIndex}
                        dashConfig={dashConfig}
                        uniqueDimensions={uniqueDimensions}
                        uniqueMetrics={uniqueMetrics}
                        currentMetrics={currentMetrics}
                        />
                    </View>
                    <View style={styles.panelContainerRow}>
                    <DashGraphChild
                        theme={theme}
                        setDashConfig={setDashConfig}
                        mainAgg={childAgg6}
                        currentChild={'6'}
                        currentSettings={currentSettings}
                        childGraphHandler={childGraphHandler}
                        mainGraphHandler={mainGraphHandler}
                        currentDash={currentDash}
                        reportIndex={reportIndex}
                        dashConfig={dashConfig}
                        uniqueDimensions={uniqueDimensions}
                        uniqueMetrics={uniqueMetrics}
                        currentMetrics={currentMetrics}
                        />
                    </View>



                </View>

            </View>
            <View style={styles.bottomRow}>
                <View style={styles.bottomRowPanel}>
                <DashGraphChild
                        theme={theme}
                        setDashConfig={setDashConfig}
                        mainAgg={childAgg1}
                        currentChild={'1'}
                        currentSettings={currentSettings}
                        childGraphHandler={childGraphHandler}
                        mainGraphHandler={mainGraphHandler}
                        currentDash={currentDash}
                        reportIndex={reportIndex}
                        dashConfig={dashConfig}
                        uniqueDimensions={uniqueDimensions}
                        uniqueMetrics={uniqueMetrics}
                        currentMetrics={currentMetrics}
                        />

                </View>
                <View style={styles.bottomRowPanel}>
                <DashGraphChild
                        theme={theme}
                        setDashConfig={setDashConfig}
                        mainAgg={childAgg2}
                        currentChild={'2'}
                        currentSettings={currentSettings}
                        childGraphHandler={childGraphHandler}
                        mainGraphHandler={mainGraphHandler}
                        currentDash={currentDash}
                        reportIndex={reportIndex}
                        dashConfig={dashConfig}
                        uniqueDimensions={uniqueDimensions}
                        uniqueMetrics={uniqueMetrics}
                        currentMetrics={currentMetrics}
                        />
                </View>
                <View style={styles.bottomRowPanel}>
                <DashGraphChild
                        theme={theme}
                        setDashConfig={setDashConfig}
                        mainAgg={childAgg3}
                        currentChild={'3'}
                        currentSettings={currentSettings}
                        childGraphHandler={childGraphHandler}
                        mainGraphHandler={mainGraphHandler}
                        currentDash={currentDash}
                        reportIndex={reportIndex}
                        dashConfig={dashConfig}
                        uniqueDimensions={uniqueDimensions}
                        uniqueMetrics={uniqueMetrics}
                        currentMetrics={currentMetrics}
                        />
                </View>

            </View>

        </View>
    )

}

export default DashTemplate3

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'red',
        height: '92.5%',
        width: '100%',
        fontFamily: 'Electrolize',
    },

    topRow: {
        width: '100%',
        height: '65%',
        flexDirection: 'row',

    },
    bottomRow: {
        width: '100%',
        height: '35%',
        flexDirection: 'row',

    },
    graphTopRow: {
        height: '100%',
        width: '66.6666666%',
        borderWidth: 1,
        borderColor: 'grey'

    },
    panelContainer: {
        height: '100%',
        width: '33.333333%',
        flexDirection: 'column',
        borderWidth: 0,
        borderColor: 'blue',
    },
    panelContainerRow: {
        width: '100%',
        height: '50%',
        flexDirection: 'row',
    },
    panelContainerRowItem: {
        width: '50%',
        height: '100%',
        borderWidth: 0,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center'
    },
    topRowPanel: {
        width: '100%',
        height: '33.333333%',
        borderWidth: 1,
        borderColor: 'grey',
    },
    bottomRowPanel: {
        width: '33.333333%',
        height: '100%',
        borderWidth: 1,
        borderColor: 'grey',
    },
})