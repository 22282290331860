export default function AttModels (models, data, dynamicFilter) {    

    const inputModel1 = models.model1
    const inputModel2 = models.model2
    const inputModel3 = models.model3

    const dimensions = [dynamicFilter];
    const metrics = ['impressions', "clicks", "spend", "regs_ft_lifetime", inputModel1, 'deposits'];

    
    function filterData(data, dimensions, metrics) {
        return data.map(item => {
            const filteredItem = { channel: item.channel };
            Object.entries(item).forEach(([key, value]) => {
                if (dimensions.includes(key) || metrics.some(metric => key.includes(metric))) {
                    filteredItem[key] = value;
                }
            });
            return filteredItem;
        });
    }

    const filteredData = filterData(data, dimensions, metrics);
    return filteredData

}