import React, { useState } from 'react';
import { View , StyleSheet, Dimensions} from 'react-native'
import AppBarRN from '../Components/AppBarRN';
import TemporaryDrawer from "../Components/Drawer";
import ChatContainer from '../Components/ChatContainer';
import ChatInstructions from '../ChatGPT/ChatInstructions'

const windowHeight = Dimensions.get('window').height;

const Chat = (props) => {

    const theme = props.theme
    const configArray = props.configArray
    const configHandler = configArray.configHandler
    const clientHandler = configArray.clientHandler
    const clientID = configArray.clientID
    console.log(clientID)

    const stateBase = {
        top: false,
        left: false,
        bottom: false,
        right: false,
      };

    const [state, setState] = useState(stateBase);
    const anchor = 'left'

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setState({ ...state, [anchor]: open });

      };

    function handleDrawer (anchor) {

        const stateChange = {
            top: false,
            left: true,
            bottom: false,
            right: false,
          };
          setState(stateChange)
    
    }

    return (
      <View style={[styles.container, {backgroundColor: theme.backgroundColor}]}>
        <AppBarRN 
            handleDrawer={handleDrawer}
            homeTitle={"Chat Analysis"}
            theme={theme}
            configHandler={configHandler}
            clientHandler={clientHandler}
            client={clientID}
        />
        <TemporaryDrawer 
            toggleDrawer={toggleDrawer}
            state={state}
            anchor={anchor}
            theme={theme}
            clientID={clientID}
        />
        <ChatInstructions
          theme={theme}
          clientID={clientID}
        />
        <ChatContainer
          theme={theme}
          clientID={clientID}
          
        />
        </View>
    )

}

const styles = StyleSheet.create({
    container: {
      borderWidth: 0,
      borderColor: 'red',
      paddingTop: 0,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      width: '100%',
      height: '100%',  
      minHeight: windowHeight,

      marginBottom: '0%'
    },

})

export default Chat