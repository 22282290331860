import React, { useState } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Pressable,
    Text
} from 'react-native'
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import LineChart from '../ChartsDesktop/LineChart';
import CumulativeMetricModal from './CumulativeMetricModal';

const windowWidth = Dimensions.get('window').width;


const CumulativeGraph = (props) => {

    const theme = props.theme
    const dynamicData = props.dynamicData
    const setIsCumulative = props.setIsCumulative
    const metricList = props.metricList
    const currentMetrics = props.currentMetrics
    const dateLabels = props.dateLabels
    const client = props.client
    const [checked, setChecked] = React.useState(true);
    const [metric, setMetric] = useState('cpa')
    const [metricModalOpen, setMetricModalOpen] = useState(false)

    const handleChange = (event) => {
        setChecked(event.target.checked);
        setIsCumulative(event.target.checked)
      };

    const headerToggleText = {
        fontFamily: 'Electrolize',
        fontSize: windowWidth * 0.0075,
        fontWeight: 'bold',
        color: theme.color
    }

    const handleMetric = () => {
        setMetricModalOpen(true)
      };

      let topValue, rightValue;

      if (windowWidth > 1800) {
          topValue = "1.5%"
          rightValue = "1%"
      } else {
          topValue = "0%"
          rightValue = "1%"
      }

      const headerRow = {
        width: '100%',
        height: '6%',
        borderWidth: 0,
        borderColor: 'grey',
        flexDirection: 'row',
        justifyContent: 'right',
        position: 'absolute',
        right: rightValue,
        top: topValue,
        zIndex: 100000,
    }
      

    return (
        <View style={styles.container}>
            <View style={headerRow}>
                <View style={styles.headerToggle}>
                    <View style={styles.headerToggleText}>
                        <Text style={headerToggleText}>
                            Cumulative:
                        </Text>
                    </View>
                    <View style={styles.headerToggleContainer}>
                    <Switch
                        checked={checked}
                        onChange={handleChange}
                        sx={{
                            color: 'red'
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                        />

                    </View>


                </View>
                <View style={styles.headerMetric}>
                    <Button 
                        sx={{
                            backgroundColor: theme.backgroundColor2,
                            fontFamily: 'Electrolize',
                            width: '100%',
                            height: '75%',
                            fontSize: windowWidth * 0.008
                        }}
                        onClick={handleMetric}
                        variant="contained">
                        Metric
                    </Button>

                </View>

            </View>
            <View style={styles.graphRow}>
                <LineChart
                    agg_daily_result={dynamicData}
                    dateLabels={dateLabels}
                    theme={theme}
                    metric={metric}
                    timeFrame={'daily'}
                    client={client}
                    currentMetrics={currentMetrics}
                    large={true}
                    />

            </View>
            <CumulativeMetricModal
                theme={theme}
                open={metricModalOpen}
                setOpen={setMetricModalOpen}
                reportList={metricList}
                setMetric={setMetric}
                currentMetrics={currentMetrics}
            />

        </View>
    )

}

export default CumulativeGraph

const styles = StyleSheet.create({
    container: {
        
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize'
    },
    headerRow: {
        width: '100%',
        height: '6%',
        borderWidth: 0,
        borderColor: 'grey',
        flexDirection: 'row',
        justifyContent: 'right'
    },
    graphRow: {
        width: '100%',
        height: '93%',
        padding: '1%'
    },
    headerToggle: {
        width: '10%',
        height: '100%',
        borderWidth: 0,
        borderColor: 'yellow',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row'
    },
    headerToggleContainer: {
        height: '100%',
        width: '40%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headerToggleText: {
        height: '100%',
        width: '60%',
        borderWidth: 0,
        borderColor: 'red',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headerMetric: {
        width: '10%',
        height: '100%',
        borderWidth: 0,
        borderColor: 'yellow',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0.5%'
    },

})