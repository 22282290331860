import React, { useEffect} from 'react';
import { Line } from 'react-chartjs-2';
import WebFont from 'webfontloader';
import { Dimensions } from 'react-native'

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const LineYears = (props) => {

    const currentData = props.agg_daily_result
    const metric = props.metric
    const theme = props.theme
    const client = props.client
    const currentMetrics = props.currentMetrics
    

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Electrolize']
            }
        });
    }, []);

    // Step 2: Create datasets based on the filtered data for each year
    const uniqueYears = [...new Set(currentData.map((item) => item.year))];
    const uniqueMonths = [...new Set(currentData.map((item) => item.month))];
    // Sort uniqueMonths in ascending order
    uniqueMonths.sort((a, b) => {
        // Assuming months are represented as strings "01", "02", ..., "12"
        return parseInt(a) - parseInt(b);
    });

    const predefinedMetric = [metric]; // Define your metrics

    function generateMonthsArray(year, data) {
        const monthsArray = [];
        for (let i = 1; i <= 12; i++) {
            const month = i.toString().padStart(2, '0'); // Ensure 2-digit month format
            const matchingItem = data.find((item) => item.year === year && item.month === month);
            const metricValue = matchingItem ? matchingItem[predefinedMetric] : 0;
            monthsArray.push({ month, [predefinedMetric]: metricValue });
        }
        return monthsArray;
    }

    // Create an array to store the results for each year
    const filteredData = uniqueYears.map((year) => {
        const yearData = currentData.filter((item) => item.year === year);
        return generateMonthsArray(year, yearData);
    });

    const colors = [
        theme.gradc80,
        theme.backgroundColor2,
        theme.panelTextColor,
        'rgba(255, 205, 86, 0.5)',
        // Add more colors as needed
    ];

    const datasets = filteredData.map((yearData, index) => {
        const colorIndex = index % colors.length; // Use modulo to cycle through the colors array
        const borderColor = colors[colorIndex];
        const backgroundColor = borderColor.replace(')', ', 0.5)').replace('rgb', 'rgba'); // Add '30' to the background color
        const yearLabel = parseInt(uniqueYears[0]) + index; // Assuming the year is the same for all records in a year        
        const fillState = () => {
        if (yearLabel.toString() === "2022") {
            return false;
        } else {
            return true;
        }
        };

        const chartType = () => {
            if (yearLabel.toString() === "2022") {
                return 'bar';
            } else if (yearLabel.toString() === "2023") {
                return 'bar';
            }  else {
                return 'bar';
            }
            };

        const chartData = yearData.map((item) => item[predefinedMetric]);
        
        
        return {
            label: yearLabel.toString(), // Set the label to the year
            data: chartData,
            fill: fillState,
            borderColor: borderColor,
            backgroundColor: backgroundColor,
            lineTension: 0.5,
            radius: 1,
            type: chartType()
        };
    });

    return (

        <LineChart data={datasets} theme={theme} dateLabels={uniqueMonths} metric={metric} client={client} currentMetrics={currentMetrics}/>

    )

}

const LineChart = ({ data, metric, theme, dateLabels, client, currentMetrics }) => {
    // Assuming you have grouped data in the 'groupedData' variable
    // Prepare data for the chart based on the selected metric
    // Step 3: Create the chart data object
    let convLabel

    if (metric === 'ftds') {
        convLabel = currentMetrics.primaryMetric
      } else if (metric === 'ngr') {
        convLabel = currentMetrics.primaryRevenue
      } else if (metric === 'regs') {
        convLabel = currentMetrics.secondaryMetric
      } else {convLabel = metric}

    let responseText;

    if (windowWidth < 1281) {
        responseText = windowHeight * 0.025
    } else if (windowWidth > 2000) {
        responseText = windowHeight * 0.01
    } else {responseText = windowHeight * 0.017}

    const chartData = {

        labels: dateLabels,
        datasets: data,
    };


    // Define chart options
    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend : {
                labels : {
                    color: theme.color,
                    font: {
                        size: responseText, // Set the font size for y-axis labels
                        weight: 'bold', // Set the font weight for y-axis labels
                        family : 'Electrolize',
                      },
                }
            }
        },
        scales: {
            x: {
                type: 'linear',
                position: 'bottom',
                grid: {
                    display: false, // Remove the gridlines on the y-axis
                  },
                title: {
                    display: true,
                    text: 'Month',
                    color : theme.color,
                    font: {
                        size: responseText, // Set the font size for y-axis labels
                        weight: 'bold', // Set the font weight for y-axis labels
                        family : 'Electrolize',
                      },
                },
                ticks: {
                    color : theme.color,
                    stepSize: 1,
                    font: {
                      size: responseText, // Set the font size for y-axis labels
                      weight: 'bold', // Set the font weight for y-axis labels
                      family : 'Electrolize',
                    },
                    
                  },
                min: 1
            },
            y: {
                title: {
                    display: true,
                    text: convLabel,
                    color : theme.color,
                    font: {
                        size: responseText, // Set the font size for y-axis labels
                        weight: 'bold', // Set the font weight for y-axis labels
                        family : 'Electrolize',
                      },
                },
                grid: {
                    display: false, // Remove the gridlines on the y-axis
                  },
                ticks: {
                    color : theme.color,
                    font: {
                      size: 10, // Set the font size for y-axis labels
                      weight: 'bold', // Set the font weight for y-axis labels
                      family : 'Electrolize',
                    },
                  },
            },
        },
    };
    return <Line data={chartData} options={chartOptions} />

};

export default LineYears