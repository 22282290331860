import React, { useEffect } from 'react';
import { Vortex, ThreeDots } from  'react-loader-spinner'
import { View , StyleSheet , Dimensions, Text} from 'react-native'
import WebFont from 'webfontloader';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const LoadingComponent = (props) => {

    const theme = props.theme

    let responseText;

    if (windowWidth < 1281) {
        responseText = windowHeight * 0.04
    } else if (windowWidth > 2000) {
        responseText = windowHeight * 0.035
    } else {responseText = windowHeight * 0.035}

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    return (
        <View style={[
            styles.container,
            {backgroundColor: theme.backgroundColor}]
            }>
            <VortexComponent 
                theme={theme}
            />
            <Text style={[styles.text, {color: theme.color, fontSize: responseText}]}>Loading...</Text>
        </View>
    )

}

const VortexComponent = (props) => {

    const theme = props.theme
    const loaderHeight = windowHeight * 0.2

    let widthPercentage = `${loaderHeight}px`

    return (
        <ThreeDots
            visible={true}
            height={widthPercentage}
            width={widthPercentage}
            ariaLabel="vortex-loading"
            wrapperStyle={{}}
            wrapperClass="vortex-wrapper"
            //colors={[theme.gradc10, theme.gradc30, theme.gradc50, theme.gradc70, theme.gradc80, theme.gradc90 ]}
            radius="5"
            color={[theme.backgroundColor2 ]}
            />
    )
}

const styles = StyleSheet.create({
    container: {
      borderWidth: 0,
      borderColor: 'blue',
      paddingTop: 0,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: windowHeight,
      minWidth: windowWidth,
      height: '100%',
      width: '100%'

    },
    loaderContainer: {
        borderWidth: 0,
        borderColor: 'blue',
    },
    text: {
        fontFamily: 'Electrolize',
        fontSize: 30
    }
})

export default LoadingComponent

