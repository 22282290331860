import React, { useState} from 'react';
import { View, StyleSheet } from 'react-native'
import AppBarRN from '../Components/AppBarRN';
import TemporaryDrawer from "../Components/Drawer";
import dashConfigOriginal from '../DashBuilder/dashConfig.json'
import moment from 'moment-timezone';
import { useCubeMeta } from '@cubejs-client/react';
import { useCubeQuery } from '@cubejs-client/react';
import CubeDailyFunction from '../DailySummary/CubeFunctions/CubeDailyFunction';
import DashPanel from '../DashBuilder/DashScreen/DashPanel';
import LoadingComponent from '../Components/Loader'

const DashCustom = (props) => {

  const configArray = props.configArray
  const currentMetrics = configArray.currentMetrics[0]
  const [dashConfigBase, setTheme] = useState(JSON.parse(localStorage.getItem('dashConfig')) ?? dashConfigOriginal)
  const tooltipArray = configArray.tooltipArray
  const dashConfig = dashConfigBase
  const configHandler = configArray.configHandler
  const clientHandler = configArray.clientHandler
  const clientID = configArray.clientID
  const client = clientID.toLowerCase();
  const cubeTable = client + "_daily"
  const cubeMeta = useCubeMeta();
  const theme = props.theme
  const homeTitle = "Dash Builder";
  const role = configArray.role
  const currentDate2 = moment.tz('Europe/Malta');
  const yesterday2 = currentDate2.clone().subtract(1, 'day');
  const yesterdayProp = yesterday2.format('YYYY-MM-DD');
  const [modalVisible, setModalVisible] = useState(false)

  const currentDate = new Date();
  currentDate.setMinutes(0);
  const previousDate = new Date(currentDate);
  previousDate.setDate(currentDate.getDate() - 1);
  previousDate.setDate(1)

  const startDateString = previousDate.toISOString().slice(0, 10);


  const stateBase = {
    top: false,
    left: false,
    bottom: false,
    right: false,
  };

  const [state, setState] = useState(stateBase);
  const anchor = 'left'

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });

  };

  function handleDrawer(anchor) {

    const stateChange = {
      top: false,
      left: true,
      bottom: false,
      right: false,
    };
    setState(stateChange)

  }

  const filterBase = {
    start: startDateString,
    end: yesterdayProp,
    channel: "Total",
    brand: 'Total',
    kw_type: "Total",
  };

  const [filters, setFilters] = useState(filterBase);

  var dataProp = []

  const propObjectBase = {
    data: dataProp,
    start: startDateString,
    end: yesterdayProp,
    theme: theme,
    brand: "Total",
    channel: "Total",
    kw_type: "Total",
  };

  const [propObject, setpropObject] = useState(propObjectBase);

  const handleStateChange = (prop) => {

    setpropObject(prop);

    const filterUpdate = {
      start: prop.start,
      end: prop.end,
      channel: prop.channel,
      brand: prop.brand,
      kw_type: prop.kw_type,
    };

    setFilters(filterUpdate)

  };

  const uniqueDimensionQuery = CubeDailyFunction (cubeTable, startDateString, yesterdayProp, 'channel', 'brand', 'kw_type', []);
  const { resultSet } = useCubeQuery(uniqueDimensionQuery);

  if (!resultSet) {
    return (
      <LoadingComponent theme={theme} />
    );
  }

  if (!cubeMeta.response) {
    return (
      <LoadingComponent theme={theme} />
    );
  }

  const cubeMetaData = cubeMeta.response.cubesMap[cubeTable]
  const cubeMeasures = Object.keys(cubeMetaData.measures)
  const cubeResponse = resultSet.loadResponse.results[0].data

  const uniqueDimensions = ['date', 'brand', 'channel', 'kw_type']

  const updateVar = `${cubeTable}.updated_at`
  const lastUpdated = cubeResponse[0][updateVar] || 'loading...'

  const uniqueMeasures = cubeMeasures.map((dimensionName) => {
    const parts = dimensionName.split(".");
    if (parts.length === 2) {
      return parts[1]; // Get the value after the dot
    } else {
      return dimensionName; // Keep the original value if there's no dot
    }
  });


  const getUniqueValues = (arr, key) => {
    const uniqueSet = new Set(arr.map(item => item[key]));
    return [...uniqueSet];
  };

  const brandRef = `${cubeTable}.brand`
  const channelRef = `${cubeTable}.channel`
  const typeRef = `${cubeTable}.kw_type`

  const uniqueBrands = getUniqueValues(cubeResponse, brandRef);
  const uniqueChannels = getUniqueValues(cubeResponse, channelRef);
  const uniqueTypes = getUniqueValues(cubeResponse, typeRef);  

  return (
    <View style={[styles.container, { backgroundColor: theme.backgroundColor }]}>
      <AppBarRN
        handleDrawer={handleDrawer}
        homeTitle={homeTitle}
        theme={theme}
        configHandler={configHandler}
        clientHandler={clientHandler}
        role={role}
        setModalVisible={setModalVisible}
        client={clientID}
        lastUpdated={lastUpdated}
      />
      <TemporaryDrawer
        toggleDrawer={toggleDrawer}
        state={state}
        anchor={anchor}
        theme={theme}
        clientID={clientID}
        setTheme={setTheme}
      />
      <DashPanel
        theme={theme}
        uniqueBrands={uniqueBrands}
        uniqueChannels={uniqueChannels}
        uniqueTypes={uniqueTypes}
        cubeTable={cubeTable}
        dashConfig={dashConfig}
        handleStateChange={handleStateChange}
        propObject={propObject}
        filters={filters}
        uniqueDimensions={uniqueDimensions}
        uniqueMeasures={uniqueMeasures}
        setModalVisible={setModalVisible}
        modalVisible={modalVisible}
        tooltipArray={tooltipArray}
        currentMetrics={currentMetrics}
        />

    </View>

  )
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 0,
    borderColor: 'yellow',
    paddingTop: 0,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    flex: 1,
  },
  panel: {
    width: '100%'
  }
})

export default DashCustom