import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Dimensions, View } from 'react-native'
import { useState } from 'react'

import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FlexModalTextBox from './FlexModalTextBox';

const windowWidth = Dimensions.get('window').width;

export default function FlexModalSave(props) {
  const open = props.open
  const setOpen = props.setOpen
  const reportList = props.reportList
  const theme = props.theme
  const setCurrentReportName = props.setCurrentReportName
  const currentReportName = props.currentReportName
  const reportNameFallback = props.reportNameFallback
  const [reportName, setReportNameBase] = useState(currentReportName)
  const currentSettings = props.currentSettings
  const currentReportConfig = props.currentReportConfig
  const [textValue, setTextValue] = useState('')
  const [selectValue, setSelectValue] = useState(currentReportName)
  const saveReport = props.saveReport

  function reportMapping(data) {
    // Initialize an object to hold the result
    let result = {};
  
    // Iterate over each object in the provided array
    data.forEach((item, index) => {
        // Extract the key (identifier) from each object
        Object.keys(item).forEach(key => {
            // Add the key and its index to the result object
            result[key] = index;
        });
    });
  
    // Return the result object
    return result;
  }

  const reportMap = reportMapping(currentReportConfig)
  


  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
    //console.log(reason)
  };

  function handleSave () {
    setCurrentReportName(textValue)
    localStorage.setItem('lastFlex', textValue)
    const reportArray = {
        reportName: textValue,
        currentSettings,
        reportMap
    }
    saveReport(reportArray)
    setOpen(false);
  }

  function filterExcludes(inputArray, filterArray) {
    const filterSet = new Set(filterArray);
    return inputArray.filter(item => !filterSet.has(item));
}

  const excludeDefault = ['default']
  const filteredReportList = filterExcludes(reportList, excludeDefault)


  const extendReportList = [...filteredReportList, 'Create New']

  function handleSelect (value) {

    setSelectValue(value)
    setTextValue(value)

  }

  const options = extendReportList.map((item, index) => (
    <MenuItem key={index} value={item}>
      {item}
    </MenuItem>
  ));


  return (
    <View style={{width: windowWidth * 0.8, borderColor: 'red', borderWidth: 0}}>
    <div>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose} maxWidth={1500}>
        <DialogTitle sx={{fontFamily: 'Electrolize'}}>Update or Create Pivot Template</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ 
                                    display: 'flex', 
                                    flexDirection: 'row',
                                    }}>
              <FormControl sx={{ m: 1, minWidth: windowWidth * 0.15,fontFamily: 'Electrolize',}}>
              <InputLabel id="demo-dialog-select-label">Current Templates</InputLabel>
              <Select
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                value={selectValue}
                

                onChange={(event) => handleSelect(event.target.value)}
                input={<OutlinedInput label="Age" />}
              >
                {options}
              </Select>
            </FormControl>
            <FlexModalTextBox
                theme={theme}
                reportNameFallback={reportNameFallback}
                setReportName={setReportNameBase}
                textValue={textValue}
                setTextValue={setTextValue}
            />

          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
    </View>
  );
}