import React, { useEffect, useRef } from 'react';
import { Bar , Line} from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import WebFont from 'webfontloader';
import { Dimensions } from 'react-native'

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const MultipleLineChart = (props) => {
  const chartRef = useRef(null);
  const dataProp = props.dataProp
  const labels = props.labels
  const theme = props.theme
  const metric1 = props.metric1
  const metric2 = props.metric2
  const agg_daily_result = props.agg_daily_result
  const dateLabels = props.dateLabels
  const client = props.client
  let convLabel1

  if (client === 'glitnor' && metric1 === 'ftds') {
    convLabel1 = 'totalConv'
  } else {convLabel1 = metric1}

  let convLabel2

  if (client === 'glitnor' && metric2 === 'ftds') {
    convLabel2 = 'totalConv'
  } else {convLabel2 = metric2}

  let responseText;

  if (windowWidth < 1281) {
    responseText = windowHeight * 0.012
  } else if (windowWidth > 2000) {
    responseText = windowHeight * 0.012
  } else {responseText = windowHeight * 0.012}


  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Electrolize']
      }
    });
   }, []);


  
//console.log(dateLabels)

  const data = {
    labels: labels,
    datasets: [
      {
        type : 'line',
        label: convLabel1,
        data: dataProp,
        yAxisID : 'A',
        borderColor: theme.color,
        lineTension: 0.5,
        radius: 0
      },
      {
        type : 'bar',
        label: convLabel2,
        data: agg_daily_result,
        yAxisID : 'B',
        borderColor: theme.color,
        backgroundColor: theme.backgroundColor2,
        borderRadius: 5,
        lineTension: 0.5,
        radius: 0
      },
    ],
  };

  
const options = {
  maintainAspectRatio: false,
  scales: {
    A: {
      beginAtZero: true,
      type: 'linear',
      position: 'left',
      grid: {
        display: false, // Remove the gridlines on the y-axis
      },
      ticks: {
        color : theme.color,
        font: {
          size: responseText, // Set the font size for y-axis labels
          weight: 'bold', // Set the font weight for y-axis labels
          family : 'Electrolize',
        },
      },
    },
    B: {
        beginAtZero: true,
        type: 'linear',
        position: 'right',
        grid: {
          display: false, // Remove the gridlines on the y-axis
        },
        ticks: {
          color : theme.color,
          font: {
            size: responseText, // Set the font size for y-axis labels
            weight: 'bold', // Set the font weight for y-axis labels
            family : 'Electrolize',
          },
        },
      },
    x: {
      grid: {
        display: false, // Remove the gridlines on the x-axis
      },
      ticks: {
        color : theme.color,
        font: {
          size: responseText, // Set the font size for x-axis labels
          family : 'Electrolize',
          
        },
      },
    },
  },
  tooltips: {
    enabled: true,    
  },
  plugins: {
    legend: {
      display: true,
      text: 'Chart Title',
      labels: {
        color : theme.color,
        // This more specific font property overrides the global property
        font: {
            size: responseText,
            family : 'Electrolize'
        }
    }
    },
    tooltip: {
      enabled: true,
    },
    title: {
      display: false,
      text: 'Chart Title',
      font: {
        size: 24, // Set the font size for the chart title
        weight: 'bold', // Set the font weight for the chart title

      },
    },
  },
};

  return <Bar ref={chartRef} data={data} options={options} style={{fontFamily : 'Electrolize',}} />;
};

export default MultipleLineChart;
