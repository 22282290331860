import React, { useEffect} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import WebFont from 'webfontloader';

const LogoutButton = (props) => {
  const { logout } = useAuth0();
  const theme = props.theme

  useEffect(() => {
    WebFont.load({
        google: {
            families: ['Droid Sans', 'Electrolize']
        }
    });
}, []);

  return (
    <button 
    style={{width: '90%', 
            height: '3%', 
            fontFamily: 'Electrolize', 
            flex: 1,
            backgroundColor: theme.color,
            color: theme.backgroundColor,
            borderColor: theme.backgroundColor,
            borderWidth: '1%'}}
    onClick={() => logout({ logoutParams: { returnTo: 'https://pattern-inc.io/' } })}>
      Log Out
    </button>
  );
};

export default LogoutButton;