import React from 'react';
import { Modal, 
    View, 
    StyleSheet, 
    Dimensions, 
    Pressable } from 'react-native';
import CloseIcon from '@mui/icons-material/Close';
import DndContainerV2 from '../ReactDnD/DndContainerv2';
import DndContainerT2 from '../ReactDnD/DndContainerT2';
import DndContainerT3 from '../ReactDnD/DndContainerT3';
import DndContainerT4 from '../ReactDnD/DndContainerT4';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
const responsiveTextSmall = windowWidth * 0.02


const DashGraphModalV2 = (props) => {

    const theme = props.theme
    const setDashConfig = props.setDashConfig
    const visibleModal = props.visible
    const setVisible = props.setVisible
    const mainGraphHandler = props.mainGraphHandler
    const uniqueMetrics = props.uniqueMetrics
    const uniqueDimensions = props.uniqueDimensions
    const currentReport = props.currentReport
    const reportIndex = props.reportIndex
    const dashConfig = props.dashConfig
    const currentMetrics = props.currentMetrics
    const currentTemplate = dashConfig[reportIndex][currentReport].template
    const currentType = dashConfig[reportIndex][currentReport].type
    const currentAxis = dashConfig[reportIndex][currentReport].xAxis
    const currentTime = dashConfig[reportIndex][currentReport].time
    const currentMetric1 = dashConfig[reportIndex][currentReport].metric1
    const currentMetric2 = dashConfig[reportIndex][currentReport].metric2
    const metricVisible = currentType === 'mixed' || currentType === 'table';

    const renderComponentContent = () => {
        if (currentTemplate === 1) {
            return <DndContainerV2
                theme={theme}
                dashConfig={dashConfig}
                currentReport={currentReport}
                mainGraphHandler={mainGraphHandler}
                setDashConfig={setDashConfig}
                reportIndex={reportIndex}
                currentMetrics={currentMetrics}
            />;
        } else if (currentTemplate === 2) {
            return <DndContainerT2
                        theme={theme}
                        dashConfig={dashConfig}
                        currentReport={currentReport}
                        mainGraphHandler={mainGraphHandler}
                        setDashConfig={setDashConfig}
                        reportIndex={reportIndex}
                        currentMetrics={currentMetrics}
                    />;
        } else if (currentTemplate === 3) {
            return <DndContainerT3
                        theme={theme}
                        dashConfig={dashConfig}
                        currentReport={currentReport}
                        mainGraphHandler={mainGraphHandler}
                        setDashConfig={setDashConfig}
                        reportIndex={reportIndex}
                        currentMetrics={currentMetrics}
                    />;
        }  else if (currentTemplate === 4) {
            return <DndContainerT4
                        theme={theme}
                        dashConfig={dashConfig}
                        currentReport={currentReport}
                        mainGraphHandler={mainGraphHandler}
                        setDashConfig={setDashConfig}
                        reportIndex={reportIndex}
                        currentMetrics={currentMetrics}
                    />;
        } else {
            return null
        }
    }

    return (
        <Modal
            visible={visibleModal}
            style={styles.modalParent}
            transparent={true}
            >
        <Pressable 
            style={styles.modalContainer}
            onPress={() => setVisible(false)}
            >
            <View style={[styles.modalWindow , {
                backgroundColor : theme.backgroundColor,
                borderColor: theme.color
            }]}>
            <CloseIcon
                    style={{
                        position: 'absolute',
                        left: '100%',
                        top: '-4%',
                        fontSize: responsiveTextSmall,
                        color: theme.color,
                        backgroundColor: theme.backgroundColor2,
                        borderRadius: 5,
                        
                    }}
                />

            {renderComponentContent()}

            </View>


        </Pressable>


        </Modal>
    )
}


const styles = StyleSheet.create({
    modalParent: {
        padding: 20,
        borderRadius: 20,
        
    },
    modalContainer: {

        width: windowWidth * 1,
        height: windowHeight * 1,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalWindow: {
        margin: 0,
        height: '93%',
        width: '90%',
        borderWidth: 2,
        borderRadius: 5,
        padding: '1%',
        alignItems: 'center',
        justifyContent: 'center',

    }
});


export default DashGraphModalV2