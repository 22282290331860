import { memo } from 'react'
import { useDrop } from 'react-dnd'
import { View, Text, Dimensions } from 'react-native'

const windowWidth = Dimensions.get('window').width;

export const Dustbin = memo(function Dustbin({
  accept,
  lastDroppedItem,
  onDrop,
  theme,
  currentConfig,
  indexCount,
  chartType,
}) {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const style = {
    color: 'white',
    height: '100%',
    width: '100%',
    textAlign: 'center',
    borderRadius: '5%',
    justifyContent: 'center',
    alignItems: 'center',

    padding: '1%',
  }


  let dynamicText;

  if (chartType === 'main' && windowWidth > 2000) {
    dynamicText = windowWidth * 0.02
  } else if (chartType === 'main' && windowWidth >= 1400) {
    dynamicText = windowWidth * 0.017
  } else if (chartType === 'main' && windowWidth < 1400) {
    dynamicText = windowWidth * 0.02
  } else if (windowWidth > 2000) {
    dynamicText = windowWidth * 0.01
  } else if (windowWidth >= 1400) {
    dynamicText = windowWidth * 0.01
  } else if (windowWidth < 1400) {
    dynamicText = windowWidth * 0.01
  }
    else {dynamicText = 15}


  const textLarge = {
    textAlign: 'center',
    color: 'white',
    fontWeight: 'bold',
    fontFamily: 'Electrolize',
    textTransform: 'capitalize',
    fontSize: dynamicText * 3

  }

  const isActive = isOver && canDrop
  const bucketType = accept[0]
  
  let bucketStyle = style;


  let backgroundColor;
  if (isActive) {
    backgroundColor = theme.backgroundColor2
  } else if (canDrop) {
    backgroundColor = '#7DFA00'
  }  else if (accept[0] === 'type') {
    backgroundColor = theme.backgroundColor2
  } else if (bucketType === 'xAxis') {
    backgroundColor = theme.gradc50
  } else if (bucketType === 'metrics') {
    backgroundColor = theme.color3
  } else {backgroundColor = 'grey' }

  let bucketLabel;
  if (! lastDroppedItem && indexCount === 0) {
    bucketLabel = 'Metric 1'
  } else if (! lastDroppedItem && indexCount === 1) {
    bucketLabel = 'Metric 2'
  } else {bucketLabel = lastDroppedItem.name}


  return (

    <View ref={drop} style={{ ...bucketStyle, backgroundColor }} data-testid="dustbin">
      <Text style={textLarge}>
      {isActive
        ? 'Release to drop'
        : ""}
      </Text>

        <Text style={textLarge}> {bucketLabel}</Text>

    </View>

  )
})
