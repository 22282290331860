export default function PivotPub (cubeFilters, table, start, end) {

    return {
        "timeDimensions": [
          {
            "dimension": `${table}.timestamp`,
            "granularity": "month",
            "dateRange": [
                start,
                end
            ]
          }
        ],
        "timezone": "Europe/Malta",
        "order": [
          [
            `${table}.cost`,
            "desc"
          ]
        ],
        "measures": [
            `${table}.impressions`,
            `${table}.clicks`,
            `${table}.cost`,
            `${table}.regs`,
            `${table}.ftds`,
            `${table}.ngr`
        ],
        "dimensions": [
            `${table}.brand`,
            `${table}.channel`,
            `${table}.kw_type`,
            `${table}.site_url`,
        ],
        "limit": 5000,
        "filters": cubeFilters
      }
    

}