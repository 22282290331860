import React, { useState } from 'react';
import { View, 
    Text, 
    Pressable, 
    Modal,
    StyleSheet,
    Dimensions } from 'react-native'
import Fab from '@mui/material/Fab';
import NavigationIcon from '@mui/icons-material/Navigation';    
import CloseIcon from '@mui/icons-material/Close';
import Export from './Export'

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
const responsiveTextSmall = windowWidth * 0.02

const MetricsPanel = (props) => {

    const theme = props.theme
    const visible = props.visible
    const setMetricsVisible = props.setMetricsVisible
    const config = props.config
    const setConfig = props.setConfig
    const saveHandler = props.saveHandler
    const configHandler = props.configHandler
    const clientID = props.clientID

    const graphPanel = {
        height: '100%',
        width: '100%',
        borderColor: 'red',
        borderWidth: 0,
        flexDirection: 'column',
        padding: '1%',
    }

    const topPanel = {
        height: '90%',
        width: '100%',
        padding: '1%',
        borderColor: 'red',
        borderWidth: 0,
    }

    const fabPanel = {
        height: '10%',
        width: '100%',
        padding: '1%',
        borderColor: 'red',
        borderWidth: 0,
        alignItems: 'center'
    }

    function fabHandler () {
        saveHandler()
    }

    return (
        
        <Modal
            visible={visible}
            style={styles.modalParent}
            transparent={true}
            >
        <View 
            style={styles.modalContainer}

            >
            <View style={[styles.modalWindow , {
                backgroundColor : theme.backgroundColor,
                borderColor: theme.color
            }]}>
                <Pressable style={{
                            position: 'absolute',
                            left: '97.85%',
                            width: 90,
                            height: 45,
                            top: '0%',
                            borderColor: 'red',
                            borderWidth: 0,
                            zIndex: 5000
                        
                        }}
                        onPress={() => setMetricsVisible(false)}>
                            <CloseIcon
                        style={{
                            fontSize: responsiveTextSmall,
                            color: theme.backgroundColor,
                            backgroundColor: 'red',
                            borderRadius: 5,
                            
                        }}
                    />
                </Pressable>

                   <View style={graphPanel}>
                        <View style={topPanel}>
                            <Export
                                theme={theme}
                                currentConfig={config}
                                clientID={clientID}
                                configHandler={configHandler}
                            />
                        </View>
                        <View style={fabPanel}>
                            <View
                                style={{
                                    borderWidth: 0,
                                    borderColor: theme.color,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '20%',
                                    padding: '1%',
                                    height: '100%'
                                }}
                            >
                            <Pressable
                                style={{flex: 1, width: '100%', height: '100%'}}
                                onPress={() => fabHandler()}>
                                <Fab variant="extended"

                                    sx={{
                                        color: 'white',
                                        backgroundColor: theme.backgroundColor2,
                                        fontSize: responsiveTextSmall * 0.5,
                                        width: '100%',
                                    }}>
                                    <NavigationIcon sx={{ mr: 1, fontSize: responsiveTextSmall * 0.5}} />
                                    Save
                                </Fab>

                                </Pressable>
                            </View>
                            
                        </View>

                    </View>
                    

            

            </View>


        </View>


        </Modal>
        )

}

export default MetricsPanel

const styles = StyleSheet.create({
    modalParent: {
        padding: 50,
        borderRadius: 20,
        
    },
    modalContainer: {
        width: windowWidth * 1,
        height: windowHeight * 1,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: 'blue',
        borderWidth: 0,
    },
    modalWindow: {
        marginTop: '0%',
        height: '86%',
        width: '92%',
        borderColor: 'blue',
        borderWidth: 0,
        borderRadius: 10,
        padding: '0%',
        alignItems: 'center',
        justiftContent: 'center',

        }

})