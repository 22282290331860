import Fab from '@mui/material/Fab';
import NavigationIcon from '@mui/icons-material/Navigation';
import { View } from 'react-native'

const ControlDnDFAB = (props) => {

    const buttonText = props.buttonText
    const theme = props.theme
    const dndHandler = props.dndHandler

    return (
        <View
            style={{
                borderWidth: 0,
                borderColor: theme.color,
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                padding: '1%',
                height: '5%'
            }}
        >
            <Fab variant="extended"
                onClick={() => { dndHandler()}}
                sx={{
                    color: 'white',
                    backgroundColor: theme.backgroundColor2,
                    fontSize: 12,
                    width: '100%'
                }}>
                <NavigationIcon sx={{ mr: 1, fontSize: 15}} />
                {buttonText}
            </Fab>
        </View>
    )
}

export default ControlDnDFAB