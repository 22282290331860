import moment from 'moment-timezone';

export default function ParseTable (prop, table) {

    var dataset = prop;

    if (!dataset || dataset.length === undefined) {
        return null;
      }
  
  var agg_result = [];

  const renamedDataArray = dataset.map(obj => {
    const newObj = {};
    for (const key in obj) {
        const newKey = key.replace(table+".", "");
        newObj[newKey] = obj[key];
    }
    return newObj;
});

agg_result = renamedDataArray.map((obj) => {
    const dateFormatted = moment.tz(obj.date, 'Europe/Paris').format('YYYY-MM-DD');

    return {
      ...obj,
      date: dateFormatted

    };
  });


        return agg_result

      
      }