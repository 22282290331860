export default function CohortDepositCube(cubeFilters, table, start, end) {

    return {
        "timeDimensions": [
            {
                "dimension": `${table}.timestamp`,
                "granularity" : 'month',
            }
        ],
        "order": [
            [
                `${table}.monthsfromftd`,
                "asc"
            ]
        ],
        "dimensions": [ 
            `${table}.cohort_month`, 
            `${table}.monthsfromftd`
          ],
        "measures": [
            `${table}.ngr`,
            `${table}.cost`,
            `${table}.ftds`,
            `${table}.deposit_amount`,
        ],
        "limit": 1000,
        "filters": cubeFilters
    }

}