import React, { useEffect, useRef, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import WebFont from 'webfontloader';
import { Dimensions } from 'react-native'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Download from '../Components/Download';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const MixedChart = (props) => {
  const chartRef = useRef(null);
  const dataProp = props.dataProp
  const labels = props.labels
  const theme = props.theme
  const metric1 = props.metric1
  const metric2 = props.metric2
  const agg_daily_result = props.agg_daily_result
  const dateLabels = props.dateLabels
  const client = props.client
  const currentMetrics = props.currentMetrics
  const [contextMenu, setContextMenu] = useState(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX + 2,
          mouseY: event.clientY - 6,
        }
        :
        null,
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  let convLabel1

  if (metric1 === 'ftds') {
    convLabel1 = currentMetrics.primaryMetric
  } else if (metric1 === 'ngr') {
    convLabel1 = currentMetrics.primaryRevenue
  } else if (metric1 === 'regs') {
    convLabel1 = currentMetrics.secondaryMetric
  } else { convLabel1 = metric1 }

  let convLabel2

  if (metric2 === 'ftds') {
    convLabel2 = currentMetrics.primaryMetric
  } else if (metric2 === 'ngr') {
    convLabel2 = currentMetrics.primaryRevenue
  } else if (metric2 === 'regs') {
    convLabel2 = currentMetrics.secondaryMetric
  } else { convLabel2 = metric2 }

  let responseText;

  if (windowWidth < 1281) {
    responseText = windowHeight * 0.012
  } else if (windowWidth > 2000) {
    responseText = windowHeight * 0.012
  } else { responseText = windowHeight * 0.012 }


  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Electrolize']
      }
    });
  }, []);

  const filteredData = agg_daily_result.filter(item => item.brand !== "");

  const values1 = [metric1];
  const values2 = [metric2];

  const filteredValuesArray1 = filteredData.flatMap((item) =>
    values1.map((field) => item[field])
  );

  const filteredValuesArray2 = filteredData.flatMap((item) =>
    values2.map((field) => item[field])
  );

  const downloadHandler = () => {
    Download(filteredData)
    handleClose()

  }


  const data = {
    labels: dateLabels,
    datasets: [
      {
        type: 'line',
        label: convLabel1,
        data: filteredValuesArray1,
        yAxisID: 'A',
        borderColor: theme.color,
        lineTension: 0.5,
        radius: 0
      },
      {
        type: 'bar',
        label: convLabel2,
        data: filteredValuesArray2,
        yAxisID: 'B',
        borderColor: theme.color,
        backgroundColor: theme.backgroundColor2,
        borderRadius: 5,
        lineTension: 0.5,
        radius: 0
      },
    ],
  };


  const options = {
    maintainAspectRatio: false,
    scales: {
      A: {
        beginAtZero: true,
        type: 'linear',
        position: 'left',
        grid: {
          display: false, // Remove the gridlines on the y-axis
        },
        ticks: {
          callback: (value, index, values) => {
            if (metric1 === 'ggr' || metric1 === 'spend' || metric1 === 'cpa' || metric1 === 'cpc') {
              return new Intl.NumberFormat('en-EN', { // Note: Typically, locale would be 'en-US' or other specific locales for Euro.
                style: 'currency',
                currency: 'EUR',
                maximumSignificantDigits: 3
              }).format(value);
            } else if (metric1 === 'ggr_percent' || metric1 === 'bet_percent') {
              return new Intl.NumberFormat('en-EN', {
                style: 'percent',
                minimumFractionDigits: 2, // Adjust number of decimal places as needed
                maximumFractionDigits: 2
              }).format(value);
            } else {
              return value;
            }
          },
          color: theme.color,
          font: {
            size: responseText, // Set the font size for y-axis labels
            weight: 'bold', // Set the font weight for y-axis labels
            family: 'Electrolize',
          },
        },
      },
      B: {
        beginAtZero: true,
        type: 'linear',
        position: 'right',
        grid: {
          display: false, // Remove the gridlines on the y-axis
        },
        ticks: {
          callback: (value, index, values) => {
            if (metric2 === 'ggr' || metric2 === 'spend' || metric2 === 'cpa' || metric2 === 'cpc') {
              return new Intl.NumberFormat('en-EN', { // Note: Typically, locale would be 'en-US' or other specific locales for Euro.
                style: 'currency',
                currency: 'EUR',
                maximumSignificantDigits: 3
              }).format(value);
            } else if (metric2 === 'ggr_percent' || metric2 === 'bet_percent') {
              return new Intl.NumberFormat('en-EN', {
                style: 'percent',
                minimumFractionDigits: 2, // Adjust number of decimal places as needed
                maximumFractionDigits: 2
              }).format(value);
            } else {
              return value;
            }
          },
          color: theme.color,
          font: {
            size: responseText, // Set the font size for y-axis labels
            weight: 'bold', // Set the font weight for y-axis labels
            family: 'Electrolize',
          },
        },
      },
      x: {
        grid: {
          display: false, // Remove the gridlines on the x-axis
        },
        ticks: {
          color: theme.color,
          font: {
            size: responseText, // Set the font size for x-axis labels
            family: 'Electrolize',

          },
        },
      },
    },
    tooltips: {
      enabled: true,
    },
    plugins: {
      legend: {
        display: true,
        text: 'Chart Title',
        labels: {
          color: theme.color,
          // This more specific font property overrides the global property
          font: {
            size: responseText,
            family: 'Electrolize'
          }
        }
      },
      tooltip: {
        enabled: true,
      },
      title: {
        display: false,
        text: 'Chart Title',
        font: {
          size: 24, // Set the font size for the chart title
          weight: 'bold', // Set the font weight for the chart title

        },
      },
    },
  };

  return (
    <div onContextMenu={handleContextMenu} style={{ cursor: 'context-menu', height: '100%', width: '100%' }}>
    <Bar 
      ref={chartRef} 
      data={data} 
      options={options} 
      style={{ fontFamily: 'Electrolize', }} 
    />
    <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem 
            style={{fontFamily : 'Electrolize', backgroundColor: theme.backgroundColor}} 
            onClick={downloadHandler}
            >
              Download Data
          </MenuItem>
        </Menu>
    </div>
    
    );
};

export default MixedChart;
