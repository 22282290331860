import { View, Text, Dimensions } from 'react-native';
import React from 'react';
import MobileFAB from '../MobileComponents/MobileFAB';
import HorizontalBarChart from '../../ChartsDesktop/HorizontalBarChart';
import BudgetArea from '../../Budgets/BudgetArea.js'

const windowWidth = Dimensions.get('window').width;

const MobileHomePanel = (props) => {

    const theme = props.theme
    const budgetArray = props.budgetArray
    const budgetData = props.budgetData
    const dateLabels = props.dateLabels
    const currentMetrics = props.currentMetrics
    const actual_spend = Math.round(budgetArray[0].actual_spend)
    const budget = budgetArray[0].budget
    const yesterdaySpend = Math.round(budgetArray[0].yesterday_spend)
    const currentDom = budgetArray[0].current_dom
    const daysRemaining = budgetArray[0].days_remaining
    const dailyTarget = Math.round(actual_spend / currentDom)
    const yestRate = Math.round((yesterdaySpend / dailyTarget) * 100)
    const spendRate = Math.round((actual_spend / budget)*100)

    /*function sortAndFilterData(data, sortKey, keysToKeep) {
        // Filter out items where the sortKey is null
        const filteredNulls = data.filter(item => item[sortKey] !== null);

        // Sort the filtered data
        const sortedData = filteredNulls.sort((a, b) => {
            const aValue = parseFloat(a[sortKey]);
            const bValue = parseFloat(b[sortKey]);

            return aValue - bValue;
        });

        // Determine the data to return based on the length
        let finalData = sortedData;
        if (sortedData.length > 10) {
            finalData = [...sortedData.slice(0, 4), ...sortedData.slice(-5)];
        }

        // Further filter the keys in each item
        finalData = finalData.map(item => {
            const filteredItem = {};
            keysToKeep.forEach(key => {
                filteredItem[key] = item[key];
            });
            return filteredItem;
        });

        return finalData;
    }

    const cpa_dod = sortAndFilterData(data, 'cpa_diff_dod', ['brand', 'kw_type', 'cpa_diff_dod']);
    const spend_dod = sortAndFilterData(data, 'spend_diff_dod', ['brand', 'kw_type', 'spend_diff_dod']);
    const ftds_dod = sortAndFilterData(data, 'ftds_diff_dod', ['brand', 'kw_type', 'ftds_diff_dod']);
    const cpa_mom = sortAndFilterData(data, 'cpa_diff_mom', ['brand', 'kw_type', 'cpa_diff_mom']);
    const spend_mom = sortAndFilterData(data, 'spend_diff_mom', ['brand', 'kw_type', 'spend_diff_mom']);
    const ftds_mom = sortAndFilterData(data, 'ftds_diff_mom', ['brand', 'kw_type', 'ftds_diff_mom']);

    let graphData;

    if (currentGraph === 'CPA DoD') {
        graphData = {
            text: 'cpa_diff_dod',
            data: cpa_dod
        }
    } else if (currentGraph === 'FTD DoD') {
        graphData = {
            text: 'ftds_diff_dod',
            data: ftds_dod
        }
    } else if (currentGraph === 'FTD MoM') {
        graphData = {
            text: 'ftds_diff_mom',
            data: ftds_mom
        }
    } else if (currentGraph === 'CPA MoM') {
        graphData = {
            text: 'cpa_diff_mom',
            data: cpa_mom
        }
    } else {
        graphData = {
            text: 'cpa_diff_dod',
            data: cpa_dod
        }
    }
    */

    let rateColor;
    if (spendRate < 90 || spendRate > 110 ) {
        rateColor = 'red'
    } else { rateColor = theme.backgroundColor2}

    let yestRateColor;
    if (parseInt(yestRate) < 90 || parseInt(yestRate) > 110 ) {
        yestRateColor = 'red'
    } else { yestRateColor = theme.backgroundColor2}

    let dynamicText;
    if (windowWidth > 2000) {
        dynamicText = 200
    } else if (windowWidth > 1281) {
        dynamicText = 125
    } else { dynamicText = 50}

    let dynamicTextSmall;
    if (windowWidth > 2000) {
        dynamicTextSmall = 50
    } else if (windowWidth > 1281) {
        dynamicTextSmall = 20
    } else { dynamicTextSmall = 18}

    const container = {
        height: '100%',
        width: '100%',
        borderColor: theme.color,
        borderWidth: 0,
        flexDirection: 'column'
    }

    const containerColumn = {
        height: '60%',
        width: '100%',
        borderColor: theme.color,
        borderWidth: 0,
        flexDirection: 'column',

    }

    const containerColumn2 = {
        height: '40%',
        width: '100%',
        borderColor: theme.color,
        borderWidth: 0,
        flexDirection: 'column',

    }

    const budgetRow = {
        height: '30%',
        width: '100%',
        borderColor: 'grey',
        borderWidth: 1,
        flexDirection: 'row',   
    }

    const graphRow = {
        height: '70%',
        width: '100%',
        borderColor: 'grey',
        borderWidth: 1,
        flexDirection: 'column',
        padding: '1.5%'
    }

    const budgetText = {
        height:'100%',
        width: '50%',
        borderColor: 'grey',
        borderWidth: 1,
        alignitems: 'center',
        justifyContent: 'center'
    }

    const metricRow = {
        height:'50%',
        width: '100%',
        borderColor: theme.backgroundColor2,
        borderWidth: 0,
        flexDirection: 'row',

    }

    const metricContainer = {
        height:'100%',
        width: '50%',
        borderColor: 'grey',
        borderWidth: 1,
        alignitems: 'center',
        justifyContent: 'center'
    }

    return (
        <View style={container}>
            <View style={containerColumn}>
                <View style={budgetRow}>
                    <View style={budgetText}>
                    <Text style={{
                        color: yestRateColor,
                        textAlign: 'center',
                        fontSize: dynamicText,
                        fontFamily: 'Electrolize',
                        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                        }}>
                        {yestRate}%
                    </Text>
                    <Text style={{
                        color: theme.color, 
                        fontSize: dynamicTextSmall,
                        textAlign: 'center',
                        fontFamily: 'Electrolize',
                        fontWeight: 'bold'
                        }}>
                        Yesterday Spend Rate
                    </Text>  

                    </View>
                    <View style={budgetText}>
                    <Text style={{
                        color: rateColor,
                        textAlign: 'center',
                        fontSize: dynamicText,
                        fontFamily: 'Electrolize',
                        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                        }}>
                        {spendRate}%
                    </Text>
                    <Text style={{
                        color: theme.color, 
                        fontSize: dynamicTextSmall,
                        textAlign: 'center',
                        fontFamily: 'Electrolize',
                        fontWeight: 'bold'
                        }}>
                        MTD Spend Rate
                    </Text>  
                    </View>

                </View>
                <View style={graphRow}>

                    <BudgetArea
                        agg_daily_result={budgetData}
                        dateLabels={dateLabels}
                        theme={theme}
                        metric1={"actual_spend"}
                        metric2={"budget"}
                        timeFrame={'Daily'}
                        currentMetrics={currentMetrics}
                    />               
                    
                </View>
            </View>

            <View style={containerColumn2}>
                <View style={metricRow}>
                    <View style={metricContainer}>
                    <Text style={{
                        color: rateColor,
                        textAlign: 'center',
                        fontSize: dynamicText * 0.8,
                        fontFamily: 'Electrolize',
                        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                        }}>
                        €{yesterdaySpend}
                    </Text>
                    <Text style={{
                        color: theme.color, 
                        fontSize: dynamicTextSmall,
                        textAlign: 'center',
                        fontFamily: 'Electrolize',
                        fontWeight: 'bold'
                        }}>
                        Yesterday Spend
                    </Text>  
                        
                    </View>
                    <View style={metricContainer}>

                    <Text style={{
                        color: rateColor,
                        textAlign: 'center',
                        fontSize: dynamicText * 0.8,
                        fontFamily: 'Electrolize',
                        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                        }}>
                        €{actual_spend}
                    </Text>
                    <Text style={{
                        color: theme.color, 
                        fontSize: dynamicTextSmall,
                        textAlign: 'center',
                        fontFamily: 'Electrolize',
                        fontWeight: 'bold'
                        }}>
                        MTD Spend
                    </Text>  
                        
                    </View>
                    
                </View>

                <View style={metricRow}>
                    <View style={metricContainer}>
                    <Text style={{
                        color: rateColor,
                        textAlign: 'center',
                        fontSize: dynamicText * 0.75,
                        fontFamily: 'Electrolize',
                        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                        }}>
                        €{dailyTarget}
                    </Text>
                    <Text style={{
                        color: theme.color, 
                        fontSize: dynamicTextSmall,
                        textAlign: 'center',
                        fontFamily: 'Electrolize',
                        fontWeight: 'bold'
                        }}>
                        Target Daily Spend
                    </Text>  
                    
                        
                    </View>
                    <View style={metricContainer}>

                    <Text style={{
                        color: rateColor,
                        textAlign: 'center',
                        fontSize: dynamicText,
                        fontFamily: 'Electrolize',
                        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                        }}>
                        {daysRemaining}
                    </Text>
                    <Text style={{
                        color: theme.color, 
                        fontSize: dynamicTextSmall,
                        textAlign: 'center',
                        fontFamily: 'Electrolize',
                        fontWeight: 'bold'
                        }}>
                        Days Remaining
                    </Text>
                        
                    </View>
                    
                </View>
                    
            </View>

        </View>
    )

}

export default MobileHomePanel