import { Dimensions, View } from 'react-native';
import React, { useState } from 'react';
import MobileAppBar from '../MobileComponents/MobileAppBar';
import MobileBottomNav from '../MobileComponents/MobileBottomNav';
import SummaryPanel from './SummaryPanel';
import moment from 'moment-timezone';
import { useCubeQuery } from '@cubejs-client/react';
import LoadingComponent from '../../Components/Loader';
import ParseDaily from '../../DailySummary/CubeFunctions/ParseDaily';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const Summary = (props) => {

    const value = props.value
    const setValue = props.setValue
    const configArray = props.configArray
    const screenHandler = props.screenHandler
    const configHandler = configArray.configHandler
    const clientHandler = configArray.clientHandler
    const currentMetrics = configArray.currentMetrics[0]
    const clientID = configArray.clientID
    const clientBase = clientID.toLowerCase();
    const theme = props.theme
    const cubeTable = clientBase + "_daily";
    const role = configArray.role
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayProp = yesterday2.format('YYYY-MM-DD');

    const [currentGraph, setCurrentGraph] = useState('ftds_diff_mom')

    function graphHandler (prop) {
        setCurrentGraph(prop)
    }

    const currentDate = new Date();
    currentDate.setMinutes(0);
    const previousDate = new Date(currentDate);
    previousDate.setDate(currentDate.getDate() - 30);
    previousDate.setDate(1)

    const startDateString = previousDate.toISOString().slice(0, 10);

    const cubeFilters = []

    const cubeQuery = ParseDaily(cubeFilters, cubeTable, startDateString, yesterdayProp)
    const { resultSet } = useCubeQuery(cubeQuery);

    if (!resultSet) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    const cubeResponse = resultSet.loadResponse.results[0].data

    const updateArray = cubeResponse.map(obj => {
        const newObj = {};
        for (const key in obj) {
            const newKey = key.replace(cubeTable + ".", "");
            newObj[newKey] = obj[key];
        }
        return newObj;
    });

    const agg_result = updateArray.map((obj) => {
        const date = moment.tz(obj.timestamp, 'Europe/Paris').format('YYYY-MM-DD');
        const spend = parseFloat(obj.spend)
        const ftds = parseInt(obj.ftds)
        const cpa = parseFloat((spend / ftds).toFixed(2))
        const impressions = parseInt(obj.impressions)
        const clicks = parseInt(obj.clicks)
        const regs = parseInt(obj.regs)
        const cpc = parseFloat((spend / clicks).toFixed(2))
        const ftdConv = ((parseFloat(ftds / clicks))*100).toFixed(2)

        return {
            date: date,
            impressions: impressions,
            clicks: clicks,
            spend: spend,
            cpc: cpc,
            regs: regs,
            ftds: ftds,
            cpa: cpa,
            clickConv: ftdConv

        };
    });

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
    };

    const dateLabels = getUniqueValues(agg_result, 'date')

    const container = {
        height: windowHeight,
        width: windowWidth,
        borderColor: theme.backgroundColor2,
        borderWidth: 0,
        backgroundColor: theme.backgroundColor
    }

    const homePanel = {
        height: windowHeight * 0.863,
        width: '100%',
        borderColor: theme.backgroundColor2,
        borderWidth: 0,
        backgroundColor: theme.backgroundColor,
        marginTop: '1%',

    }

    return (
        <View style={container}>
            <MobileAppBar
                theme={theme}
                configHandler={configHandler}
                clientHandler={clientHandler}
                homeTitle={'Summaries'}
                role={'admin'}
                screenHandler={screenHandler}
            />
            <View style={homePanel}>

            <SummaryPanel 
                theme={theme}
                agg_daily={agg_result}
                agg_monthly={agg_result}
                agg_brand={agg_result}
                agg_type={agg_result}
                dateLabels={dateLabels}
                currentMetrics={currentMetrics}
            >


            </SummaryPanel>

            <MobileBottomNav
                theme={theme}
                screenHandler={screenHandler}
                graphHandler={graphHandler}
                clientID={clientID}
                value={value}
                setValue={setValue}
            />
            </View>            
        </View>
    )

}

export default Summary