import React, { useState } from 'react';
import { Template3 } from './Template3';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { View, Dimensions, StyleSheet } from 'react-native'

const windowHeight = Dimensions.get('window').height;

function DndContainerT3 (props) {

    const theme = props.theme
    const setDashConfig = props.setDashConfig
    const metricList = ['impressions', 'clicks', 'spend', 'regs', 'ftds', 'cpa']
    const dimensionList = ['daily', 'weekly', 'monthly', 'brand', 'channel', 'kw_type']
    const currentDash = props.currentReport
    const config = props.dashConfig
    const reportIndex = props.reportIndex
    const currentMetrics = props.currentMetrics

    const [masterConfig, setMasterConfig] = useState(config)

    const currentType = masterConfig[reportIndex][currentDash]['type']
    const currentTime = masterConfig[reportIndex][currentDash]['time']
    const currentAxis = masterConfig[reportIndex][currentDash]['xAxis']
    const currentMetric1 = masterConfig[reportIndex][currentDash]['metric1']
    const currentMetric2 = masterConfig[reportIndex][currentDash]['metric2']
    const currentPanel1 = masterConfig[reportIndex][currentDash]['panel1']
    const currentPanel2 = masterConfig[reportIndex][currentDash]['panel2']
    const currentPanel3 = masterConfig[reportIndex][currentDash]['panel3']
    const currentPanel4 = masterConfig[reportIndex][currentDash]['panel4']
    const currentPanel5 = masterConfig[reportIndex][currentDash]['panel5']
    const currentPanel6 = masterConfig[reportIndex][currentDash]['panel6']
    const currentchildGraph1Type = masterConfig[reportIndex][currentDash]['childGraph1']['type']
    const currentchildGraph1Time = masterConfig[reportIndex][currentDash]['childGraph1']['time']
    const currentchildGraph1Axis = masterConfig[reportIndex][currentDash]['childGraph1']['xAxis']
    const currentchildGraph1Metric1 = masterConfig[reportIndex][currentDash]['childGraph1']['metric1']
    const currentchildGraph1Metric2 = masterConfig[reportIndex][currentDash]['childGraph1']['metric2']
    const currentchildGraph2Type = masterConfig[reportIndex][currentDash]['childGraph2']['type']
    const currentchildGraph2Time = masterConfig[reportIndex][currentDash]['childGraph2']['time']
    const currentchildGraph2Axis = masterConfig[reportIndex][currentDash]['childGraph2']['xAxis']
    const currentchildGraph2Metric1 = masterConfig[reportIndex][currentDash]['childGraph2']['metric1']
    const currentchildGraph2Metric2 = masterConfig[reportIndex][currentDash]['childGraph2']['metric2']
    const currentchildGraph3Type = masterConfig[reportIndex][currentDash]['childGraph3']['type']
    const currentchildGraph3Time = masterConfig[reportIndex][currentDash]['childGraph3']['time']
    const currentchildGraph3Axis = masterConfig[reportIndex][currentDash]['childGraph3']['xAxis']
    const currentchildGraph3Metric1 = masterConfig[reportIndex][currentDash]['childGraph3']['metric1']
    const currentchildGraph3Metric2 = masterConfig[reportIndex][currentDash]['childGraph3']['metric2']

    const currentchildGraph4Type = masterConfig[reportIndex][currentDash]['childGraph4']['type']
    const currentchildGraph4Time = masterConfig[reportIndex][currentDash]['childGraph4']['time']
    const currentchildGraph4Axis = masterConfig[reportIndex][currentDash]['childGraph4']['xAxis']
    const currentchildGraph4Metric1 = masterConfig[reportIndex][currentDash]['childGraph4']['metric1']
    const currentchildGraph4Metric2 = masterConfig[reportIndex][currentDash]['childGraph4']['metric2']
    const currentchildGraph5Type = masterConfig[reportIndex][currentDash]['childGraph5']['type']
    const currentchildGraph5Time = masterConfig[reportIndex][currentDash]['childGraph5']['time']
    const currentchildGraph5Axis = masterConfig[reportIndex][currentDash]['childGraph5']['xAxis']
    const currentchildGraph5Metric1 = masterConfig[reportIndex][currentDash]['childGraph5']['metric1']
    const currentchildGraph5Metric2 = masterConfig[reportIndex][currentDash]['childGraph5']['metric2']
    const currentchildGraph6Type = masterConfig[reportIndex][currentDash]['childGraph6']['type']
    const currentchildGraph6Time = masterConfig[reportIndex][currentDash]['childGraph6']['time']
    const currentchildGraph6Axis = masterConfig[reportIndex][currentDash]['childGraph6']['xAxis']
    const currentchildGraph6Metric1 = masterConfig[reportIndex][currentDash]['childGraph6']['metric1']
    const currentchildGraph6Metric2 = masterConfig[reportIndex][currentDash]['childGraph6']['metric2']


    const currentConfig = {
        currentType,
        currentTime,
        currentAxis,
        currentMetric1,
        currentMetric2,
        currentPanel1,
        currentPanel2,
        currentPanel3,
        currentPanel4,
        currentPanel5,
        currentPanel6,
        currentchildGraph1Type,
        currentchildGraph1Time,
        currentchildGraph1Axis,
        currentchildGraph1Metric1,
        currentchildGraph1Metric2,
        currentchildGraph2Type,
        currentchildGraph2Time,
        currentchildGraph2Axis,
        currentchildGraph2Metric1,
        currentchildGraph2Metric2,
        currentchildGraph3Type,
        currentchildGraph3Time,
        currentchildGraph3Axis,
        currentchildGraph3Metric1,
        currentchildGraph3Metric2,
        currentchildGraph4Type,
        currentchildGraph4Time,
        currentchildGraph4Axis,
        currentchildGraph4Metric1,
        currentchildGraph4Metric2,
        currentchildGraph5Type,
        currentchildGraph5Time,
        currentchildGraph5Axis,
        currentchildGraph5Metric1,
        currentchildGraph5Metric2,
        currentchildGraph6Type,
        currentchildGraph6Time,
        currentchildGraph6Axis,
        currentchildGraph6Metric1,
        currentchildGraph6Metric2
    }


    function dropHandler (index, name, type) {

        let containerName;
        if (index === 0) {
            containerName = 'type'
        } else if (index === 1) {
            containerName = 'xAxis'
        } else if (index === 2) {
            containerName = 'metric1'
        } else if (index === 3) {
            containerName = 'metric2'
        }
        
        setMasterConfig(prevState => {
        // Copy the outer array
            const newState = [...prevState];

            function childHandler (type) {
                if (type === 'main') {
                    return dashboard1[containerName] = name.toLowerCase();
                } else {
                    return dashboard1[type][containerName] = name.toLowerCase();
                }
            }
            
            function timeHandler (type) {
                if (type === 'main' && name === 'Weekly') {
                    dashboard1['time'] = 'weekly'
                    dashboard1['xAxis'] = 'date'
                } else if (type.includes('child') && name === 'Weekly') {
                    dashboard1[type]['time'] = 'weekly'
                    dashboard1[type]['xAxis'] = 'date'
                } else if (type === 'main' && name === 'Monthly') {
                    dashboard1['time'] = 'monthly'
                    dashboard1['xAxis'] = 'date'
                } else if (type.includes('child') && name === 'Monthly') {
                    dashboard1[type]['time'] = 'monthly'
                    dashboard1[type]['xAxis'] = 'date'
                }   else if (type.includes('child') && name === 'Date') {
                    dashboard1[type]['time'] = 'date'
                    dashboard1[type]['xAxis'] = 'date'
                } else if (type === 'main' && name === 'Date') {
                    dashboard1['time'] = 'date'
                    dashboard1['xAxis'] = 'date'
                }
            }
            
            // Copy the 'Dashboard 1' object
            const dashboardIndex = reportIndex + 1
            const dashboardKey = `Dashboard ${dashboardIndex}`; 
            const dashboard1 = { ...newState[reportIndex][dashboardKey] };
            
            // Update the specific containerName within 'Dashboard 1'
            childHandler(type)
            timeHandler(type)
            
            // Update the 'Dashboard 1' object in the copied array
            const updatedState = [...newState];
            updatedState[reportIndex][dashboardKey] = dashboard1;
            
            // Return the updated state
            return updatedState;
        });

    }

    function dropMetricHandler (index, name) {
        const containerName = `panel${index + 1}`
        console.log(index, name, containerName)

        
        setMasterConfig(prevState => {
        // Copy the outer array
            const newState = [...prevState];
            
            // Copy the 'Dashboard 1' object
            const dashboardIndex = reportIndex + 1
            const dashboardKey = `Dashboard ${dashboardIndex}`; 
            const dashboard1 = { ...newState[reportIndex][dashboardKey] };
            
            // Update the specific containerName within 'Dashboard 1'
            dashboard1[containerName] = name.toLowerCase();
            
            // Update the 'Dashboard 1' object in the copied array
            const updatedState = [...newState];
            updatedState[reportIndex][dashboardKey] = dashboard1;

            
            // Return the updated state
            return updatedState;
        });

    }

    function updateConfig (prop) {
        setDashConfig(masterConfig)
        localStorage.setItem("dashConfig", JSON.stringify(masterConfig))

    }

    const container = {
        width: '100%',
        height: windowHeight * 0.9,
        borderColor: theme.color,
        borderWidth: 1
    }

    return (
        <View style={container}>

                <DndProvider backend={HTML5Backend}>

                    <Template3 
                        metricList={metricList}
                        dimensionList={dimensionList}
                        theme={theme}
                        dropHandler={dropHandler}
                        dropMetricHandler={dropMetricHandler}
                        currentConfig={currentConfig}
                        updateConfig={updateConfig}
                        setDashConfig={setDashConfig}
                        currentMetrics={currentMetrics}
                    />

            </DndProvider>
        </View>
    )
}

export default DndContainerT3

const styles = StyleSheet.create({
    modalParent: {
        padding: 20,
        borderRadius: 20,
    },
    modalContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        alignItems: 'center',
        justiftContent: 'center',


    },
    modalWindow: {
        height: '90%',
        width: '60%',
        borderRadius: 15,
        padding: '1%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
})