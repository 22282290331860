import React, { useState } from 'react';
import {
    View,
    Pressable
} from 'react-native'
import DashEditorLayout from './DashEditorLayout';
import DashEditorViewer from './DashEditorViewer';
import DashEditorModify from './DashEditorModify';
import Tooltip from '@mui/material/Tooltip';
import DashEditorSettings from './DashEditorSettings';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const DashEditorContent = (props) => {

    const currentMetrics = props.currentMetrics
    const theme = props.theme
    const cubeTable = props.cubeTable
    const uniqueBrands = props.uniqueBrands
    const uniqueChannels = props.uniqueChannels
    const uniqueTypes = props.uniqueTypes
    const dateLabels = props.dateLabels
    const agg_result = props.agg_result
    const dashConfigs = props.dashConfigs
    const setDashConfigs = props.setDashConfigs
    const viewerVisible = props.viewerVisible
    const setViewerVisible = props.setViewerVisible
    const editorVisible = props.editorVisible
    const setEditorVisible = props.setEditorVisible
    const addDashboard = props.addDashboard
    const removeDashboard = props.removeDashboard
    const startDateString = props.startDateString
    const yesterdayProp = props.yesterdayProp
    const filters = props.filters
    const setFilters = props.setFilters
    const [settingsVisible, setSettingsVisible] = useState(false)
    const configBase = dashConfigs
    const currentItemBase = {
        gridConfig: configBase[0][0]['gridConfig'],
        gridLayout: configBase[0][0]['gridLayout'],
        gridChartLayout: configBase[0][0]['gridChartLayout'],
        reportName: configBase[0][0]['reportName'],
    }
  

    function getLayout (layout, index) {
        const gridConfig = configBase[index][0]['gridConfig']
        const gridLayout = configBase[index][0]['gridLayout']
        const gridChartLayout = configBase[index][0]['gridChartLayout']
        const reportName = configBase[index][0]['reportName']

        return {gridConfig, gridLayout, gridChartLayout, reportName}
    }

    const [currentItem, setCurrentItem] = useState(currentItemBase)
    const [updateFilters, setUpdateFilters] = useState(filters)

    function updateHandler (prop) {
        setFilters(updateFilters)
    }

    const container = {
        width: '100%',
        height: '100%',
        borderColor: 'red',
        borderWidth: 0,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap'
    }

    const iconContainer = {
        width: '25%',
        height: '35%',
        borderColor: 'red',
        borderWidth: 0,
        alignItems: 'center',
        justifyContent: 'center',
    }

    const addIcon = {
        color: theme.backgroundColor2+50,
        fontSize: 150
    }

    return (
        <View style={container}>
        {dashConfigs.map((item, index) => {
            const layoutData = getLayout(item, index);
            const currentLayout = layoutData.gridConfig

            return (

                <DashEditorLayout
                    theme={theme}
                    key={index}
                    config={layoutData}
                    layoutConfig={item}
                    layoutIndex={index}
                    setViewerVisible={setViewerVisible}
                    setEditorVisible={setEditorVisible}
                    setCurrentItem={setCurrentItem}
                    settingsVisible={settingsVisible}
                    setSettingsVisible={setSettingsVisible}
                    currentLayout={currentLayout}
                    removeDashboard={removeDashboard}
                    getLayout={getLayout}
                    currentMetrics={currentMetrics}

                />

            );
        })}
        <Tooltip
                    title={"Add Dashboard"}
                    placement="top"
                    componentsProps={{
                        arrow: { sx: { color: theme.backgroundColor2, } },
                        tooltip: {
                            sx: {
                                bgcolor: theme.backgroundColor2,
                                color: "white",
                                fontFamily: 'Electrolize',
                            }
                        },
                    }}
                    arrow
                >
        <Pressable 
            style={iconContainer}
            onPress={() => addDashboard()}>
        <AddCircleOutlineIcon style={addIcon}/>
        </Pressable>
        </Tooltip>
        <DashEditorViewer
            theme={theme}
            getLayout={getLayout}
            currentItem={currentItem}
            agg_result={agg_result}
            uniqueBrands={uniqueBrands}
            uniqueChannels={uniqueChannels}
            uniqueTypes={uniqueTypes}
            dateLabels={dateLabels}
            setViewerVisible={setViewerVisible}
            viewerVisible={viewerVisible}
            cubeTable={cubeTable}
            dashConfigs={dashConfigs}
            filters={filters}
            setFilters={setFilters}
            currentMetrics={currentMetrics}
        />
        <DashEditorModify
            theme={theme}
            getLayout={getLayout}
            currentItem={currentItem}
            setCurrentItem={setCurrentItem}
            agg_result={agg_result}
            uniqueBrands={uniqueBrands}
            uniqueChannels={uniqueChannels}
            uniqueTypes={uniqueTypes}
            dateLabels={dateLabels}
            setViewerVisible={setEditorVisible}
            viewerVisible={editorVisible}
            cubeTable={cubeTable}
            dashConfigs={dashConfigs}
            filters={filters}
            setFilters={setFilters}
            updateHandler={updateHandler}
            setUpdateFilters={setUpdateFilters}
            startDateString={startDateString}
            yesterdayProp={yesterdayProp}
            viewerModal={setViewerVisible}
            setDashConfigs={setDashConfigs}
            currentMetrics={currentMetrics}
        />
        <DashEditorSettings
            theme={theme}
            settingsVisible={settingsVisible}
            setSettingsVisible={setSettingsVisible}
            uniqueBrands={uniqueBrands}
            uniqueChannels={uniqueChannels}
            uniqueTypes={uniqueTypes}
            filters={filters}
            setFilters={setFilters}
            setUpdateFilters={setUpdateFilters}
            updateHandler={updateHandler}
            dashConfigs={dashConfigs}
            currentItem={currentItem}
            currentMetrics={currentMetrics}
        />
    </View>
    )

}

export default DashEditorContent