export default function ParseDimensionsAtt(dimData, table, dynamicType) {

    if (!dimData || dimData.length === undefined) {
        return null;
    }

    const type = `${table}.${dynamicType}`;

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
      };


    return getUniqueValues(dimData, type)


}