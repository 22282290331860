import React, { useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import WebFont from 'webfontloader';
import { Dimensions } from 'react-native'

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const DashBar = (props) => {
  const chartRef = useRef(null);
  const dataProp = props.dataProp
  const labels = props.labels
  const theme = props.theme
  const metric = props.metric
  const currentMetrics = props.currentMetrics

  let responseText, formatLabel;

  if (metric === 'ctr') {
    formatLabel = "ctr%"
  } else if (metric === 'ftds') {
    formatLabel = currentMetrics.primaryMetric
  } else if (metric === 'ngr') {
    formatLabel = currentMetrics.primaryRevenue
  } else if (metric === 'regs') {
    formatLabel = currentMetrics.secondaryMetric
  } else {formatLabel = metric}

  if (windowWidth < 1281) {
    responseText = windowHeight * 0.012
  } else if (windowWidth > 2000) {
    responseText = windowHeight * 0.012
  } else {responseText = windowHeight * 0.012}

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Electrolize']
      }
    });
   }, []);

  const filteredData = dataProp.filter(item => item.brand !== "");

  const desiredFields = [metric];  

  const filteredValuesArray = filteredData.flatMap((item) =>
  desiredFields.map((field) => item[field])
);

  const data = {
    labels: labels,
    datasets: [
      {
        label: formatLabel,
        data: filteredValuesArray,
        backgroundColor: theme.backgroundColor2,
        borderRadius: 5,
      },
    ],
  };

  
const options = {
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        display: false, // Remove the gridlines on the y-axis
      },
      ticks: {
        callback: (value, index, values) => {
          if (metric === 'ggr' || metric === 'spend' || metric === 'cpa') {
              return new Intl.NumberFormat('en-EN', { // Note: Typically, locale would be 'en-US' or other specific locales for Euro.
                  style: 'currency',
                  currency: 'EUR',
                  maximumSignificantDigits: 3
              }).format(value);
          } else if (metric === 'ggr_percent' || metric === 'bet_percent') {
              return new Intl.NumberFormat('en-EN', {
                  style: 'percent',
                  minimumFractionDigits: 2, // Adjust number of decimal places as needed
                  maximumFractionDigits: 2
              }).format(value);
          } else {
              return value;
          }
      },
        color : theme.color,
        font: {
          size: responseText, // Set the font size for y-axis labels
          weight: 'bold', // Set the font weight for y-axis labels
          family : 'Electrolize',
        },
      },
    },
    x: {
      grid: {
        display: false, // Remove the gridlines on the x-axis
      },
      ticks: {
        color : theme.color,
        font: {
          size: responseText, // Set the font size for x-axis labels
          family : 'Electrolize'
        },
      },
    },
  },
  tooltips: {
    enabled: true,    
  },
  maintainAspectRatio: false,
  
  plugins: {
    legend: {
      display: true,
      text: 'Chart Title',
      labels: {
        color : theme.color,
        // This more specific font property overrides the global property
        font: {
            size: responseText,
            family : 'Electrolize'
        }
    }
    },
    tooltip: {
      enabled: true,
    },
    title: {
      display: false,
      text: 'Chart Title',
      font: {
        size: responseText, // Set the font size for the chart title
        weight: 'bold', // Set the font weight for the chart title

      },
    },
  },
};

  return <Bar ref={chartRef} data={data} options={options} style={{fontFamily : 'Electrolize'}} />;
};

export default DashBar;
