import { Dimensions, View } from 'react-native';
import React, { useState } from 'react';
import MobileHome from './HomeScreen/MobileHome';
import Summary from './SummaryScreen/Summary';
import Charts from './ChartScreen/Charts';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const MobileScreen = (props) => {

    const theme = props.theme
    const configArray = props.configArray
    const currentMetrics = configArray.currentMetrics[0]
    const [currentScreen, setCurrentScreen] = useState('MobileHome')
    const [value, setValue] = React.useState(0);

    function screenHandler (prop) {
        setCurrentScreen(prop)
    }

    const container = {
        height: windowHeight,
        width: windowWidth,
        borderColor: theme.backgroundColor2,
        borderWidth: 0
    }

    const renderComponentContent = () => {
        if (currentScreen === 'MobileHome') {
            return <MobileHome 
                        theme={theme}
                        configArray={configArray}
                        screenHandler={screenHandler}
                        value={value}
                        setValue={setValue}
                        currentMetrics={currentMetrics}
                        />;
        } else if (currentScreen === 'MobileSummary') {
            return <Summary 
                        theme={theme}
                        configArray={configArray}
                        screenHandler={screenHandler}
                        value={value}
                        setValue={setValue}
                        currentMetrics={currentMetrics}
                        />;
        } else if (currentScreen === 'MobileCharts') {
            return <Charts 
                        theme={theme}
                        configArray={configArray}
                        screenHandler={screenHandler}
                        value={value}
                        setValue={setValue}
                        currentMetrics={currentMetrics}
                        />;
        } else {
            return null;
        }
    };

    return (
        <View style={container}>
            {renderComponentContent()}
        </View>
    )

}

export default MobileScreen