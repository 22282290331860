import React, { useState } from 'react';
import { View, 
    Text, 
    Pressable, 
    Modal,
    StyleSheet,
    Dimensions } from 'react-native'
import CloseIcon from '@mui/icons-material/Close';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ControlDnD from './ControlDnD';
import CanvasDnDExport from './CanvasDnDExport.js'

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
const responsiveTextSmall = windowWidth * 0.02

const ControlModal = (props) => {

    const currentMetrics = props.currentMetrics
    const theme = props.theme
    const visibleModal = props.visibleModal
    const setVisible = props.setVisible
    const config = props.config
    const dndHandler = props.dndHandler
    const setChartLayout = props.setChartLayout
    const currentItem = props.currentItem

    const [masterConfig, setMasterConfig] = useState(config)

        function dropHandler (index, name, type) {

        let containerName;
        if (index === 0) {
            containerName = 'type'
        } else if (index === 1) {
            containerName = 'xAxis'
        } else if (index === 2) {
            containerName = 'metric1'
        } else if (index === 3) {
            containerName = 'metric2'
        }
        
        setChartLayout(prevState => {
        // Copy the outer array
            const newState = [...prevState];
            
            function timeHandler (type) {
                if (type === 'main' && name === 'Weekly') {
                    dashboard1['time'] = 'weekly'
                    dashboard1['xAxis'] = 'date'
                } else if (type.includes('child') && name === 'Weekly') {
                    dashboard1['time'] = 'weekly'
                    dashboard1['xAxis'] = 'date'
                } else if (type === 'main' && name === 'Monthly') {
                    dashboard1['time'] = 'monthly'
                    dashboard1['xAxis'] = 'date'
                } else if (type.includes('child') && name === 'Monthly') {
                    dashboard1['time'] = 'monthly'
                    dashboard1['xAxis'] = 'date'
                } else if (type.includes('child') && name === 'Date') {
                    dashboard1['time'] = 'date'
                    dashboard1['xAxis'] = 'date'
                } else if (type === 'main' && name === 'Date') {
                    dashboard1['time'] = 'date'
                    dashboard1['xAxis'] = 'date'
                }
            }
            
            // Copy the 'Dashboard 1' object
            const dashboard1 = config[currentItem];
            
            // Update the specific containerName within 'Dashboard 1'
            timeHandler(type)
            
            // Update the 'Dashboard 1' object in the copied array
            const updatedState = [...newState];
            dashboard1[containerName] = name.toLowerCase()
            updatedState[currentItem] = dashboard1;
           
            // Return the updated state
            return updatedState;
        });

    }


    return (
        <Modal
            visible={visibleModal}
            style={styles.modalParent}
            transparent={true}
            >
        <Pressable 
            style={styles.modalContainer}
            onPress={() => setVisible(false)}
            >
            <View style={[styles.modalWindow , {
                backgroundColor : theme.backgroundColor,
                borderColor: theme.color
            }]}>
            <CloseIcon
                    style={{
                        position: 'absolute',
                        left: '100%',
                        top: '-4%',
                        fontSize: responsiveTextSmall,
                        color: theme.color,
                        backgroundColor: theme.backgroundColor2,
                        borderRadius: 5,
                        
                    }}
                />

                <DndProvider backend={HTML5Backend}>
                    <CanvasDnDExport
                        theme={theme}
                        config={config}
                        dndHandler={dndHandler}
                        dropHandler={dropHandler}
                        masterConfig={masterConfig}
                        currentItem={currentItem}
                        currentMetrics={currentMetrics}
                    />
                    
                </DndProvider>
            

            </View>


        </Pressable>


        </Modal>)
    }

export default ControlModal

const styles = StyleSheet.create({
    modalParent: {
        padding: 20,
        borderRadius: 20,
    },
    modalContainer: {

        width: windowWidth * 1,
        height: windowHeight * 1,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalWindow: {
        margin: 10,
        height: '90%',
        width: '90%',
        borderColor: 'white',
        borderWidth: 1,
        borderRadius: 15,
        padding: '1%',
        alignItems: 'center',
        justiftContent: 'center',

        }

})