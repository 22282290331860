import React, { useState, useEffect } from 'react';
import { View , StyleSheet , Dimensions, Text} from 'react-native'
import { useSelector } from 'react-redux';
import AppBarRN from '../Components/AppBarRN';
import TemporaryDrawer from "../Components/Drawer";

import LoadingComponent from '../Components/Loader';


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const PPCKwTreeScreen = (props) => {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const theme = props.theme
    const homeTitle = "KW Tree";
    const role = 'Admin'
    const clientID = 'Rank'
    const configArray = {
        role,
        clientID
      }


    const stateBase = {
        top: false,
        left: false,
        bottom: false,
        right: false,
      };

    const [state, setState] = useState(stateBase);
    const anchor = 'left'

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setState({ ...state, [anchor]: open });

      };

    function handleDrawer (anchor) {

        const stateChange = {
            top: false,
            left: true,
            bottom: false,
            right: false,
          };
          setState(stateChange)
    
    }
  
    
      if (loading) {
        return (
          <LoadingComponent theme={theme}/>
        );
      }
    
    //const dataset = data.kw
    const dataset = []
    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
      };
  
      const uniqueBrands = ["YoBingo"];
      const uniqueChannels = getUniqueValues(dataset, 'channel');
      const uniqueTypes = getUniqueValues(dataset, 'campaign');

    return (
      <View style={[styles.container, {backgroundColor: theme.backgroundColor}]}>
        <AppBarRN 
            handleDrawer={handleDrawer}
            homeTitle={homeTitle}
            theme={theme}
        />
        <TemporaryDrawer 
            toggleDrawer={toggleDrawer}
            state={state}
            anchor={anchor}
            theme={theme}
        />

        </View>
        
    )
}

const styles = StyleSheet.create({
    container: {
      borderWidth: 0,
      borderColor: 'blue',
      paddingTop: 0,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      width: windowWidth,
      height: '100%',
      flex: 1,
    },
})

export default PPCKwTreeScreen