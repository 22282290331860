
import update from 'immutability-helper'
import { memo, useCallback, useState } from 'react'
import { ControlDnDBox } from './ControlDnDBox.js'
import { ControlDnDDustbin } from './ControlDnDDustbin.js'
import ControlDnDFAB from './ControlDnDFAB.js'
import { ItemTypes } from '../ItemTypes.js'
import { View, StyleSheet } from 'react-native'


export const ControlDnD = memo(function Container(props) {

    const theme = props.theme
    const config = props.config
    const dndHandler = props.dndHandler
    const dropHandler = props.dropHandler
    const currentItem = props.currentItem
    const currentMetrics = props.currentMetrics

    const [dustbins, setDustbins] = useState([
        { accepts: [ItemTypes.TYPE], lastDroppedItem: null },
        { accepts: [ItemTypes.XAXIS], lastDroppedItem: null },
        { accepts: [ItemTypes.METRIC], lastDroppedItem: null,},
        { accepts: [ItemTypes.METRIC], lastDroppedItem: null },
    ])

    const [boxes] = useState([
        { name: 'Line', type: ItemTypes.TYPE },
        { name: 'Bar', type: ItemTypes.TYPE },
        { name: 'Mixed', type: ItemTypes.TYPE },
        { name: 'Table', type: ItemTypes.TYPE },
        { name: 'Date', type: ItemTypes.XAXIS },
        { name: 'Weekly', type: ItemTypes.XAXIS },
        { name: 'Monthly', type: ItemTypes.XAXIS },
        { name: 'Brand', type: ItemTypes.XAXIS },
        { name: 'Channel', type: ItemTypes.XAXIS },
        { name: 'KW_Type', type: ItemTypes.XAXIS },
        { name: 'Impressions', type: ItemTypes.METRIC },
        { name: 'Clicks', type: ItemTypes.METRIC },
        { name: 'CTR', type: ItemTypes.METRIC },
        { name: 'Spend', type: ItemTypes.METRIC },
        { name: 'CPC', type: ItemTypes.METRIC },
        { name: 'Regs', type: ItemTypes.METRIC },
        { name: 'CPR', type: ItemTypes.METRIC },
        { name: 'FTDs', type: ItemTypes.METRIC },
        { name: 'CPA', type: ItemTypes.METRIC },
        { name: 'NGR', type: ItemTypes.METRIC },
    ])


    const [droppedBoxNames, setDroppedBoxNames] = useState([])
    function isDropped(boxName) {
        return droppedBoxNames.indexOf(boxName) > -1
    }

    const handleDrop = useCallback(
        (index, item, type) => {
            const { name } = item
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
                dropHandler(index, name, type)
            )
            setDustbins(
                update(dustbins, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            )
        },
        [droppedBoxNames, dustbins, dropHandler],
    )

    const acceptsType = [];
    const acceptsXaxis = [];
    const acceptsMetrics = []

    dustbins.forEach(dustbin => {
        if (dustbin.accepts[0] === 'type') {
            acceptsType.push(dustbin);
        } else if (dustbin.accepts[0] === 'xAxis') {
            acceptsXaxis.push(dustbin);
        } else {
            acceptsMetrics.push(dustbin);
        }
    });

    const container = {
        width: '100%',
        height: '100%',
        borderColor: 'red',
        borderWidth: 0,
        flexDirection: 'column'
    }

    const panel = {
        width: '100%',
        height: '100%',
        flexDirection: 'row'
    }

    const panelChart = {
        height: '100%',
        width: '80%'
    }

    const panelMetrics = {
        height: '100%',
        width: '20%',
        borderColor: 'grey',
        borderWidth: 1,
    }

    const footer = {
        width: '100%',
        height: '0%',
        borderWidth: 1,
        borderColor: 'red',
    }

    return (
        <View style={container}>
            <View style={panel}>
                <View style={panelChart}>
                <View style={{ width: '100%', 
                                        height: '100%', 
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        fontFamily: 'Electrolize',
                                        borderColor: 'red',
                                        borderWidth: 0}}>
                    {dustbins.map(({ accepts, lastDroppedItem }, index) => (
                        <ControlDnDDustbin
                            accept={accepts}
                            lastDroppedItem={lastDroppedItem}
                            onDrop={(item) => handleDrop(index, item, 'childGraph1')}
                            key={index}
                            theme={theme}
                            currentConfig={config}
                            indexCount={index}
                            chartType={'childGraph1'}
                            currentItem={currentItem}
                            currentMetrics={currentMetrics}
                            />
                        ))}                            
                    </View>  

                </View>
                <View style={panelMetrics}>

                <View style={styles.footer}>
            
                <div >
                    {boxes.map(({ name, type }, index) => (
                        <ControlDnDBox
                            name={name}
                            type={type}
                            isDropped={isDropped(name)}
                            key={index}
                            theme={theme}
                            currentMetrics={currentMetrics}

                        />
                    ))}
                </div>
                <ControlDnDFAB
                    theme={theme}
                    buttonText={'Update'}
                    dndHandler={dndHandler}
                />
            </View>


                </View>

            </View>

        </View>
    )
}
)

const styles = StyleSheet.create({
    
    parent: {
        flexDirection: 'row',
        height: '100%',
        width: '100%',
        borderWidth: 0,
        borderColor: 'red',
    },    
    container: {
        borderWidth: 0,
        borderColor: 'red',
        height: '100%',
        width: '80%',
        fontFamily: 'Electrolize',
        flexDirection: 'column'
    },
    footer : {
        borderWidth: 1,
        borderColor: 'grey',
        height: '100%',
        width: '100%',
        fontFamily: 'Electrolize',
        flexDirection: 'column',
        justifyContent: 'center',
        flexWrap: 'wrap',
        paddingLeft: '0.5%',
        paddingRight: '0.5%'

    }
})