export default function ExecSummaryDim (table, dim1, dim2, dim3, dim4, filters) {
  
      return {
          "order": [
            [
                `${table}.dim`,
                "desc"
            ]
        ],
          "limit": 1000,
          "dimensions": [
          `${table}.${dim1}`,
          `${table}.${dim2}`,
          `${table}.updated_at`,
          //`${table}.${dim3}`,
          //`${table}.${dim4}`,
          ],
          "filters" : filters
        }
  
  
  }