import React, {useState} from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Dimensions } from 'react-native'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

const MenuProps = {
    MenuListProps: {
        disablePadding: true
    },
    PaperProps: {
        style: {
            maxWidth: 250,
            maxHeight: '85%'
        },

    },
};


export default function FilterAutoCompleteCohort(props) {


    const [filterList, setFilterList] = useState([])
    const currentMetrics = props.currentMetrics
    const filterList2 = props.filterList
    const setFilterList2 = props.setFilterList
    const setCurrentValue = props.setCurrentValue
    const filterIndex = props.filterIndex
    const filterIndexAdj = filterIndex + 1
    const propObject = props.propObject
    const itemListBase = props.listValues
    const itemTitle = props.type
    const itemTitleString = currentMetrics.primaryDimensionString || 'KW Type'
    const setList = props.setList
    const theme = props.theme
    const handleStateChange = props.handleStateChange
    const large = props.large
    const singleModel = props.singleModel
    const doubleFilter = props.doubleFilter
    const itemList = ['Total', ...itemListBase]

    const currentRef = `filter${filterIndexAdj}List`

    let filterWidth;

    if (doubleFilter && singleModel && !large) {
      filterWidth = windowWidth * 0.20
    } else if (doubleFilter && singleModel && large) {
      filterWidth = windowWidth * 0.33
    } else if (!singleModel && !large) {
      filterWidth = windowWidth * 0.18
    } else if (!singleModel && large) {
      filterWidth = windowWidth * 0.18
    } else if (singleModel && large) {
      filterWidth = windowWidth * 0.24
    } else {
      filterWidth = windowWidth * 0.32
    }

    function handleClose(prop) {

        let defaultArray;

        if (filterList.length === 0) {
            defaultArray = 'Total'
        } else { defaultArray = filterList }

        const propObjectCopy = propObject
        propObjectCopy[itemTitle] = filterList

        setList(filterList)
        const propUpdate = {
            ...propObject,
            [itemTitle] : filterList
        }
        handleStateChange(propUpdate)

        if (filterList.length === 0) {
          setCurrentValue(filterList)
        }
        const filterCopy = {
          ...filterList2,
          [currentRef] : filterList
        }
        
        setFilterList2(filterCopy)
    }

    const handleChange = (event, option) => {
        const {
            target: { value },
        } = event;
        setFilterList(
            // On autofill we get a stringified value.
            
            typeof option === 'string' ? option.split(',') : option,
            console.log(option)
        );

        const filterCopy = {
          ...filterList2,
          [currentRef] : typeof value === 'string' ? value.split(',') : value
        }
        
        setFilterList2(filterCopy)
    };

    let responseHeight;

  if (windowWidth < 1441) {
    responseHeight = windowHeight * 0.045
  } else if (windowWidth > 2000 && large === 1) {
    responseHeight = windowHeight * 0.035
  }
   else if (windowWidth > 2000) {
    responseHeight = windowHeight * 0.03
  } else {responseHeight = windowHeight * 0.0415}

  let responseMargin;

  if (windowHeight < 800 && large === 1) {
    responseMargin = '-1%'
  }  else if (windowWidth > 2000) {
    responseMargin = '-0.8%'
  } else if (windowHeight > 800 && large === 1) {
    responseMargin = '-1.2%'
  }  else if (windowHeight > 800) {
    responseMargin = '-1%'
  } else if (windowWidth < 1441 && large === 1) {
    responseMargin = '-3%'
  } else if (windowWidth < 1441) {
    responseMargin = '-1%'
  } else  {responseMargin = '-1%'}

  let responseText;

  if (windowWidth < 1281) {
    responseText = windowHeight * 0.025
  } else if (windowWidth > 2000 && large === 1) {
    responseText = windowHeight * 0.012
  } else if (windowWidth > 2000) {
    responseText = windowHeight * 0.01
  } else {responseText = windowHeight * 0.017}

  const SelectedOptions = ({ selected }) => (
    <div style={{ maxHeight: 30, overflow: 'hidden', whiteSpace: 'pre-wrap', borderWidth : 0.1,
    borderColor: theme.backgroundColor2,}}>
        {selected.join(', ')}
    </div>
);


    return (

        <Autocomplete
            multiple
            renderTags={(selected) => <SelectedOptions selected={selected} />}
            
            style={{ width: filterWidth,
                        maxHeight: 50,
                        fontFamily : 'Electrolize' , 
                        color : theme.color,
                        borderWidth : 0.1,
                        borderColor: theme.backgroundColor2,
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: theme.backgroundColor}}
            id="checkboxes-tags-demo"
            options={itemList}
            disableCloseOnSelect={true}
            ListboxProps={{ style: { maxHeight: windowHeight * 0.8 } }}
            getOptionLabel={(option) => option || ""}
            onChange={handleChange}
            onClose={handleClose}
            sx={{
                  "& .MuiInputBase-root": { height: 45, border: `1px solid ${theme.backgroundColor2}`, color: theme.color },
                }}
            renderOption={(props, option, { selected }) => (
                <li 
                style={{
                  width : '100%',
                            height: '85%',
                            fontFamily : 'Electrolize', 
                            color : theme.color , 
                            backgroundColor : theme.backgroundColor,
                            borderWidth : 1,
                            border: `1px solid grey`,

                    }}
                {...props}>
                <Checkbox
                    icon={icon}

                    sx={{color: theme.color}}
                    checked={selected}
                />
                {option}
                </li>
            )}
            renderInput={(params) => (
                <TextField 
                {...params}
                InputLabelProps={{ sx: { fontFamily: 'Electrolize',
                        color : theme.color,
                        borderWidth : 1,
                        borderColor: theme.backgroundColor2,
                        fontSize: responseText,
                        marginTop: responseMargin,  
                        textTransform: 'capitalize'   } }}
                
                label={itemTitleString}
                //onChange={(event) => setInputValue(event.target.value)}
                >
                

                </TextField>
            )}
            />
    );
}