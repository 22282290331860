import { memo } from 'react'
import { useDrop } from 'react-dnd'
import { View, Text, Dimensions } from 'react-native'

const windowWidth = Dimensions.get('window').width;

export const BinMetricsT4 = memo(function Dustbin({
  accept,
  lastDroppedItem,
  onDrop,
  theme,
  currentConfig,
  indexCount,
  currentMetrics
}) {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  let dynamicText;

    if (windowWidth > 2000) {
      dynamicText = windowWidth * 0.015
    } else if (windowWidth >= 1400) {
      dynamicText = windowWidth * 0.015
    } else if (windowWidth < 1400) {
      dynamicText = windowWidth * 0.015
    }

  const style = {
    color: 'white',
    height: '80%',
    width: '100%',
    textAlign: 'center',
    lineHeight: 'normal',
    float: 'left',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid white',
  }

  const smallStyle = {
    color: 'white',
    height: '100%',
    width: '16.666667%',
    textAlign: 'center',
    lineHeight: 'normal',
    float: 'left',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid white',
  }

  const textLarge = {
    textAlign: 'center',
    color: 'white',
    fontWeight: 'bold',
    fontFamily: 'Electrolize',
    textTransform: 'capitalize',
    fontSize: dynamicText

  }

  const isActive = isOver && canDrop
  const bucketType = accept[0]
  
  let bucketStyle = bucketType === 'type' ? style : smallStyle;
  let bucketDefault;

  const bucketComments = `Panel ${indexCount + 1}: `
  

  if (!lastDroppedItem && indexCount === 0) {
    bucketDefault = `Panel 1: ${currentConfig.currentPanel1}`
  } else if (!lastDroppedItem && indexCount === 1) {
    bucketDefault = `Panel 2: ${currentConfig.currentPanel2}`
  } else if (!lastDroppedItem && indexCount === 2) {
    bucketDefault = `Panel 3: ${currentConfig.currentPanel3}`
  } else if (!lastDroppedItem && indexCount === 3) {
    bucketDefault = `Panel 4: ${currentConfig.currentPanel4}`
  } else if (!lastDroppedItem && indexCount === 4) {
    bucketDefault = `Panel 5: ${currentConfig.currentPanel5}`
  } else if (!lastDroppedItem && indexCount === 5) {
    bucketDefault = `Panel 6: ${currentConfig.currentPanel6}`
  }  else if (lastDroppedItem && indexCount === 0) {
    bucketDefault = `Panel 1: ${currentConfig.currentPanel1}`
  } else if (lastDroppedItem && indexCount === 1) {
    bucketDefault = `Panel 2: ${currentConfig.currentPanel2}`
  } else if (lastDroppedItem && indexCount === 2) {
    bucketDefault = `Panel 3: ${currentConfig.currentPanel3}`
  } else if (lastDroppedItem && indexCount === 3) {
    bucketDefault = `Panel 4: ${currentConfig.currentPanel4}`
  } else if (lastDroppedItem && indexCount === 4) {
    bucketDefault = `Panel 5: ${currentConfig.currentPanel5}`
  } else if (lastDroppedItem && indexCount === 5) {
    bucketDefault = `Panel 6: ${currentConfig.currentPanel6}`
  }  else {bucketDefault = null}

  let backgroundColor;
  if (isActive) {
    backgroundColor = theme.backgroundColor2
  } else if (canDrop) {
    backgroundColor = '#7DFA00'
  } else if (accept[0] === 'type') {
    backgroundColor = theme.backgroundColor2
  } else if (bucketType === 'xAxis') {
    backgroundColor = theme.gradc50
  } else if (bucketType === 'metric') {
    backgroundColor = theme.color3
  } else {backgroundColor = 'grey' }

  let finalBucketComments;

  if (bucketDefault && bucketDefault.includes('ftds')) {
    finalBucketComments = bucketDefault.replace('ftds', currentMetrics.primaryMetric)
  } else if (bucketDefault && bucketDefault.includes('regs')) {
    finalBucketComments = bucketDefault.replace('regs', currentMetrics.secondaryMetric)
  } else if (bucketDefault && bucketDefault.includes('ngr')) {
    finalBucketComments = bucketDefault.replace('ngr', currentMetrics.primaryRevenue)
  } else {finalBucketComments = bucketDefault}

  return (

    <View ref={drop} style={{ ...bucketStyle, backgroundColor }} data-testid="dustbin">
      <Text style={textLarge}>
      {isActive
        ? 'Release to drop'
        : ""}
      </Text>

        <Text style={textLarge}> {finalBucketComments}</Text>

    </View>

  )
})
