const FlexConfig = (props) => {


    const dataset = props.dataset
    const fieldValues = props.fields
    const dim1 = props.dim1
    const dim2 = props.dim2
    const dim3 = props.dim3
    const primaryMetric = props.currentMetrics.primaryMetric
    const secondaryMetric = props.currentMetrics.secondaryMetric
    const primaryRevenue = props.currentMetrics.primaryRevenue
    const primaryDimension = props.currentMetrics.primaryDimension

    const formats = [{name: "",thousandsSeparator: ",",maxDecimalPlaces: 0,infinityValue: "-",divideByZeroValue: "-",textAlign: "center"},
        {name: "currency",currencySymbol: "€"},
        {name: "spend",decimalPlaces: 0,currencySymbol: " pcs.",positiveCurrencyFormat: "1$"},
        {name: "percent",isPercent: true},
    ]

    

    let excludeArray, slice, rows, columns, measures, dims, metrics;

    function filterIncludes(inputArray, filterArray) {
        const filterSet = new Set(filterArray);
        return inputArray.filter(item => filterSet.has(item));
    }

    function filterExcludes(inputArray, filterArray) {
        const filterSet = new Set(filterArray);
        return inputArray.filter(item => !filterSet.has(item));
    }

    function transformMetrics(metrics) {
        return metrics.map(item => {
            let transformedItem = {
                uniqueName: item,
                aggregation: 'sum',
            };
            if (item === "spend" || item === ("ngr")) {
                transformedItem.format = "currency";
            }
    
            return transformedItem;
        });
    }

    if (dataset === 'summary') {
        excludeArray = [dim1, dim2, dim3, 'timestamp', 'timestamp.day', 'adgroup']
        dims = filterIncludes(fieldValues, excludeArray)        
        metrics = filterExcludes(fieldValues, excludeArray)
        measures = transformMetrics(metrics)

        let cpa, cpr

        cpa = {
            "uniqueName": "CPA",
            "formula": "sum('spend') / sum('ftds')",
            "caption": "cpa",
            "format": "currency"
          }

        cpr = {
            "uniqueName": "CPR",
            "formula": "sum('spend') / sum('regs')",
            "caption": "cpa",
            "format": "currency"
          }

        slice =  [
                {"uniqueName": "date.Month"},
                {"uniqueName": "date.Day","filter": {"members": ["date.year.[2024]"]}},
                {"uniqueName": dim1},
                {"uniqueName": dim2},
                {"uniqueName": dim3},
                {"uniqueName": 'adgroup'},
            ]
        
        rows = [{"uniqueName": dim1}]
        columns = [{"uniqueName": "[Measures]"}]

        measures.push(cpr, cpa)

        return {
            rows,
            columns,
            slice,
            measures,
            formats
        }

        } else if (dataset === 'budget') {

            excludeArray = [dim1, dim2, dim3, 'calendar_date', 'current_dom', 'days_in_month', 'days_remaining']
            metrics = filterExcludes(fieldValues, excludeArray)
            measures = transformMetrics(metrics)

            let cpa, cpr, spend_rate

            cpa = {
                "uniqueName": "CPA",
                "formula": "sum('actual_spend') / sum('ftd')",
                "caption": "cpa",
                "format": "currency"
              }
    
            cpr = {
                "uniqueName": "CPR",
                "formula": "sum('actual_spend') / sum('reg')",
                "caption": "cpr",
                "format": "currency"
              }

              spend_rate = {
                "uniqueName": "SpendRate",
                "formula": "sum('budget') / sum('actual_spend')",
                "caption": "SpendRate",
                "format": "percent"
              }

              slice =  [
                {"uniqueName": dim1},
            ]

            rows = [{"uniqueName": dim1}]
            columns = [{"uniqueName": "[Measures]"}]

            measures.push(cpr, cpa, spend_rate)


            return {
                rows,
                columns,
                slice,
                measures,
                formats
            }

        } else if (dataset === 'publisher') {

            excludeArray = [dim1, dim2, dim3,, 'site_url', 'cpc', 'cpa', 'clickConv']
            metrics = filterExcludes(fieldValues, excludeArray)
            measures = transformMetrics(metrics)

            let cpa, roas

            cpa = {
                "uniqueName": "CPA",
                "formula": "sum('cost') / sum('ftds')",
                "caption": "CPA",
                "format": "currency"
              }

            roas = {
                "uniqueName": "ROAS",
                "formula": "sum('ngr') / sum('cost')",
                "caption": "ROAS",
                "format": "percent"
              }

            
              measures.push(cpa, roas)

              slice =  [
                {"uniqueName": 'timestamp.Month'},
                {"uniqueName": dim1},
                {"uniqueName": dim2},
                {"uniqueName": dim3},
            ]

            rows = [{"uniqueName": dim3}]
            columns = [{"uniqueName": "[Measures]"}]
        
        
            return {
                rows,
                columns,
                slice,
                measures,
                formats
            }
        }

    // next condition

    }



export default FlexConfig