import moment from 'moment-timezone';

export default function ParseAnalytics (cubeResponse, xAxisValue) {

    var dataset = cubeResponse

    if (!dataset || dataset.length === undefined) {
      return null;
    }

    var agg_result = [];

      dataset.reduce(function (res, value) {

        if (!res[value[xAxisValue]]) {
          res[value[xAxisValue]] = {
            [xAxisValue]: value[xAxisValue],
            new_users: 0,
            sessions: 0,
            pageviews: 0,
            reg_start: 0,
            regs: 0,
            ftds: 0,
            ngr: 0,
            spend: 0
          };
          agg_result.push(res[value[xAxisValue]]);
        }
        res[value[xAxisValue]].new_users += parseInt(value.new_users);
        res[value[xAxisValue]].sessions += parseInt(value.sessions);
        res[value[xAxisValue]].pageviews += parseInt(value.pageviews);
        res[value[xAxisValue]].reg_start += parseInt(value.reg_start);
        res[value[xAxisValue]].regs += parseInt(value.regs);
        res[value[xAxisValue]].ftds += parseInt(value.ftds);
        res[value[xAxisValue]].ngr += parseInt(value.ngr);
        res[value[xAxisValue]].spend += parseInt(value.spend);
    
        return res;
      }, {});

      agg_result = agg_result.map((obj) => {
        const spend = obj.spend;
        const ftds = obj.ftds;
        const month = moment.tz(obj.timestamp, 'Europe/Paris').format('YYYY-MM')
        const cpa = spend === 0 || ftds === 0 || isNaN(ftds) ? 0 : spend / ftds;
        return {
          ...obj,
          cpa: parseInt(cpa),
          month: month
        };
      });

    

      return agg_result

}