export default function PivotDims (table, dim1, dim2, dim3, filters) {
  
    return {
        "order": [
          [
              `${table}.${dim1}`,
              "desc"
          ]
      ],
        "limit": 1000,
        "dimensions": [
        `${table}.${dim1}`,
        `${table}.${dim2}`,
        `${table}.${dim3}`,
        `${table}.updated_at`,
        ],
        "filters" : filters
      }


}