import React, { useState } from 'react';
import {
    Modal,
    View,
    StyleSheet,
    Dimensions,
    Text,
    Pressable
} from 'react-native';
import CloseIcon from '@mui/icons-material/Close';
import MobileFABUpdate from '../MobileComponents/MobileFABUpdate';


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
const responsiveTextSmall = windowWidth * 0.05


const ChartScreenModal = (props) => {

    const theme = props.theme
    const visibleModal = props.visible
    const setVisible = props.setVisible
    const setChartConfig = props.setChartConfig
    const agg_daily = props.agg_daily
    const setColumnVisibilityModel = props.setColumnVisibilityModel
    const columnVisibilityModel = props.columnVisibilityModel
    const chartConfig = props.chartConfig
    const selectChart = props.selectChart
    const selectAxis = props.selectAxis
    const selectMetric1 = props.selectMetric1
    const selectMetric2 = props.selectMetric2
    const setSelectChart = props.setSelectChart
    const setSelectAxis = props.setSelectAxis
    const setSelectMetric1 = props.setSelectMetric1
    const setSelectMetric2 = props.setSelectMetric2
    const currentMetrics = props.currentMetrics

    const metric1 = chartConfig.metric1.toLowerCase();
    const metric2 = chartConfig.metric2.toLowerCase();    

    const isChartSelected = (componentId) => {
        return selectChart === componentId;
      };

    const isAxisSelected = (componentId) => {
        return selectAxis === componentId;
      };

    const isMetric1Selected = (componentId) => {
        return selectMetric1 === componentId;
      };

    const isMetric2Selected = (componentId) => {
        return selectMetric2 === componentId;
      };
    function clickHandler (prop) {
        setSelectChart(prop)
    }

    function axisHandler (prop) {
        setSelectAxis(prop)
    }

    function metric1Handler (prop) {
        const lowerProp = prop.toLowerCase();
        setSelectMetric1(prop)
        
        function updateMetric1(input, value) {
        // Create a copy of the input object to avoid modifying the original object
        const updatedInput = { ...input };

        // Update the 'metric1' key with the provided 'value'
        updatedInput[value] = true;
        updatedInput[metric1] = false

        return updatedInput;
        }

        const updatedInput = updateMetric1(columnVisibilityModel, lowerProp);
        setColumnVisibilityModel(updatedInput)

    }

    function metric2Handler (prop) {
        
        const lowerProp = prop.toLowerCase();
        setSelectMetric2(prop)
        
        function updateMetric1(input, value) {
        // Create a copy of the input object to avoid modifying the original object
        const updatedInput = { ...input };

        // Update the 'metric1' key with the provided 'value'
        updatedInput[value] = true;
        updatedInput[metric2] = false

        return updatedInput;
        }

        const updatedInput = updateMetric1(columnVisibilityModel, lowerProp);
        setColumnVisibilityModel(updatedInput)
    }

    function updateHandler (prop) {
        const baseConfig = {
            chart: selectChart,
            axis: selectAxis,
            brand: 'Total',
            channel: 'Total',
            kw_type: 'Total',
            start: chartConfig.start,
            end: chartConfig.end,
            time: 'Day',
            metric1: selectMetric1,
            metric2: selectMetric2
        }

        setChartConfig(baseConfig)
    }

    const chartList = ['Line', 'Bar', 'Mixed', 'Table' ]
    const axisList = ['Date', 'Brand', 'Channel', 'KW_Type' ]
    const metricList = ['Impressions', 'Clicks', 'Spend', 'Regs', 'FTDs', 'CPA']

    const itemContainer = {
        flexDirection: 'row',
        flexWrap: 'wrap', 
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    }

    const itemModal = {
        flexBasis: 'calc(50% - 20px)',
        margin: 5,
        borderWidth: 1,
        borderColor: theme.color,
        borderRadius: 10,
        
    }

    const selectedItem = {
        flexBasis: 'calc(50% - 20px)',
        margin: 5,
        borderWidth: 1,
        borderColor: theme.color,
        borderRadius: 10,
        backgroundColor: theme.backgroundColor2
    }


    const contentContainer = {
        height: '100%',
        width: '100%',
        borderColor: theme.color,
        borderWidth: 0,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        
    }

    const settingContainer = {
        height: '20%',
        width: '100%',
        borderColor: theme.color,
        borderWidth: 1,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        margin: '1%'
    }



    const buttonContainer = {
        height: '10%',
        width: '100%',
        borderColor: theme.color,
        borderWidth: 0,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 10,

    }

    const titleText = {
        fontSize: 20,
        fontFamily: 'Electrolize',
        color: theme.backgroundColor2
   }

   const itemText = { color: theme.color, fontSize: 20, textAlign: 'center' }
   const selectedItemText = { color: theme.backgroundColor, fontSize: 20, textAlign: 'center' }

   const metricText = { color: theme.color, fontSize: 14, textAlign: 'center' }
   const selectedMetricText = { color: theme.backgroundColor, fontSize: 14, textAlign: 'center' }

   const textHandler = (value) => {
    let output;
    console.log(value)
    if (value === 'FTDs') {
      output = currentMetrics.primaryMetricString
    } else if (value === 'Regs') {
      output = currentMetrics.secondaryMetricString
    } else if (value === 'NGR') {
      output = currentMetrics.primaryRevenueString
    } else {
      output = value
    }
    return output
  }

    return (
        <Modal
            visible={visibleModal}
            style={styles.modalParent}
            transparent={true}
        >
            <Pressable
                style={styles.modalContainer}
                onPress={() => setVisible(false)}
            >
                <View style={[styles.modalWindow, {
                    backgroundColor: theme.backgroundColor,
                    borderColor: theme.color
                }]}>
                    <CloseIcon
                        style={{
                            position: 'absolute',
                            left: '94%',
                            top: '0.3%',
                            fontSize: responsiveTextSmall,
                            color: theme.color,
                            backgroundColor: theme.backgroundColor2,
                            borderRadius: 5,

                        }}
                    />
                    <View style={contentContainer}>
                        <View style={settingContainer}>
                        <Text style={titleText}>Chart Type</Text>
                            <View style={itemContainer}>
                                {chartList.map((item) =>
                                    <Pressable
                                        style={isChartSelected(item) ? selectedItem : itemModal}
                                        onPress={() => { clickHandler(item)}}
                                        key={item}
                                    >
                                        <Text style={isChartSelected(item) ? selectedItemText : itemText}>
                                            {item}
                                        </Text>

                                    </Pressable>
                                )
                                }
                            </View>


                        </View>
                        <View style={settingContainer}>
                        <Text style={titleText}>X-Axis</Text>
                        <View style={itemContainer}>
                                {axisList.map((item) =>
                                    <Pressable
                                        style={isAxisSelected(item) ? selectedItem : itemModal}
                                        onPress={() => { axisHandler(item)}}
                                        key={item}
                                    >
                                        <Text style={isAxisSelected(item) ? selectedItemText : itemText}>
                                            {item}
                                        </Text>

                                    </Pressable>
                                )
                                }
                            </View>

                        </View>
                        <View style={settingContainer}>
                        <Text style={titleText}>Metric 1</Text>
                        <View style={itemContainer}>
                                {metricList.map((item) =>
                                    <Pressable
                                        style={isMetric1Selected(item) ? selectedItem : itemModal}
                                        onPress={() => { metric1Handler(item)}}
                                        key={item}
                                    >
                                        <Text style={isMetric1Selected(item) ? selectedMetricText : metricText}>
                                            {textHandler(item)}
                                        </Text>

                                    </Pressable>
                                )
                                }
                            </View>
                        </View>
                        <View style={settingContainer}>
                        <Text style={titleText}>Metric 2</Text>
                        <View style={itemContainer}>
                                {metricList.map((item) =>
                                    <Pressable
                                        style={isMetric2Selected(item) ? selectedItem : itemModal}
                                        onPress={() => { metric2Handler(item)}}
                                        key={item}
                                    >
                                        <Text style={isMetric2Selected(item) ? selectedMetricText : metricText}>
                                        {textHandler(item)}
                                        </Text>

                                    </Pressable>
                                )
                                }
                            </View>

                        </View>
                        <View style={buttonContainer}>
                            <MobileFABUpdate
                                theme={theme}
                                buttonText={'Update'}
                                setChartConfig={setChartConfig}
                                setVisible={setVisible}
                                updateHandler={updateHandler}
                            />

                        </View>

                    </View>
                </View>


            </Pressable>


        </Modal>
    )
}


const styles = StyleSheet.create({
    modalParent: {
        padding: 20,
        borderRadius: 20,

    },
    modalContainer: {

        width: windowWidth * 1,
        height: windowHeight * 0.845,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalWindow: {
        margin: 0,
        height: '95%',
        width: '95%',
        borderWidth: 2,
        borderRadius: 5,
        padding: '1%',
        alignItems: 'center',
        justifyContent: 'center',
    }
});


export default ChartScreenModal