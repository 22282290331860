import React from 'react';
import { Modal, 
    View, 
    StyleSheet, 
    Dimensions, 
    Pressable } from 'react-native';
import OptionsModalClient from '../Options/OptionsModalClient';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const ModalClient = (props) => {

    const modalClientHandler = props.modalClientHandler
    const visibleClientModal = props.visibleClientModal
    const clientHandler = props.clientHandler
    const theme = props.theme


    return (
        <Modal
            visible={visibleClientModal}
            style={styles.modalParent}
            transparent={true}
            >
        <Pressable 
            style={styles.modalContainer}
            onPress={() => modalClientHandler(false)}
            >
            <View style={[styles.modalWindow , {
                backgroundColor : theme.backgroundColor2
                }]}>

                <OptionsModalClient 
                    style={{flex: 1}}
                    theme={theme}
                    clientHandler={clientHandler}
                    modalClientHandler={modalClientHandler}
                    >
                    

                </OptionsModalClient>

            </View>


        </Pressable>


        </Modal>
    )

}

const styles = StyleSheet.create({
    modalParent: {
        padding: 20,
        borderRadius: 20,
    },
    modalContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        width: windowWidth * 1,
        height: windowHeight * 1,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
    modalWindow: {
        margin: 10,
        height: '30%',
        width: '25%',
        borderColor: 'white',
        borderWidth: 1,
        borderRadius: 15,
        padding: '1%',
        alignItems: 'center',
        justiftContent: 'center'
    }
});


export default ModalClient