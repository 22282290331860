import React, { useEffect } from 'react';
import { Modal, View, StyleSheet, Dimensions, Text } from 'react-native';
import { Button } from '@react-native-material/core';
import ModalPanelChart from '../ChartBuilder/ModalPanelChart';
import ModalPanelAxis from '../ChartBuilder/ModalPanelAxis';
import ModalPanelMetric1 from '../ChartBuilder/ModalPanelMetric1';
import ModalPanelMetric2 from '../ChartBuilder/ModalPanelMetric2';
import WebFont from 'webfontloader';


const windowHeight = Dimensions.get('window').height;

const ModalChartType = (props) => {

    const setChartClick = props.setChartClick
    const chartFilterVisible = props.chartFilterVisible
    const chartClickHandler = props.chartClickHandler
    const axisClickHandler = props.axisClickHandler
    const metric1Handler = props.metric1Handler
    const metric2Handler = props.metric2Handler
    const theme = props.theme;
    const handleModalButton = props.handleModalButton
    const modalType = props.modalType
    const currentMetrics = props.currentMetrics

    const containerStyle = {
        padding: 20,
        borderRadius: 20,

    };


    const renderComponentContent = () => {
        if (modalType === 'chart') {
        return <ModalPanelChart 
                    theme={theme} 
                    chartClickHandler={chartClickHandler}
                    />;
        } else if (modalType === 'axis') {
        return <ModalPanelAxis 
                    theme={theme} 
                    axisClickHandler={axisClickHandler}
                    />;
         } else if (modalType === 'metric1') {
            return <ModalPanelMetric1 
                        theme={theme} 
                        metric1Handler={metric1Handler}
                        currentMetrics={currentMetrics}
                        />;
        } else if (modalType === 'metric2') {
            return <ModalPanelMetric2 
                        theme={theme} 
                        metric2Handler={metric2Handler}
                        currentMetrics={currentMetrics}
                        />;
        } else {
        return null;
        }
    };

    return (

        <Modal
            visible={chartFilterVisible}
            contentContainerStyle={containerStyle}
            transparent={true}
        >

            <View
                style={{
                    flexDirection: 'column',
                    borderColor: 'red',
                    borderWidth: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                    


                }}>
                <View style={{
                    margin: 10,
                    height: '50%',
                    width: '50%',
                    borderColor: theme.color,
                    backgroundColor: theme.backgroundColor,
                    borderWidth: 1,
                    borderRadius: 15,
                    padding: '1%',
                    alignItems: 'center',
                    justiftContent: 'center'
                }}>

                    {renderComponentContent()}
                    <Button
                        style={{ height: '9%', 
                                    width: '99%', 
                                    marginBottom: 0, 
                                    marginLeft: 0,
                                    fontFamily: 'Electrolize', }}
                        onPress={() => handleModalButton(false)}
                        color={theme.backgroundColor2}
                        title={'Close'}>

                    </Button>

                </View>

            </View>
        </Modal>
    );
};

export default ModalChartType;

const styles = StyleSheet.create({
    containerStyle: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 50,
    },
});
