import { Dimensions, View } from 'react-native';
import React, { useState } from 'react';
import MobileAppBar from '../MobileComponents/MobileAppBar';
import MobileHomePanel from './MobileHomePanel';
import { useCubeQuery } from '@cubejs-client/react';
import ParseHome from '../../Home/CubeFunctions/ParseHome';
import HomeBudget from '../../Home/CubeFunctions/HomeBudget';
import CubeBudget from '../../Budgets/CubeFunctions/CubeBudget.js';
import ParseBudget from '../../Budgets/CubeFunctions/ParseBudget.js';
import LoadingComponent from '../../Components/Loader';
import MobileBottomNav from '../MobileComponents/MobileBottomNav';
import moment from 'moment-timezone';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const MobileHome = (props) => {

    const value = props.value
    const setValue = props.setValue
    const configArray = props.configArray
    const screenHandler = props.screenHandler
    const currentMetrics = props.currentMetrics
    const configHandler = configArray.configHandler
    const clientHandler = configArray.clientHandler
    const clientID = configArray.clientID
    const comparisonURL = configArray.comparison_url
    const clientBase = clientID.toLowerCase();
    let client;
    if (clientBase === 'all_clients') {
        client = 'stack'
    } else (client = clientBase)
    const cubeTable = client + "_alerts";
    const budgetTable = client + "_budget";
    const theme = props.theme
    const homeTitle = "Pattern Home";
    const role = configArray.role
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayProp = yesterday2.format('YYYY-MM-DD');

    const [currentGraph, setCurrentGraph] = useState('ftds_diff_mom')

    function graphHandler (prop) {
        setCurrentGraph(prop)
    }

    const currentDate = new Date();
    currentDate.setMinutes(0);
    const previousDate = new Date(currentDate);
    previousDate.setDate(currentDate.getDate() - 30);
    previousDate.setDate(1)

    const startDateString = previousDate.toISOString().slice(0, 10);

    const cubeFilters = []

    const cubeQuery = ParseHome(cubeTable)
    const { resultSet } = useCubeQuery(cubeQuery);
    const budgetCubeQuery = HomeBudget(budgetTable, startDateString, yesterdayProp)
    const budgetResultSet = useCubeQuery(budgetCubeQuery);

    const budgetQuery = CubeBudget(cubeFilters, budgetTable, startDateString, yesterdayProp)
    const budgetRawresultSet = useCubeQuery(budgetQuery);

    if (!resultSet) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    if (budgetResultSet.isLoading === true) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    if (budgetRawresultSet.isLoading === true) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    const cubeResponse = resultSet.loadResponse.results[0].data
    const budgetCubeResponse = budgetResultSet.resultSet.loadResponse.results[0].data
    const budgetRawCubeResponse = budgetRawresultSet.resultSet.loadResponse.results[0].data
    

    const updateArray = cubeResponse.map(obj => {
        const newObj = {};
        for (const key in obj) {
            const newKey = key.replace(cubeTable + ".", "");
            newObj[newKey] = obj[key];
        }
        return newObj;
    });

    const budgetArray = budgetCubeResponse.map(obj => {
        const newObj = {};
        for (const key in obj) {
            const newKey = key.replace(budgetTable + ".", "");
            newObj[newKey] = obj[key];
        }
        return newObj;
    });

  const getUniqueValues = (arr, key) => {
      const uniqueSet = new Set(arr.map(item => item[key]));
      return [...uniqueSet];
  };

  console.log(budgetRawCubeResponse)  
  const agg_result = ParseBudget(budgetRawCubeResponse, budgetTable, 'graph')


    const dateLabels = getUniqueValues(agg_result, 'calendar_date')

    const container = {
        height: windowHeight,
        width: windowWidth,
        borderColor: theme.backgroundColor2,
        borderWidth: 0,
        backgroundColor: theme.backgroundColor
    }

    const homePanel = {
        height: windowHeight * 0.863,
        width: '100%',
        borderColor: theme.backgroundColor2,
        borderWidth: 0,
        backgroundColor: theme.backgroundColor,
        marginTop: '0.1%',

    }

    return (
        <View style={container}>
            <MobileAppBar
                theme={theme}
                configHandler={configHandler}
                clientHandler={clientHandler}
                homeTitle={'Home'}
                role={role}
                screenHandler={screenHandler}
            />
            <View style={homePanel}>
                <MobileHomePanel
                    theme={theme}
                    data={updateArray}
                    clientID={clientID}
                    comparisonURL={comparisonURL}
                    budgetArray={budgetArray}
                    currentGraph={currentGraph}
                    graphHandler={graphHandler}
                    budgetData={agg_result}
                    dateLabels={dateLabels}
                    currentMetrics={currentMetrics}
                />
            <MobileBottomNav
                theme={theme}
                screenHandler={screenHandler}
                value={value}
                setValue={setValue}
            />
            </View>            
        </View>
    )

}

export default MobileHome