import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Text,
} from 'react-native'
import WebFont from 'webfontloader';
import OptionsProfile from './OptionsProfile';
import OptionsTheme from './OptionsTheme';
import OptionsClient from './OptionsClient';
import OptionsHomePanel from './OptionsHomePanel';


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const OptionsPanel = (props) => {

    const configArray = props.configArray  
    const theme = props.theme
    const configHandler = props.configHandler
    const userProfile = props.userProfile
    const clientHandler = props.clientHandler
    const role = configHandler.role
    const isAdmin = role === "admin";
    const clientID = props.clientID

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);


    return (
        <View style={styles.container}>
                <OptionsProfile
                    theme={theme}
                    configArray={configArray}
                    configHandler={configHandler}
                    userProfile={userProfile}
                />
                <OptionsTheme
                    theme={theme}
                    configArray={configArray}
                    configHandler={configHandler}
                    userProfile={userProfile}
                />
                {isAdmin && 
                <OptionsClient
                    theme={theme}
                    configArray={configArray}
                    clientHandler={clientHandler}
                    userProfile={userProfile}
                />}
                <OptionsHomePanel
                    theme={theme}
                    clientID={clientID}
                />

     
        </View>

    )

}

export default OptionsPanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: windowHeight * 0.931,
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
        paddingLeft: '5%',
        paddingRight: '5%'
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})