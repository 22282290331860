import React from 'react';
import { View , 
    Dimensions , 
    Text,
    Pressable
} from 'react-native'
import { useNavigate } from 'react-router-dom';
import DrawerImport from '../../DrawerList/DrawerList';
import routemap from '../../routes.json'
import DrawerIcons from '../../Components/DrawerIcons';
 
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const DrawerList = (props) => {

    const client = props.clientID
    const theme = props.theme
    const navigate = useNavigate();
    const drawerList = DrawerImport(client, theme)
    const currentRoutes = routemap[client]['config']

    const excludeRoutes = ['Home', 'Login', 'TESTING']


    const filterList = currentRoutes.filter((item) => {
        return !excludeRoutes.includes(item.text);
    });
    
    let responseText, isEnhanced;

    if (client === 'Glitnor') {
      isEnhanced = true
    } else if (client === 'Rank') {
      isEnhanced = true
    } else if (client === 'Hero') {
      isEnhanced = true
    } else { isEnhanced = false}

    if (windowWidth < 1281) {
        responseText = windowHeight * 0.025
    } else if (windowWidth > 2000) {
        responseText = windowHeight * 0.015
    } else {responseText = windowHeight * 0.017}


    const configBase = {
        mainGraph: 'cumulative',
        panel1: 'mtdSpendRate',
        panel2: 'yestSpendRate',
        shortcuts: ['Daily Summary', 
                    'Weekly Summary', 
                    'Monthly Summary',
                    'Budget Summary',
                    'Dashboard Builder',
                    'GA4 Summary',
                    'GA4 Attribution',
                    'PPT Builder',
                    'Cohorts ROAS'],
        mainIcon: 'dashBuilder'
    }

    
    if (!JSON.parse(localStorage.getItem("homeConfig"))) {
        localStorage.setItem("homeConfig", JSON.stringify(configBase))
    }

    const storageConfig = JSON.parse(localStorage.getItem("homeConfig"))
    const shortcutConfig = storageConfig.shortcuts


    function filterByConfig(data, strings) {
        const filteredData = [];
    
        // Loop through each item in the strings array
        strings.forEach(text => {
            // Find the item in the data array that matches the current string
            const item = data.find(item => item.text === text);
            // If the item is found, push it to the filteredData array
            if (item) {
                filteredData.push(item);
            }
        });
    
        return filteredData;
    }

    const newShortcutConfig = filterByConfig(filterList, shortcutConfig)

    let dynamicHeight;

    if (windowWidth > 2000) {
        dynamicHeight = 2
    } else if (windowWidth > 1281) {
        dynamicHeight = 1
    }  else (dynamicHeight = 0.45)

    let dynamicPanel;
    if (windowWidth > 1600) {
        dynamicPanel = '100%'
    } else (dynamicPanel = '95%')

    let dyanmcicText;

    if (windowWidth > 2000) {
        dyanmcicText = 100
    } else if ( windowWidth > 1280) {
        dyanmcicText = 80
    } else { dyanmcicText = 40}


    const container = {
        height: dynamicPanel,
        width: '100%',
        borderWidth: 1,
        borderRadius: 0,
        borderColor: theme.color,
        flexDirection: 'row',
        fontFamily: 'Electrolize',
        alignItems: 'center',
        justifyContent: 'center',
    }

    const metricContainer = {
        flex: 1,
        height: '100%',
        borderWidth: 0,
        borderColor: theme.color,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: `linear-gradient(to top, ${theme.backgroundColor} 60%, ${theme.backgroundColor} 0%)`

    }

    const textStyle = {
        fontFamily: 'Electrolize',
        color: theme.color,
        fontSize: dyanmcicText * 0.2,
        padding: '6%',
        fontWeight: 'bold'
    }

    const textStyleLarge = {
        fontFamily: 'Electrolize',
        color: theme.color,
        fontSize: dyanmcicText * 0.25,
        padding: '6%',
        fontWeight: 'bold'
    }

    const metricStyle = {
        fontFamily: 'Electrolize',
        color: theme.backgroundColor,
        fontSize: dyanmcicText,
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
        borderColor: theme.color,
        borderWidth: 1,

    }

    const metricStyleLarge = {
        fontFamily: 'Electrolize',
        color: theme.backgroundColor,
        fontSize: dyanmcicText * 1.4,
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
        borderColor: theme.color,
        borderWidth: 1,

    }

    const iconCircle = {
        borderColor: theme.color,
        borderRadius: 100,
        borderWidth: 1,
        padding: '6%',
        backgroundColor: theme.backgroundColor2,
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))'

    }

    const containerParent = {
        borderWidth: 0,
        borderColor: theme.color,
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    }

    const iconLargeStyle = {
        fontFamily: 'Electrolize',
        color: theme.backgroundColor,
        fontSize: responseText * 4 ,
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
        borderColor: theme.color,
        borderWidth: 1,
    }

    const iconSmall = {
        fontFamily: 'Electrolize',
        color: theme.backgroundColor,
        fontSize: responseText * 6 ,
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
        borderColor: theme.color,
        borderWidth: 1
    }

    return (
        <View style={containerParent}>
            <View style={container}>
           {newShortcutConfig.map((item, index) => {
            const IconComponentSmall = DrawerIcons[item.iconHome]
            const IconComponentLarge = DrawerIcons[item.iconLarge]
            return (
                
                <View style={metricContainer} key={index}>
                        <Pressable 
                            style={iconCircle}
                            onPress={() =>  navigate(item.path)}>    
                                {index === 4 ? 
                                    <IconComponentSmall
                                        sx={iconSmall}
                                    /> : 
                                    <IconComponentLarge
                                        sx={iconLargeStyle}
                                    />}                            
                        </Pressable>
                        <Text style={textStyle}>
                            {item.text}
                        </Text>
                    </View>
           )}
                            )}
                </View>
        </View>
    )


}

export default DrawerList
