import React, { useState } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Pressable,
    Text
} from 'react-native'
import moment from 'moment-timezone';
import LineChart from '../../ChartsDesktop/LineChart';
import BarChart from '../../ChartsDesktop/BarChart';
import MixedChart from '../../ChartsDesktop/MixedChart';
import ChartBuilderTable from '../ChartBuilderTable';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import ChartBuilderSettingsModal from '../ChartBuilderModals/ChartBuilderSettingsModal';
import ChartBuilderSaveModal from '../ChartBuilderModals/ChartBuilderSaveModal';
import ChartBuilderOpenModal from '../ChartBuilderModals/ChartBuilderOpenModal';
import ChartBuilderDeleteModal from '../ChartBuilderModals/ChartBuilderDeleteModal';
import LoadingComponent from '../../Components/Loader';

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

const ChartBuilderTime = (props) => {

    const theme = props.theme
    const dataProp = props.dataProp
    const currentMetrics = props.currentMetrics
    const client = props.client
    const config = props.config.currentDataset    
    const panelContent = props.config.panelContent
    const [isCumulative, setIsCumulative] = useState(false)
    const [checked, setChecked] = React.useState(false);    
    const [settingsOpen, setSettingsOpen] =  useState(false)
    const [saveOpen, setSaveOpen] = useState(false)
    const [openOpen, setOpenOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [currentConfig, setCurrentConfig] = useState(config)    

    const currentConfigStorage = JSON.parse(localStorage.getItem('chartConfig'))
    const reportList = Object.keys(currentConfigStorage)
    const lastChartUsed = localStorage.getItem('lastChart')

    if (!lastChartUsed) {
        localStorage.setItem('lastChart', 'default')
    }

    const type = currentConfig.type
    const dimension = currentConfig.dimension
    const metric1 = currentConfig.metric1
    const metric2 = currentConfig.metric2

    const handleChange = (event) => {
        setChecked(event.target.checked);
        setIsCumulative(event.target.checked)
        
      };   

    const saveHandler = (textValue) => {
        let storagePush;        

        if(reportList.includes(textValue)) {
            const configCopy = currentConfigStorage
            configCopy[lastChartUsed][panelContent] = currentConfig
            localStorage.setItem('chartConfig', JSON.stringify(configCopy))
            localStorage.setItem('lastChart', textValue)
            //push to storage
        } else if (panelContent === 'time') {
            storagePush = {
                [textValue]: {
                    time: {
                        dataset: 'time',
                        type: type,
                        dimension: dimension,
                        metric1: metric1,
                        metric2: metric2
                    },
                    adgroup: {
                        dataset: 'adgroup',
                        type: 'table',
                        dimension: 'adgroup',
                        metric1: 'spend',
                        metric2: 'ftds'
                    },
                    budget: {
                        dataset: 'budget',
                        type: 'area',
                        dimension: 'calendar_date',
                        metric1: 'actual_spend',
                        metric2: 'budget'
                    }
        
                }
            }
            const storageUpdate = {...currentConfigStorage, ...storagePush}
            
            localStorage.setItem('chartConfig', JSON.stringify(storageUpdate))
            localStorage.setItem('lastChart', textValue)
        }

    }

    const openHandler = (value) => {
        const openConfig = currentConfigStorage[value][panelContent]
        setCurrentConfig(openConfig)
        localStorage.setItem('lastChart', value)
    }

    const deleteHandler = (value) => {

        function filterObjectByKey(obj, keyToRemove) {
            const filteredObject = {};
            Object.keys(obj).forEach(key => {
                if (key !== keyToRemove) {
                    filteredObject[key] = obj[key];
                }
            });
        
            return filteredObject;
        }

        const newReportList = filterObjectByKey(currentConfigStorage, value);

        if (value === lastChartUsed) {
            localStorage.setItem('chartConfig', JSON.stringify(newReportList))            
            localStorage.setItem('lastChart', 'default')
            openHandler('default')
            
        } else {
            localStorage.setItem('chartConfig', JSON.stringify(newReportList))  
        }
    }
      
      const headerToggleContainer = {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        fontFamily: 'Electrolize',
    }

    const cumuText = {
        fontFamily: 'Electrolize',
        fontWeight: 'bold',
        color: theme.color,
        fontSize: windowWidth * 0.01
    }

    function getDateObject(timestamp, dimension) {
        const date = moment.tz(timestamp, 'Europe/Malta');
        switch (dimension) {
            case 'day':
                return date.format('YYYY-MM-DD');
            case 'week':
                return date.startOf('isoWeek').format('YYYY-MM-DD');
            case 'month':
                return date.startOf('month').format('YYYY-MM-DD');
            case 'year':
                return date.startOf('year').format('YYYY-MM-DD');
            default:
                return date.format('YYYY-MM-DD');
        }
    }

    function aggregateData(data, dimension) {
        const groupedData = {};
    
        data.forEach(obj => {
            const dateObject = getDateObject(obj.timestamp, dimension);
            if (!groupedData[dateObject]) {
                groupedData[dateObject] = {
                    date: dateObject,
                    impressions: 0,
                    clicks: 0,
                    spend: 0,
                    regs: 0,
                    ftds: 0,
                    ngr: 0,
                    cpc: 0,
                    cpa: 0,
                    roas: 0,
                    count: 0 // Count the number of entries for averaging calculations
                };
            }
            groupedData[dateObject].impressions += parseInt(obj.impressions);
            groupedData[dateObject].clicks += parseInt(obj.clicks);
            groupedData[dateObject].spend += parseFloat(obj.spend);
            groupedData[dateObject].regs += parseInt(obj.regs);
            groupedData[dateObject].ftds += parseInt(obj.ftds);
            groupedData[dateObject].ngr += parseFloat(obj.ngr);
            groupedData[dateObject].count++;
        });
    
        // Calculate averages for CPC, CPA, ROAS
        const result = Object.values(groupedData).map(entry => {
            entry.cpc = (entry.spend / entry.clicks) || 0;
            entry.cpa = (entry.spend / entry.ftds) || 0;
            entry.roas = (entry.ngr / entry.spend) || 0;
            return entry;
        });
    
        return result;
    }

    const agg_result = aggregateData(dataProp, dimension)
 
    const calcCumulative = (data) => {
        let cumuImps = 0, cumuClicks = 0, cumuSpend = 0, cumuRegs = 0, cumuFtds = 0, cumuNgr = 0;

        return data.map(item => {
            cumuImps += item.impressions;
            cumuClicks += item.clicks;
            cumuSpend += item.spend;
            cumuRegs += item.regs;
            cumuFtds += item.ftds;
            cumuNgr += item.ngr;

            return {
                date: item.date,
                impressions: cumuImps,
                clicks: cumuClicks,
                spend: cumuSpend,
                regs: cumuRegs,
                ftds: cumuFtds,
                ngr: cumuNgr,
                cpc: cumuImps > 0 ? cumuClicks / cumuImps : 0,
                cpa: cumuFtds > 0 ? cumuSpend / cumuFtds : 0,
                roas: cumuSpend > 0 ? cumuNgr / cumuSpend : 0
            }
        })
    }
    
    const cumuData = calcCumulative(agg_result)

    let dynamicData;

    if (isCumulative) {
    dynamicData = cumuData
    } else {
    dynamicData = agg_result
    }

    if (!agg_result[0]) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    const configKeys = Object.keys(agg_result[0])

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
    };

    const dateLabels = getUniqueValues(agg_result, 'date')

    const renderComponentContent = () => {
        if (type === 'line') {
            return <LineChart
                    agg_daily_result={dynamicData}
                    dateLabels={dateLabels}
                    theme={theme}
                    metric={metric1}
                    timeFrame={'daily'}
                    client={client}
                    currentMetrics={currentMetrics}
                    currentConfig={currentConfig}
                />;
        } else if (type === 'bar') {
                    return <BarChart
                    dataProp={dynamicData}
                    labels={dateLabels}
                    theme={theme}
                    metric={metric1}
                    timeFrame={'daily'}
                    client={client}
                    currentMetrics={currentMetrics}
                />;
        } else if (type === 'mixed') {
                    return <MixedChart
                    dataProp={dynamicData}
                    labels={dateLabels}
                    theme={theme}
                    agg_daily_result={dynamicData}
                    dateLabels={dateLabels}
                    metric1={metric1}
                    metric2={metric2}
                    timeFrame={'daily'}
                    client={client}
                    currentMetrics={currentMetrics}
                />
        } else if (type === 'table') {
                    return <ChartBuilderTable
                    dataProp={dynamicData}
                    theme={theme}
                    currentMetrics={currentMetrics}
                    currentDim={'date'}
                    isCumulative={isCumulative}
                />
        } else {
            return null;
        }
    };

    return (
        <View style={styles.container}>
            <View style={styles.headerRow}>
            <View style={styles.textContainer}>
                <Text style={{
                    fontSize: windowWidth * 0.01,
                    fontFamily: 'Electrolize',
                    fontWeight: 'bold',
                    color: theme.color,
                }}>
                    Current Template: {lastChartUsed}
                </Text>

            </View>
            <View style={styles.buttonContainer}>
            
            <View style={headerToggleContainer}>
                    <Text style={cumuText}>Cumulative:</Text>
                    <Switch
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        />

                    </View>

            </View>
            
            <View style={styles.buttonContainer}>
                <Button 
                    sx={{
                        backgroundColor: theme.backgroundColor2,
                        fontFamily: 'Electrolize',
                        width: '100%',
                        height: '100%',
                        fontSize: windowWidth * 0.007
                    }}
                    onClick={() => setDeleteOpen(true)}
                    variant="contained">
                    Delete
                </Button>

            </View>
            <View style={styles.buttonContainer}>
                <Button 
                    sx={{
                        backgroundColor: theme.backgroundColor2,
                        fontFamily: 'Electrolize',
                        width: '100%',
                        height: '100%',
                        fontSize: windowWidth * 0.007
                    }}
                    onClick={() => setOpenOpen(true)}
                    variant="contained">
                    Open
                </Button>

            </View>
            <View style={styles.buttonContainer}>
                <Button 
                    sx={{
                        backgroundColor: theme.backgroundColor2,
                        fontFamily: 'Electrolize',
                        width: '100%',
                        height: '100%',
                        fontSize: windowWidth * 0.007
                    }}
                    onClick={() => setSaveOpen(true)}
                    variant="contained">
                    Save
                </Button>

            </View>
            <View style={styles.buttonContainer}>
                <Button 
                    sx={{
                        backgroundColor: theme.backgroundColor2,
                        fontFamily: 'Electrolize',
                        width: '100%',
                        height: '100%',
                        fontSize: windowWidth * 0.007
                    }}
                    onClick={() => setSettingsOpen(true)}
                    variant="contained">
                    Settings
                </Button>

            </View>

            </View>
            <View style={styles.chartRow}>
                {renderComponentContent()}
            </View>
            <ChartBuilderSettingsModal
                theme={theme}
                open={settingsOpen}
                setOpen={setSettingsOpen}
                setCurrentConfig={setCurrentConfig}
                lastChartUsed={lastChartUsed}
                panelContent={panelContent}
                currentConfig={currentConfig}
                configKeys={configKeys}
                reportList={reportList}
                currentMetrics={currentMetrics}
            />
            <ChartBuilderSaveModal
                theme={theme}
                open={saveOpen}
                setOpen={setSaveOpen}
                setCurrentConfig={setCurrentConfig}
                lastChartUsed={lastChartUsed}
                panelContent={panelContent}
                currentConfig={currentConfig}
                configKeys={configKeys}
                reportList={reportList}
                saveHandler={saveHandler}
            />
            <ChartBuilderOpenModal
                theme={theme}
                open={openOpen}
                setOpen={setOpenOpen}
                setCurrentConfig={setCurrentConfig}
                lastChartUsed={lastChartUsed}
                panelContent={panelContent}
                currentConfig={currentConfig}
                configKeys={configKeys}
                reportList={reportList}
                openHandler={openHandler}
            />
            <ChartBuilderDeleteModal
                theme={theme}
                open={deleteOpen}
                setOpen={setDeleteOpen}
                setCurrentConfig={setCurrentConfig}
                lastChartUsed={lastChartUsed}
                panelContent={panelContent}
                currentConfig={currentConfig}
                configKeys={configKeys}
                reportList={reportList}
                deleteHandler={deleteHandler}
            />

        </View>

    )

}

export default ChartBuilderTime

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: windowHeight * 0.83,
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    headerRow: {
        height: '7%',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'right',
        //position: 'absolute',
        //left: 0,
        //top: 0,
        //zIndex: 100000,
        borderWidth: 0.1,
        borderColor: 'grey',
        padding: '0.5%'
    },
    chartRow: {
        height: '93%',
        width: '100%',
        padding: '1%'
    },
    buttonContainer: {
        height: '100%',
        width: '10%',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0.2%'
    },
    textContainer: {
        height: '100%',
        width: '20%',
        //alignItems: 'center',
        justifyContent: 'center',
        padding: '1%',
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 100000,
    }

})