import React, { useState } from 'react';
import {View, 
    Dimensions, 
    Text,
    Pressable } from 'react-native';

const windowWidth = Dimensions.get('window').width;
const responsiveTextSmall = windowWidth * 0.02

const DashGraphMetrics = (props) => {

    const theme = props.theme
    const mainGraphHandler = props.mainGraphHandler
    const currentMetric = props.currentMetric
    const uniqueMetrics = props.uniqueMetrics
    const panelNumber = props.panelNumber
    const setVisible = props.setVisible
    const currentMetrics = props.currentMetrics

    const [pressedElement, setPressedElement] = useState(currentMetric);
    
    const handlePress = (type , panelNumber) => {
        mainGraphHandler(panelNumber, type)
        console.log(panelNumber, type)
        setPressedElement(type);
        setVisible(false)

      };

    const container = {
        height: '15%',
        width: '100%',
        flexDirection: 'column',
        flex: 1
        
        
    }
    const typeItem = {
        flex: 1,
        width: '100%',
        flexDirection: 'column',
        borderWidth: 1,
        borderColor: theme.color,
        backgroundColor: theme.backgroundColor,
        alignItems: 'center',
        justifyContent: 'center',

    }

    const text = {
        fontSize : responsiveTextSmall,
        fontFamily: 'Electrolize',
        textAlign: 'center',
        color: theme.color
    }

    const selectedChild = {
        borderWidth: 1,
        borderColor: theme.color2,
        backgroundColor: theme.backgroundColor2,
        alignItems: 'center',
        justifyContent: 'center'
      }
    const selectedText = {
        color: theme.color2, // Change this to your desired text color
        fontSize : responsiveTextSmall,
        fontFamily: 'Electrolize',
        textAlign: 'center',
      }

    return (
        <View style={container}>
      {uniqueMetrics.map((item) => {
        let formatLabel;

        if (item === 'ftds') {
          formatLabel = currentMetrics.primaryMetric
        } else if (item === 'ngr') {
          formatLabel = currentMetrics.primaryRevenue
        } else if (item === 'regs') {
          formatLabel = currentMetrics.secondaryMetric
        } else {formatLabel = item}
        
        return (
        <Pressable
          key={item}
          style={[
            typeItem,
            pressedElement === item && selectedChild,
          ]}
          onPress={() => handlePress(item, panelNumber)}
        >
          <Text
            style={[
              text,
              pressedElement === item && selectedText,
            ]}
          >
            {formatLabel}
          </Text>
        </Pressable>
      )}
      )}
    </View>
    )
}

export default DashGraphMetrics