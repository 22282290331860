import React from 'react';
import {
    View,
    StyleSheet,
    Dimensions
} from 'react-native'
import FilterBrand from '../Filters/FilterBrand';
import FilterChannel from '../Filters/FilterChannel'
import FilterType from '../Filters/FilterType';
import FilterDate from '../Filters/FilterDate';


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

let dateCorrect;

    if (windowWidth > 1600) {
        dateCorrect = '0.7%'
    } else { dateCorrect = '1.2%'}

const FilterPanel = (props) => {

    const uniqueBrands = props.uniqueBrands
    const uniqueChannels = props.uniqueChannels
    const uniqueTypes = props.uniqueTypes
    const propObject = props.propObject
    const handleStateChange = props.handleStateChange
    const typeFilterState = props.typeFilterState !== undefined ? props.typeFilterState : false;
    const brandFilterState = props.brandFilterState !== undefined ? props.brandFilterState : false;
    const channelFilterState = props.channelFilterState !== undefined ? props.channelFilterState : false;
    const theme = props.theme

    


    return (


        <View style={styles.container}>
            <View style={styles.filterContainer}>
                <FilterBrand
                    uniqueBrands={uniqueBrands}
                    propObject={propObject}
                    handleStateChange={handleStateChange}
                    theme={theme}
                    brandFilterState={brandFilterState}
                />
            </View>
            <View style={styles.filterContainer}>
                <FilterChannel
                    uniqueChannels={uniqueChannels}
                    propObject={propObject}
                    handleStateChange={handleStateChange}
                    theme={theme}
                    channelFilterState={channelFilterState}
                />
            </View>
            <View style={styles.filterContainer}>
                <FilterType
                    uniqueTypes={uniqueTypes}
                    propObject={propObject}
                    handleStateChange={handleStateChange}
                    theme={theme}
                    typeFilterState={typeFilterState}
                />
            </View>

            <View style={styles.dateFilterContainer}>
                <FilterDate
                    propObject={propObject}
                    handleStateChange={handleStateChange}
                    theme={theme}
                />
            </View>
        </View>


    )

}

export default FilterPanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        flexDirection: 'row',
        width: '100%',
        flex: 1,
        padding: '1%'


    },
    filterContainer: {
        width: '25%',
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 0,
        borderColor: 'blue',
        paddingLeft: '0.5%'

    },
    dateFilterContainer: {

        borderWidth: 0,
        borderColor: 'red',
        width: '25%',
        paddingLeft: '0.5%',


    }
})