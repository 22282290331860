import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid
   } from '@mui/x-data-grid';
import { Dimensions } from 'react-native'
import WebFont from 'webfontloader';
const windowWidth = Dimensions.get('window').width;


export default function DocTable (props) {

    const dataPropBase = props.dataProp
    const currentTemplate = props.currentTemplate
    const rows = currentTemplate === 4 ? 10 : props.size
    const columnWidth = props.columnWidth
    const theme = props.theme    
    const componentRef = useRef(null);
    const [componentHeight, setComponentHeight] = useState(0);

    const fallback = [{
        channel: 'No Data',
        impressions: 0,
        clicks: 0,
        spend: 0,
        regs: 0,
        ftds: 0,
        cpa: 0
    }]

    const dataProp = dataPropBase.length > 0 ? dataPropBase : fallback

    const responsiveFont = windowWidth * 0.01
    const responsiveFontLarge = windowWidth * 0.015
    var rowHeight = 0
    if (rows >4 ) { rowHeight = componentHeight / 11}
    else {rowHeight = componentHeight / 8.5}
    
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
        id: false,
        impressions: true,
        clicks: true,
        ctr: false,
        cpc: false,
        clickConv: false



    });


    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Electrolize']
            }
        });
    }, []);

    useEffect(() => {
        if (componentRef.current) {
          const height = componentRef.current.clientHeight;
          setComponentHeight(height);
        }
      }, []);

    let uniqueIdCounter = 1;

    // Use forEach to add a unique ID to each record
    dataProp.forEach((record) => {
        record.id = uniqueIdCounter++;
    });

    console.log(dataProp)

    const labels = Object.keys(dataProp[0]).map((key, index) => {
        return {
            field: key,
            headerName: key,
            minWidth: windowWidth * columnWidth * 1.25,
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'table-header',
            align: 'center',
            renderCell: (params) => {
                const { value, field } = params;
                const heatmapColor = calculateHeatmapColor(value, { field });
                if (index <= 0) {
                    return (
                        <div
                            className="heatmap-cell"
                            style={{
                                backgroundColor: heatmapColor,
                                padding: '10%',
                                color: theme.color,
                                width: '80%',
                                fontSize: responsiveFont,
                                fontFamily: 'Electrolize'
                            }}
                        >
                            {value}
                        </div>
                    );
                }

                return (
                    <div
                        className="heatmap-cell"
                        style={{
                            backgroundColor: heatmapColor,
                            padding: '10%',
                            width: '80%',
                            fontSize: responsiveFont,
                            fontFamily: 'Electrolize'
                        }}
                    >
                        {value}
                    </div>
                );
            },

        };
    });

    const columnRanges = {};
    Object.keys(dataProp[0]).forEach((column) => {
        const values = dataProp.map((record) => record[column]);
        const min = Math.min(...values);
        const max = Math.max(...values);
        columnRanges[column] = { min, max };
    });

    function calculateHeatmapColor(value, column) {
        const { min, max } = columnRanges[column.field];
        const range = max - min;

        // Define input colors for the heatmap in RGB format
        const fromColor = { r: theme.comboStopColor1ARRAY[0], g: theme.comboStopColor1ARRAY[1], b: theme.comboStopColor1ARRAY[2] };
        const toColor = { r: theme.comboStopColor2ARRAY[0], g: theme.comboStopColor2ARRAY[1], b: theme.comboStopColor2ARRAY[2] };;
      


        const relativeValue = (value - min) / range;
        const red = Math.round((1 - relativeValue) * fromColor.r + relativeValue * toColor.r);
        const green = Math.round((1 - relativeValue) * fromColor.g + relativeValue * toColor.g);
        const blue = Math.round((1 - relativeValue) * fromColor.b + relativeValue * toColor.b);

        return `rgba(${red},${green},${blue},0.7)`;
    }

    return (
        <Box
            style={{
                width: '100%',
                fontSize: 50,
                color: 'black',
                fontFamily: 'Electrolize',
                height: '100%',
                

            }}
            sx={{
                '& .table-header': { 
                headerAlign: 'center', 
                align : 'center' , 
                fontFamily : 'Electrolize' , 
                color: theme.color, 
                fontSize: responsiveFontLarge },
                width: '100%'
            }}
            ref={componentRef}
        >
            <DataGrid
                rows={dataProp}
                columns={labels}
                rowHeight={rowHeight}


                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                    setColumnVisibilityModel(newModel)
                }
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: rows }
                    },
                    
                    sorting: {
                        sortModel: [{ field: 'ftds', sort: 'desc' }],
                    },
                }}

            />
        </Box>
    );
}