export default function PivotAdGroup(cubeFilters, table, start, end, dim1, dim2, dim3) {

    return {
        "timeDimensions": [
            {
                "dimension": `${table}.timestamp`,
                "dateRange": [
                    start,
                    end
                ]
            }
        ],
        "order": [
            [
                `${table}.date`,
                "desc"
            ]
        ],
        "measures": [
            `${table}.impressions`,
            `${table}.clicks`,
            `${table}.spend`,
            `${table}.regs`,
            `${table}.ftds`,
            `${table}.ftd_ngr`,
        ],
        "dimensions": [
            `${table}.${dim1}`,
            `${table}.${dim2}`,
            `${table}.${dim3}`,
            `${table}.adgroup`,
            `${table}.date`
        ],
        "limit": 10000,
        "filters": cubeFilters
    }

}