import React, { useState , useEffect } from 'react';
import { View , 
    StyleSheet , 
    Dimensions , 
    Text,
    Pressable
} from 'react-native'
import LineChart from '../ChartsDesktop/LineChart';
import AnalyticsText from '../Analytics/AnalyticsText';
import FiltersEnhancedSingle_2 from '../ComponentsEnhanced/FiltersEnhancedSingle_2';
import BarChart from '../ChartsDesktop/BarChart';
import MixedChart from '../ChartsDesktop/MixedChart';
import WebFont from 'webfontloader';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const AnalyticsPanels = (props) => {

    const dataProp = props.dataProp
    const currentMetrics = props.currentMetrics
    const agg_channel_result = dataProp.agg_channel_result
    const agg_type_result = dataProp.agg_type_result
    const agg_brand_result = dataProp.agg_brand_result
    const dataPropFilter = props.dataPropFilter
    const uniqueFilter1 = dataPropFilter.uniqueFilter1
    const uniqueFilter2 = dataPropFilter.uniqueFilter2
    const filterParams = props.filterParams
    const dateLabels = props.dateLabels
    const currentData = dataProp.agg_daily_result
    const timeFrame = props.timeFrame
    const [selectedComponent, setSelectedComponent] = useState("Row2_Bar2");
    const theme = props.theme
    const handleStateChange = props.handleStateChange
    const propObject = props.propObject
    const currentChannel = props.currentChannel
    const filterList = props.filterList
    const setFilterList = props.setFilterList

    const handleComponentClick = (componentName) => {
        setSelectedComponent(componentName);
    };

    useEffect(() => {
        WebFont.load({
          google: {
            families: ['Droid Sans', 'Electrolize']
          }
        });
       }, []);

    const renderComponentContent = () => {
        if (selectedComponent === 'Row1_Line1') {
        return <Row1_Line1 style={panelMain}/>;
        } else if (selectedComponent === 'Row1_Line2') {
        return <Row1_Line2 style={panelMain}/>;
        } else if (selectedComponent === 'Row1_Line3') {
        return <Row1_Line3 style={panelMain}/>;
        } else if (selectedComponent === 'Row2_Bar1') {
        return <Row2_Bar1 style={panelMain}/>;
        } else if (selectedComponent === 'Row2_Mixed1') {
        return <Row2_Mixed1 style={panelMain}/>;
        } else if (selectedComponent === 'Row2_Bar2') {
        return <Row2_Bar2 style={panelMain}/>;
        } else {
        return null;
        }
    };


    const panelMain = {

        width : '66.666666666%',
        height : windowHeight * 0.65,
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems : 'center',
        justifyContent : 'center',
        textAlign : 'center',
        fontSize : 40,
        fontFamily : 'Electrolize',
        borderRadius : 5
    }
    
    const panelChild = {
        flex : 1,
        height : '100%',
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems : 'center',
        justifyContent : 'center',
        textAlign : 'center',
        fontSize : 20,
        fontFamily : 'Electrolize',
        borderRadius : 5,
    }

    agg_channel_result.sort((a, b) => b.ftds - a.ftds);

// Get the first 5 results
    const firstFiveChannels = agg_channel_result.slice(0, 5);

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
      };

    const uniqueFirstFive = getUniqueValues(firstFiveChannels , "channel")


    const Row1_Line1 = () => {

        return (
        <View style={styles.lineChart}>
            <LineChart
                agg_daily_result={currentData}
                dateLabels={dateLabels}
                theme={theme}
                metric={"sessions"}
                timeFrame={timeFrame}
                currentMetrics={currentMetrics}
            />
        </View>)
    }

    const Row1_Line2 = () => {

        return (
        <View style={styles.lineChart}>
            <LineChart
                agg_daily_result={currentData}
                dateLabels={dateLabels}
                theme={theme}
                metric={"new_users"}
                timeFrame={timeFrame}
                currentMetrics={currentMetrics}
            />
        </View>)
    }


    const Row1_Line3 = () => {

        return (
        <View style={styles.lineChart}>
            <LineChart
                agg_daily_result={currentData}
                dateLabels={dateLabels}
                theme={theme}
                metric={"ngr"}
                timeFrame={timeFrame}
                currentMetrics={currentMetrics}
            />
        </View>)
    }

    const Row2_Bar1 = () => {
        return(
        <View style={{flex : 1 , width : '100%', alignItems : 'center' , justifyContent : 'center'}}>
            <BarChart 
            dataProp={firstFiveChannels}
            labels={uniqueFirstFive}
            theme={theme}
            metric={'new_users'}
            timeFrame={timeFrame}
            currentMetrics={currentMetrics}
        />
        </View>)
    }

    const Row2_Mixed1 = () => {
        return(
        <View style={{flex : 1 , width : '100%', alignItems : 'center' , justifyContent : 'center'}}>
            <MixedChart 
            dataProp={agg_brand_result}
            labels={uniqueFilter1}
            theme={theme}
            agg_daily_result={currentData}
            dateLabels={dateLabels}
            metric1={'spend'}
            metric2={'ftds'}
            timeFrame={timeFrame}
            currentMetrics={currentMetrics}
        />
        </View>)
    }

    const Row2_Bar2 = () => {
        return(
            <View style={{flex : 1 , width : '100%', alignItems : 'center' , justifyContent : 'center'}}>
                <BarChart 
                dataProp={firstFiveChannels}
                labels={uniqueFirstFive}
                theme={theme}
                metric={'ftds'}
                timeFrame={timeFrame}
                currentMetrics={currentMetrics}
            />
            </View>)
        }

    return (
        <View style={styles.container}>
        <View style={{flexDirection : 'row' }}>
        <View style={panelMain}>
        <View style={{width : '100%', flexDirection : 'flex-start' , fontFamily : 'Electrolize'}}>
            <FiltersEnhancedSingle_2
                style={{
                    height: '10%',
                    borderColor: 'red',
                    borderWidth: 0,
                    width: '100%',
                    fontFamily: 'Electrolize'
                }}
                uniqueFilter1={uniqueFilter1}
                uniqueFilter2={uniqueFilter2}
                handleStateChange={handleStateChange}
                propObject={propObject}
                filterParams={filterParams}
                theme={theme}
                currentChannel={currentChannel}
                setFilterList={setFilterList}
                filterList={filterList}
                currentMetrics={currentMetrics}
            />
                </View>
                
        {renderComponentContent()}        
        </View>
        <AnalyticsText 
            style={{width : '100%'}}
            theme={theme}
            dataProp={dataProp}
            currentMetrics={currentMetrics}
        />
        </View>
        <View style={{flexDirection : 'row', flex : 1}}>
        <Pressable style={panelChild} onPress={() => handleComponentClick('Row1_Line1')}>
        <View style={styles.lineChart}>
        <LineChart
            agg_daily_result={currentData}
            dateLabels={dateLabels}
            theme={theme}
            metric={"sessions"}
            timeFrame={timeFrame}
            currentMetrics={currentMetrics}
        />
        </View>
        </Pressable>
        <Pressable style={panelChild} onPress={() => handleComponentClick('Row1_Line2')}>
        <View style={styles.lineChart}>
        <LineChart
            agg_daily_result={currentData}
            dateLabels={dateLabels}
            theme={theme}
            metric={"new_users"}
            timeFrame={timeFrame}
            currentMetrics={currentMetrics}
        />
        </View>
        </Pressable>
        <Pressable style={panelChild} onPress={() => handleComponentClick('Row1_Line3')}>
        <View style={styles.lineChart}>
        <LineChart
            agg_daily_result={currentData}
            dateLabels={dateLabels}
            theme={theme}
            metric={"ngr"}
            timeFrame={timeFrame}
            currentMetrics={currentMetrics}
        />
        </View>
        </Pressable>

        </View>
        
        <View style={{flexDirection : 'row', flex : 1}}>
        <Pressable style={panelChild} onPress={() => handleComponentClick('Row2_Bar1')}>
        <BarChart 
            dataProp={firstFiveChannels}
            labels={uniqueFirstFive}
            theme={theme}
            metric={'new_users'}
            timeFrame={timeFrame}
            currentMetrics={currentMetrics}
        />
        </Pressable>
        <Pressable style={panelChild} onPress={() => handleComponentClick('Row2_Mixed1')}>
        <MixedChart 
            dataProp={agg_brand_result}
            labels={uniqueFilter1}
            theme={theme}
            agg_daily_result={currentData}
            dateLabels={dateLabels}
            metric1={'spend'}
            metric2={'ftds'}
            timeFrame={timeFrame}
            currentMetrics={currentMetrics}
        />
        </Pressable>
        <Pressable style={panelChild} onPress={() => handleComponentClick('Row2_Bar2')}>
        <BarChart 
            dataProp={firstFiveChannels}
            labels={uniqueFirstFive}
            theme={theme}
            metric={'ftds'}
            timeFrame={timeFrame}
            currentMetrics={currentMetrics}
        />
        </Pressable>

        </View>
        
        </View>
        
    )

}

export default AnalyticsPanels

const styles = StyleSheet.create({
    container: {
      borderWidth: 0,
      borderColor: 'blue',
      height : (windowHeight * 0.93) * 1.3,
      width : '100%',
      flexDirection : 'column',
      fontFamily : 'Electrolize',
      

    },
    lineChart : {
        flex : 1,
        width : '100%',
        alignItems : 'center',
        padding : '1%',
        fontFamily : 'Electrolize'
    }
})