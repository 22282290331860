import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { GridToolbarContainer,
        GridToolbarColumnsButton,
        GridToolbarFilterButton,
        GridToolbarDensitySelector,
        GridToolbarExport 
       } from '@mui/x-data-grid';
import { Dimensions } from 'react-native'
import WebFont from 'webfontloader';
import { DataGridPremium,
  GRID_AGGREGATION_FUNCTIONS, } from '@mui/x-data-grid-premium';
import AttModels from '../AttModels';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function AttEnhancedLookerTable(props) {

  const dataProp = props.dataProp
  const models = props.models
  const columnWidth = props.columnWidth
  const theme = props.theme
  const model1 = models.model1
  const model2 = models.model2
  const model3 = models.model3
  const dynamicFilter = props.dynamicFilter
  const filterParams = props.filterParams
  const filter1name = filterParams.filter1name
  const filter2name = filterParams.filter2name
  const filter3name = filterParams.filter3name


  const [aggregationModel, setAggregationModel] = useState({
    spend: 'sum',
    netProfit: 'sum',
    cpc: 'cpc',
    cpa: 'cpa',
    [filter1name]: 'total',
    costConv: 'costConv',
    recovered: 'recovered',
    ftds: 'sum',
    totalConv: 'sum'

  });


  let responseText;

    if (windowWidth < 1281) {
        responseText = windowHeight * 0.025
    } else if (windowWidth > 2000) {
        responseText = windowHeight * 0.018
    } else {responseText = windowHeight * 0.017}

    let responseRow;

    if (windowWidth < 1281) {
      responseRow = windowHeight * 0.05
    } else if (windowWidth > 2000) {
      responseRow = windowHeight * 0.05
    } else {responseRow = windowHeight * 0.04}


  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
    clicks: false,
    impressions: false,
    regs: false,
    spend: true,
    totalConv: true,
    ngr: false,
    ftds: true
  });

  const valueFormat = (value, field) => {
    if (field === 'cpa' || field === 'spend' || field.includes("ngr") || field === 'cpc' || field === 'netProfit' || field === 'costConv'  ) {
        return `€${value}`;
    } else if (field === 'recovered') {
        const percent = (value * 100).toFixed(2); // Convert to percent and round to 2 decimal places
        return `${percent}%`;
    }
    return value;
};

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Electrolize']
      }
    });
   }, []);

    let uniqueIdCounter = 1;

    // Use forEach to add a unique ID to each record
    dataProp.forEach((record) => {
    record.id = uniqueIdCounter++;
    });

    const labelObject = dataProp[0] || []

    const labels = Object.keys(labelObject).map((key , index) => {
        return { 
        field: key, 
        headerName: key, 
        minWidth: windowWidth * columnWidth * 1.25, 
        flex: 1, 
        headerAlign: 'center', 
        headerClassName: 'table-header', 
        align: 'center',
        type: 'number',
        renderCell: (params) => {
            const { value, field } = params;
            const heatmapColor = calculateHeatmapColor(value, { field });
            if (index < 1) {
                return (
                  <div
                    className="heatmap-cell"
                    style={{
                      backgroundColor: heatmapColor,
                      padding: '7%',
                      color: theme.color,
                      width: '100%',
                      fontSize: responseText * 0.8
                    }}
                  >
                    {valueFormat(params.formattedValue, params.field)}
                  </div>
                );
              } else if (params.aggregation) {
                return (
                    <div
                        className="heatmap-cell-aggregated"
                        style={{
                            fontWeight: 'bold', // Add your custom font weight
                            display: 'flex', // Apply flex styles to center content vertically/horizontally
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%', // Ensure the cell takes up the full height
                            width: '100%',
                            color: theme.color,
                            paddingLeft: 0,
                            fontFamily: 'Electrolize',
                            fontSize: responseText * 0.8,
                        }}
                    >
                        {valueFormat(params.formattedValue, params.field)}
                    </div>
                );
            } else return (
                <div
                  className="heatmap-cell"
                  style={{
                    backgroundColor: heatmapColor,
                    padding: '10%',
                    width: '100%' ,
                    fontSize: responseText * 0.8
                    
                  }}
                >
                  {valueFormat(params.formattedValue, params.field)}
                </div>
              );
            },
        
        };
      });  

    const columnRanges = {};
    Object.keys(labelObject).forEach((column) => {
    const values = dataProp.map((record) => record[column]);
    const min = Math.min(...values);
    const max = Math.max(...values);
    columnRanges[column] = { min, max };
    });

    function calculateHeatmapColor(value, column) {
  const { min, max } = columnRanges[column.field];
  const range = max + 1- min;

// Define input colors for the heatmap in RGB format
  const fromColor = { r: theme.comboStopColor1ARRAY[0], g: theme.comboStopColor1ARRAY[1], b: theme.comboStopColor1ARRAY[2] };
  const toColor = { r: theme.comboStopColor2ARRAY[0], g: theme.comboStopColor2ARRAY[1], b: theme.comboStopColor2ARRAY[2] };;

  const relativeValue = (value - min) / range;
  const red = Math.round((1 - relativeValue) * fromColor.r + relativeValue * toColor.r);
  const green = Math.round((1 - relativeValue) * fromColor.g + relativeValue * toColor.g);
  const blue = Math.round((1 - relativeValue) * fromColor.b + relativeValue * toColor.b);

  return `rgba(${red},${green},${blue},0.9)`;
}

function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{color: theme.color}}>
      <GridToolbarColumnsButton sx={{color: theme.color}}/>
        <GridToolbarFilterButton sx={{color: theme.color}}/>
        <GridToolbarDensitySelector sx={{color: theme.color}}/>
        <GridToolbarExport sx={{color: theme.color}}/>
    </GridToolbarContainer>
  );
}

function calculateCPA (spend, ftd) {
  const cpa =  spend === 0 || ftd === 0 || isNaN(ftd) ? 0 : spend / ftd

  return cpa
}

function calculateARPU (ngr, ftd) {
  const arpu =  (ngr / ftd)

  return Math.round(arpu)
}

function calculateCPC (spend, clicks) {
    const cpc =  (spend / clicks)
  
    return parseFloat(cpc.toFixed(2))
  }

function calculateCostConv (spend, totalConv) {
    const costConv =  spend === 0 || totalConv === 0 || isNaN(totalConv) ? 0 : spend / totalConv
  
    return parseFloat(costConv.toFixed(2))
  }

  function calculateRecovered (ngr, spend) {
    const recovered =  spend === 0 || ngr === 0 || isNaN(ngr) ? 0 : ngr / spend
  
    return parseFloat(recovered.toFixed(2))
  }

function calculateTotal (spend, ftd) {
  return "TOTAL"
  }

  const recovered = {
    label: '',
    getCellValue: ({ row }) => ({ ngr: row.ngr, spend: row.spend }),
    apply: ({ values }) => {
      let ngr = 0;
      let spend = 0;
      values.forEach((value) => {
        if (value) {
            ngr += value.ngr || 0; // Adds 0 if value.spend is null or NaN
            spend += value.spend || 0;
        }
      });
      return calculateRecovered(ngr, spend);
    },
    columnTypes: ['number'],
  };

  const costConv = {
    label: '',
    getCellValue: ({ row }) => ({ spend: row.spend, totalConv: row.totalConv }),
    apply: ({ values }) => {
      let spend = 0;
      let totalConv = 0;
      values.forEach((value) => {
        if (value) {
            spend += value.spend || 0; // Adds 0 if value.spend is null or NaN
            totalConv += value.totalConv || 0;
        }
      });
      return calculateCostConv(spend, totalConv);
    },
    columnTypes: ['number'],
  };

  const cpc = {
    label: '',
    getCellValue: ({ row }) => ({ spend: row.spend, clicks: row.clicks }),
    apply: ({ values }) => {
      let spend = 0;
      let clicks = 0;
      values.forEach((value) => {
        if (value) {
            spend += value.spend || 0; // Adds 0 if value.spend is null or NaN
            clicks += value.clicks || 0;
        }
      });
      return calculateCPC(spend, clicks);
    },
    columnTypes: ['number'],
  };

const cpa = {
  label: '',
  getCellValue: ({ row }) => ({ spend: row.spend, ftd: row.ftds }),
  apply: ({ values }) => {
    let spend = 0;
    let ftd = 0;
    values.forEach((value) => {
      if (value) {
          spend += value.spend || 0; // Adds 0 if value.spend is null or NaN
          ftd += value.ftd || 0;
      }
    });
    return calculateCPA(spend, ftd);
  },
  columnTypes: ['number'],
};

const arpu = {
  label: '',
  getCellValue: ({ row }) => ({ ngr: row[`ngr_${model1}`], ftd: row[[`ftds_${model1}`]] }),
  apply: ({ values }) => {
    let ngr = 0;
    let ftd = 0;
    values.forEach((value) => {
      if (value) {
          ngr += value.ngr || 0; // Adds 0 if value.spend is null or NaN
          ftd += value.ftd || 0;
      }
    });
    return calculateARPU(ngr, ftd);
  },
  columnTypes: ['number'],
};

  const total = {
    label: '',
    getCellValue: ({ row }) => ({ spend: row.spend, ftd: row.ftds }),
    apply: ({ values }) => {
      let spend = 0;
      let ftd = 0;
      values.forEach((value) => {
        if (value) {
            spend += value.spend || 0; // Adds 0 if value.spend is null or NaN
            ftd += value.ftd || 0;
        }
      });
      return calculateTotal(spend, ftd);
    },
    columnTypes: ['number'],
  };

  const largeRows = ((windowHeight / 120).toFixed(0))

  return (
    <Box
    style={{
      width: '100%',
      height : windowHeight * 0.82, 
      fontSize: 50,
      color: 'black',
      fontFamily : 'Electrolize',
 
      
    }}
      sx={{
        '& .table-header': { 
          headerAlign: 'center', 
          align : 'center' , 
          fontFamily : 'Electrolize' , 
          color: theme.color, 
          fontSize: responseText, },
          '& .MuiDataGrid-row' : {backgroundColor : theme.backgroundColor},
          '& .MuiTablePagination-root' : {color: theme.color},
        width: '100%'}}
    >
      <DataGridPremium 
        rows={dataProp}
        columns={labels}
        rowHeight={responseRow * 0.75}

        pagination
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        aggregationModel={aggregationModel}
                onAggregationModelChange={(newModel) => setAggregationModel(newModel)}
                aggregationFunctions={{
                ...GRID_AGGREGATION_FUNCTIONS,
                cpa,
                total,
                arpu,
                cpc,
                costConv,
                recovered
                }}
        initialState={{
          pagination: {
            paginationModel: { pageSize: parseInt(largeRows) }           
          },
        sorting: {
        sortModel: [{ field: 'spend', sort: 'desc' }],
        },
        }}
        
        />
    </Box>
  );
}