import React, {useEffect} from 'react';
import { View, Text, StyleSheet, Dimensions } from 'react-native';
import WebFont from 'webfontloader';

const windowWidth = Dimensions.get('window').width;

const PanelsText = (props) => {

    const theme = props.theme
    const responsiveLarge = windowWidth *0.025
    const responsiveSmall = windowWidth *0.01
    const client = props.client
    const currentMetrics = props.currentMetrics
    let convLabel

    if (client === 'glitnor') {
      convLabel = 'TotalConv'
    } else {convLabel = 'NDCs'}

    useEffect(() => {
      WebFont.load({
        google: {
          families: ['Droid Sans', 'Electrolize']
        }
      });
     }, []);

    const valueText = {
        fontSize: responsiveLarge,
        color : theme.color,
        textAlign : 'center',
        padding : '1%',
        fontFamily: 'Electrolize'

      }
    const subHeaderText = {
        fontSize : responsiveSmall,
        color : theme.color,
        fontWeight : 100,
        fontFamily: 'Electrolize'
        
      }

    const container = {
        flex: 1,
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        backgroundColor : theme.backgroundColor
      }

    const agg_daily_result = props.dataProp
    
    
    let totalSpend = 0;
      for (let i in agg_daily_result) {
        totalSpend += agg_daily_result[i].spend
      }


      let totalReg = 0;
      for (let i in agg_daily_result) {
        totalReg += agg_daily_result[i].regs
      }
      

      let totalFTD = 0;
      for (let i in agg_daily_result) {
        totalFTD += agg_daily_result[i].ftds
      }


      let totalClicks = 0;
      for (let i in agg_daily_result) {
        totalClicks += agg_daily_result[i].clicks
      }

      let totalImps = 0;
      for (let i in agg_daily_result) {
        totalImps += agg_daily_result[i].impressions
      }


      const totalCPA = parseFloat((totalSpend / totalFTD).toFixed(2)).toLocaleString();
      const totalCPR = parseFloat((totalSpend / totalReg).toFixed(2)).toLocaleString();
      const spendTotal = parseFloat(totalSpend.toFixed(0)).toLocaleString();
      const clickThrough = ((totalClicks / totalImps)*100).toFixed(2)
      const regFormat = parseFloat(totalReg.toFixed(0)).toLocaleString();
      const ftdFormat = parseFloat(totalFTD.toFixed(0)).toLocaleString();
      const clickFormat = parseFloat(totalClicks.toFixed(0)).toLocaleString();


  return (
    <View style={container}>
      <View style={styles.row}>
        <View style={styles.column}>
          <View style={styles.section}>
            <Text
            style={valueText}>
            €{spendTotal}
            </Text>
            <Text style={subHeaderText}>
            Total Spend
            </Text>
          </View>
        </View>
        <View style={styles.column}>
          <View style={styles.section}>
            <Text style={valueText}>
            €{totalCPA}
            </Text>
            <Text style={subHeaderText}>
            CPA
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.column}>
          <View style={styles.section}>
          <Text style={valueText}>
            {regFormat}
            </Text>
            <Text style={subHeaderText}>
            {currentMetrics.secondaryMetricString}</Text>
          </View>
        </View>
        <View style={styles.column}>
          <View style={styles.section}>
          <Text style={valueText}>
            {ftdFormat}
            </Text>
            <Text style={subHeaderText}>
            {currentMetrics.primaryMetricString}</Text>
          </View>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.column}>
          <View style={styles.section}>
          <Text style={valueText}>
            {clickFormat}
            </Text>
            <Text style={subHeaderText}>
            Clicks</Text>
          </View>
        </View>
        <View style={styles.column}>
          <View style={styles.section}>
          <Text style={valueText}>
            {clickThrough}%
            </Text>
            <Text style={subHeaderText}>
            CTR</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  
  row: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  column: {
    flex: 1,
    alignItems: 'center',

    borderRadius : 10,

  },
  section: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 0.1,
    borderColor: 'grey',
    padding : '1%',
    borderRadius : 0,
    filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2))',
  },
  
});

export default PanelsText;
