import React, { useState } from 'react';
import { View, 
    Text, 
    Pressable, 
    Modal,
    StyleSheet,
    Dimensions } from 'react-native'
import CloseIcon from '@mui/icons-material/Close';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import DashEditorGrid from './DashEditorGrid';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
const responsiveTextSmall = windowWidth * 0.02

const DashEditorViewer = (props) => {

    const currentMetrics = props.currentMetrics
    const theme = props.theme
    const cubeTable = props.cubeTable
    const setVisible = props.setViewerVisible
    const viewerVisible = props.viewerVisible
    const currentItem = props.currentItem
    const agg_result = props.agg_result
    const dateLabels = props.dateLabels
    const uniqueBrands = props.uniqueBrands
    const uniqueChannels = props.uniqueChannels
    const uniqueTypes = props.uniqueTypes


    const graphPanel = {
        height: '100%',
        width: '100',
        borderColor: 'red',
        borderWidth: 0
    }

    return (
        
        <Modal
            visible={viewerVisible}
            style={styles.modalParent}
            transparent={true}
            >
        <Pressable 
            style={styles.modalContainer}
            onPress={() => setVisible(false)}
            >
            <View style={[styles.modalWindow , {
                backgroundColor : theme.backgroundColor,
                borderColor: theme.color
            }]}>


                <DndProvider backend={HTML5Backend}>
                    <View style={graphPanel}>
                        <DashEditorGrid
                            theme={theme}
                            currentItem={currentItem}
                            agg_result={agg_result}
                            uniqueBrands={uniqueBrands}
                            uniqueChannels={uniqueChannels}
                            uniqueTypes={uniqueTypes}
                            dateLabels={dateLabels}
                            cubeTable={cubeTable}
                            currentMetrics={currentMetrics}

                        />

                    </View>
                    
                </DndProvider>
            

            </View>


        </Pressable>


        </Modal>
        )
    }

export default DashEditorViewer

const styles = StyleSheet.create({
    modalParent: {
        padding: 50,
        borderRadius: 20,
        
    },
    modalContainer: {
        width: windowWidth * 1,
        height: windowHeight * 1,
        backgroundColor: 'rgba(0, 0, 0, 0.0)',
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: 'blue',
        borderWidth: 0,
    },
    modalWindow: {
        marginTop: '0%',
        height: '85%',
        width: '100%',
        borderColor: 'blue',
        borderWidth: 0,
        borderRadius: 0,
        padding: '0%',
        alignItems: 'center',
        justiftContent: 'center',

        }

})