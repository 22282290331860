import React, { useState } from 'react';
import {View, 
    Dimensions, 
    StyleSheet,
    Pressable } from 'react-native';
import DashLine from './DashLine';
import DashGraphModalChildV2 from './DashGraphModalChildV2';
import DashBar from './DashBar';
import DashMixed from './DashMixed';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import DashTable from './DashTable';
import Tooltip from '@mui/material/Tooltip';


const windowWidth = Dimensions.get('window').width;
const responsiveTextSmall = windowWidth * 0.01

const DashGraphChild = (props) => {

    const theme = props.theme
    const setDashConfig = props.setDashConfig
    const [visible, setVisible] = useState(false)
    const currentMetrics = props.currentMetrics
    const mainAgg = props.mainAgg
    const currentSettings = props.currentSettings
    const currentChild = props.currentChild
    const currentChildText = 'child'+currentChild
    const currentType = currentSettings[currentChildText].type
    const currentAxis = currentSettings[currentChildText].xAxis
    const currentTime = currentSettings[currentChildText].time
    const currentMetric1 = currentSettings[currentChildText].metric1
    const currentMetric2 = currentSettings[currentChildText].metric2
    const childGraphHandler = props.childGraphHandler
    const mainGraphHandler = props.mainGraphHandler
    const uniqueDimensions = props.uniqueDimensions
    const dashConfig = props.dashConfig
    const currentDash = props.currentDash
    const reportIndex = props.reportIndex

    const uniqueMetrics = ['impressions', 'clicks', 'spend', 'cpc', 'regs', 'ftds', 'cpr', 'cpa']

    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
        id: false,
        impressions: currentMetric1 === 'impressions' || currentMetric2 === 'impressions',
        clicks: currentMetric1 === 'clicks' || currentMetric2 === 'clicks',
        cpa: currentMetric1 === 'cpa' || currentMetric2 === 'cpa',
        cpc: currentMetric1 === 'cpc' || currentMetric2 === 'cpc',
        cpr: currentMetric1 === 'cpr' || currentMetric2 === 'cpr',
        ctr: currentMetric1 === 'ctr' || currentMetric2 === 'ctr',
        regs: currentMetric1 === 'regs' || currentMetric2 === 'regs',
        ftds: currentMetric1 === 'ftds' || currentMetric2 === 'ftds',
        spend: currentMetric1 === 'spend' || currentMetric2 === 'spend',
        brand: currentAxis === 'brand',
        channel: currentAxis === 'channel',
        kw_type: currentAxis === 'kw_type',

    });

    const visibilityCopy = {
        id: false,
        impressions: currentMetric1 === 'impressions' || currentMetric2 === 'impressions',
        clicks: currentMetric1 === 'clicks' || currentMetric2 === 'clicks',
        cpa: currentMetric1 === 'cpa' || currentMetric2 === 'cpa',
        cpc: currentMetric1 === 'cpc' || currentMetric2 === 'cpc',
        cpr: currentMetric1 === 'cpr' || currentMetric2 === 'cpr',
        ctr: currentMetric1 === 'ctr' || currentMetric2 === 'ctr',
        regs: currentMetric1 === 'regs' || currentMetric2 === 'regs',
        ftds: currentMetric1 === 'ftds' || currentMetric2 === 'ftds',
        spend: currentMetric1 === 'spend' || currentMetric2 === 'spend',
        brand: currentAxis === 'brand',
        channel: currentAxis === 'channel',
        kw_type: currentAxis === 'kw_type',

    }


    const labels = mainAgg.map((obj) => obj[currentAxis]);

    const panelMain = {
        width: '100%',
        height: '100%'
    }

    const widthPercentage = currentType === 'mixed' ? '95%' : '95%';

    const optionsContainer = {
        width: '5%',
        height: '7%',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        left: widthPercentage,
        top: '0%',
    }

    const optionsButton = {
        color: theme.backgroundColor2,
        fontSize: responsiveTextSmall,
    }


    const renderComponentContent = () => {
        if (currentType === 'line') {
        return (<DashLine 
                    style={panelMain}
                    agg_daily_result={mainAgg}
                    metric={currentMetric1}
                    theme={theme}
                    timeFrame={currentTime}
                    labels={labels}
                    currentMetrics={currentMetrics}
                    />);
        } else if (currentType === 'bar') {
            return (<DashBar 
                        style={panelMain}
                        dataProp={mainAgg}
                        metric={currentMetric1}
                        theme={theme}
                        timeFrame={currentTime}
                        labels={labels}
                        currentMetrics={currentMetrics}
                        />);
        } else if (currentType === 'mixed') {
            return (<DashMixed
                    style={panelMain}
                    agg_daily_result={mainAgg}
                    metric1={currentMetric1}
                    metric2={currentMetric2}
                    dateLabels={labels}
                    theme={theme}
                    currentMetrics={currentMetrics}
                    />);
        } else if (currentType === 'table') {
            return (<DashTable
                    style={panelMain}
                    dataProp={mainAgg}
                    columnWidth={0.005}
                    theme={theme}
                    currentMetric1={currentMetric1}
                    currentMetric2={currentMetric2}
                    currentAxis={currentAxis}
                    columnVisibilityModel={visibilityCopy}
                    setColumnVisibilityModel={setColumnVisibilityModel}
                    size={4}
                    currentMetrics={currentMetrics}
                    />);
        } else { return null }
    };

    return (
        <View  style={styles.container}>
        {renderComponentContent()}
        <Tooltip
                    title={"Select chart type, dimensions and metrics"}
                    placement="top"
                    componentsProps={{
                        arrow: { sx: { color: theme.backgroundColor2, } },
                        tooltip: {
                            sx: {
                                bgcolor: theme.backgroundColor2,
                                color: "white",
                                fontFamily: 'Electrolize',
                            }
                        },
                    }}
                    arrow
                >
        <Pressable style={optionsContainer}
            onPress={() => setVisible(true)}>
            <TuneOutlinedIcon style={optionsButton}/>
        </Pressable>
        </Tooltip>
            
            <DashGraphModalChildV2
                theme={theme}
                setDashConfig={setDashConfig}
                visible={visible}
                setVisible={setVisible}
                childGraphHandler={childGraphHandler}
                mainGraphHandler={childGraphHandler}
                currentReport={currentDash}
                currentChild={currentChild}
                reportIndex={reportIndex}
                currentType={currentType}
                dashConfig={dashConfig}
                uniqueDimensions={uniqueDimensions}
                uniqueMetrics={uniqueMetrics}
                currentMetrics={currentMetrics}
                />

        
        </View>
    )

}

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'red',
        height: '100%',
        width: '100%',
        fontFamily: 'Electrolize',
    }
})

export default DashGraphChild