export default function StatfunctionsEMA(data, windowSize) {

    const firstObject = data[0]
    const firstObjectKeys = Object.keys(firstObject)
    const excludeFields = ['date']
    console.log(windowSize)

    const metricList = firstObjectKeys.filter((item) => {
        return !excludeFields.includes(item);
    });

    const keys = Object.keys(data[0]).filter(key => typeof data[0][key] === 'number');  // Get numeric keys only
    const smoothingFactor = 2 / (windowSize + 1);  // Smoothing factor calculation
    let emaResults = [];

    // Initialize the EMA array with empty objects
    data.forEach(() => {
        emaResults.push({});
    });

    keys.forEach(key => {
        let previousEMA = 0;

        // Calculate initial SMA (Simple Moving Average) for the first N values
        const initialSMA = data.slice(0, windowSize).reduce((acc, obj) => acc + obj[key], 0) / windowSize;
        emaResults[windowSize - 1][key] = initialSMA;  // Set initial SMA in results
        previousEMA = initialSMA;

        // Calculate EMA for each subsequent data point starting from the windowSize-th element
        for (let i = windowSize; i < data.length; i++) {
            const currentValue = data[i][key];
            const currentEMA = (currentValue - previousEMA) * smoothingFactor + previousEMA;
            emaResults[i][key] = currentEMA;  // Set calculated EMA in results
            previousEMA = currentEMA;
        }
    });

    // Return results with the original dates
    return data.map((entry, index) => {
        return { date: entry.date, ...emaResults[index] };
    });
}