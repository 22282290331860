import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import TimelineIcon from '@mui/icons-material/Timeline';
import AddchartIcon from '@mui/icons-material/Addchart';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Dimensions } from 'react-native';

const windowWidth = Dimensions.get('window').width;

export default function MobileBottomNav(props) {
  const value = props.value
  const setValue = props.setValue
  const theme = props.theme
  const screenHandler = props.screenHandler


  return (
    <Box sx={{ 
      width: windowWidth, 
      filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.7))',
      
      }} >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        sx={{minHeight: 65, backgroundColor: theme.backgroundColor}}
      >
        <BottomNavigationAction 
          sx={{
          "& .Mui-selected, .Mui-selected > svg": {color: theme.backgroundColor2},
          color: theme.color
          }}
          onClick={() => { screenHandler('MobileHome')}}
        label="Home" 
        icon={<HomeOutlinedIcon sx={{color: theme.color}}/>} />
        <BottomNavigationAction 
          sx={{
          "& .Mui-selected, .Mui-selected > svg": {color: theme.backgroundColor2},
          color: theme.color
          }}
          onClick={() => { screenHandler('MobileSummary')}}
        label="Summaries" 
        icon={<TimelineIcon sx={{color: theme.color}}/>} />
        <BottomNavigationAction 
          sx={{
          "& .Mui-selected, .Mui-selected > svg": {color: theme.backgroundColor2},
          color: theme.color
          }}
          onClick={() => { screenHandler('MobileCharts')}}
        label="Charts" 
        icon={<AddchartIcon sx={{color: theme.color}}/>} />

      </BottomNavigation>
    </Box>
  );
}