import React, { useEffect, useState } from "react";
import { AppBar } from "@react-native-material/core";
import Tooltip from '@mui/material/Tooltip';
import { Text, Dimensions } from 'react-native';
import AccountCircle from '@mui/icons-material/AccountCircle';
import WebFont from 'webfontloader';
import { View, Pressable } from 'react-native'
import SettingsBrightnessOutlinedIcon from '@mui/icons-material/SettingsBrightnessOutlined';
import MobileModalTheme from "./MobileModalTheme";
import MobileModalClient from "./MobileModalClient";

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const MobileAppBar = (props) => {

  const homeTitle = props.homeTitle
  const theme = props.theme
  const configHandler = props.configHandler
  const clientHandler = props.clientHandler
  const screenHandler = props.screenHandler
  const role = props.role
  const [visibleModal, setVisible] = useState(false);
  const [visibleClientModal, setClientVisible] = useState(false);
  const isAdmin = role === "admin";

  let responseText;

    if (windowWidth < 1281) {
        responseText = windowHeight * 0.04
    } else if (windowWidth > 2000) {
        responseText = windowHeight * 0.035
    } else {responseText = windowHeight * 0.035}

  const appBarHeight = windowHeight * 0.05

  let widthPercentage = `${appBarHeight}px`

  function modalHandler (props){
    setVisible(props)
  }

  function modalClientHandler (props) {
    setClientVisible(props)

  }

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Droid Sans', 'Electrolize']
      }
    });
  }, []);



  return (
<View style={{}}>
    <AppBar
sx={{
        fontFamily: 'Electrolize',
        backgroundColor: theme.backgroundColor,
        border: `1px solid ${theme.color}`,
        borderRadius: 5,
        minHeight: 100   
      }}
      style={{height: widthPercentage, justifyContent: 'center'}}
      title={<Text
        style={{
          fontFamily: 'Electrolize',
          color: theme.color,
          fontSize: responseText *0.7,
          textAlign: 'left',
          fontWeight: 600

        }}>
        {homeTitle}
      </Text>}
      color={theme.backgroundColor}
      


      trailing={props => (

        <View style={{ flexDirection: 'row' }}>

          <Tooltip
                    title={"Colour Mode"}

                    componentsProps={{
                        arrow: { sx: { color: theme.backgroundColor2, } },
                        tooltip: {
                            sx: {
                                bgcolor: theme.backgroundColor2,
                                color: "white",
                                fontFamily: 'Electrolize',
                            }
                        },
                    }}
                    arrow
                >

          <Pressable 
            onPress={() => modalHandler(true)}
            >
            <SettingsBrightnessOutlinedIcon

              edge="start"
              color="primary"
              aria-label="menu1"
              sx={{ mr: 2, color: theme.backgroundColor2, fontSize: responseText }}
            >
            </SettingsBrightnessOutlinedIcon>
          </Pressable>
          </Tooltip>
          <Tooltip
                    title={"Select Client"}

                    componentsProps={{
                        arrow: { sx: { color: theme.backgroundColor2, } },
                        tooltip: {
                            sx: {
                                bgcolor: theme.backgroundColor2,
                                color: "white",
                                fontFamily: 'Electrolize',
                            }
                        },
                    }}
                    arrow
                >
          {isAdmin && <Pressable 
            onPress={() => modalClientHandler(true)}
            >
            <AccountCircle

              edge="start"
              color="primary"
              aria-label="menu1"
              sx={{ mr: 2, color: theme.backgroundColor2, fontSize: responseText }}
            >

            </AccountCircle>
          </Pressable>}
          </Tooltip>
          
          <MobileModalTheme
            modalHandler={modalHandler}
            configHandler={configHandler}
            visibleModal={visibleModal}
            theme={theme}
            />
          <MobileModalClient
            modalClientHandler={modalClientHandler}
            visibleClientModal={visibleClientModal}
            clientHandler={clientHandler}
            theme={theme}
            screenHandler={screenHandler}
            />
        </View>

      )}
    />
    </View>
  )

};

export default MobileAppBar;