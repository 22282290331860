import React, {useEffect} from 'react';
import { Dimensions } from 'react-native'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import WebFont from 'webfontloader';

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

export default function FilterType(props) {
  const [age, setAge] = React.useState('');
  const uniqueTypesRaw = props.uniqueTypes
  const total = "Total"
  const uniqueTypes = [total, ...uniqueTypesRaw];
  const handleStateChange = props.handleStateChange
  const propObject = props.propObject
  const typeFilterState = props.typeFilterState
  const theme = props.theme
  const large = props.large

  const typeFilter = propObject.kw_type

  let filterValue;

  if (typeFilter === 'Total') {
    filterValue = ''
  } else {filterValue = typeFilter}

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Droid Sans', 'Electrolize']
      }
    });
   }, []);

  const handleChange = (event) => {
    setAge(event.target.value);
    handleUpdate(event.target.value)
  };

  const handleUpdate = (prop) =>{
  
  const propUpdate = {
    start: propObject.start,
    end: propObject.end,
    channel: propObject.channel,
    brand: propObject.brand,
    kw_type: prop,
  }

  handleStateChange(propUpdate)

}

const stateColour = typeFilterState ? 'grey' : theme.backgroundColor;

let responseHeight;

if (windowWidth < 1441) {
  responseHeight = windowHeight * 0.045
} else if (windowWidth > 2000 && large === 1) {
  responseHeight = windowHeight * 0.035
}
 else if (windowWidth > 2000) {
  responseHeight = windowHeight * 0.03
} else {responseHeight = windowHeight * 0.0415}

let responseMargin;

  if (windowHeight < 800 && large === 1) {
    responseMargin = '-1%'
  } else if (windowWidth > 2000) {
    responseMargin = '-0.8%'
  } else if (windowHeight > 800 && large === 1) {
    responseMargin = '-1.2%'
  }  else if (windowHeight > 800) {
    responseMargin = '-1%'
  } else if (windowWidth < 1441 && large === 1) {
    responseMargin = '-3%'
  } else if (windowWidth < 1441) {
    responseMargin = '-1%'
  } else  {responseMargin = '-1%'}

let responseText;

if (windowWidth < 1281) {
  responseText = windowHeight * 0.025
} else if (windowWidth > 2000 && large === 1) {
  responseText = windowHeight * 0.012
} else if (windowWidth > 2000) {
  responseText = windowHeight * 0.01
} else {responseText = windowHeight * 0.017}

  return (
    <Box sx={{ 
      width: '100%' , 
      height : '100%' , 
      fontFamily : 'Electrolize', 
      color : theme.color,
      backgroundColor : theme.backgroundColor,
      
      
      }}>
      <FormControl fullWidth sx={{backgroundColor : theme.backgroundColor}}
        disabled={typeFilterState}>
        <InputLabel id="demo-simple-select-label" 
          style={{
            fontFamily : 'Electrolize', 
            color : theme.color,
            backgroundColor : theme.backgroundColor,
            marginTop: responseMargin,
            fontSize: responseText
            
            }}>
            Type
            </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={filterValue}          
          onChange={handleChange}
          MenuProps={{
            MenuListProps: {
              disablePadding: true
          }}}
          style={{
              width : '100%' , 
              left : 0, 
              fontFamily : 'Electrolize', 
              color : theme.color , 
              backgroundColor : stateColour, 
              height: 45,
              border: `1px solid ${theme.backgroundColor2}`}}
          sx={{padding : 0}}
        >
          {uniqueTypes.map((option, index) => (
          <MenuItem 
            key={index}
            style={{
              width : '100%', 
              fontFamily : 'Electrolize', 
              color : theme.color , 
              backgroundColor : theme.backgroundColor,
              border: `1px solid grey`,
              }}
            value={option} >
            {option}
          </MenuItem>
        ))}
        </Select>
      </FormControl>
    </Box>
  );
}