import update from 'immutability-helper'
import { memo, useCallback, useState } from 'react'
import { Box } from './Box.js'
import { Dustbin } from './Dustbin.js'
import { BinMetrics } from './BinMetrics.js'
import { ItemTypes } from './ItemTypes.js'
import { View, StyleSheet } from 'react-native'
import DndFAB from './DndFAB.js'

export const Container = memo(function Container(props) {

    const theme = props.theme
    const dropHandler = props.dropHandler
    const dropMetricHandler = props.dropMetricHandler
    const currentConfig = props.currentConfig
    const updateConfig = props.updateConfig
    const currentMetrics = props.currentMetrics


    const [dustbins, setDustbins] = useState([
        { accepts: [ItemTypes.TYPE], lastDroppedItem: null },
        { accepts: [ItemTypes.XAXIS], lastDroppedItem: null },
        { accepts: [ItemTypes.METRIC], lastDroppedItem: null,},
        { accepts: [ItemTypes.METRIC], lastDroppedItem: null },
    ])
    const [chartGraph1, setchartGraph1] = useState([
        { accepts: [ItemTypes.TYPE], lastDroppedItem: null },
        { accepts: [ItemTypes.XAXIS], lastDroppedItem: null },
        { accepts: [ItemTypes.METRIC], lastDroppedItem: null,},
        { accepts: [ItemTypes.METRIC], lastDroppedItem: null },
    ])
    const [chartGraph2, setchartGraph2] = useState([
        { accepts: [ItemTypes.TYPE], lastDroppedItem: null },
        { accepts: [ItemTypes.XAXIS], lastDroppedItem: null },
        { accepts: [ItemTypes.METRIC], lastDroppedItem: null,},
        { accepts: [ItemTypes.METRIC], lastDroppedItem: null },
    ])
    const [chartGraph3, setchartGraph3] = useState([
        { accepts: [ItemTypes.TYPE], lastDroppedItem: null },
        { accepts: [ItemTypes.XAXIS], lastDroppedItem: null },
        { accepts: [ItemTypes.METRIC], lastDroppedItem: null,},
        { accepts: [ItemTypes.METRIC], lastDroppedItem: null },
    ])
    const [binMetrics, setBinMetrics] = useState([
        { accepts: [ItemTypes.METRIC], lastDroppedItem: null },
        { accepts: [ItemTypes.METRIC], lastDroppedItem: null },
        { accepts: [ItemTypes.METRIC], lastDroppedItem: null },
        { accepts: [ItemTypes.METRIC], lastDroppedItem: null },
        { accepts: [ItemTypes.METRIC], lastDroppedItem: null },
        { accepts: [ItemTypes.METRIC], lastDroppedItem: null },
    ])
    const [boxes] = useState([
        { name: 'Line', type: ItemTypes.TYPE },
        { name: 'Bar', type: ItemTypes.TYPE },
        { name: 'Mixed', type: ItemTypes.TYPE },
        { name: 'Table', type: ItemTypes.TYPE },
        { name: 'Date', type: ItemTypes.XAXIS },
        { name: 'Weekly', type: ItemTypes.XAXIS },
        { name: 'Monthly', type: ItemTypes.XAXIS },
        { name: 'Brand', type: ItemTypes.XAXIS },
        { name: 'Channel', type: ItemTypes.XAXIS },
        { name: 'KW_Type', type: ItemTypes.XAXIS },
        { name: 'Impressions', type: ItemTypes.METRIC },
        { name: 'Clicks', type: ItemTypes.METRIC },
        { name: 'CTR', type: ItemTypes.METRIC },
        { name: 'Spend', type: ItemTypes.METRIC },
        { name: 'CPC', type: ItemTypes.METRIC },
        { name: 'Regs', type: ItemTypes.METRIC },
        { name: 'CPR', type: ItemTypes.METRIC },
        { name: 'FTDs', type: ItemTypes.METRIC },
        { name: 'CPA', type: ItemTypes.METRIC },
        { name: 'NGR', type: ItemTypes.METRIC },
    ])

    const [droppedBoxNames, setDroppedBoxNames] = useState([])
    function isDropped(boxName) {
        return droppedBoxNames.indexOf(boxName) > -1
    }

    const [droppedChild1Names, setDroppedChild1Names] = useState([])
    function isDropped(boxName) {
        return droppedChild1Names.indexOf(boxName) > -1
    }

    const [droppedChild2Names, setDroppedChild2Names] = useState([])
    function isDropped(boxName) {
        return droppedChild2Names.indexOf(boxName) > -1
    }

    const [droppedChild3Names, setDroppedChild3Names] = useState([])
    function isDropped(boxName) {
        return droppedChild3Names.indexOf(boxName) > -1
    }

    const [droppedMetricNames, setDroppedMetricNames] = useState([])
    function isDropped(boxName) {
        return droppedMetricNames.indexOf(boxName) > -1
    }

    const handleDrop = useCallback(
        (index, item, type) => {
            const { name } = item
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
                dropHandler(index, name, type)
            )
            setDustbins(
                update(dustbins, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            )
        },
        [droppedBoxNames, dustbins, dropHandler],
    )

    const handleChild1Drop = useCallback(
        (index, item, type) => {
            const { name } = item
            setDroppedChild1Names(
                update(droppedChild1Names, name ? { $push: [name] } : { $push: [] }),
                dropHandler(index, name, type)
            )
            setchartGraph1(
                update(chartGraph1, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            )
        },
        [droppedChild1Names, chartGraph1, dropHandler],
    )

    const handleChild2Drop = useCallback(
        (index, item, type) => {
            const { name } = item
            setDroppedChild2Names(
                update(droppedChild2Names, name ? { $push: [name] } : { $push: [] }),
                dropHandler(index, name, type)
            )
            setchartGraph2(
                update(chartGraph2, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            )
        },
        [droppedChild2Names, chartGraph2, dropHandler],
    )

    const handleChild3Drop = useCallback(
        (index, item, type) => {
            const { name } = item
            setDroppedChild3Names(
                update(droppedChild3Names, name ? { $push: [name] } : { $push: [] }),
                dropHandler(index, name, type)
            )
            setchartGraph3(
                update(chartGraph3, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            )
        },
        [droppedChild3Names, chartGraph3, dropHandler],
    )

    const handleMetricDrop = useCallback(
        (index, item) => {
            const { name } = item
            setDroppedMetricNames(
                update(droppedMetricNames, name ? { $push: [name] } : { $push: [] }),
                dropMetricHandler(index, name)
            )
            setBinMetrics(
                update(binMetrics, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            )
        },
        [droppedMetricNames, binMetrics, dropMetricHandler],
    )



    const acceptsType = [];
    const acceptsXaxis = [];
    const acceptsMetrics = []

    dustbins.forEach(dustbin => {
        if (dustbin.accepts[0] === 'type') {
            acceptsType.push(dustbin);
        } else if (dustbin.accepts[0] === 'xAxis') {
            acceptsXaxis.push(dustbin);
        } else {
            acceptsMetrics.push(dustbin);
        }
    });

    return (
        <View style={styles.parent}>
        <View style={styles.container}>
            <View style={styles.topRow}>
                <View style={styles.graphTopRow}>
                    <View style={{ width: '100%', 
                                    height: '100%', 
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    fontFamily: 'Electrolize',
                                    borderColor: 'red',
                                    borderWidth: 0}}>
                        {dustbins.map(({ accepts, lastDroppedItem }, index) => (
                            <Dustbin
                                accept={accepts}
                                lastDroppedItem={lastDroppedItem}
                                onDrop={(item) => handleDrop(index, item, 'main')}
                                key={index}
                                theme={theme}
                                currentConfig={currentConfig}
                                indexCount={index}
                                chartType={'main'}
                                currentMetrics={currentMetrics}
                                />
                            ))}                            
                    </View>  
                </View>
                <View style={styles.panelContainer}>
                    <View style={{ width: '100%', 
                                        height: '100%', 
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        fontFamily: 'Electrolize',
                                        borderColor: 'red',
                                        borderWidth: 0}}>
                            

                                {binMetrics.map(({ accepts, lastDroppedItem }, index) => (
                                    <BinMetrics
                                        accept={accepts}
                                        lastDroppedItem={lastDroppedItem}
                                        onDrop={(item) => handleMetricDrop(index, item)}
                                        key={index}
                                        theme={theme}
                                        currentConfig={currentConfig}
                                        indexCount={index}
                                        currentMetrics={currentMetrics}
                                        />
                                    ))}

                                
                            </View>


                    </View>
            </View>
            <View style={styles.bottomRow}>
                <View style={styles.bottomRowPanel}>
                    <View style={{ width: '100%', 
                                        height: '100%', 
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        fontFamily: 'Electrolize',
                                        borderColor: 'red',
                                        borderWidth: 0}}>
                    {chartGraph1.map(({ accepts, lastDroppedItem }, index) => (
                        <Dustbin
                            accept={accepts}
                            lastDroppedItem={lastDroppedItem}
                            onDrop={(item) => handleChild1Drop(index, item, 'childGraph1')}
                            key={index}
                            theme={theme}
                            currentConfig={currentConfig}
                            indexCount={index}
                            chartType={'childGraph1'}
                            currentMetrics={currentMetrics}
                            />
                        ))}                            
                    </View>  

                </View>
                <View style={styles.bottomRowPanel}>
                <View style={{ width: '100%', 
                                        height: '100%', 
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        fontFamily: 'Electrolize',
                                        borderColor: 'red',
                                        borderWidth: 0}}>
                    {chartGraph2.map(({ accepts, lastDroppedItem }, index) => (
                        <Dustbin
                            accept={accepts}
                            lastDroppedItem={lastDroppedItem}
                            onDrop={(item) => handleChild2Drop(index, item, 'childGraph2')}
                            key={index}
                            theme={theme}
                            currentConfig={currentConfig}
                            indexCount={index}
                            chartType={'childGraph2'}
                            currentMetrics={currentMetrics}
                            />
                        ))}                            
                    </View>  

                </View>
                <View style={styles.bottomRowPanel}>
                <View style={{ width: '100%', 
                                        height: '100%', 
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        fontFamily: 'Electrolize',
                                        borderColor: 'red',
                                        borderWidth: 0}}>
                    {chartGraph3.map(({ accepts, lastDroppedItem }, index) => (
                        <Dustbin
                            accept={accepts}
                            lastDroppedItem={lastDroppedItem}
                            onDrop={(item) => handleChild3Drop(index, item, 'childGraph3')}
                            key={index}
                            theme={theme}
                            currentConfig={currentConfig}
                            indexCount={index}
                            chartType={'childGraph3'}
                            currentMetrics={currentMetrics}
                            />
                        ))}                            
                    </View>  

                </View>

            </View>
            

        </View>
            <View style={styles.footer}>
                <div style={{}}>
                    {boxes.map(({ name, type }, index) => (
                        <Box
                            name={name}
                            type={type}
                            isDropped={isDropped(name)}
                            key={index}
                            theme={theme}
                            style={{padding: '1px'}}
                            currentMetrics={currentMetrics}
                        />
                    ))}
                </div>
                <DndFAB
                    theme={theme}
                    buttonText={'Update'}
                    updateConfig={updateConfig}
                />

            </View>

            

        </View>
        
    )
})

const styles = StyleSheet.create({
    
    parent: {
        flexDirection: 'row',
        height: '100%',
        width: '100%',
        borderWidth: 0,
        borderColor: 'red',
    },    
    container: {
        borderWidth: 0,
        borderColor: 'red',
        height: '100%',
        width: '80%',
        fontFamily: 'Electrolize',
        flexDirection: 'column'
    },
    footer : {
        borderWidth: 1,
        borderColor: 'grey',
        height: '100%',
        width: '20%',
        fontFamily: 'Electrolize',
        flexDirection: 'column',
        justifyContent: 'center',
        flexWrap: 'wrap',
        padding: '0.5%'

    },
    footerFAB: {
        borderWidth: 1,
        borderColor: 'grey',
        height: '10%',
        width: '20%',
        fontFamily: 'Electrolize',

        justifyContent: 'center',

    },

    topRow: {
        width: '100%',
        height: '65%',
        flexDirection: 'row',

    },
    bottomRow: {
        width: '100%',
        height: '35%',
        flexDirection: 'row',

    },
    graphTopRow: {
        height: '100%',
        width: '66.6666666%',
        borderWidth: 1,
        borderColor: 'grey'

    },
    panelContainer: {
        height: '100%',
        width: '33.333333%',
        flexDirection: 'column',
        borderWidth: 1,
        borderColor: 'grey',
    },
    panelContainerRow: {
        width: '100%',
        height: '33.333333%',
        flexDirection: 'row',
    },
    panelContainerRowItem: {
        width: '50%',
        height: '100%',
        borderWidth: 1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center'
    },
    topRowPanel: {
        width: '100%',
        height: '33.333333%',
        borderWidth: 1,
        borderColor: 'grey',
    },
    bottomRowPanel: {
        width: '33.333333%',
        height: '100%',
        borderWidth: 1,
        borderColor: 'grey',
    },
})
