import React, { useRef , useEffect} from 'react';
import { Line } from 'react-chartjs-2';
import WebFont from 'webfontloader';
import { Dimensions } from 'react-native'

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const HomeArea = (props) => {

    const currentMetrics = props.currentMetrics
    const lastMonth = props.lastMonth || []
    const thisMonth = props.thisMonth || []
    const labels = props.dateLabels    
    const theme = props.theme
    const metric = props.metric1 || "spend";
    const thisMonthData = thisMonth.map((obj) => obj[metric]);
    const lastMonthData = lastMonth.map((obj) => obj[metric]);

    let customLabel;

    if (metric === 'ftds') {
      customLabel = currentMetrics.primaryMetric
    } else if (metric === 'ngr') {
      customLabel = currentMetrics.primaryRevenue
    } else if (metric === 'regs') {
      customLabel = currentMetrics.secondaryMetric
    } else {customLabel = metric}

    let responseText;

    if (windowWidth < 450) {
      responseText = windowHeight * 0.0125
  }else if (windowWidth < 1281) {
        responseText = windowHeight * 0.025
    } else if (windowWidth > 2000) {
        responseText = windowHeight * 0.01
    } else {responseText = windowHeight * 0.017}

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Electrolize']
            }
        });
    }, []);

    function toProperCase(str) {
        return str.replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
      }

    const data = {
        labels: labels,
        datasets: [
            {
                label: "This Month Cumulative " + toProperCase(customLabel),
                data: thisMonthData,
                borderColor: theme.color, // Color of the line
                fill: false, // Do not fill the area under the line
                type: 'line', // Use 'line' chart type
                radius: 0,
            },
            {
                label: "Last Month Cumulative " + toProperCase(customLabel),
                data: lastMonthData,
                fill: true,
                borderColor: theme.backgroundColor2,
                backgroundColor: theme.backgroundColor2+30,
                lineTension: 0.5,
                radius: 0,
                type: 'line', // Use 'bar' chart type to create an area chart
            },
        ],
    };

    const options = {
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: false, // Remove the gridlines on the y-axis
            },
            ticks: {
              callback: (value, index, values) => {
                if (metric === 'ngr' || metric === 'spend' || metric === 'cpa') {
                    return new Intl.NumberFormat('en-EN', { // Note: Typically, locale would be 'en-US' or other specific locales for Euro.
                        style: 'currency',
                        currency: 'EUR',
                        maximumSignificantDigits: 3
                    }).format(value);
                } else if (metric === 'ggr_percent' || metric === 'bet_percent') {
                    return new Intl.NumberFormat('en-EN', {
                        style: 'percent',
                        minimumFractionDigits: 2, // Adjust number of decimal places as needed
                        maximumFractionDigits: 2
                    }).format(value);
                } else {
                    return value;
                }
            },
              color : theme.color,
              font: {
                size: responseText, // Set the font size for y-axis labels
                weight: 'bold', // Set the font weight for y-axis labels
                family : 'Electrolize',
              },
            },
          },
          x: {
            grid: {
              display: false, // Remove the gridlines on the x-axis
            },
            ticks: {
              color : theme.color,
              font: {
                size: responseText, // Set the font size for x-axis labels
                family : 'Electrolize'
              },
            },
          },
        },
        maintainAspectRatio: false,
        
        plugins: {
          legend: {
            display: true,
            text: 'Chart Title',
            labels: {
              color : theme.color,
              // This more specific font property overrides the global property
              font: {
                  size: responseText,
                  family : 'Electrolize'
              }
          }
          },
          tooltip: {
            enabled: false,
          },
          title: {
            display: false,
            text: 'Chart Title',
            font: {
              size: 24, // Set the font size for the chart title
              weight: 'bold', // Set the font weight for the chart title
      
            },
          },
        },
      };

    return (

        <Line data={data} options={options} />

    )

};

export default HomeArea;
