import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport 
   } from '@mui/x-data-grid';
import { Dimensions } from 'react-native'
import { DataGridPremium,
    GRID_AGGREGATION_FUNCTIONS, } from '@mui/x-data-grid-premium';
import WebFont from 'webfontloader';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function BudgetDataGrid(props) {

    const currentMetrics = props.currentMetrics
    const dataProp = props.dataProp
    const columnWidth = props.columnWidth
    const theme = props.theme
    const [aggregationModel, setAggregationModel] = useState({
        actual_spend: 'sum',
        budget: 'sum',
        yesterday_spend: 'sum',
        ftd: 'sum',
        cpa: 'cpa',
        spend_rate : 'spendRate',
        kw_type: 'total',
        channel: 'total',
        target_daily: 'sum',
      });

    let responseText;

    if (windowWidth < 1281) {
        responseText = windowHeight * 0.025
    } else if (windowWidth > 2000) {
        responseText = windowHeight * 0.018
    } else {responseText = windowHeight * 0.017}

    let responseRow;

    if (windowWidth < 1281) {
      responseRow = windowHeight * 0.05
    } else if (windowWidth > 2000) {
      responseRow = windowHeight * 0.05
    } else {responseRow = windowHeight * 0.04}

    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
        id: false,
        clicks: false,
        impressions: false,
        regs: false,
        channel: false,
        days_in_month: false,
        current_dom: false,
        days_remaining: false,
        spend_rate: true,
        cpa: true,
        target_daily: true
    });


    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Electrolize']
            }
        });
    }, []);

    let uniqueIdCounter = 1;

    // Use forEach to add a unique ID to each record
    dataProp.forEach((record) => {
        record.id = uniqueIdCounter++;
    });

    const valueFormat = (value, field) => {
        if (field === 'cpa' || field === 'actual_spend'|| field === 'budget'|| field === 'yesterday_spend' || field === 'target_daily') {
            return `€${value}`;
        } else if (field === 'spend_rate') {
            const percent = (value * 100).toFixed(0); // Convert to percent and round to 2 decimal places
            return `${percent}%`;
        }
        return value;
    };



    const labels = Object.keys(dataProp[0]).map((key, index) => {

        let newLabel;
        if (key === 'ftds') {
            newLabel = currentMetrics.primaryMetric
        } else if (key === 'ftd') {
            newLabel = currentMetrics.primaryMetric
        }else if (key === 'regs') {
            newLabel = currentMetrics.secondaryMetric
        } else if (key === 'kw_type') {
            newLabel = currentMetrics.primaryDimensionString.toLowerCase()
        } else {newLabel = key}

        return {
            field: key,
            headerName: newLabel,
            //minWidth: windowWidth * columnWidth * 1.25,
            flex: 1,
            headerClassName: 'table-header',
            groupable: false,
            type: 'number',
            headerAlign: 'center',
            renderCell: (params) => {
                const { value, field } = params;
                const heatmapColor = calculateHeatmapColor(value, { field });
                if (index <= 1) {
                    return (
                        <div
                            className="heatmap-cell"
                            style={{
                                backgroundColor: heatmapColor,
                                padding: '10%',
                                color: theme.color,
                                width: '100%',
                                fontSize: responseText * 0.8,
                                fontFamily: 'Electrolize',

                            }}
                        >
                            {value}
                        </div>
                    );
                } else if (params.aggregation) {
                    return (
                        <div
                            className="heatmap-cell-aggregated"
                            style={{
                                fontWeight: 'bold', // Add your custom font weight
                                display: 'flex', // Apply flex styles to center content vertically/horizontally
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%', // Ensure the cell takes up the full height
                                width: '100%',
                                color: theme.color,
                                paddingLeft: 0,
                                fontFamily: 'Electrolize',
                                fontSize: responseText * 0.8,
                            }}
                        >
                            {valueFormat(params.formattedValue, params.field)}
                        </div>
                    );
                } else

                return (
                    <div
                        className="heatmap-cell"
                        style={{
                            backgroundColor: heatmapColor,
                            padding: '10%',
                            width: '100%',
                            fontSize: responseText * 0.8,
                            fontFamily: 'Electrolize',

                        }}
                    >
                        {valueFormat(params.formattedValue, params.field)}
                    </div>
                );
            },

        };
    });

    const columnRanges = {};
    Object.keys(dataProp[0]).forEach((column) => {
        const values = dataProp.map((record) => record[column]);
        const min = Math.min(...values);
        const max = Math.max(...values);
        columnRanges[column] = { min, max };
    });

    function calculateHeatmapColor(value, column) {
        const { min, max } = columnRanges[column.field];
        const range = max +1 - min;

        // Define input colors for the heatmap in RGB format
        const fromColor = { r: theme.comboStopColor1ARRAY[0], g: theme.comboStopColor1ARRAY[1], b: theme.comboStopColor1ARRAY[2] };
        const toColor = { r: theme.comboStopColor2ARRAY[0], g: theme.comboStopColor2ARRAY[1], b: theme.comboStopColor2ARRAY[2] };;
      



        const relativeValue = (value - min) / range;
        const red = Math.round((1 - relativeValue) * fromColor.r + relativeValue * toColor.r);
        const green = Math.round((1 - relativeValue) * fromColor.g + relativeValue * toColor.g);
        const blue = Math.round((1 - relativeValue) * fromColor.b + relativeValue * toColor.b);

        return `rgba(${red},${green},${blue},0.7)`;
    }

    function CustomToolbar() {
        return (
          <GridToolbarContainer sx={{color: theme.color}}>
            <GridToolbarColumnsButton sx={{color: theme.color}}/>
              <GridToolbarFilterButton sx={{color: theme.color}}/>
              <GridToolbarDensitySelector sx={{color: theme.color}}/>
              <GridToolbarExport sx={{color: theme.color}}/>
          </GridToolbarContainer>
        );
      }

    

    const largeRows = ((windowHeight / 60).toFixed(0))

    function calculateCPA (spend, ftd) {
        const cpa =  (spend / ftd)

        return Math.round(cpa)
    }

    function calculateSpendRate (budget, actual_spend) {

        const rate = budget / actual_spend

        return Math.round(rate * 100) / 100
    }

    function calculateTotal (spend, ftd) {
        return "TOTAL"
        }

    const cpa = {
        label: '',
        getCellValue: ({ row }) => ({ spend: row.actual_spend, ftd: row.ftd }),
        apply: ({ values }) => {
          let spend = 0;
          let ftd = 0;
          values.forEach((value) => {
            if (value) {
                spend += value.spend || 0; // Adds 0 if value.spend is null or NaN
                ftd += value.ftd || 0;
            }
          });
          return calculateCPA(spend, ftd);
        },
        columnTypes: ['number'],
      };

      const spendRate = {
        label: '',
        getCellValue: ({ row }) => ({ spend: row.actual_spend, budget: row.budget }),
        apply: ({ values }) => {
          let spend = 0;
          let budget = 0;
          values.forEach((value) => {
            if (value) {
                spend += value.spend || 0; // Adds 0 if value.spend is null or NaN
                budget += value.budget || 0;
            }
          });
          return calculateSpendRate(spend, budget);
        },
        columnTypes: ['number'],
      };

      const total = {
        label: '',
        getCellValue: ({ row }) => ({ spend: row.spend, ftd: row.ftds }),
        apply: ({ values }) => {
          let spend = 0;
          let ftd = 0;
          values.forEach((value) => {
            if (value) {
                spend += value.spend || 0; // Adds 0 if value.spend is null or NaN
                ftd += value.ftd || 0;
            }
          });
          return calculateTotal(spend, ftd);
        },
        columnTypes: ['number'],
      };

       

    return (
        <Box
            style={{
                width: '99%',
                fontSize: 50,
                color: 'black',
                fontFamily: 'Electrolize',

            }}
            sx={{
                '& .table-header': {
                fontFamily : 'Electrolize' ,
                color: theme.color, 
                fontSize: responseText,
                },
                '& .MuiDataGrid-row' : {backgroundColor : theme.backgroundColor},
                '& .MuiTablePagination-root' : {color: theme.color},

                width: '100%'
            }}
        >
            <DataGridPremium
                rows={dataProp}
                columns={labels}
                rowHeight={responseRow}
                slots={{ toolbar: CustomToolbar  }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                    setColumnVisibilityModel(newModel)
                }
                aggregationModel={aggregationModel}
                onAggregationModelChange={(newModel) => setAggregationModel(newModel)}
                aggregationFunctions={{
                ...GRID_AGGREGATION_FUNCTIONS,
                cpa,
                spendRate,
                total
                }}
                pagination
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: parseInt(largeRows) - 2, page: 0 }
                    },
                    sorting: {
                        sortModel: [{ field: 'actual_spend', sort: 'desc' }],
                    },
                }}

            />
        </Box>
    );
}