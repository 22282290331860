import React, { useEffect, useRef } from 'react';
import { Bar , Line} from 'react-chartjs-2';
import WebFont from 'webfontloader';
import { Dimensions } from 'react-native'
import Download from '../../Components/Download';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const MACDChart = (props) => {
  const chartRef = useRef(null);
  const theme = props.theme
  const signalLines = props.signalLines
  const metric1 = props.metric1
  const metric2 = props.metric2
  const metric3 = props.metric3
  const dataProp = props.dataProp
  const dateLabels = props.dateLabels
  const client = props.client
  const currentMetrics = props.currentMetrics
  const metricValue = props.metricValue

  let convLabel1

  if (metric1 === 'ftds') {
    convLabel1 = `${currentMetrics.primaryMetric}_${metricValue}`
  } else if (metric1 === 'ngr') {
    convLabel1 = `${currentMetrics.primaryRevenue}_${metricValue}`    
  } else if (metric1 === 'regs') {
    convLabel1 = `${currentMetrics.secondaryMetric}_${metricValue}`    
  } else {convLabel1 = `${metric1}_${metricValue}`  }
  

  let convLabel2

  if (metric2 === 'ftds') {
    convLabel2 = currentMetrics.primaryMetric
  } else if (metric2 === 'ngr') {
    convLabel2 = currentMetrics.primaryRevenue
  } else if (metric2 === 'regs') {
    convLabel2 = currentMetrics.secondaryMetric
  } else {convLabel2 = metric2}

  let convLabel3

  if (metric3 === 'ftds') {
    convLabel3 = currentMetrics.primaryMetric
  } else if (metric3 === 'ngr') {
    convLabel3 = currentMetrics.primaryRevenue
  } else if (metric3 === 'regs') {
    convLabel3 = currentMetrics.secondaryMetric
  } else {convLabel3 = metric3}

  let responseText;

  if (windowWidth < 1281) {
    responseText = windowHeight * 0.012
  } else if (windowWidth > 2000) {
    responseText = windowHeight * 0.012
  } else {responseText = windowHeight * 0.012}


  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Electrolize']
      }
    });
   }, []);

  const filteredData = dataProp.filter(item => item.brand !== "");

  const values1 = [metric1];  
  const values2 = [metric2];
  const values3 = [metric3];

  const filteredValuesArray1 = filteredData.flatMap((item) =>
  values1.map((field) => item[field])
);

const filteredValuesArray2 = filteredData.flatMap((item) =>
values2.map((field) => item[field])
);

const filteredValuesArray3 = filteredData.flatMap((item) =>
    values3.map((field) => item[field])
    );

const value1Slice = filteredValuesArray1.slice(26)
const value2Slice = filteredValuesArray2.slice(26)
const value3Slice = filteredValuesArray3.slice(26)
const dateSlice = dateLabels.slice(26)

const histoColourPos = [];

for (let i = 0; i < value3Slice.length; i++) {
    const value = value3Slice[i];

    if (value === undefined) {        // Check for undefined first
        histoColourPos.push('yellow');
    } else if (value === null) {        // Check for undefined first
        histoColourPos.push('green');
    } else if (value < 0) {           // For negative values
        histoColourPos.push('red');
    } else if (value > 0) {           // For positive values
        histoColourPos.push('green');
    }
}

const histoColourNeg = [];

for (let i = 0; i < value3Slice.length; i++) {
    const value = value3Slice[i];

    if (value === undefined) {        // Check for undefined first
        histoColourNeg.push('yellow');
    } else if (value === null) {        // Check for undefined first
        histoColourNeg.push('green');
    } else if (value > 0) {           // For negative values
        histoColourNeg.push('red');
    } else if (value < 0) {           // For positive values
        histoColourNeg.push('green');
    }
}

let histoColour;
if (metricValue === 'cpa' || metricValue == 'cpr') {
    histoColour = histoColourNeg
} else {
    histoColour = histoColourPos
}

  let macdDatasets;

  if (signalLines) {
    macdDatasets = [
      {
        type : 'line',
        label: convLabel1,
        data: value1Slice,
        yAxisID : 'A',
        borderColor: theme.backgroundColor2,
        borderWidth: 5,
        lineTension: 0.5,
        radius: 0
      },
      {
        type : 'line',
        label: convLabel2,
        data: value2Slice,
        yAxisID : 'A',
        borderColor: '#F0F8FF',
        backgroundColor: theme.backgroundColor2,
        borderRadius: 5,
        lineTension: 0.5,
        borderWidth: 2,
        radius: 0
      },
      {
        type : 'bar',
        label: convLabel3,
        data: value3Slice,
        yAxisID : 'B',
        borderColor: theme.color,
        backgroundColor: histoColour,
        borderRadius: 5,
        lineTension: 0.5,
        radius: 0
      },
    ]
  } else {
    macdDatasets = [
      {
        type : 'bar',
        label: convLabel3,
        data: value3Slice,
        yAxisID : 'A',
        borderColor: theme.color,
        backgroundColor: histoColour,
        borderRadius: 5,
        lineTension: 0.5,
        radius: 0
      }
    ]
  }

  const data = {
    labels: dateSlice,
    datasets: macdDatasets,
  };
  
const options = {
  maintainAspectRatio: false,
  scales: {
    A: {
      beginAtZero: true,
      type: 'linear',
      position: 'left',
      grid: {
        display: false, // Remove the gridlines on the y-axis
      },
      ticks: {
        callback: (value, index, values) => {
          if (metric1 === 'ggr' || metric1 === 'spend' || metric1 === 'cpa') {
              return new Intl.NumberFormat('en-EN', { // Note: Typically, locale would be 'en-US' or other specific locales for Euro.
                  style: 'currency',
                  currency: 'EUR',
                  maximumSignificantDigits: 3
              }).format(value);
          } else if (metric1 === 'ggr_percent' || metric1 === 'bet_percent' || metricValue === 'roas') {
              return new Intl.NumberFormat('en-EN', {
                  style: 'percent',
                  minimumFractionDigits: 2, // Adjust number of decimal places as needed
                  maximumFractionDigits: 2
              }).format(value);
          } else {
              return value;
          }
      },
        color : theme.color,
        font: {
          size: responseText, // Set the font size for y-axis labels
          weight: 'bold', // Set the font weight for y-axis labels
          family : 'Electrolize',
        },
      },
    },
    B: {
        beginAtZero: true,
        type: 'linear',
        position: 'right',
        grid: {
          display: false, // Remove the gridlines on the y-axis
        },
        ticks: {
          callback: (value, index, values) => {
            if (metric2 === 'ggr' || metric2 === 'spend' || metric2 === 'cpa') {
                return new Intl.NumberFormat('en-EN', { // Note: Typically, locale would be 'en-US' or other specific locales for Euro.
                    style: 'currency',
                    currency: 'EUR',
                    maximumSignificantDigits: 3
                }).format(value);
            } else if (metric2 === 'ggr_percent' || metric2 === 'bet_percent' || metricValue === 'roas') {
                return new Intl.NumberFormat('en-EN', {
                    style: 'percent',
                    minimumFractionDigits: 2, // Adjust number of decimal places as needed
                    maximumFractionDigits: 2
                }).format(value);
            } else {
                return value;
            }
        },
          color : theme.color,
          font: {
            size: responseText, // Set the font size for y-axis labels
            weight: 'bold', // Set the font weight for y-axis labels
            family : 'Electrolize',
          },
        },
      },
    x: {
      grid: {
        display: false, // Remove the gridlines on the x-axis
      },
      ticks: {
        color : theme.color,
        font: {
          size: responseText, // Set the font size for x-axis labels
          family : 'Electrolize',
          
        },
      },
    },
  },
  tooltips: {
    enabled: true,    
  },
  plugins: {
    legend: {
      display: true,
      text: 'Chart Title',
      labels: {
        color : theme.color,
        // This more specific font property overrides the global property
        font: {
            size: responseText,
            family : 'Electrolize'
        }
    }
    },
    tooltip: {
      enabled: true,
    },
    title: {
      display: false,
      text: 'Chart Title',
      font: {
        size: 24, // Set the font size for the chart title
        weight: 'bold', // Set the font weight for the chart title

      },
    },
  },
};

  return <Bar ref={chartRef} data={data} options={options} style={{fontFamily : 'Electrolize',}} />;
};

export default MACDChart;
