import React, {useEffect} from 'react';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { LicenseInfo } from '@mui/x-license-pro';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import { Dimensions } from 'react-native'
import { View } from 'react-native'
import moment from 'moment'
import WebFont from 'webfontloader';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function MobileDateRange(props) {

  LicenseInfo.setLicenseKey('6bf1987e6f50457e0ccce5aee1489680Tz04MzczNyxFPTE3Mzg4NjE3NTUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');  
  const handleStateChange = props.setChartConfig
    const propObject = props.propObject
    const theme = props.theme
    const endDateProp = props.end
    const startDateProp = props.start
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayProp = yesterday2.format('YYYY-MM-DD');
    const currentDate = new Date();
    currentDate.setMinutes(0);
    const previousDate = new Date(currentDate);
    previousDate.setDate(currentDate.getDate() - 30);
    previousDate.setDate(1)
    const startDateString = previousDate.toISOString().slice(0, 10);
    //const large = props.large

    useEffect(() => {
      WebFont.load({
        google: {
          families: ['Droid Sans', 'Electrolize']
        }
      });
     }, []);

     const themeCustom = createTheme({
      palette: {
        primary: {
          main: theme.backgroundColor2,
        },
        secondary: purple,
        main: purple,
      },
    });


    const handleTest = (value) => {

        let defaultStart;
        let defaultEnd;

        if (!value[0] && !value[0]) {
          defaultStart = startDateString
          defaultEnd = yesterdayProp

        }

        else {
          const startDate = new Date(value[0]);
        const endDate = new Date(value[1]);
      
        const startOptions = { timeZone: 'CET', year: 'numeric', month: '2-digit', day: '2-digit' };
        const endOptions = { timeZone: 'CET', year: 'numeric', month: '2-digit', day: '2-digit' };
      
        // Format start date as YYYY-MM-DD
        defaultStart = startDate.toLocaleDateString('en-US', startOptions).replace(/\//g, '-');
      
        // Format end date as YYYY-MM-DD
        defaultEnd = endDate.toLocaleDateString('en-US', endOptions).replace(/\//g, '-');
        }

        
      
        const prop = {
          start: defaultStart,
          end: defaultEnd,
        };
      
        handleUpdate(prop);
      };

    const handleUpdate = (prop) =>{
  
        const propUpdate = {
            chart: propObject.chart,
            axis: propObject.axis,
            brand: propObject.brand,
            channel: propObject.channel,
            kw_type: propObject.kw_type,
            start: prop.start,
            end: prop.end,
            time: propObject.time,
            metric1: propObject.metric1,
            metric2: propObject.metric2,
        }
      
        handleStateChange(propUpdate)

      
      }

      /*let responseHeight;

      //if (windowWidth < 1281) {
      //  responseHeight = windowHeight * 0.0035
      //} else if (windowWidth > 2000 && large === 1) {
      //  responseHeight = windowHeight * 0.019
      //} else if (windowWidth > 2000) {
      //  responseHeight = windowHeight * 0.0135
      //}
      //else {responseHeight = windowHeight * 0.0035}

      if (windowWidth > 2000 && large === 1)
          {responseHeight =  windowHeight * 0.0055}

      else if (windowWidth > 2000)
      {responseHeight =  windowHeight * 0.0005}
      else if (windowWidth > 1440) {
        responseHeight = windowHeight * 0.003
      } else if (windowWidth <= 1440) {
        responseHeight = windowHeight * 0.003
      } else { responseHeight = windowHeight * 0.000}
      */

      let responseText;

      if (windowWidth < 1281) {
        responseText = windowHeight * 0.025
      } else if (windowWidth > 2000) {
        responseText = windowHeight * 0.01
      } else {responseText = windowHeight * 0.017}

  return (
    <View style={{
        borderColor : 'yellow', 
        borderWidth : 0 , 
        fontFamily : 'Electrolize',
        width: '100%',
     

        }}>
    <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={themeCustom} components={['SingleInputDateRangeField']}>
          <DateRangePicker
            onAccept={handleTest}
            slots={{ field: SingleInputDateRangeField }}
            defaultValue = {[moment(startDateProp), moment(endDateProp)]}
            sx={{
                width: "96%",
                "& .MuiInputLabel-root.Mui-focused": { color: "#979797" }, //styles the label
                "& .MuiOutlinedInput-root": {
                "&:hover > fieldset": { borderColor: "#C7C8CD" },

                },
              }}
            style={{fontFamily : 'Electrolize' , backgroundColor : theme.backgroundColor2}}

            slotProps={{
              actionBar: {
                  actions: ["accept"]
                },
              textField: () => ({
                color: 'main',
                inputProps: {
                  
                  style: {
                    color: theme.color, // Replace with your custom text color
                    border: `1px solid ${theme.backgroundColor2}`,
                    borderRadius: 5,
                    fontFamily : 'Electrolize',
                    fontSize: responseText,
                    "&:hover > fieldset": { borderColor: "#C7C8CD" },
                    height: 10,
                    textAlign: 'center'
                  },
                },
                focused: false,
              })
            }}

          />
          </ThemeProvider>
    </LocalizationProvider>
    </View>
  );
}