import React, { useState, useEffect } from 'react';
import { Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Dimensions } from 'react-native'

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

let responseText;

if (windowWidth < 1281) {
  responseText = windowHeight * 0.025
} else if (windowWidth > 2000) {
  responseText = windowHeight * 0.02
} else { responseText = windowHeight * 0.017 }

const CheckboxLabels = (props) => {
  const { metric, checked, handleCheckBoxChange } = props;
  const theme = props.theme

  const handleChange = (event) => {
    let reverseSelect;
    if (metric.key === 'campaign') {
      reverseSelect = 'kw_type'
    } else if (metric.key === 'totalConv') {
      reverseSelect = 'ftds'
    } else if (metric.key === 'ft') {
      reverseSelect = 'ftds'
    }  else if (metric.key === 'ql') {
      reverseSelect = 'regs'
    } else {
      reverseSelect = metric.key
    }
    handleCheckBoxChange(reverseSelect, event.target.checked);
  };

  return (
    <FormGroup
      sx={{ border: '0px solid red', paddingLeft: '20%' }}>
      <FormControlLabel
        control={<Checkbox checked={checked} sx={{ color: theme.color }} onChange={handleChange} />}
        label={<Typography variant="body2" style={{ color: theme.color, fontSize: responseText }}>{metric.key}</Typography>}
      />
    </FormGroup>

  );
};

const MetricList = (props) => {
  const { currentSetting, handleCheckBoxChange } = props;
  var metricList = props.metricList
  const [currentSettings, setcurrentSettings] = useState([]);
  const theme = props.theme
  const currentMetrics = props.currentMetrics

  useEffect(() => {
    // Initialize the currentSettings state with the currentSetting prop
    setcurrentSettings(currentSetting || ["brand", "spend", "ftds", "cpa"]);
  }, [currentSetting]);

  const handleCheckBoxChangeInternal = (value, isChecked) => {
    // Create a new array with the updated checked items
    const updatedCurrentSettings = isChecked
      ? [...currentSettings, value]
      : currentSettings.filter((item) => item.toString() !== value.toString());

    // Update the state with the new array of checked items
    setcurrentSettings(updatedCurrentSettings);

    // Pass the updatedCurrentSettings to the parent component using the handleCheckBoxChange prop
    handleCheckBoxChange(updatedCurrentSettings);
  };

  return (
    <div style={{ width: '100%', height: windowHeight * 0.928, border: '0.1px solid grey', borderRadius: 5, color: theme.color }}>
      {metricList.map((metricItem) => {
        
        let newLabel;
        if (metricItem.key === 'ftds') {
          newLabel = {'key' : currentMetrics.primaryMetric}
        } else if (metricItem.key === 'reg') {
          newLabel = {'key' : currentMetrics.secondaryMetric}
        } else if (metricItem.key === 'regs') {
          newLabel = {'key' : currentMetrics.secondaryMetric}
        } else if (metricItem.key === 'kw_type') {
          newLabel = {'key' : currentMetrics.primaryDimensionString.toLowerCase()}
        } else { newLabel = metricItem }

        return (
          <CheckboxLabels
            key={metricItem.key}
            metric={newLabel}
            checked={currentSettings.includes(metricItem.key)}
            handleCheckBoxChange={handleCheckBoxChangeInternal}
            sx={{ color: theme.color }}
            theme={theme}
            metricList={metricList}
          />
        )
      }
      )}
    </div>
  );
};

export default MetricList;
