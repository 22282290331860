const Download = (jsonData) => {
    const csvRows = [];
  
    // Extract the keys from the first object in the JSON to use as headers
    const headers = Object.keys(jsonData[0]);
    csvRows.push(headers); // Add headers to the CSV
  
    // Add the data rows by iterating through each object in the JSON
    jsonData.forEach(obj => {
      const row = headers.map(header => obj[header]); // Match each value by header key
      csvRows.push(row);
    });
  
    // Convert the rows into CSV format
    const csvContent = csvRows.map(row => row.join(',')).join('\n');
  
    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv' });
  
    // Create a download link
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'pattern_chart_data.csv';
    link.click();
  
    // Clean up the URL object
    window.URL.revokeObjectURL(url);
  };

export default Download