import React, { useState } from 'react';
import {
    View,
    StyleSheet,
    Dimensions
} from 'react-native'
import FiltersEnhanced from '../../ComponentsEnhanced/FiltersEnhanced';
import AttEnhancedTableModal from './AttEnhancedTableModal';
import AttEnhancedTableParse from './AttEnhancedTableParse';
import AttEnhancedTableDataGrid from './AttEnhanceTableDataGrid';

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

const AttEnhancedTableContent = (props) => {

    const currentMetrics = props.currentMetrics
    const dataProp = props.dataProp
    const tableConfig = props.tableConfig
    const setTableConfig = props.setTableConfig
    const uniqueFilter1 = dataProp.uniqueFilter1
    const uniqueFilter2 = dataProp.uniqueFilter2
    const uniqueFilter3 = dataProp.uniqueFilter3
    const uniqueFilter4 = dataProp.uniqueFilter4
    const filterList = props.filterList
    const setFilterList = props.setFilterList
    const aggResult = dataProp.aggResult
    const models = dataProp.models
    const theme = props.theme
    const handleStateChange = props.handleStateChange
    const propObject = props.propObject
    const dynamicFilter = props.dynamicFilter
    const setOpen = props.setOpen
    const attModalOpen = props.attModalOpen
    const filterParams = props.filterParams
    const cubeTable = props.cubeTable

    const enhancedOutput = AttEnhancedTableParse(aggResult, cubeTable, tableConfig)
    const enhancedData = enhancedOutput.agg_result
    const enhancedTotals = enhancedOutput.aggregatedResults

    let responseHeight;

    if (windowWidth < 1441) {
        responseHeight = windowHeight * 0.11
      } else if (windowWidth > 2000) {
        responseHeight = windowHeight * 0.08
      } else {responseHeight = windowHeight * 0.08}

    const panelMain = {

        width: '100%',
        height: responseHeight,
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 40,
        fontFamily: 'Electrolize',     

        
    }

    const tablePanel = {
        width: '100%',
        height: '100%',
        borderColor: 'blue',
        borderWidth: 0
    }

    const panelChild = {
        flex: 1,
        height: windowHeight * 0.2,
        width: windowWidth,
        borderWidth: 0,
        borderColor: 'grey',

        textAlign: 'center',
        fontSize: 20,
        fontFamily: 'Electrolize',
        flexDirection: 'row',
        
    }

    return (
        <View style={styles.container}>
            <View style={{ flexDirection: 'column' }}>
                <View style={panelMain}>
                    <View style={{ width: '100%', flexDirection: 'flex-start', fontFamily: 'Electrolize' }}>
                        <FiltersEnhanced
                            style={{
                                height: '10%',
                                borderColor: 'red',
                                borderWidth: 0,
                                width: '100%',
                                fontFamily: 'Electrolize'
                            }}
                            uniqueFilter1={uniqueFilter1}
                            uniqueFilter2={uniqueFilter2}
                            uniqueFilter3={uniqueFilter3}
                            uniqueFilter4={uniqueFilter4}
                            handleStateChange={handleStateChange}
                            propObject={propObject}
                            theme={theme}
                            type={dynamicFilter}
                            filterParams={filterParams}
                            setFilterList={setFilterList}
                            filterList={filterList}
                            currentMetrics={currentMetrics}
                        />
                    </View>
                    
                </View>
                    
                

            </View>
                <View style={panelChild}>
                    <View style={tablePanel}>
                        <AttEnhancedTableDataGrid
                            dataProp={enhancedData}
                            theme={theme}
                            models={tableConfig}
                            filterParams={filterParams}
                            dynamicFilter={dynamicFilter}
                            currentMetrics={currentMetrics}
                            enhancedTotals={enhancedTotals}
                        />

                    </View>
                    
                </View>
                <AttEnhancedTableModal
                    open={attModalOpen}
                    setOpen={setOpen}
                    models={tableConfig}
                    modelHandler={setTableConfig}
                    currentMetrics={currentMetrics}
                />
                
            

        </View>
    )

}

export default AttEnhancedTableContent

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: (windowHeight * 0.93),
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize'
    }
})