import React, { useState } from 'react';
import Box from '@mui/material/Box';
import {Dimensions} from 'react-native'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';

import FormControl from '@mui/material/FormControl';


const windowWidth = Dimensions.get('window').width;

export default function ChartBuilderTextBox(props) {
    
    const theme = props.theme
    const setTextValue = props.setTextValue
    const [inputValue, setInputValue] = useState('');

    let themeColor;

      if (theme.color === 'white') {
        themeColor = '#FFFFFF'
      } else {
        themeColor = theme.color
      }


    const handleChange = (event) => {
        setInputValue(event.target.value);
        setTextValue(event.target.value);
      //console.log(event.target.value)
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    

    return (

        <Box sx={{ display: 'flex', flexWrap: 'wrap', maxWidth: '100%', width: '100%' }}>
            <div>

                <FormControl sx={{ m: 1, maxWidth: '90%', width: '100%',  }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-amount">Template Name</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-report"
                        label="Report Name"
                        type={'text'}
                        value={inputValue}
                        onChange={handleChange}
                        //sx={{height: windowWidth * 0.03}}

                        
                    />
                </FormControl>

            </div>
        </Box>

    );
}