import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import TableChartIcon from '@mui/icons-material/TableChart';
import SavingsIcon from '@mui/icons-material/Savings';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import PivotTableChartTwoToneIcon from '@mui/icons-material/PivotTableChartTwoTone';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import AutoGraphTwoToneIcon from '@mui/icons-material/AutoGraphTwoTone';
import CampaignTwoToneIcon from '@mui/icons-material/CampaignTwoTone';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import { Dimensions } from 'react-native'

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;


export default function DrawerList(client, theme) {


    let responseText, isEnhanced;

    if (client === 'Glitnor') {
      isEnhanced = true
    } else if (client === 'Rank') {
      isEnhanced = true
    } else if (client === 'Hero') {
      isEnhanced = true
    } else { isEnhanced = false}

    if (windowWidth < 1281) {
        responseText = windowHeight * 0.025
    } else if (windowWidth > 2000) {
        responseText = windowHeight * 0.015
    } else {responseText = windowHeight * 0.017}

    const summaryList = [
        {
          parent: 'Summaries',
          text: 'Daily Summary',
          icon: <CalendarMonthTwoToneIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <CalendarMonthTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
        />,
        iconLarge: <CalendarMonthTwoToneIcon
        sx={{
              fontFamily: 'Electrolize',
              color: theme.backgroundColor,
              fontSize: responseText * 6 ,
              filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
              borderColor: theme.color,
              borderWidth: 1,

          }}
        />,
          link: '/daily',
        },
        {
          parent: 'Summaries',
          text: 'Weekly Summary',
          icon: <CalendarMonthTwoToneIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <CalendarMonthTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
        />,
        iconLarge: <CalendarMonthTwoToneIcon
        sx={{
              fontFamily: 'Electrolize',
              color: theme.backgroundColor,
              fontSize: responseText * 6 ,
              filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
              borderColor: theme.color,
              borderWidth: 1,

          }}
        />,
          link: '/weekly',
        },
        {
          parent: 'Summaries',
          text: 'Monthly Summary',
          icon: <CalendarMonthTwoToneIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <CalendarMonthTwoToneIcon
            sx={{
                  fontFamily: 'Electrolize',
                  color: theme.backgroundColor,
                  fontSize: responseText * 4 ,
                  filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                  borderColor: theme.color,
                  borderWidth: 1,

              }}
        />,
        iconLarge: <CalendarMonthTwoToneIcon
        sx={{
              fontFamily: 'Electrolize',
              color: theme.backgroundColor,
              fontSize: responseText * 6 ,
              filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
              borderColor: theme.color,
              borderWidth: 1,

          }}
        />,
          link: '/monthly',
        },
        {
          parent: 'Summaries',
          text: 'YoY Summary',
          icon: <CalendarMonthTwoToneIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <CalendarMonthTwoToneIcon
            sx={{
                  fontFamily: 'Electrolize',
                  color: theme.backgroundColor,
                  fontSize: responseText * 4 ,
                  filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                  borderColor: theme.color,
                  borderWidth: 1,

              }}
            />,
            iconLarge: <CalendarMonthTwoToneIcon
            sx={{
                  fontFamily: 'Electrolize',
                  color: theme.backgroundColor,
                  fontSize: responseText * 6 ,
                  filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                  borderColor: theme.color,
                  borderWidth: 1,
    
              }}
            />,
          link: '/yearly',
        },
        {
          parent: 'Summaries',
          text: 'Campaigns Summary',
          icon: <TableChartTwoToneIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <TableChartTwoToneIcon
            sx={{
                  fontFamily: 'Electrolize',
                  color: theme.backgroundColor,
                  fontSize: responseText * 4 ,
                  filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                  borderColor: theme.color,
                  borderWidth: 1,

              }}
            />,
            iconLarge: <TableChartTwoToneIcon
            sx={{
                  fontFamily: 'Electrolize',
                  color: theme.backgroundColor,
                  fontSize: responseText * 6 ,
                  filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                  borderColor: theme.color,
                  borderWidth: 1,
    
              }}
            />,
          link: '/campaign_summary',
        },
        {
          parent: 'Summaries',
          text: 'Table Builder',
          icon: <TableChartTwoToneIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <TableChartTwoToneIcon
            sx={{
                  fontFamily: 'Electrolize',
                  color: theme.backgroundColor,
                  fontSize: responseText * 4 ,
                  filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                  borderColor: theme.color,
                  borderWidth: 1,

              }}
            />,
            iconLarge: <TableChartTwoToneIcon
            sx={{
                  fontFamily: 'Electrolize',
                  color: theme.backgroundColor,
                  fontSize: responseText * 6 ,
                  filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                  borderColor: theme.color,
                  borderWidth: 1,
    
              }}
            />,
          link: '/table',
        },
        {
          parent: 'Summaries',
          text: 'Chart Builder',
          icon: <AutoGraphTwoToneIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <AutoGraphTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <AutoGraphTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/chart',
        },
        {
          parent: 'Summaries',
          text: 'Dashboard Studio',
          icon: <DashboardCustomizeIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <DashboardCustomizeIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <DashboardCustomizeIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/dash_editor',
        },
        {
          parent: 'Summaries',
          text: 'Dashboard Builder',
          icon: <DashboardCustomizeIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <DashboardCustomizeIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <DashboardCustomizeIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/dash_custom',
        },
        {
          parent: 'Summaries',
          text: 'PPT Builder',
          icon: <CoPresentIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <CoPresentIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <CoPresentIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/doc_builder',
        },
        
        {
          parent: 'Summaries',
          text: 'Cohorts ROAS',
          icon: <PivotTableChartTwoToneIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <PivotTableChartTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <PivotTableChartTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/cohorts',
        },
        //{
          //parent: 'Summaries',
          //text: 'Chat',
          //icon: <PivotTableChartTwoToneIcon
            //sx={{ color: theme.backgroundColor2 }}
          ///>,
          //link: '/chat',
        //}
      ];
    
      const ppcList = [
        {
          parent: 'PPC',
          text: 'PPC Campaigns',
          icon: <GoogleIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <GoogleIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <GoogleIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/ppc_campaign',
        },
        {
          parent: 'PPC',
          text: 'AdGroups',
          icon: <GoogleIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <GoogleIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <GoogleIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/ppc_adgroup',
        },
        //{
          //parent: 'PPC',
          //text: 'KW Density',
          //icon: <GoogleIcon
            //sx={{ color: theme.backgroundColor2 }}
          ///>,
          //link: '/ppc_bubble',
        //},
        //{
          //parent: 'PPC',
          //text: 'KW Tree (WIP)',
          //icon: <GoogleIcon
            //sx={{ color: theme.backgroundColor2 }}
          ///>,
          //link: '/ppc_tree',
        //},
      ]
    
      const analyticsList = [
        {
          parent: 'GA4',
          text: 'GA4 Summary',
          icon: <AnalyticsIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <AnalyticsIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <AnalyticsIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/analytics',
        },
        {
          parent: 'GA4',
          text: 'GA4 Channels',
          icon: <AnalyticsIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <AnalyticsIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <AnalyticsIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/analytics_table',
        },
        {
          parent: 'GA4',
          text: 'GA4 All Channels',
          icon: <AnalyticsIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <AnalyticsIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <AnalyticsIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/all_channels',
        },
        {
          parent: 'GA4',
          text: 'GA4 Attribution',
          icon: <AnalyticsIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <AnalyticsIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <AnalyticsIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/attribution_comparison',
        },
        {
          parent: 'GA4',
          text: 'GA4 Dimensions',
          icon: <AnalyticsIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <AnalyticsIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <AnalyticsIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/attribution_dimensions',
        },
      ]
    
    
      const budgetList = [
        {
          parent: 'Budget',
          text: 'Budget Summary',
          icon: <SavingsIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <SavingsIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <SavingsIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/budget',
        },
        {
          parent: 'Budget',
          text: 'Budget Types',
          icon: <SavingsIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <SavingsIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <SavingsIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/budget_table',
        },
      ]
    
      const socialList = [
        {
          parent: 'Paid Social',
          text: 'Campaigns',
          icon: <FacebookIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <FacebookIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <FacebookIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/social_campaign',
        },
        {
          parent: 'Paid Social',
          text: 'AdSets',
          icon: <FacebookIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <FacebookIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <FacebookIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/social_adgroup',
        },
      ]
    
      const docList = [
        {
          parent: 'Docs Hub',
          text: 'Performance PPT',
          icon: <PictureAsPdfIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <PictureAsPdfIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <PictureAsPdfIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/ppt',
        },
      ]
      const progList = [
        {
          parent: 'Display',
          text: 'DSPs',
          icon: <CampaignTwoToneIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <CampaignTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <CampaignTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/prog_channel',
        },
        {
          parent: 'Display',
          text: 'Strategies',
          icon: <CampaignTwoToneIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <CampaignTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <CampaignTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/prog_type',
        },
        {
          parent: 'Display',
          text: 'Publishers',
          icon: <CampaignTwoToneIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <CampaignTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <CampaignTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/prog_url',
        },
        {
          parent: 'Display',
          text: 'Exchange',
          icon: <CampaignTwoToneIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <CampaignTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <CampaignTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/prog_exchange',
        },
        {
          parent: 'Display',
          text: 'Banners',
          icon: <CampaignTwoToneIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <CampaignTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <CampaignTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/prog_banners',
        },
        {
          parent: 'Display',
          text: 'Banner Size',
          icon: <CampaignTwoToneIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <CampaignTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <CampaignTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/prog_banner_size',
        },
        {
          parent: 'Display',
          text: 'Device',
          icon: <CampaignTwoToneIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <CampaignTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <CampaignTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/prog_device',
        },
        {
          parent: 'Display',
          text: 'Browser',
          icon: <CampaignTwoToneIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <CampaignTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <CampaignTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/prog_browser',
        }
      ]
    
      const optionsList = [
        {
          parent: 'Options',
          text: 'User',
          icon: <SettingsTwoToneIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <SettingsTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <SettingsTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/options',
        },
      ]
    
      const dashList = [
        {
          parent: 'Custom Dashboards',
          text: 'Config',
          icon: <DashboardCustomizeIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <DashboardCustomizeIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <DashboardCustomizeIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/dash_custom',
        },
      ]

      const enhancedList = [
        {
          parent: 'Enhanced Attribution',
          text: 'Attribution - Channel',
          icon: <TableChartTwoToneIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <TableChartTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <TableChartTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/attribution_enhanced',
        },

        {
          parent: 'Enhanced Attribution',
          text: 'Attribution - Campaign',
          icon: <TableChartTwoToneIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <TableChartTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <TableChartTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/attribution_enhanced_campaign',
        },

        {
          parent: 'Enhanced Attribution',
          text: 'Attribution - AdGroup',
          icon: <TableChartTwoToneIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <TableChartTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <TableChartTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/attribution_enhanced_adgroup',
        },

        {
          parent: 'Enhanced Attribution',
          text: 'Attribution - KW',
          icon: <TableChartTwoToneIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <TableChartTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <TableChartTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/attribution_enhanced_kw',
        },

        {
          parent: 'Enhanced Attribution',
          text: 'Attribution - Last 10 Days',
          icon: <CalendarMonthTwoToneIcon
            sx={{ color: theme.backgroundColor2, fontSize: responseText * 1.4 }}
          />,
          iconHome: <CalendarMonthTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 4 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,

            }}
          />,
          iconLarge: <CalendarMonthTwoToneIcon
          sx={{
                fontFamily: 'Electrolize',
                color: theme.backgroundColor,
                fontSize: responseText * 6 ,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
                borderColor: theme.color,
                borderWidth: 1,
  
            }}
          />,
          link: '/attribution_enhanced_custom',
        },

      ]
    
    
      let filteredSummaryList;
    
      const excludedTexts = ['Cohorts ROAS']
      const excludedDisplay = ['Exchange', 'Browser', 'Device', 'OS']
    
      const filterSummaryList = summaryList.filter((item) => {
        return !excludedTexts.includes(item.text);
      });

      const filterDisplayList = progList.filter((item) => {
        return !excludedDisplay.includes(item.text);
      });
    
      if (client === 'DMJ') {
        filteredSummaryList = filterSummaryList
      } else {filteredSummaryList = summaryList}
    
      if (client === 'AFUN') {
        var combinedList = [...summaryList, ...budgetList, ...progList, ...docList, ...optionsList];
      }
    
      else if (client === 'Rank') {
      
        combinedList = [...summaryList, ...budgetList, ...ppcList, ...socialList, ...analyticsList, ...docList, ...optionsList]
      }
    
      else if (client === 'FM') {
        combinedList = [...summaryList, ...budgetList, ...filterDisplayList, ...docList, ...optionsList];
      }

      else if (client === 'Hero') {
        combinedList = [...summaryList, ...budgetList, ...progList, ...docList, ...analyticsList, ...optionsList];
      }
    
    
      else if (client === 'Test') {
      
        combinedList = [...summaryList, ...enhancedList, ...budgetList, ...ppcList, ...socialList, ...progList, ...analyticsList, ...docList, ...optionsList]
      }
    
      else if (client === 'All_Clients') {
        combinedList = [...summaryList, ...budgetList, ...optionsList];
      }
    
      else if (client === 'DMJ') {
        combinedList = [...filteredSummaryList, ...budgetList, ...filterDisplayList, ...optionsList];
      }
    
      else if (client === 'Gamdom') {
        combinedList = [...filteredSummaryList, ...budgetList, ...socialList, ...optionsList];
      }

      else if (client === 'Glitnor') {
        combinedList = [...summaryList, ...enhancedList, ...budgetList,...ppcList, ...socialList, ...progList, ...analyticsList, ...optionsList];
      }
    
      else {
        combinedList = [...summaryList, ...budgetList, ...optionsList];
      }
      
      
    return combinedList

}