import React, { useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import WebFont from 'webfontloader';
import { Dimensions } from 'react-native'

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const BarChart = (props) => {
    const chartRef = useRef(null);
    const dataProp = props.dataProp
    const theme = props.theme
    const metric = props.metric
    const axis = props.timeFrame

    let responseText;

    if (windowWidth < 428) {
        responseText = windowHeight * 0.011
    } else if (windowWidth < 1281) {
        responseText = windowHeight * 0.02
    } else if (windowWidth > 2000) {
        responseText = windowHeight * 0.012
    } else {responseText = windowHeight * 0.014}

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Electrolize']
            }
        });
    }, []);

    const getUniqueValues = (arr, key1, key2) => {
        const uniqueSet = new Set(arr.map(item => `${item[key1]} | ${item[key2]}`));
        return [...uniqueSet];
    };

    const labels = getUniqueValues(dataProp, 'brand', axis)

    const filteredData = dataProp.filter(item => item.brand !== "");    

    const desiredFields = [metric];

    const filteredValuesArray = filteredData.flatMap((item) =>
        desiredFields.map((field) => item[field])
    );
    const min = Math.min(...filteredValuesArray);
    const max = Math.max(...filteredValuesArray);

    let positive, negative;

    if (metric !== 'cpa_diff_dod' && metric !== 'cpa_diff_mom') {
        positive = theme.backgroundColor2;
        negative = theme.color;
    } else {
        positive = theme.color;
        negative = theme.backgroundColor2;
    }

    const data = {
        labels: labels,
        datasets: [
            {
                label: desiredFields + "%",
                data: filteredValuesArray,
                backgroundColor: filteredValuesArray.map(value => value >= 0 ? positive : negative),
            },
        ],
    };


    const options = {
        maintainAspectRatio: false,
        indexAxis: 'y',
        // Elements options apply to all of the options unless overridden in a dataset
        // In this case, we are setting the border of each horizontal bar to be 2px wide
        elements: {
            bar: {
                borderWidth: 2,
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    display: false, // Remove the gridlines on the y-axis
                },
                ticks: {
                    color: theme.color,
                    font: {
                        size: responseText, // Set the font size for y-axis labels
                        weight: 'bold', // Set the font weight for y-axis labels
                        family: 'Electrolize',
                    },
                },
            },
            x: {
                min: min,
                max: max,
                grid: {
                    display: false, // Remove the gridlines on the x-axis
                },
                ticks: {
                    color: theme.color,
                    font: {
                        size: responseText, // Set the font size for x-axis labels
                        family: 'Electrolize'
                    },
                },
            },
        },
        tooltips: {
            enabled: true,
        },
        plugins: {
            legend: {
                display: true,
                text: 'Chart Title',
                labels: {
                    color: theme.color,
                    // This more specific font property overrides the global property
                    font: {
                        size: responseText,
                        family: 'Electrolize'
                    }
                }
            },
            tooltip: {
                enabled: true,
            },
            title: {
                display: false,
                text: 'Chart Title',
                font: {
                    size: responseText * 1.2, // Set the font size for the chart title
                    weight: 'bold', // Set the font weight for the chart title

                },
            },
        },
    };

    return <Bar ref={chartRef} data={data} options={options} style={{ fontFamily: 'Electrolize' }} />;
};

export default BarChart;
