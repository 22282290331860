import React, { useState } from 'react';
import { View, 
    Text, 
    Pressable, 
    Modal,
    StyleSheet,
    Dimensions } from 'react-native'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ShortcutPanel from './ShortcutDnD/ShortcutPanel';
import MetricsPanel from './MetricsDnD/MetricsPanel';
import CloseIcon from '@mui/icons-material/Close';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
const responsiveTextSmall = windowWidth * 0.02

const OptionsHome = (props) => {

    const theme = props.theme
    const visible = props.visible
    const setVisible = props.setVisible
    const clientID = props.clientID
    const [shortcutVisible, setShortcutVisible] = useState(false)
    const [metricsVisible, setMetricsVisible] = useState(false)

    const configBase = {
        mainGraph: 'cumulative',
        panel1: 'mtdSpendRate',
        panel2: 'yestSpendRate',
        shortcuts: ['Daily Summary', 
                    'Weekly Summary', 
                    'Monthly Summary',
                    'Budget Summary',
                    'Dashboard Builder',
                    'GA4 Summary',
                    'GA4 Attribution',
                    'PPT Builder',
                    'Cohorts ROAS'],
        mainIcon: 'dashBuilder'
    }

    const storageConfig = JSON.parse(localStorage.getItem("homeConfig")) || configBase

    const [config, setConfig] = useState(storageConfig)

    function configHandler(index, name, type) {
        // Create a copy of the config state
        const configCopy = { ...config };
    
        if (type === 'shortcut') {
            // Modify the copy of the state
            configCopy.shortcuts[index] = name;
    
            // Set the state with the modified copy
            setConfig(configCopy);

        } else if (type === 'metrics') {

            const metricIndex = `panel${index+1}`
            // Modify the copy of the state
            configCopy[metricIndex] = name;
            // Set the state with the modified copy
            setConfig(configCopy);
        }
    }

    function saveHandler () {
        console.log('save')
        setShortcutVisible(false)
        setMetricsVisible(false)
        localStorage.setItem("homeConfig", JSON.stringify(config))
        

    }

    const graphPanel = {
        height: '100%',
        width: '100%',
        borderColor: 'red',
        borderWidth: 0,
        flexDirection: 'column',
        padding: '2%',
    }

    const topPanel = {
        height: '50%',
        width: '100%',
        padding: '1%',
        borderColor: 'grey',
        borderWidth: 1,
        flexDirection: 'row'
    }

    const topPanelGraph = {
        height: '100%',
        width: '65%',
        borderColor: 'grey',
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'center'
    }

    const topPanelMetrics = {
        height: '100%',
        width: '35%',
        borderColor: 'grey',
        borderWidth: 0 ,
        alignItems: 'center',
        justifyContent: 'center',
        padding: '2%'
    }

    const metricPress = {
        height: '35%',
        width: '100%',
        borderRadius: 20,
        backgroundColor: theme.backgroundColor2,
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: theme.backgroundColor,
        borderWidth: 0,
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.7))',
    }

    const bottomPanel = {
        height: '50%',
        width: '100%',
        padding: '1%',
        borderColor: 'grey',
        borderWidth: 1,
        flexDirection: 'row'
    }

    const fabPanel = {
        height: '10%',
        width: '100%',
        padding: '1%',
        borderColor: 'red',
        borderWidth: 0,
    }

    const bottomPress = {
        height: '35%',
        width: '35%',
        borderRadius: 20,
        backgroundColor: theme.backgroundColor2,
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: theme.backgroundColor,
        borderWidth: 0,
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.7))',
    }

    const editText = {
        fontSize: 30,
        alignText: 'center',
        color: theme.backgroundColor,
        fontFamily : 'Electrolize',
    }

    const modalWindow = {
        marginTop: '0%',
        height: '50%',
        width: '75%',
        borderColor: theme.color,
        borderWidth: 2,
        borderRadius: 10,
        padding: '0%',
        alignItems: 'center',
        justiftContent: 'center',

        }

    const panelText = {
        fontSize: 50,
        alignText: 'center',
        color: theme.color,
        fontFamily : 'Electrolize',
    }


    return (
        
        <Modal
            visible={visible}
            style={styles.modalParent}
            transparent={true}
            >
        <Pressable 
            style={styles.modalContainer}

            >
            <View style={[modalWindow , {
                backgroundColor : theme.backgroundColor,
            }]}>
                <Pressable style={{
                            position: 'absolute',
                            left: '97.4%',
                            width: 90,
                            height: 45,
                            top: '-0.2%',
                            borderColor: 'red',
                            borderWidth: 0,
                            zIndex: 5000
                        
                        }}
                        onPress={() => setVisible(false)}>
                            <CloseIcon
                        style={{
                            fontSize: responsiveTextSmall,
                            color: theme.backgroundColor,
                            backgroundColor: 'red',
                            borderRadius: 5,
                            
                        }}
                    />
                </Pressable>


                <DndProvider backend={HTML5Backend}>
                    <View style={graphPanel}>
                        <View style={topPanel}>
                            <View style={topPanelGraph}>
                                <Text style={panelText}>
                                    Edit metrics panel:
                                </Text>

                            </View>
                            
                            <View style={topPanelMetrics}>
                                <Pressable 
                                    style={metricPress}
                                    onPress={() => setMetricsVisible(true)}>
                                    <Text style={editText}>
                                        Configure
                                    </Text>
                                    <MetricsPanel
                                        visible={metricsVisible}
                                        setMetricsVisible={setMetricsVisible}
                                        config={config}
                                        setConfig={setConfig}
                                        theme={theme}
                                        configHandler={configHandler}
                                        clientID={clientID}
                                        saveHandler={saveHandler}
                                    />
                                    

                                </Pressable>
                            </View>

                        </View>
                        <View style={bottomPanel}>
                            <View style={topPanelGraph}>
                                <Text style={panelText}>
                                    Edit shortcut panel:
                                </Text>

                            </View>
                            <View style={{alignContent: 'center', justifyContent: 'center', width: '95%', padding: '2%'}}>
                            <Pressable 
                                style={bottomPress}
                                onPress={() => setShortcutVisible(true)}>
                                <Text style={editText}>
                                    Configure
                                </Text>
                                <ShortcutPanel
                                    visible={shortcutVisible}
                                    setShortcutVisible={setShortcutVisible}
                                    config={config}
                                    setConfig={setConfig}
                                    theme={theme}
                                    configHandler={configHandler}
                                    clientID={clientID}
                                    saveHandler={saveHandler}
                                />

                            </Pressable>
                        </View>
                        </View>


                    </View>
                    
                </DndProvider>
            

            </View>


        </Pressable>


        </Modal>
        )

}

export default OptionsHome

const styles = StyleSheet.create({
    modalParent: {
        padding: 50,
        borderRadius: 20,
        
    },
    modalContainer: {
        width: windowWidth * 1,
        height: windowHeight * 1,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: 'blue',
        borderWidth: 0,
    },
    modalWindow: {
        marginTop: '0%',
        height: '86%',
        width: '92%',
        borderColor: 'blue',
        borderWidth: 0,
        borderRadius: 10,
        padding: '0%',
        alignItems: 'center',
        justiftContent: 'center',

        }

})