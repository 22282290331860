import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Pressable,
    Text
} from 'react-native'
import FiltersEnhancedSingle from '../ComponentsEnhanced/FiltersEnhancedSingle';
import ChartBuilderTime from './ChartBuilderTime/ChartBuilderTime';
import ChartBuilderCampaign from './ChartBuilderCampaign/ChartBuilderCampaign';
import ChartBuilderBudget from './ChartBuilderBudget/ChartBuilderBudget';
import LoadingComponent from '../Components/Loader';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import BarChartIcon from '@mui/icons-material/BarChart';

import TableChartIcon from '@mui/icons-material/TableChart';

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

const ChartBuilderPanels = (props) => {

    const client = props.client
    const dataProp = props.dataProp
    const uniqueFilter1 = dataProp.uniqueFilter1
    const uniqueFilter2 = dataProp.uniqueFilter2
    const uniqueFilter3 = dataProp.uniqueFilter3
    const aggResult = dataProp.aggResult
    const currentMetrics = props.currentMetrics
    const filterParams = props.filterParams
    const dateLabels = props.dateLabels
    const theme = props.theme
    const handleStateChange = props.handleStateChange
    const propObject = props.propObject
    const barBrands = props.barBrands
    const filterList = props.filterList
    const setFilterList = props.setFilterList
    const lastUpdated = props.lastUpdated
    
    const panelContent = props.panelContent
    const setPanelContent = props.setPanelContent


    const lastChartUsed = localStorage.getItem('lastChart')

    if (!lastChartUsed) {
        localStorage.setItem('lastChart', 'default')
    }

    const chartConfigStorage = JSON.parse(localStorage.getItem('chartConfig'))

    const defaultChartConfig = {
        default: {
            time: {
                dataset: 'time',
                type: 'line',
                dimension: 'day',
                metric1: 'spend',
                metric2: 'ftds'
            },
            adgroup: {
                dataset: 'adgroup',
                type: 'table',
                dimension: 'adgroup',
                metric1: 'spend',
                metric2: 'ftds'
            },
            budget: {
                dataset: 'budget',
                type: 'area',
                dimension: 'calendar_date',
                metric1: 'actual_spend',
                metric2: 'budget'
            }

        }
    }

    if (!chartConfigStorage) {
        localStorage.setItem('chartConfig', JSON.stringify(defaultChartConfig))
    }

    if (!chartConfigStorage) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    const currentReport = chartConfigStorage[lastChartUsed]

    const currentDataset = currentReport[panelContent]
    

    const configProp = {
        currentDataset,
        panelContent,
        lastChartUsed
    }

    const renderComponentContent = () => {
        if (panelContent === 'time') {
            return <ChartBuilderTime
                theme={theme}
                dataProp={aggResult}
                config={configProp}
                dateLabels={dateLabels}
                currentMetrics={currentMetrics}
                client={client}
            />;
        } else if (panelContent === 'adgroup') {    
            return <ChartBuilderCampaign
                theme={theme}
                dataProp={aggResult}
                config={configProp}
                dateLabels={dateLabels}
                currentMetrics={currentMetrics}
                client={client}
            />;
            
        } else if (panelContent === 'budget') {            
            return <ChartBuilderBudget
                theme={theme}
                dataProp={aggResult}
                config={configProp}
                dateLabels={dateLabels}
                currentMetrics={currentMetrics}
                client={client}
            />;
        } else {
            return null;
        }
    };


    const panelMain = {

        width: '100%',
        height: windowHeight * 0.1,
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 40,
        fontFamily: 'Electrolize',


    }

    const panelChild = {
        flex: 1,
        height: '100%',
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 20,
        fontFamily: 'Electrolize',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2))',
    }

    const lineChart = {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
    }

    const chartPanel = {
        width: '15%',
        height: windowHeight * 0.83,
        borderColor: 'blue',
        borderWidth: 0,
        flexDirection: 'column'
    }

    const chartChild = {
        width: '100%',
        height: '20%',
        borderColor: 'grey',
        borderWidth: 1,
        padding: '1%',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2))',
        flexDirection: 'column'
    }

    const chartChildContentSelected = {
        width: '100%',
        height: '80%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.backgroundColor2,
        color: 'white',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2))',
    }

    const chartChildContent = {
        width: '100%',
        height: '80%',
        alignItems: 'center',
        justifyContent: 'center',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2))',
    }

    const iconStyleSelected = {
        color: theme.backgroundColor,
        fontSize: windowWidth * 0.06
    }

    const iconStyle = {
        color: theme.backgroundColor2,
        fontSize: windowWidth * 0.06
    }

    const chartChildTitleSelected = {
        width: '100%',
        height: '20%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.backgroundColor2,
    }

    const chartChildTitle = {
        width: '100%',
        height: '20%',
        alignItems: 'center',
        justifyContent: 'center',
    }

    const titleTextSelected = {
        fontFamily: 'Electrolize',
        color: 'white',
        textTransform: 'capitalize'
    }

    const titleText = {
        fontFamily: 'Electrolize',
        color: theme.color,
        textTransform: 'capitalize'
    }

    const iconStyleDisabled = {
        color: 'grey',
        fontSize: windowWidth * 0.06
    }





    return (
        <View style={styles.container}>
            <View style={{ flexDirection: 'column' }}>
                <View style={panelMain}>
                    <View style={{ width: '100%', flexDirection: 'flex-start', fontFamily: 'Electrolize' }}>
                        <FiltersEnhancedSingle
                            style={{
                                height: '10%',
                                borderColor: 'red',
                                borderWidth: 0,
                                width: '100%',
                                fontFamily: 'Electrolize'
                            }}
                            uniqueFilter1={uniqueFilter1}
                            uniqueFilter2={uniqueFilter2}
                            uniqueFilter3={uniqueFilter3}
                            handleStateChange={handleStateChange}
                            propObject={propObject}
                            filterParams={filterParams}
                            theme={theme}
                            setFilterList={setFilterList}
                            filterList={filterList}
                            currentMetrics={currentMetrics}
                            large={1}
                        />
                    </View>


                </View>
                <View style={{ width: '100%', 'height': windowHeight * 0.83, flexDirection: 'row' }}>
                    <View style={{ width: '85%', height: windowHeight * 0.83 }}>
                        {renderComponentContent()}

                    </View>
                    <View style={chartPanel}>
                        <View style={chartChild}>
                            <Pressable
                                onPress={() => setPanelContent('time')}
                                style={panelContent === 'time' ? chartChildContentSelected : chartChildContent}>
                                <ShowChartIcon style={panelContent === 'time' ? iconStyleSelected : iconStyle} />
                            </Pressable>
                            <View style={panelContent === 'time' ? chartChildTitleSelected : chartChildTitle}>
                                <Text style={panelContent === 'time' ? titleTextSelected : titleText}>
                                    Time
                                </Text>
                            </View>
                        </View>
                        <View style={chartChild}>
                            <Pressable
                                onPress={() => setPanelContent('adgroup')}
                                style={panelContent === 'adgroup' ? chartChildContentSelected : chartChildContent}>
                                <BarChartIcon style={panelContent === 'adgroup' ? iconStyleSelected : iconStyle} />
                            </Pressable>
                            <View style={panelContent === 'adgroup' ? chartChildTitleSelected : chartChildTitle}>
                                <Text style={panelContent === 'adgroup' ? titleTextSelected : titleText}>
                                    Campaign / Ad Group
                                </Text>
                            </View>
                        </View>
                        <View style={chartChild}>
                            <Pressable
                                onPress={() => setPanelContent('budget')}
                                style={panelContent === 'budget' ? chartChildContentSelected : chartChildContent}>
                                <TableChartIcon style={panelContent === 'budget' ? iconStyleSelected : iconStyle} />
                            </Pressable>
                            <View style={panelContent === 'budget' ? chartChildTitleSelected : chartChildTitle}>
                                <Text style={panelContent === 'budget' ? titleTextSelected : titleText}>
                                    Budget
                                </Text>
                            </View>
                        </View>
                        <View style={chartChild}>
                            <View
                                //onPress={() => setPanelContent('summary')}
                                style={chartChildContent}>
                                <TableChartIcon style={iconStyleDisabled} />
                            </View>
                            <View style={chartChildTitle}>
                                <Text style={titleText}>

                                </Text>
                            </View>
                        </View>
                        <View style={chartChild}>
                            <View
                                //onPress={() => setPanelContent('summary')}
                                style={chartChildContent}>
                                <TableChartIcon style={iconStyleDisabled} />
                            </View>
                            <View style={chartChildTitle}>
                                <Text style={titleText}>

                                </Text>
                            </View>
                        </View>
                    </View>



                </View>

            </View>

        </View>

    )

}

export default ChartBuilderPanels

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: windowHeight * 0.93,
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',


    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize',

    }
})