export default function ExecSummaryMetricsRegParse (regResult, ftdResult, brand, country) {

    var dataset = regResult

    if (!dataset || dataset.length === undefined) {
        return null;
    }

    function filterByBrand(data, brand) {
        return data.filter(item => item.brand === brand);
    }

    function filterByCountries(data, countries) {
        return data.filter(item => countries.includes(item.country));
    }
    


    const brandFilteredReg = filterByBrand(dataset, brand)
    const countryFilteredReg  = filterByCountries(brandFilteredReg, country)

    const brandFilteredFTD = filterByBrand(ftdResult, brand)
    const countryFilteredFTD  = filterByCountries(brandFilteredFTD, country)


    function aggData (input) {

    const aggregatedData = input.reduce((acc, item) => {
        const dim = item.dim;
        const minus2_day = item.minus2_day;
        const minus3_day = item.minus3_day;
        const mtd = item.mtd;
        const prevMonth = item.prevMonth;
        const prevMonthYear = item.prevMonthYear;
        const prevYTD = item.prevYTD;
        const prevWeek = item.prevWeek;
        const yesterday = item.yesterday;
        const ytd = item.ytd
        

        if (!acc[dim]) {
            acc[dim] = {
                dim: dim,
                minus2_day: parseFloat(minus2_day) || 0,
                minus3_day: parseFloat(minus3_day) || 0,
                mtd: parseFloat(mtd) || 0,
                prevMonth: parseInt(prevMonth) || 0,
                prevMonthYear: parseFloat(prevMonthYear) || 0,
                prevYTD: parseFloat(prevYTD) || 0,
                prevWeek: parseFloat(prevWeek) || 0,
                yesterday: parseFloat(yesterday) || 0,
                ytd: parseFloat(ytd) || 0,
            };
        } else {
            acc[dim].minus2_day += parseFloat(minus2_day) || 0;
            acc[dim].minus3_day += parseFloat(minus3_day) || 0;
            acc[dim].mtd += parseFloat(mtd) || 0;
            acc[dim].prevMonth += parseInt(prevMonth) || 0;
            acc[dim].prevMonthYear += parseFloat(prevMonthYear) || 0;
            acc[dim].prevYTD += parseFloat(prevYTD) || 0;
            acc[dim].prevWeek += parseFloat(prevWeek) || 0;
            acc[dim].yesterday += parseFloat(yesterday) || 0;
            acc[dim].ytd += parseFloat(ytd) || 0;
        }

        return acc;

    }, {});

    return aggregatedData 
}

    const regAggregated = aggData (countryFilteredReg)
    const ftdAggregated = aggData (countryFilteredFTD)

    const regFlat = Object.values(regAggregated)
    const ftdFlat = Object.values(ftdAggregated)

    function joinAndCalculate(ftdAgg, regConv) {
        return ftdAgg.map(ftdItem => {
            const dim = ftdItem.dim
            const regItem = regConv.find(item => item.dim === ftdItem.dim);
            const minus2_day = regItem ? ftdItem.minus2_day / regItem.minus2_day : 0;
            const minus3_day = regItem ? ftdItem.minus3_day / regItem.minus3_day : 0;
            const mtd = regItem ? ftdItem.mtd / regItem.mtd : 0;
            const prevMonth = regItem ? ftdItem.prevMonth / regItem.prevMonth : 0;
            const prevMonthYear = regItem ? ftdItem.prevMonthYear / regItem.prevMonthYear : 0;
            const prevYTD = regItem ? ftdItem.prevYTD / regItem.prevYTD : 0;
            const prevWeek = regItem ? ftdItem.prevWeek / regItem.prevWeek : 0;
            const yesterday = regItem ? ftdItem.yesterday / regItem.yesterday : 0;
            const ytd = regItem ? ftdItem.ytd / regItem.ytd : 0;
            
            return {
                dim: dim,
                minus2_day: minus2_day || 0,
                minus3_day: minus3_day || 0,
                mtd: mtd || 0,
                prevMonth: prevMonth || 0,
                prevMonthYear: prevMonthYear || 0,
                prevYTD: prevYTD || 0,
                prevWeek: prevWeek || 0,
                yesterday: yesterday || 0,
                ytd: ytd || 0,
            };
        });
    }

    const aggJoin = joinAndCalculate(ftdFlat, regFlat)

    let dataProp;

    dataProp = aggJoin.map((obj) => {
        const dim = obj.dim
        const minus2_day = obj.minus2_day
        const minus3_day = obj.minus3_day
        const mtd = obj.mtd
        const prevMonth = obj.prevMonth
        const prevMonthYear = obj.prevMonthYear
        const prevYTD = obj.prevYTD
        const prevWeek = obj.prevWeek
        const yesterday = obj.yesterday
        const ytd = obj.ytd
        const mom = (!prevMonth || isNaN(prevMonth) || prevMonth === 0) ? 0 : parseFloat(((mtd - prevMonth) / prevMonth).toFixed(2));
        const mtdYoY = (!prevMonthYear || isNaN(prevMonthYear) || prevMonthYear === 0) ? 0 : parseFloat(((mtd - prevMonthYear) / prevMonthYear).toFixed(2));
        const ytdYoY = (!prevYTD || isNaN(prevYTD) || prevYTD === 0) ? 0 : parseFloat(((ytd - prevYTD) / prevYTD).toFixed(2));

        function checkInfinite (value) {
            let output;
            if (!isFinite(value)) {
                output = 0
            } else { output = value}
            return output
        }

        return {
          metric: dim || "NULL",
          yesterday: checkInfinite(yesterday) ||0,
          same_day_last_week: checkInfinite(prevWeek) || 0,
          two_days_ago: checkInfinite(minus2_day) || 0,
          three_days_ago: checkInfinite(minus3_day) || 0,
          mtd: checkInfinite(mtd) || 0,
          mom: checkInfinite(mom) ||0,
          mtd_yoy: checkInfinite(mtdYoY) || 0,
          ytd_yoy: checkInfinite(ytdYoY) || 0

    
        };
      });

    

    return dataProp

}