import update from 'immutability-helper'
import { memo, useCallback, useState } from 'react'
import { Box } from './Box.js'
import { Dustbin } from './Dustbin.js'
import { ItemTypes } from './ItemTypes.js'
import { View, StyleSheet } from 'react-native'



export const Container = memo(function Container(props) {

    const theme = props.theme
    const dropHandler = props.dropHandler
    const currentConfig = props.currentConfig
    const configHandler = props.configHandler


    const drawerMap = [

        {
            'name' : 'currentSpend',
            'type' : 'metrics',
            'link' : 'MTD Spend',
            'parent' : 'Summaries'
        },
        {
            'name' : 'currentFtd',
            'type' : 'metrics',
            'link' : 'MTD FTDs',
            'parent' : 'Summaries'
        },
        {
            'name' : 'currentReg',
            'type' : 'metrics',
            'link' : 'MTD NRCs',
            'parent' : 'Summaries'
        },
        {
            'name' : 'currentCPA',
            'type' : 'metrics',
            'link' : 'Current CPA',
            'parent' : 'Summaries'
        },
        {
            'name' : 'currentCPR',
            'type' : 'metrics',
            'link' : 'Current CPR',
            'parent' : 'Summaries'
        },
        {
            'name' : 'currentBudget',
            'type' : 'metrics',
            'link' : 'MTD Budget',
            'parent' : 'Summaries'
        },
        {
            'name' : 'currentNgr',
            'type' : 'metrics',
            'link' : 'MTD NGR',
            'parent' : 'Summaries'
        },
        {
            'name' : 'mtdSpendRate',
            'type' : 'metrics',
            'link' : 'MTD Spend Rate',
            'parent' : 'Budget'
        },
        {
            'name' : 'yestSpendRate',
            'type' : 'metrics',
            'link' : 'Yest Spend Rate',
            'parent' : 'Budget'
        },
    ]


    const [chartGraph1, setchartGraph1] = useState([
        { accepts: [ItemTypes.METRIC], lastDroppedItem: null },
        { accepts: [ItemTypes.METRIC], lastDroppedItem: null }
    ])



    const [droppedChild1Names, setDroppedChild1Names] = useState([])
    function isDropped(boxName) {
        return droppedChild1Names.indexOf(boxName) > -1
    }




    const handleChild1Drop = useCallback(
        (index, item, type) => {
            const { name } = item
            setDroppedChild1Names(
                update(droppedChild1Names, name ? { $push: [name] } : { $push: [] }),
                configHandler(index, name, type),
            )
            setchartGraph1(
                update(chartGraph1, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            )
        },
        [droppedChild1Names, chartGraph1, dropHandler],
    )




    return (
        <View style={styles.parent}>
        <View style={styles.container}>
            <View style={styles.topRow}>
                <View style={styles.graphTopRow}>
                <div style={{paddingLeft: '0%', }}>
                    {drawerMap.map(({ name, type, parent, link}, index) => (
                        <Box
                            name={name}
                            type={type}
                            parent={parent}
                            isDropped={isDropped(name)}
                            key={index}
                            theme={theme}
                            style={{padding: '1px'}}
                            link={link}
                        />
                    ))}
                </div>

                </View>

            </View>
            <View style={styles.bottomRow}>
                
                    <View style={{ width: '100%', 
                                        height: '100%', 
                                        paddingLeft: '0%',
                                        flexDirection: 'column',

                                        fontFamily: 'Electrolize',
                                        borderColor: 'red',
                                        borderWidth: 0}}>
                    {chartGraph1.map(({ accepts, lastDroppedItem }, index) => (
                        <View style={styles.bottomRowPanel}
                            key={index}>
                        <Dustbin
                            accept={accepts}
                            lastDroppedItem={lastDroppedItem}
                            onDrop={(item) => handleChild1Drop(index, item, 'metrics')}
                            key={index}
                            theme={theme}
                            currentConfig={currentConfig}
                            indexCount={index}
                            chartType={'metrics'}
                            />
                            </View>
                        ))}                            
                    </View>  

                

            </View>
            

        </View>
           

        </View>
        
    )
})

const styles = StyleSheet.create({
    
    parent: {
        flexDirection: 'row',
        height: '100%',
        width: '100%',
        borderWidth: 0,
        borderColor: 'red',
    },    
    container: {
        borderWidth: 1,
        borderRadiius: 10,
        borderColor: 'grey',
        borderRadius: 10,
        height: '100%',
        width: '100%',
        fontFamily: 'Electrolize',
        flexDirection: 'row'
    },
    footer : {
        borderWidth: 0,
        borderColor: 'grey',
        height: '100%',
        width: '20%',
        fontFamily: 'Electrolize',
        flexDirection: 'column',
        justifyContent: 'center',
        flexWrap: 'wrap',
        padding: '0.5%'

    },
    footerFAB: {
        borderWidth: 0,
        borderColor: 'grey',
        height: '10%',
        width: '20%',
        fontFamily: 'Electrolize',

        justifyContent: 'center',

    },

    topRow: {
        width: '60%',
        height: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 10

    },
    bottomRow: {
        width: '40%',
        height: '100%',
        flexDirection: 'row',
        borderWidth: 1,
        borderColor: 'grey',
        borderRadius: 10

    },
    graphTopRow: {
        height: '100%',
        width: '100%',
        borderWidth: 1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: '6%'

    },
    panelContainer: {
        height: '100%',
        width: '33.333333%',
        flexDirection: 'column',
        borderWidth: 1,
        borderColor: 'grey',
    },
    panelContainerRow: {
        width: '100%',
        height: '33.333333%',
        flexDirection: 'row',
    },
    panelContainerRowItem: {
        width: '50%',
        height: '100%',
        borderWidth: 1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center'
    },
    topRowPanel: {
        width: '100%',
        height: '33.333333%',
        borderWidth: 1,
        borderColor: 'grey',
        
    },
    bottomRowPanel: {
        width: '100%',
        height: '50%',
        borderWidth: 0,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1%'
    },
})
