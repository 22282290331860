import React, { useEffect } from 'react';
import {
    View,
    StyleSheet,
    Text,
    Dimensions
} from 'react-native'
import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';
import WebFont from 'webfontloader';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;



const ChatText = (props) => {

    const theme = props.theme

    const mainText = {
        fontSize : windowWidth * 0.012,
        fontFamily: 'Electrolize',
        textAlign: 'center'
    }

    return (
        <View style={styles.ChatText}>
            
             <Text
            style={[mainText, {color: theme.color}]}
            >
             - As with any AI, the right prompts will get better results
             </Text>
             <Text
            style={[mainText, {color: theme.color}]}
            >
             - Line Chart with ....
             </Text>
             <Text
            style={[mainText, {color: theme.color}]}
            >
             - Bar Chart with ....
             </Text>
             <Text
            style={[mainText, {color: theme.color}]}
            >
             - Pie Chart with ....
             </Text>
             <Text
            style={[mainText, {color: theme.color}]}
            >
             - Mixed Chart with ....
             </Text>
             <Text
            style={[mainText, {color: theme.color}]}
            >
             - Table with ....
             </Text>
             <Text
            style={[mainText, {color: theme.color}]}
            >
             - Ask for a single metric with filters
             </Text>
             <Text
            style={[mainText, {color: theme.color}]}
            >
             - If not specified, a table or single metric will be returned
             </Text>
             <Text
            style={[mainText, {color: theme.color}]}
            >
             - Sometimes responses might not be 100% accurate to your request
             </Text>
             <Text
            style={[mainText, {color: theme.color}]}
            >
             - Account for case sensitivity in any filtering
             </Text>
        </View>
    )
}

const ChatImage = () => {

    return (
        <View style={styles.ChatImage}>
            <SmartToyTwoToneIcon/>

        </View>
    )
}

const ChatInstructions = (props) => {

    const theme = props.theme

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    return (
        <View style={styles.container}>
            <ChatText 
                theme={theme}
                />


        </View>
    )

}

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'yellow',
        paddingTop: 0,
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
        width: '100%',
        height: windowHeight * 0.35,

    },
    ChatText: {
        width: '100%',
        borderWidth: 0,
        borderColor: 'blue',
        borderRadius : 10,
        fontFamily: 'Electrolize',
        padding: '1%',
        textAlign: 'center',
    },
    ChatImage: {
        width: '25%',
        borderWidth: 0,
        borderColor: 'yellow',
        flex : 1
    },
    mainText: {
        fontSize : 25,
        fontFamily: 'Electrolize',
        textAlign: 'center'
    }
})

export default ChatInstructions