import React from 'react';
import { View, StyleSheet, Dimensions } from 'react-native'
import Tooltip from '@mui/material/Tooltip';
import FiltersEnhancedSingle from '../ComponentsEnhanced/FiltersEnhancedSingle';
import FilterPanelV2 from '../Components/FilterPanelV2';
import Fab from '@mui/material/Fab';
import ModalChartType from '../Modals/ModalChartType';
import ChartPanelLarge from './ChartPanelLarge';
import AddchartTwoToneIcon from '@mui/icons-material/AddchartTwoTone';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
const responsiveText = windowWidth * 0.006
const responsiveImage = windowWidth * 0.0125

const ChartPanel = (props) => {

    const theme = props.theme
    const chartConfig = props.chartConfig
    const currentMetrics = props.currentMetrics
    const dataset = props.dataProp
    const uniqueFilter1 = dataset.uniqueFilter1
    const uniqueFilter2 = dataset.uniqueFilter2
    const uniqueFilter3 = dataset.uniqueFilter3
    const handleStateChange = props.handleStateChange
    const propObject = props.propObject
    const tooltipArray = props.tooltipArray
    const labels = props.labels
    const uniqueBrands = props.uniqueBrands
    const uniqueChannels = props.uniqueChannels
    const uniqueTypes = props.uniqueTypes
    const chartFilterVisible = props.chartFilterVisible
    const setmodalVisible = props.setmodalVisible
    const modalType = props.modalType
    const setModalType = props.setModalType
    const setChartClick = props.setChartClick
    const setAxisClick = props.setAxisClick
    const setMetric1 = props.setMetric1
    const setMetric2 = props.setMetric2
    const filterParams = props.filterParams
    const filterList = props.filterList
    const setFilterList = props.setFilterList

    function chartClickHandler(props) {
        setChartClick(props)
        handleModalButton(false)
    }

    function axisClickHandler(props) {
        setAxisClick(props)
        handleModalButton(false)
    }

    function metric1Handler(props) {
        setMetric1(props)
        handleModalButton(false)
    }

    function metric2Handler(props) {
        setMetric2(props)
        handleModalButton(false)
    }


    const handleModalButton = (prop) => {
        setmodalVisible(false)
    }

    return (
        <View style={[styles.container, { backgroundColor: theme.backgroundColor }]}>
        <View>
        <FiltersEnhancedSingle
                            style={{
                                height: '10%',
                                borderColor: 'red',
                                borderWidth: 0,
                                width: '100%',
                                fontFamily: 'Electrolize'
                            }}
                            uniqueFilter1={uniqueFilter1}
                            uniqueFilter2={uniqueFilter2}
                            uniqueFilter3={uniqueFilter3}
                            handleStateChange={handleStateChange}
                            propObject={propObject}
                            filterParams={filterParams}
                            theme={theme}
                            setFilterList={setFilterList}
                            filterList={filterList}
                            currentMetrics={currentMetrics}
                            large={1}
                        />
        </View>
            <TopPanel
                theme={theme}
                chartClickHandler={chartClickHandler}
                axisClickHandler={axisClickHandler}
                metric1Handler={metric1Handler}
                metric2Handler={metric2Handler}
                handleModalButton={handleModalButton}
                chartFilterVisible={chartFilterVisible}
                setmodalVisible={setmodalVisible}
                setModalType={setModalType}
                chartConfig={chartConfig}
                dataset={dataset}
                modaltype={modalType}
                uniqueBrands={uniqueBrands}
                uniqueChannels={uniqueChannels}
                uniqueTypes={uniqueTypes}
                labels={labels}
                tooltipArray={tooltipArray}
                currentMetrics={currentMetrics}
            />
        </View>
    )

}

const TopPanel = (props) => {

    const theme = props.theme
    const chartClickHandler = props.chartClickHandler
    const axisClickHandler = props.axisClickHandler
    const metric1Handler = props.metric1Handler
    const metric2Handler = props.metric2Handler
    const handleModalButton = props.handleModalButton
    const chartFilterVisible = props.chartFilterVisible
    const setmodalVisible = props.setmodalVisible
    const modaltype = props.modaltype
    const setModalType = props.setModalType
    const chartConfig = props.chartConfig
    const dataset = props.dataset
    const uniqueBrands = props.uniqueBrands
    const uniqueChannels = props.uniqueChannels
    const uniqueTypes = props.uniqueTypes
    const labels = props.labels
    const tooltipArray = props.tooltipArray
    const currentMetrics = props.currentMetrics

    let typeDisabled = false;

    if (chartConfig.type !== 'Mixed') {
        typeDisabled = true;
    }

    let groupingDisabled = true;

    if (chartConfig.type !== 'Daily Bar' && chartConfig.type !== 'Daily Line' && chartConfig.type !== 'Monthly Line' && chartConfig.type !== 'Monthly Bar') {
        groupingDisabled = false;
    }

    return (
        <View style={[styles.topPanelParent, { borderColor: theme.color }]}>
            <View style={styles.topPanelChildLarge}>
            <ChartPanelLarge
                    modaltype={modaltype}
                    chartConfig={chartConfig}
                    dataset={dataset}
                    theme={theme}
                    uniqueBrands={uniqueBrands}
                    uniqueChannels={uniqueChannels}
                    uniqueTypes={uniqueTypes}
                    labels={labels}
                    currentMetrics={currentMetrics}
                    
                />

            </View>
            <View style={styles.topPanelChildSmall}>
                

                <FabBox
                    buttonText={"Select Chart Type"}
                    theme={theme}
                    disabled={false}
                    setmodalVisible={setmodalVisible}
                    handleModalButton={handleModalButton}
                    chartFilterVisible={chartFilterVisible}
                    chartClickHandler={chartClickHandler}
                    axisClickHandler={axisClickHandler}
                    metric1Handler={metric1Handler}
                    metric2Handler={metric2Handler}
                    modaltype={'chart'}
                    setModalType={setModalType}
                    modalView={modaltype}
                    tooltipValue={"Line, Bar, Grouped and Mixed"}
                    tooltipArray={tooltipArray}
                    currentMetrics={currentMetrics}
                >
                </FabBox>


                <FabBox
                    buttonText={"Select Grouping"}
                    theme={theme}
                    disabled={groupingDisabled}
                    setmodalVisible={setmodalVisible}
                    handleModalButton={handleModalButton}
                    chartFilterVisible={chartFilterVisible}
                    chartClickHandler={chartClickHandler}
                    axisClickHandler={axisClickHandler}
                    metric1Handler={metric1Handler}
                    metric2Handler={metric2Handler}
                    modaltype={'axis'}
                    setModalType={setModalType}
                    modalView={modaltype}
                    tooltipValue={"Choose 'Grouped Chart' to define dimension"}
                    tooltipArray={tooltipArray}
                    currentMetrics={currentMetrics}
                />
                <FabBox
                    buttonText={"Select Metric 1"}
                    theme={theme}
                    disabled={false}
                    setmodalVisible={setmodalVisible}
                    handleModalButton={handleModalButton}
                    chartFilterVisible={chartFilterVisible}
                    chartClickHandler={chartClickHandler}
                    axisClickHandler={axisClickHandler}
                    metric1Handler={metric1Handler}
                    metric2Handler={metric2Handler}
                    modaltype={'metric1'}
                    setModalType={setModalType}
                    modalView={modaltype}
                    tooltipValue={"Select primary metric"}
                    tooltipArray={tooltipArray}
                    currentMetrics={currentMetrics}
                />
                <FabBox
                    buttonText={"Select Metric 2"}
                    theme={theme}
                    disabled={typeDisabled}
                    setmodalVisible={setmodalVisible}
                    handleModalButton={handleModalButton}
                    chartFilterVisible={chartFilterVisible}
                    chartClickHandler={chartClickHandler}
                    axisClickHandler={axisClickHandler}
                    metric1Handler={metric1Handler}
                    metric2Handler={metric2Handler}
                    modaltype={'metric2'}
                    setModalType={setModalType}
                    modalView={modaltype}
                    tooltipValue={"Choose 'Mixed' chart type to define secondary metric"}
                    tooltipArray={tooltipArray}
                    currentMetrics={currentMetrics}
                />


            </View>

        </View>
    )
}

const FabBox = (props) => {

    const buttonText = props.buttonText
    const theme = props.theme
    const setmodalVisible = props.setmodalVisible
    const chartFilterVisible = props.chartFilterVisible
    const handleModalButton = props.handleModalButton
    const chartClickHandler = props.chartClickHandler
    const axisClickHandler = props.axisClickHandler
    const metric1Handler = props.metric1Handler
    const metric2Handler = props.metric2Handler
    const modalType = props.modaltype
    const setModalType = props.setModalType
    const modalView = props.modalView
    const disabledProp = props.disabled
    const tooltipValue = props.tooltipValue
    const tooltipArray = props.tooltipArray
    const tooltipVisible = tooltipArray.toolTips
    const currentMetrics = props.currentMetrics

    return (
        <View
            style={{
                
                paddingLeft: '5%',
                paddingRight: '5%',
                borderWidth: 0,
                borderColor: theme.color,
                height: '25%',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
        <Tooltip 
            title={tooltipVisible === false ? "" : (tooltipValue)}
            componentsProps={{
                    arrow: {
                    sx: {
                        color: theme.backgroundColor2,
                    }
                    },
                    tooltip: {
                    sx: {
                        bgcolor: theme.backgroundColor2,
                        color: "white",
                        fontFamily : 'Electrolize',
                    }
                    },

                }}
            arrow
            >
        <span style={{paddingTop: '0%'}}>
            <Fab variant="extended"
                onClick={() => { setmodalVisible(true); setModalType(modalType) }}
                modalType={modalType}
                disabled={disabledProp}
                sx={{
                    color: 'white',
                    backgroundColor: theme.backgroundColor2,
                    width: '100%',
                    fontFamily : 'Electrolize',
                    fontSize: responsiveText,
                    border: `1px solid ${theme.backgroundColor2}`,
                }}>
                <AddchartTwoToneIcon sx={{
                    mr: 1,
                    color: 'white',
                    fontSize: responsiveImage
                }} />
                {buttonText}
            </Fab>
            </span>
            </Tooltip>
            <ModalChartType
                handleModalButton={handleModalButton}
                chartFilterVisible={chartFilterVisible}
                chartClickHandler={chartClickHandler}
                axisClickHandler={axisClickHandler}
                metric1Handler={metric1Handler}
                metric2Handler={metric2Handler}
                modalType={modalView}
                theme={theme}
                currentMetrics={currentMetrics}
            />
        </View>
    )
}


const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'yellow',
        paddingTop: 0,
        flexDirection: 'column',
        width: '100%',
        height: windowHeight * 0.931,

    },
    topPanelParent: {
        width: '100%',
        height: '90%',
        borderWidth: 0,
        paddingTop: '1.5%',
        paddingBottom: '1%',
        flexDirection: 'row',
    },
    topPanelChildLarge: {
        width: '85%',
        height: '100%',
        borderWidth: 0,
        borderColor: 'yellow',
    },
    topPanelChildSmall: {
        width: '15%',
        height: '100%',
        borderWidth: 0,
        borderColor: 'yellow',
        flexDirection: 'column',
        padding: '1%'
    }
})

export default ChartPanel