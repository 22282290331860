export default function ExecSummaryMetricsParse (data) {

    function filterByCriteria(data, key, acceptedValues) {
        // Filter the data array
        return data.filter(item => {
            // Check if the item's specified key's value is in the acceptedValues array
            return acceptedValues.includes(item[key]);
        });
    }

    const ftdData = filterByCriteria(data, 'type', ['FTD']);
    const regData = filterByCriteria(data, 'type', ['Reg']);
    const regConvData = filterByCriteria(data, 'type', ['Reg Conv']);
    const productGgr = filterByCriteria(data, 'type', ['Product GGR']);
    const productBet = filterByCriteria(data, 'type', ['Product Bet']);
    const productNgr = filterByCriteria(data, 'type', ['Product NGR']);
    const productActives = filterByCriteria(data, 'type', ['Product Actives']);
    const vipNgr = filterByCriteria(data, 'type', ['VIP NGR']);
    const vipBonus = filterByCriteria(data, 'type', ['VIP Bonus']);
    const vipDeposits = filterByCriteria(data, 'type', ['VIP Deposits']);
    const vipActives = filterByCriteria(data, 'type', ['VIP Actives']);
    const vipDepositors = filterByCriteria(data, 'type', ['VIP Depositors']);
    const firstFtd = filterByCriteria(data, 'type', ['First Played FTD']);
    const summary = filterByCriteria(data, 'type', ['Summary']);
    const providerGgr = filterByCriteria(data, 'type', ['Provider GGR']);
    const providerBet = filterByCriteria(data, 'type', ['Provider Bet']);

    const output = {
        "Summary" : summary,
        "FTD" : ftdData,
        "Reg" : regData,
        "First Product FTD" : firstFtd,
        "Reg Conv" : regConvData,
        "VIP" : {
            vipNgr,
            vipBonus,
            vipDeposits,
            vipActives,
            vipDepositors
        },
        "Product" : {
            productGgr,
            productBet,
            productNgr,
            productActives
        },
        "Provider" : {
            providerGgr,
            providerBet
        }

    }

    return output

}