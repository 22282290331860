import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,

} from 'react-native'
import WebFont from 'webfontloader';
import moment from 'moment-timezone';
import { useCubeQuery } from '@cubejs-client/react';
import LoadingComponent from '../Components/Loader.js';
import CubeAnalytics from './CubeFunctions/CubeAnalytics.js';
import AnalyticsPanels from './AnalyticsPanels';
import ParseAnalytics from './CubeFunctions/ParseAnalytics.js';
import CubeAnalyticsDimensionsV2 from './CubeFunctions/CubeAnalyticsDimensionsV2.js';
import ParseDimensionsAtt from '../AttributionEnhanced/CubeFunctions/ParseDimensionsAtt.js';

const windowHeight = Dimensions.get('window').height;

const AnalyticsPanelsV2 = (props) => {

    const theme = props.theme
    const currentMetrics = props.currentMetrics
    const setLastUpdated = props.setLastUpdated
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayProp = yesterday2.format('YYYY-MM-DD');
    const homeTitle = "Daily Summary";
    const clientID = props.clientID

    let currentChannel;

    if (clientID === 'Glitnor') {
        currentChannel = ['Google Ads', 'Microsoft Ads']
    } else {currentChannel = ['PPC']}

    const client = clientID.toLowerCase();
    const cubeTable = client + "_analytics_monthly";

    const currentDate = new Date();

    // Set the timezone offset to 0 to work with local time
    currentDate.setMinutes(0);
    const previousDate = new Date(currentDate);
    previousDate.setDate(currentDate.getDate() - 1);
    previousDate.setDate(1)

    const filter1name = 'brand'
    const filter2name = 'channel'
    const filter3name = 'kw_type'

    const [filter1List, setFilter1List] = useState('Total')
    const [filter2List, setFilter2List] = useState('Total')

    const filterValueBase = {
        filter1List : [],
        filter2List : [],
        filter3List : []
      }
    const [filterList, setFilterList] = useState(filterValueBase);

    const filterParams = {
        filter1name,
        filter2name,
        filter1List,
        filter2List,
        setFilter1List,
        setFilter2List,
    }

    const filterBase = {
        start: "2023-01-01",
        end: yesterdayProp,
        [filter1name]: "Total",
        [filter2name]: 'Total',
    };


    const [filters, setFilters] = useState(filterBase);

    const start = filters.start
    const end = filters.end
    let filter1Filter, filter1Operator;

    if (filters[filter1name] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name].length === 0) {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name][0] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else {
        filter1Filter = filter1List
        filter1Operator = ['equals']
    }

    let filter2Filter, filter2Operator;

    if (filters[filter2name] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name].length === 0) {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name][0] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else {
        filter2Filter = filter2List
        filter2Operator = ['equals']
    }

      const propObjectBase = {
        data: [],
        start: "2023-01-01",
        end: yesterdayProp,
        theme: theme,
        [filter1name]: "Total",
        [filter2name]: 'Total',
      };
    
      const [propObject, setpropObject] = useState(propObjectBase);

      const handleStateChange = (prop) => {

        setpropObject(prop);
  
        const filterUpdate = {
            start: prop.start,
            end: prop.end,
            [filter1name]: prop[filter1name],
            [filter2name]: prop[filter2name],
          };
  
        setFilters(filterUpdate)
  
      };


    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    const cubeFilters = [
        {
            "member": `${cubeTable}.${filter1name}`,
            "operator": `${filter1Operator}`,
            "values": filter1Filter
        },
        {
            "member": `${cubeTable}.${filter2name}`,
            "operator": `${filter2Operator}`,
            "values": filter2Filter
        }
    ]


    const cubeQuery = CubeAnalytics(cubeFilters, cubeTable, start, end)
    const { resultSet } = useCubeQuery(cubeQuery);
    const dimQuery = CubeAnalyticsDimensionsV2(cubeTable, start, end, filter1name, filter2name, cubeFilters)
    const dimResult = useCubeQuery(dimQuery);

    if (!resultSet) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    if (dimResult.isLoading === true) {
        return (
            <LoadingComponent theme={theme} />
        );
    }


    const cubeResponse = resultSet.loadResponse.results[0].data
    const dimData = dimResult.resultSet.loadResponses[0].data

    const uniqueFilter1 = ParseDimensionsAtt(dimData, cubeTable, filter1name);
    const uniqueFilter2 = ParseDimensionsAtt(dimData, cubeTable, filter2name);

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
    };



    const updateArray = cubeResponse.map(obj => {
        const newObj = {};
        for (const key in obj) {
            const newKey = key.replace(cubeTable + ".", "");
            newObj[newKey] = obj[key];
        }
        return newObj;
    });

    const updateVar = `${cubeTable}.updated_at`
    const lastUpdated = dimData[0][updateVar] || 'loading...'

    if (lastUpdated) {
        setLastUpdated(lastUpdated)
    }

    const agg_daily_result = ParseAnalytics(updateArray, 'timestamp');
    const agg_brand_result = ParseAnalytics(updateArray, 'brand');
    const agg_channel_result = ParseAnalytics(updateArray, 'channel');
    const agg_type_result = ParseAnalytics(updateArray, 'kw_type');

    const dateLabels = getUniqueValues(agg_daily_result, 'month');

    const dataProp = {
        agg_daily_result,
        agg_brand_result,
        agg_channel_result,
        agg_type_result,
        dateLabels
    }

    const dataPropFilter = {
        aggResult : agg_daily_result,
        uniqueFilter1,
        uniqueFilter2,
    }

    return (
        <View style={styles.container}>

            <View style={{width : '100%' , height : '100%'}}>
            <AnalyticsPanels 
                style={{flex : 1 }}
                theme={theme}
                dataProp={dataProp}
                dateLabels={dateLabels}
                propObject={propObject}
                handleStateChange={handleStateChange}
                setHomeTitle={homeTitle}
                timeFrame={"Monthly"}
                filterParams={filterParams}
                dataPropFilter={dataPropFilter}
                currentChannel={currentChannel}
                setFilterList={setFilterList}
                filterList={filterList}
                currentMetrics={currentMetrics}
                />
            </View>           
        </View>

    )

}

export default AnalyticsPanelsV2

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: windowHeight * 1.21,
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})