import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Dimensions, View } from 'react-native'
import { useState } from 'react'

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

export default function AttModelSelect(props) {
  const open = props.open
  const setOpen = props.setOpen
  const modelHandler = props.modelHandler
  const models = props.models

  
  const [modelState, setModelState] = useState(models)

  const model1 = modelState.model1
  const model2 = modelState.model2
  const model3 = modelState.model3

  const modelList = ["ft_lifetime",
    "lt_lifetime",
    "ft_28",
    "ft_14",
    "ft_7",
    "ft_1",
    "linear_1",
    "linear_7",
    "linear_14",
    "linear_28",
    "linear_lifetime",
    "td_first_1",
    "td_first_7",
    "td_first_14",
    "td_first_28",
    "td_first_lifetime",
    "td_last_1",
    "td_last_7",
    "td_last_14",
    "td_last_28",
    "td_last_lifetime",    
    "pos_1",
    "pos_7",
    "pos_14",
    "pos_28",
    "pos_lifetime",
    "custom_pixel"]

  const [age, setAge] = React.useState('');

  const handleChange = (event, model) => {
    setAge((event.target.value) || '');

    const modelCopy = models
    modelCopy[model] = event.target.value
    setModelState(modelCopy)


  };


  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
    console.log(reason)
  };

  function handleSave () {
    modelHandler(modelState);
    setOpen(false);
  }



  const options = modelList.map((item, index) => (
    <MenuItem key={index} value={item}>
      {item}
    </MenuItem>
  ));

  return (
    <View style={{width: windowWidth * 0.8, borderColor: 'red', borderWidth: 0}}>
    <div>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose} maxWidth={1500}>
        <DialogTitle sx={{fontFamily: 'Electrolize'}}>Select Attribution Models</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ 
                                    display: 'flex', 
                                    flexDirection: 'row',
                                    }}>
            <FormControl sx={{ m: 1, minWidth: windowWidth * 0.15,fontFamily: 'Electrolize',}}>
              <InputLabel id="demo-dialog-select-label">1st</InputLabel>
              <Select
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                value={model1}
                onChange={(event) => handleChange(event, 'model1')}
                input={<OutlinedInput label="Age" />}
              >
                {options}
              </Select>
            </FormControl><FormControl sx={{ m: 1, minWidth: windowWidth * 0.15}}>
              <InputLabel id="demo-dialog-select-label">2nd</InputLabel>
              <Select
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                value={model2}
                onChange={(event) => handleChange(event, 'model2')}
                input={<OutlinedInput label="Age" />}
              >
                {options}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: windowWidth * 0.15}}>
              <InputLabel id="demo-dialog-select-label">3rd</InputLabel>
              <Select
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                value={model3}
                onChange={(event) => handleChange(event, 'model3')}
                input={<OutlinedInput label="Age" />}
              >
                {options}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
    </View>
  );
}