import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
} from 'react-native'
import WebFont from 'webfontloader';
import moment from 'moment-timezone';
import AllChannelYearlyPanels from './AllChannelYearlyPanels.js';
import { useCubeQuery } from '@cubejs-client/react';
import LoadingComponent from '../../Components/Loader.js';
import CubeDailyFunction from '../../DailySummary/CubeFunctions/CubeDailyFunction.js';
import ParseYearly from '../../Yearly/CubeFunctions/ParseYearly.js';
import FiltersEnhancedSingle from '../../ComponentsEnhanced/FiltersEnhancedSingle';
import ParseDimensionsAtt from '../../AttributionEnhanced/CubeFunctions/ParseDimensionsAtt.js';

const windowWidth = Dimensions.get('window').width;

const AllChannelYearlyPanel = (props) => {

    const theme = props.theme
    const currentMetrics = props.currentMetrics
    const setLastUpdated = props.setLastUpdated
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayProp = yesterday2.format('YYYY-MM-DD');
    const clientID = props.clientID
    const client = clientID.toLowerCase();
    const cubeTable = client + "_daily_all";

    const filter1name = 'brand'
    const filter2name = 'channel'
    const filter3name = 'kw_type'

    const [filter1List, setFilter1List] = useState('Total')
    const [filter2List, setFilter2List] = useState('Total')
    const [filter3List, setFilter3List] = useState('Total')

    const filterValueBase = {
        filter1List : [],
        filter2List : [],
        filter3List : []
      }
    const [filterList, setFilterList] = useState(filterValueBase);

    const filterParams = {
        filter1name,
        filter2name,
        filter3name,
        filter1List,
        filter2List,
        filter3List,
        setFilter1List,
        setFilter2List,
        setFilter3List,
    }


    const filterBase = {
        start: "2022-07-01",
        end: yesterdayProp,
        [filter1name]: "Total",
        [filter2name]: 'Total',
        [filter3name]: "Total",
    };

    const [filters, setFilters] = useState(filterBase);

    const start = filters.start
    const end = filters.end
    let filter1Filter, filter1Operator;

    if (filters[filter1name] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name].length === 0) {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name][0] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else {
        filter1Filter = filter1List
        filter1Operator = ['equals']
    }

    let filter2Filter, filter2Operator;

    if (filters[filter2name] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name].length === 0) {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name][0] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else {
        filter2Filter = filter2List
        filter2Operator = ['equals']
    }

    let filter3Filter, filter3Operator;

    if (filters[filter3name] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filter3name].length === 0) {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filter3name][0] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    }  else {
        filter3Filter = filter3List
        filter3Operator = ['equals']
    }

    const propObjectBase = {
        data: [],
        start: "2022-07-01",
        end: yesterdayProp,
        theme: theme,
        [filter1name]: "Total",
        [filter2name]: 'Total',
        [filter3name]: "Total",
    };

    const [propObject, setpropObject] = useState(propObjectBase);

    const handleStateChange = (prop) => {

        setpropObject(prop);

        const filterUpdate = {
            start: prop.start,
            end: prop.end,
            [filter1name]: prop[filter1name],
            [filter2name]: prop[filter2name],
            [filter3name]: prop[filter3name],
        };

        setFilters(filterUpdate)

    };


    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    const cubeFilters = [
        {
            "member": `${cubeTable}.${filter1name}`,
            "operator": `${filter1Operator}`,
            "values": filter1Filter
        },
        {
            "member": `${cubeTable}.${filter2name}`,
            "operator": `${filter2Operator}`,
            "values": filter2Filter
        },
        {
            "member": `${cubeTable}.${filter3name}`,
            "operator": `${filter3Operator}`,
            "values": filter3Filter
        }
    ]


    const cubeQuery = ParseYearly(cubeFilters, cubeTable, start, end);
    const { resultSet } = useCubeQuery(cubeQuery);
    const dimQuery = CubeDailyFunction (cubeTable, start, end, filter1name, filter2name, filter3name, cubeFilters)
    const dimResult = useCubeQuery(dimQuery)

    if (!resultSet) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    if (dimResult.isLoading === true) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    const cubeResponse = resultSet.loadResponse.results[0].data
    const dimData = dimResult.resultSet.loadResponses[0].data


    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
    };

    const uniqueFilter1 = ParseDimensionsAtt(dimData, cubeTable, filter1name);
    const uniqueFilter2 = ParseDimensionsAtt(dimData, cubeTable, filter2name);
    const uniqueFilter3 = ParseDimensionsAtt(dimData, cubeTable, filter3name);

    const updateArray = cubeResponse.map(obj => {
        const newObj = {};
        for (const key in obj) {
            const newKey = key.replace(cubeTable + ".", "");
            newObj[newKey] = obj[key];
        }
        return newObj;
    });

    const updateVar = `${cubeTable}.updated_at`
    const lastUpdated = dimData[0][updateVar] || 'loading...'

    if (lastUpdated) {
        setLastUpdated(lastUpdated)
    }

    const agg_result = updateArray.map((obj) => {
        const date = moment.tz(obj.timestamp, 'Europe/Paris').startOf('month').format('YYYY-MM-DD');
        const yearFormatted = moment.tz(obj.timestamp, 'Europe/Paris').startOf('month').format('YYYY');
        const monthFormatted = moment.tz(obj.timestamp, 'Europe/Paris').startOf('month').format('MM');

        const spend = parseFloat(obj.spend)
        const ftds = parseInt(obj.ftds)
        const cpa = parseFloat((spend / ftds).toFixed(2))
        const impressions = parseInt(obj.impressions)
        const clicks = parseInt(obj.clicks)
        const regs = parseInt(obj.regs)
        const cpc = parseFloat((spend / clicks).toFixed(2))
        const ftdConv = ((parseFloat(ftds / clicks)) * 100).toFixed(2)

        return {
            date: date,
            month: monthFormatted,
            year: yearFormatted,
            impressions: impressions,
            clicks: clicks,
            spend: spend,
            cpc: cpc,
            regs: regs,
            ftds: ftds,
            cpa: cpa,
            clickConv: ftdConv

        };
    });

    const dateLabels = getUniqueValues(agg_result, 'date')

    const dataProp = {
        aggResult : agg_result,
        uniqueFilter1,
        uniqueFilter2,
        uniqueFilter3,
    }

    return (
        <View style={styles.container}>
            <View style={{ width: '100%' }}>
            <FiltersEnhancedSingle
                style={{
                    height: '10%',
                    borderColor: 'red',
                    borderWidth: 0,
                    width: '100%',
                    fontFamily: 'Electrolize'
                }}
                uniqueFilter1={uniqueFilter1}
                uniqueFilter2={uniqueFilter2}
                uniqueFilter3={uniqueFilter3}
                handleStateChange={handleStateChange}
                propObject={propObject}
                filterParams={filterParams}
                theme={theme}
                large={1}
                setFilterList={setFilterList}
                filterList={filterList}
                currentMetrics={currentMetrics}
            />
            </View>
            <View style={{ width: '100%' }}>
                <AllChannelYearlyPanels
                    theme={theme}
                    dataProp={agg_result}
                    dateLabels={dateLabels}
                    agg_brand_result={agg_result}
                    agg_yearly_result={agg_result}
                    client={client}
                    setFilterList={setFilterList}
                    filterList={filterList}
                    currentMetrics={currentMetrics}
                >
                </AllChannelYearlyPanels>
            </View>
        </View>

    )

}

export default AllChannelYearlyPanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})