import React, { useState } from 'react';
import Box from '@mui/material/Box';
import {Dimensions} from 'react-native'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Pressable } from 'react-native'

const windowWidth = Dimensions.get('window').width;

export default function DashEditorText(props) {
    const [showPassword, setShowPassword] = React.useState(false);
    
    const theme = props.theme
    const dashConfigs = props.dashConfigs
    const currentItem = props.currentItem
    const setDashConfigs = props.setDashConfigs
    const [inputValue, setInputValue] = useState('');
    const configCopy = dashConfigs
    const configName = currentItem.gridConfig
    const configIndex = parseInt(configName.replace('gridConfig', ''))
    const dashName = dashConfigs[configIndex -1][0]['reportName']

    let themeColor;

      if (theme.color === 'white') {
        themeColor = '#FFFFFF'
      } else {
        themeColor = theme.color
      }


    const themeCustom = createTheme({
        components : {MuiOutlinedInput: {
            styleOverrides: {
              root: {
                color: themeColor,
                border: 'solid 3px',
          borderColor: theme.backgroundColor2,
          borderRadius: 11
              },
            },
          }},
        palette: {
          text: {
            primary: '#FFFFFF',
            secondary: '#FFFFFF',
            main: '#FFFFFF'
          },
          primary: {
            main: theme.backgroundColor2,
          },
          secondary: purple,
          main: purple,
        },
        typography: {
          fontSize: 15
        },
        
      });

    const handleChange = (event) => {
        setInputValue(event.target.value);
    };
    console.log(inputValue)

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    function clickHandler(prop) {
        
        configCopy[configIndex -1][0]['reportName'] = inputValue
        setDashConfigs(configCopy)
        
    }

    return (
        <ThemeProvider theme={themeCustom} >
        <Box sx={{ display: 'flex', flexWrap: 'wrap', maxWidth: '100%', width: 500 }}>
            <div>

                <FormControl sx={{ m: 1, maxWidth: '90%', width: windowWidth * 0.27 }} variant="outlined">
                    
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={'text'}
                        value={inputValue || dashName}
                        onChange={handleChange}
                        sx={{height: windowWidth * 0.03}}
                        endAdornment={
                            <InputAdornment position="end">
                                <Pressable
                                    onPress={() => clickHandler()}>
                                    <SaveAltIcon
                                        aria-label="toggle password visibility"
                                        onPress={clickHandler}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        sx={{color: theme.backgroundColor2}}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </SaveAltIcon>
                                </Pressable>
                            </InputAdornment>
                        }
                        label="Password"
                    />
                </FormControl>

            </div>
        </Box>
        </ThemeProvider>
    );
}