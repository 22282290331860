import { View } from 'react-native';
import React, { useState } from 'react';
import MobileFABModal from '../MobileComponents/MobileFABModal';
import ChartScreenModal from './ChartScreenModal';
import MobileDateRange from '../MobileComponents/MobileDateRange';
import MobileMulitSelect from '../MobileComponents/MobileMultiSelect';
import ChartsGraphPanel from './ChartGraphPanel';

const ChartsPanel = (props) => {

    const theme = props.theme
    const agg_daily = props.agg_daily
    const dateLabels = props.dateLabels
    const uniqueBrands = props.uniqueBrands
    const uniqueChannels = props.uniqueChannels
    const uniqueTypes = props.uniqueTypes
    const setBrandList = props.setBrandList
    const setChannelList = props.setChannelList
    const setTypeList = props.setTypeList
    const chartConfig = props.chartConfig
    const setChartConfig = props.setChartConfig
    const selectChart = props.selectChart
    const selectAxis = props.selectAxis
    const selectMetric1 = props.selectMetric1
    const selectMetric2 = props.selectMetric2
    const setSelectChart = props.setSelectChart
    const setSelectAxis = props.setSelectAxis
    const setSelectMetric1 = props.setSelectMetric1
    const setSelectMetric2 = props.setSelectMetric2
    const currentMetrics = props.currentMetrics
    const end = chartConfig.end
    const start = chartConfig.start

    const [modalVisible, setModalVisible] = useState(false)
    const keysArray = Object.keys(agg_daily[0]);
    const metric1 = chartConfig.metric1.toLowerCase();
    const metric2 = chartConfig.metric2.toLowerCase();
    const visibility = {
        [keysArray[0]] : true,
        [keysArray[1]] : metric1 === keysArray[1] || metric2 === keysArray[1] ,
        [keysArray[2]] : metric1 === keysArray[2] || metric2 === keysArray[2],
        [keysArray[3]] : metric1 === keysArray[3] || metric2 === keysArray[3],
        [keysArray[4]] : metric1 === keysArray[4] || metric2 === keysArray[4],
        [keysArray[5]] : metric1 === keysArray[5] || metric2 === keysArray[5],
        [keysArray[6]] : metric1 === keysArray[6] || metric2 === keysArray[6],
        [keysArray[7]] : metric1 === keysArray[7] || metric2 === keysArray[7],
        [keysArray[8]] : metric1 === keysArray[8] || metric2 === keysArray[8],
        id : metric1 === keysArray[9] || metric2 === keysArray[9],
    }
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(visibility);

    const container = {
        height: '100%',
        width: '100%',
        borderColor: theme.color,
        borderWidth: 0,
        flexDirection: 'column',
    }

    const chartContainer = {
        height: '90%',
        width: '100%',
        borderColor: theme.color,
        borderWidth: 0,
        flexDirection: 'column',
    }

    const selectRow = {
        height:'10%',
        width: '100%',
        borderColor: theme.color,
        backgroundColor: theme.backgroundColor,
        borderWidth: 0,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        padding: '1%'
    }

    const dateRow = {
        height:'9%',
        width: '100%',
        padding: '1%',
        borderColor: theme.color,
        borderWidth: 0,
        justifyContent: 'center',
    }

    const filterRow = {
        height:'8%',
        width: '100%',

        borderColor: theme.color,
        borderWidth: 0,
        flexDirection: 'row',

    }

    const filterContainer = {
        height: '100%',
        width: '33.33333%',
        justifyContent: 'center',
        paddingLeft: '1%',
        paddingRight: '1%'

    }

    return (
        <View style={container}>
            <View style={chartContainer}>
                <View style={dateRow}>
                    <MobileDateRange
                        theme={theme}
                        start={start}
                        end={end}
                        propObject={chartConfig}
                        setChartConfig={setChartConfig}
                    />
                </View>
                <View style={filterRow}>
                    <View style={filterContainer}>
                        <MobileMulitSelect
                            theme={theme}
                            itemList={uniqueBrands}
                            itemTitle={'Brand'}
                            setList={setBrandList}
                            setChartConfig={setChartConfig}
                            chartConfig={chartConfig}
                        />
                    </View>
                    <View style={filterContainer}>
                        <MobileMulitSelect
                            theme={theme}
                            itemList={uniqueChannels}
                            itemTitle={'Channel'}
                            setList={setChannelList}
                            setChartConfig={setChartConfig}
                            chartConfig={chartConfig}
                        />
                    </View>
                    <View style={filterContainer}>
                        <MobileMulitSelect
                            theme={theme}
                            itemList={uniqueTypes}
                            itemTitle={'Type'}
                            setList={setTypeList}
                            setChartConfig={setChartConfig}
                            chartConfig={chartConfig}
                        />
                    </View>
                    
                </View>
                <ChartsGraphPanel
                        theme={theme}
                        chartConfig={chartConfig}
                        agg_daily={agg_daily}
                        dateLabels={dateLabels}
                        columnVisibilityModel={columnVisibilityModel}
                        setColumnVisibilityModel={setColumnVisibilityModel}
                        currentMetrics={currentMetrics}
                    />
                
            </View>
            <View style={selectRow}>
                
                <MobileFABModal
                    theme={theme}
                    setModalVisible={setModalVisible}
                    buttonText={'Configure'}
                />
                <ChartScreenModal
                    theme={theme}
                    visible={modalVisible}
                    setVisible={setModalVisible}
                    uniqueBrands={uniqueBrands}
                    uniqueChannels={uniqueChannels}
                    uniqueTypes={uniqueTypes}
                    chartConfig={chartConfig}
                    setChartConfig={setChartConfig}
                    selectChart={selectChart}
                    selectAxis={selectAxis}
                    selectMetric1={selectMetric1}
                    selectMetric2={selectMetric2}
                    setSelectChart={setSelectChart}
                    setSelectAxis={setSelectAxis}
                    setSelectMetric1={setSelectMetric1}
                    setSelectMetric2={setSelectMetric2}
                    setColumnVisibilityModel={setColumnVisibilityModel}
                    columnVisibilityModel={columnVisibilityModel}
                    agg_daily={agg_daily}
                    currentMetrics={currentMetrics}
                />

            </View>
           

        </View>
    )

}

export default ChartsPanel