import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { GridToolbarContainer,
        GridToolbarColumnsButton,
        GridToolbarFilterButton,
        GridToolbarDensitySelector,
        GridToolbarExport 
       } from '@mui/x-data-grid';
import { Dimensions } from 'react-native'
import WebFont from 'webfontloader';
import { DataGridPremium,
  GRID_AGGREGATION_FUNCTIONS, } from '@mui/x-data-grid-premium';
import AttModels from '../AttModels';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function AttEnhancedLookerTableDaily(props) {

  
  const dataProp = props.dataProp.daily_result
  const totals = props.dataProp.totals
  const models = props.models
  const columnWidth = props.columnWidth
  const theme = props.theme
  const model1 = models.model1
  const model2 = models.model2
  const model3 = models.model3
  const dynamicFilter = props.dynamicFilter
  const primaryMetric = props.primaryMetric
  const filterParams = props.filterParams
  const filter1name = filterParams.filter1name
  const filter2name = filterParams.filter2name
  const filter3name = filterParams.filter3name

  const [aggregationModel, setAggregationModel] = useState({
    spend: 'sum',
    [props.dataProp.day1]: 'dynamicTotal1',
    [props.dataProp.day2]: 'dynamicTotal2',
    [props.dataProp.day3]: 'dynamicTotal3',
    [props.dataProp.day4]: 'dynamicTotal4',
    [props.dataProp.day5]: 'dynamicTotal5',
    [props.dataProp.day6]: 'dynamicTotal6',
    [props.dataProp.day7]: 'dynamicTotal7',
    [props.dataProp.day8]: 'dynamicTotal8',
    [props.dataProp.day9]: 'dynamicTotal9',
    [props.dataProp.day10]: 'dynamicTotal10',
  });


  let responseText;

    if (windowWidth < 1281) {
        responseText = windowHeight * 0.025
    } else if (windowWidth > 2000) {
        responseText = windowHeight * 0.018
    } else {responseText = windowHeight * 0.017}

    let responseRow;

    if (windowWidth < 1281) {
      responseRow = windowHeight * 0.05
    } else if (windowWidth > 2000) {
      responseRow = windowHeight * 0.05
    } else {responseRow = windowHeight * 0.04}


  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
    channel: true,
    "2023-03-31" : true,
    spend: false  });

  const valueFormat = (value, field) => {
    if (field === 'cpa' || field === 'spend' || field.includes("ngr") || field === 'cpc' || field === 'netProfit' || field === 'costConv' || field.includes("2024")  ) {
        return `€${value}`;
    } else if (field === 'recovered') {
        const percent = (value * 100).toFixed(2); // Convert to percent and round to 2 decimal places
        return `${percent}%`;
    }
    return value;
};

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Electrolize']
      }
    });
   }, []);

    let uniqueIdCounter = 1;

    // Use forEach to add a unique ID to each record
    dataProp.forEach((record) => {
    record.id = uniqueIdCounter++;
    });

    const labelObject = dataProp[0] || []

    const labels = Object.keys(labelObject).map((key , index) => {
        return { 
        field: key, 
        headerName: key, 
        minWidth: windowWidth * columnWidth * 1.25, 
        flex: 1, 
        headerAlign: 'center', 
        headerClassName: 'table-header', 
        align: 'center',
        type: 'number',
        renderCell: (params) => {
            const { value, field } = params;
            const heatmapColor = calculateHeatmapColor(value, { field });
            if (index < 1) {
                return (
                  <div
                    className="heatmap-cell"
                    style={{
                      backgroundColor: heatmapColor,
                      padding: '7%',
                      color: theme.color,
                      width: '100%',
                      fontSize: responseText * 0.8
                    }}
                  >
                    {valueFormat(params.formattedValue, params.field)}
                  </div>
                );
              } else if (params.aggregation) {
                return (
                    <div
                        className="heatmap-cell-aggregated"
                        style={{
                            fontWeight: 'bold', // Add your custom font weight
                            display: 'flex', // Apply flex styles to center content vertically/horizontally
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%', // Ensure the cell takes up the full height
                            width: '100%',
                            color: theme.color,
                            paddingLeft: 0,
                            fontFamily: 'Electrolize',
                            fontSize: responseText * 0.8,
                        }}
                    >
                        {valueFormat(params.formattedValue, params.field)}
                    </div>
                );
            } else return (
                <div
                  className="heatmap-cell"
                  style={{
                    backgroundColor: heatmapColor,
                    padding: '10%',
                    width: '100%' ,
                    fontSize: responseText * 0.8
                    
                  }}
                >
                  {valueFormat(params.formattedValue, params.field)}
                </div>
              );
            },
        
        };
      });  

    const columnRanges = {};
    Object.keys(labelObject).forEach((column) => {
    const values = dataProp.map((record) => record[column]);
    const min = Math.min(...values);
    const max = Math.max(...values);
    columnRanges[column] = { min, max };
    });

    function calculateHeatmapColor(value, column) {
  const { min, max } = columnRanges[column.field];
  const range = max + 1- min;

// Define input colors for the heatmap in RGB format
  const fromColor = { r: theme.comboStopColor1ARRAY[0], g: theme.comboStopColor1ARRAY[1], b: theme.comboStopColor1ARRAY[2] };
  const toColor = { r: theme.comboStopColor2ARRAY[0], g: theme.comboStopColor2ARRAY[1], b: theme.comboStopColor2ARRAY[2] };;

  const relativeValue = (value - min) / range;
  const red = Math.round((1 - relativeValue) * fromColor.r + relativeValue * toColor.r);
  const green = Math.round((1 - relativeValue) * fromColor.g + relativeValue * toColor.g);
  const blue = Math.round((1 - relativeValue) * fromColor.b + relativeValue * toColor.b);

  return `rgba(${red},${green},${blue},0.9)`;
}

function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{color: theme.color}}>
      <GridToolbarColumnsButton sx={{color: theme.color}}/>
        <GridToolbarFilterButton sx={{color: theme.color}}/>
        <GridToolbarDensitySelector sx={{color: theme.color}}/>
        <GridToolbarExport sx={{color: theme.color}}/>
    </GridToolbarContainer>
  );
}

function calculateTotal (spend, ftd) {
  return "TOTAL"
  }

  function calculateCPA (spend, totalConv) {
    const cpa =  (spend / totalConv)
  
    return parseFloat(cpa.toFixed(2))
  }

const dynamicTotal1 = {
  label: '',
  getCellValue: ({ row, index }) => ({ field: row, index: index }),
  apply: ({ values }) => {
    return (totals.length > 0 && totals[0][primaryMetric]) || 0;
  },
  columnTypes: ['number'],
};

const dynamicTotal2 = {
  label: '',
  getCellValue: ({ row, index }) => ({ field: row, index: index }),
  apply: ({ values }) => {
    return (totals.length > 1 && totals[1][primaryMetric]) || 0;
  },
  columnTypes: ['number'],
};

const dynamicTotal3 = {
  label: '',
  getCellValue: ({ row, index }) => ({ field: row, index: index }),
  apply: ({ values }) => {
    return (totals.length > 2 && totals[2][primaryMetric]) || 0;
  },
  columnTypes: ['number'],
};

const dynamicTotal4 = {
  label: '',
  getCellValue: ({ row, index }) => ({ field: row, index: index }),
  apply: ({ values }) => {
    return (totals.length > 3 && totals[3][primaryMetric]) || 0;
  },
  columnTypes: ['number'],
};

const dynamicTotal5 = {
  label: '',
  getCellValue: ({ row, index }) => ({ field: row, index: index }),
  apply: ({ values }) => {
    return (totals.length > 4 && totals[4][primaryMetric]) || 0;
  },
  columnTypes: ['number'],
};

const dynamicTotal6 = {
  label: '',
  getCellValue: ({ row, index }) => ({ field: row, index: index }),
  apply: ({ values }) => {
    return (totals.length > 5 && totals[5][primaryMetric]) || 0;
  },
  columnTypes: ['number'],
};

const dynamicTotal7 = {
  label: '',
  getCellValue: ({ row, index }) => ({ field: row, index: index }),
  apply: ({ values }) => {
    return (totals.length > 6 && totals[6][primaryMetric]) || 0;
  },
  columnTypes: ['number'],
};

const dynamicTotal8 = {
  label: '',
  getCellValue: ({ row, index }) => ({ field: row, index: index }),
  apply: ({ values }) => {
    return (totals.length > 7 && totals[7][primaryMetric]) || 0;
  },
  columnTypes: ['number'],
};

const dynamicTotal9 = {
  label: '',
  getCellValue: ({ row, index }) => ({ field: row, index: index }),
  apply: ({ values }) => {
    return (totals.length > 8 && totals[8][primaryMetric]) || 0;
  },
  columnTypes: ['number'],
};

const dynamicTotal10 = {
  label: '',
  getCellValue: ({ row, index }) => ({ field: row, index: index }),
  apply: ({ values }) => {
    return (totals.length > 9 && totals[9][primaryMetric]) || 0;
  },
  columnTypes: ['number'],
};

  const total = {
    label: '',
    getCellValue: ({ row }) => ({ spend: row.spend, ftd: row.ftds }),
    apply: ({ values }) => {
      let spend = 0;
      let ftd = 0;
      values.forEach((value) => {
        if (value) {
            spend += value.spend || 0; // Adds 0 if value.spend is null or NaN
            ftd += value.ftd || 0;
        }
      });
      return calculateTotal(spend, ftd);
    },
    columnTypes: ['number'],
  };

  const cpa = {
    label: '',
    getCellValue: ({ row }) => ({ spend: row.spend, totalConv: row.totalConv }),
    apply: ({ values }) => {
      let spend = 0;
      let totalConv = 0;
      values.forEach((value) => {
        if (value) {
            spend += value.spend || 0; // Adds 0 if value.spend is null or NaN
            totalConv += value.totalConv || 0;
        }
      });
      return calculateCPA(spend, totalConv);
    },
    columnTypes: ['number'],
  };

  const largeRows = ((windowHeight / 120).toFixed(0))

  return (
    <Box
    style={{
      width: '100%',
      height : windowHeight * 0.82, 
      fontSize: 50,
      color: 'black',
      fontFamily : 'Electrolize',
 
      
    }}
      sx={{
        '& .table-header': { 
          headerAlign: 'center', 
          align : 'center' , 
          fontFamily : 'Electrolize' , 
          color: theme.color, 
          fontSize: responseText, },
          '& .MuiDataGrid-row' : {backgroundColor : theme.backgroundColor},
          '& .MuiTablePagination-root' : {color: theme.color},
        width: '100%'}}
    >
      <DataGridPremium 
        rows={dataProp}
        columns={labels}
        rowHeight={responseRow * 0.75}

        pagination
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        aggregationModel={aggregationModel}
                onAggregationModelChange={(newModel) => setAggregationModel(newModel)}
                aggregationFunctions={{
                ...GRID_AGGREGATION_FUNCTIONS,
                total,
                cpa,
                dynamicTotal1,
                dynamicTotal2,
                dynamicTotal3,
                dynamicTotal4,
                dynamicTotal5,
                dynamicTotal6,
                dynamicTotal7,
                dynamicTotal8,
                dynamicTotal9,
                dynamicTotal10,
                }}
        initialState={{
          pagination: {
            paginationModel: { pageSize: parseInt(largeRows) }           
          },
        sorting: {
        sortModel: [{ field: 'spend', sort: 'desc' }],
        },
        }}
        
        />
    </Box>
  );
}