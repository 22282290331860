import React, { useState, useEffect } from 'react';
import {
    View,
    Dimensions,
    Text,
} from 'react-native'
import Button from '@mui/material/Button';
import TuneIcon from '@mui/icons-material/Tune';
import Stack from '@mui/material/Stack';
import OptionsHome from './OptionsHome/OptionsHome';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const OptionsHomePanel = (props) => {

    const theme = props.theme
    const [visible, setVisible] = useState(false)
    const clientID = props.clientID

    const container = {
        width: '100.8%',
        height: windowHeight * 0.2,
        borderColor: 'grey',
        borderWidth: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    }

    const textColumn = {
        width: '40%',
        height: '100%',
        paddingLeft: '1.5%',
        justifyContent: 'center'
    }

    const buttonColumn = {
        width: '60%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: 'grey',
        borderWidth: 1,
        
    }

    const text = {
        fontFamily : 'Electrolize',
        fontSize: 40
    }

    const button = {
        height: '40%',
        width: '30%',
        fontFamily : 'Electrolize',
        backgroundColor: theme.backgroundColor2
    }

    return (
        <View style={container}>
            <View style={textColumn}>
                <Text style={text}>
                Homepage View:</Text>

            </View>
            <View style={buttonColumn}>
                <Button 
                    sx={button} 
                    onClick={() => {
                        setVisible(true);
                    }}
                    variant="contained"  
                    endIcon={<TuneIcon />}
                    >
                    Configure
                </Button>
                
            </View>
            <OptionsHome
                theme={theme}
                visible={visible}
                setVisible={setVisible}
                clientID={clientID}
            />

        </View>
    )

}

export default OptionsHomePanel