import React, { useState , useEffect } from 'react';
import { View , 
    StyleSheet , 
    Dimensions , 
    Pressable
} from 'react-native'
import LineYears from '../../ChartsDesktop/LineYears';
import WebFont from 'webfontloader';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const AllChannelYearlyPanels = (props) => {

    const client = props.client
    const dateLabels = props.dateLabels
    const currentData = props.agg_yearly_result
    const currentMetrics = props.currentMetrics
    
    const [selectedComponent, setSelectedComponent] = useState("Row1_Line1");
    const theme = props.theme
    const timeFrame = "Yearly"
    
    currentData.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
    });


    const handleComponentClick = (componentName) => {
        setSelectedComponent(componentName);
    };

    useEffect(() => {
        WebFont.load({
          google: {
            families: ['Droid Sans', 'Electrolize']
          }
        });
       }, []);

    const renderComponentContent = () => {
        if (selectedComponent === 'Row1_Line1') {
        return <Row1Line1 style={panelMain}/>;
        } else if (selectedComponent === 'Row1_Line2') {
        return <Row1Line2 style={panelMain}/>;
        } else if (selectedComponent === 'Row1_Line3') {
        return <Row1Line3 style={panelMain}/>;
        } else {
        return null;
        }
    };


    const panelMain = {

        width : '100%',
        height : windowHeight * 0.5,
        borderWidth: 0,
        borderColor: 'green',
        alignItems : 'center',
        justifyContent : 'center',
        textAlign : 'center',
        fontSize : 40,
        fontFamily : 'Electrolize',
        borderRadius : 5,

    }
    
    const panelChild = {
        width: '33.3333333%',
        height : '100%',
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems : 'center',
        justifyContent : 'center',
        textAlign : 'center',
        fontSize : 20,
        fontFamily : 'Electrolize',

    }


    const Row1Line1 = () => {

        return (
        <View style={styles.lineChart}>
            <LineYears
            agg_daily_result={currentData}
            dateLabels={dateLabels}
            theme={theme}
            metric={"spend"}
            timeFrame={timeFrame}
            client={client}
            currentMetrics={currentMetrics}
        />
        </View>)
    }

    const Row1Line2 = () => {

        return (
        <View style={styles.lineChart}>
            <LineYears
            agg_daily_result={currentData}
            dateLabels={dateLabels}
            theme={theme}
            metric={"ftds"}
            timeFrame={timeFrame}
            client={client}
            currentMetrics={currentMetrics}
        />
        </View>)
    }


    const Row1Line3 = () => {

        return (
        <View style={styles.lineChart}>
            <LineYears
            agg_daily_result={currentData}
            dateLabels={dateLabels}
            theme={theme}
            metric={"cpa"}
            timeFrame={timeFrame}
            client={client}
            currentMetrics={currentMetrics}
        />
        </View>)
    }

   

    return (
        <View style={styles.container}>
        <View style={{flexDirection : 'row' }}>
        <View style={panelMain}>
        <View style={{width : windowWidth, flexDirection : 'flex-start' , fontFamily : 'Electrolize'}}>
                </View>
                
        {renderComponentContent()}        
        </View>
  
        </View>
        <View style={{flexDirection : 'row', flex : 1 , width : '100%'}}>
        <Pressable style={panelChild} onPress={() => handleComponentClick('Row1_Line1')}>
        <View style={styles.lineChart}>
        <LineYears
            agg_daily_result={currentData}
            dateLabels={dateLabels}
            theme={theme}
            metric={"spend"}
            timeFrame={timeFrame}
            client={client}
            currentMetrics={currentMetrics}
        />
        </View>
        </Pressable>
        <Pressable style={panelChild} onPress={() => handleComponentClick('Row1_Line2')}>
        <View style={styles.lineChart}>
        <LineYears
            agg_daily_result={currentData}
            dateLabels={dateLabels}
            theme={theme}
            metric={"ftds"}
            timeFrame={timeFrame}
            client={client}
            currentMetrics={currentMetrics}
        />
        </View>
        </Pressable>
        <Pressable style={panelChild} onPress={() => handleComponentClick('Row1_Line3')}>
        <View style={styles.lineChart}>
        <LineYears
            agg_daily_result={currentData}
            dateLabels={dateLabels}
            theme={theme}
            metric={"cpa"}
            timeFrame={timeFrame}
            client={client}
            currentMetrics={currentMetrics}
        />
        </View>
        </Pressable>

        </View>
        

        
        </View>
        
    )
}

export default AllChannelYearlyPanels

const styles = StyleSheet.create({
    container: {
      borderWidth: 0,
      borderColor: 'blue',
      height : (windowHeight * 0.87) ,
      width: '100%',
      flexDirection : 'column',
      fontFamily : 'Electrolize',
      

    },
    lineChart : {
        flex : 1,
        width : '100%',
        alignItems : 'center',
        padding : '1%',
        fontFamily : 'Electrolize'
    },
    lineChart2 : {
        flex : 1,
        width : '100%',
        alignItems : 'center',
        padding : '1%',
        fontFamily : 'Electrolize'
    }
})