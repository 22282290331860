import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Text,
} from 'react-native'
import WebFont from 'webfontloader';
import moment from 'moment-timezone';
import { useCubeQuery } from '@cubejs-client/react';
import LoadingComponent from '../Components/Loader.js';
import ExecSummaryContent from './ExecSummaryContent.js';
import ExecSummaryCube from './ExecSummaryCube.js';
import ExecSummaryCubeFtd from './ExecSummaryCubeFtd.js';
import ExecSummaryProviderCube from './ExecSummaryProvider/ExecSummaryProviderCube.js';
import ExecSummaryMetricsCube from './ExecSummaryMetrics/ExecSummaryMetricsCube.js';
import ExecSummaryDim from './ExecSummaryDim.js';
import ParseDimensionsAtt from '../AttributionEnhanced/CubeFunctions/ParseDimensionsAtt.js';


const ExecSummaryPanel = (props) => {

    const theme = props.theme
    const currentMetrics = props.currentMetrics
    const setLastUpdated = props.setLastUpdated
    const [panelContent, setPanelContent] = useState('metrics')
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayProp = yesterday2.format('YYYY-MM-DD');
    const clientID = props.clientID
    const attModalOpen = props.attModalOpen
    const setOpen = props.setOpen
    const client = clientID.toLowerCase();
    const cubeTable = 'glitnor_execSummary_metrics'
    const dynamicFilter = 'channel'
   

    const filter1name = 'brand'
    const filter2name = 'country'
    const filter3name = 'brand'
    const filter4name = 'country'

    const [filter1List, setFilter1List] = useState('Total')
    const [filter2List, setFilter2List] = useState('Total')
    const [filter3List, setFilter3List] = useState('Total')
    const [filter4List, setFilter4List] = useState('Total')

    const filterValueBase = {
        filter1List : [],
        filter2List : [],
        filter3List : [],
        filter4List : []
      }
    const [filterList, setFilterList] = useState(filterValueBase);


    const filterParams = {
        filter1name,
        filter2name,
        filter3name,
        filter4name,
        filter1List,
        filter2List,
        filter3List,
        filter4List,
        setFilter1List,
        setFilter2List,
        setFilter3List,
        setFilter4List
    }

    const modelsBase = {
        model1: 'lt_lifetime',
        model2: 'ft_1',
        model3: 'pos_28'
    }

    const [models, setModels] = useState(modelsBase)

    function modelHandler (prop) {

        setModels(prop)
    }

    const dimensions = ['channel']

    const currentDate = new Date();

    // Set the timezone offset to 0 to work with local time
    currentDate.setMinutes(0);
    const previousDate = new Date(currentDate);
    previousDate.setDate(currentDate.getDate() - 1);
    previousDate.setDate(1)
    const startDateString = previousDate.toISOString().slice(0, 10);

    const filterBase = {
        start: startDateString,
        end: yesterdayProp,
        [filter1name]: "Total",
        [filter2name]: 'Total',
        [filter3name]: "Total",
        [filter4name]: "Total",
    };

    const [filters, setFilters] = useState(filterBase);

    const start = filters.start
    const end = filters.end

    let filter1Filter, filter1Operator;

    if (filters[filter1name] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name].length === 0) {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name][0] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else {
        filter1Filter = filter1List
        filter1Operator = ['equals']
    }

    let filter2Filter, filter2Operator;

    if (filters[filter2name] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name].length === 0) {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name][0] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else {
        filter2Filter = filter2List
        filter2Operator = ['equals']
    }

    let filter3Filter, filter3Operator;

    if (filters[filter3name] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filter3name].length === 0) {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filter3name][0] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    }  else {
        filter3Filter = filter3List
        filter3Operator = ['equals']
    }

    let filter4Filter, filter4Operator;

    if (filters[filter4name] === 'Total') {
        filter4Filter = ['null']
        filter4Operator = ['notEquals']
    } else if (filters[filter4name].length === 0) {
        filter4Filter = ['null']
        filter4Operator = ['notEquals']
    } else if (filters[filter4name][0] === 'Total') {
        filter4Filter = ['null']
        filter4Operator = ['notEquals']
    }  else {
        filter4Filter = filter4List
        filter4Operator = ['equals']
    }

    const propObjectBase = {
        data: [],
        start: startDateString,
        end: yesterdayProp,
        theme: theme,
        [filter1name]: "Total",
        [filter2name]: 'Total',
        [filter3name]: "Total",
        [filter4name]: "Total",
    };

    const [propObject, setpropObject] = useState(propObjectBase);

    const handleStateChange = (prop) => {

        setpropObject(prop);

        const filterUpdate = {
            start: prop.start,
            end: prop.end,
            [filter1name]: prop[filter1name],
            [filter2name]: prop[filter2name],
            [filter3name]: prop[filter3name],
            [filter4name]: prop[filter4name],
        };


        setFilters(filterUpdate)

    };


    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    const cubeFilters = [
        {
            "member": `${cubeTable}.${filter1name}`,
            "operator": `${filter1Operator}`,
            "values": filter1Filter
        },
        {
            "member": `${cubeTable}.${filter2name}`,
            "operator": `${filter2Operator}`,
            "values": filter2Filter
        }
    ]

    const params = {
        cubeTable : cubeTable,
        start,
        end,
        dimensions,
        models
    }

    let cubeQueryDynamic;

   
    const cubeQuery = ExecSummaryMetricsCube(cubeTable, cubeFilters)

    const { resultSet } = useCubeQuery(cubeQuery);
    const dimQuery = ExecSummaryDim(cubeTable, filter1name, filter2name, filter3name, filter4name, cubeFilters)
    const dimResult = useCubeQuery(dimQuery)


    if (!resultSet) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    if (dimResult.isLoading === true) {
        return (
            <LoadingComponent theme={theme} />
        );
    }


    const cubeResponse = resultSet.loadResponse.results[0].data
    const dimData = dimResult.resultSet.loadResponses[0].data

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
    };

    

    const uniqueFilter1 = ParseDimensionsAtt(dimData, cubeTable, filter1name)
    const uniqueFilter2 = ParseDimensionsAtt(dimData, cubeTable, filter2name)
    // repeat filters 1 & 2
    const uniqueFilter3 = ParseDimensionsAtt(dimData, cubeTable, filter1name)
    const uniqueFilter4 = ParseDimensionsAtt(dimData, cubeTable, filter2name)

    const aggResult = cubeResponse.map(obj => {
        const newObj = {};
        for (const key in obj) {
            const newKey = key.replace(cubeTable + ".", "");
            newObj[newKey] = obj[key];
        }
        return newObj;
    });

    const updateVar = `${cubeTable}.updated_at`
    const lastUpdated = dimData[0][updateVar] || 'loading...'

    if (lastUpdated) {
        setLastUpdated(lastUpdated)
    }
    
    const dataProp = {
        aggResult,
        uniqueFilter1,
        uniqueFilter2,
        uniqueFilter3,
        uniqueFilter4,
        models
    }


    return (
        <View style={styles.container}>

            <View style={{ width: '100%', height: '100%' }}>
                <ExecSummaryContent
                        theme={theme}
                        dataProp={dataProp}
                        handleStateChange={handleStateChange}
                        dynamicFilter={dynamicFilter}
                        propObject={propObject}
                        modelHandler={modelHandler}
                        attModalOpen={attModalOpen}
                        setOpen={setOpen}
                        filterParams={filterParams}
                        setFilterList={setFilterList}
                        filterList={filterList}
                        currentMetrics={currentMetrics}
                        panelContent={panelContent}
                        setPanelContent={setPanelContent}
                    />

            </View>
        </View>

    )

}

export default ExecSummaryPanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})