import moment from 'moment-timezone';

export default function DashEditorParse (cubeResponse, xAxisValueBase, currentMetric1, currentMetric2, table, currentType, start, end, time) {

    var dataset = cubeResponse

    if (!dataset || dataset.length === undefined) {
        return null;
      }

      let xAxisValue;
      

      if (xAxisValueBase === 'weekly' || xAxisValueBase === 'monthly') {
        xAxisValue = 'date'
      } else {xAxisValue = xAxisValueBase}


      const xAxis = `${xAxisValue}`
      const impressions = `impressions`
      const clicks = `clicks`
      const spend = `spend`
      const regs = `regs`
      const ftds = `ftds`
      const ngr = 'ngr'

      var agg_result = [];

      dataset.reduce(function (res, value) {

        let axisValue;

        if (xAxisValue === 'date' && time === "monthly") {
          axisValue = moment.tz(value[xAxis], 'Europe/Paris').format('YYYY-MM')
        }  else if (xAxisValue === 'date' && time === "weekly") {
          axisValue = moment.tz(value[xAxis], 'Europe/Paris').startOf('isoWeek').format('YYYY-MM-DD');
        }  else if (xAxisValue === 'date' && time === "daily") {
            axisValue = moment.tz(value[xAxis], 'Europe/Paris').format('YYYY-MM-DD');
          }
        else { axisValue = value[xAxis] }

        if (!res[axisValue]) {
          res[axisValue] = {
            [xAxisValue]: axisValue,
            impressions: 0,
            clicks: 0,
            spend: 0,
            regs: 0,
            ftds: 0,
            ngr: 0
          };
          agg_result.push(res[axisValue]);
        }
        res[axisValue].impressions += parseInt(value[impressions]) || 0;
        res[axisValue].clicks += parseInt(value[clicks]) || 0;
        res[axisValue].spend += parseInt(value[spend]) || 0;
        res[axisValue].regs += parseInt(value[regs]) || 0;
        res[axisValue].ftds += parseInt(value[ftds]) || 0;
        res[axisValue].ngr += parseInt(value[ngr]) || 0;
    
        return res;
      }, {});

      agg_result = agg_result.map((obj) => {
        const impressions = obj.impressions || 0;
        const clicks = obj.clicks || 0;
        const spend = obj.spend || 0;
        const regs = obj.regs || 0;
        const ftds = obj.ftds || 0;
        const ctr = impressions === 0 || clicks === 0 || isNaN(clicks) ? 0 : clicks / impressions;
        const cpc = spend === 0 || clicks === 0 || isNaN(clicks) ? 0 : spend / clicks;
        const cpa = spend === 0 || ftds === 0 || isNaN(ftds) ? 0 : spend / ftds;
        const cpr = spend === 0 || regs === 0 || isNaN(regs) ? 0 : spend / regs;
        return {
          ...obj,
          ctr: parseFloat(ctr).toFixed(2),
          cpc: parseFloat(cpc).toFixed(2),
          cpa: parseInt(cpa),
          cpr: parseInt(cpr),
        };
      });

      function sortByKey(dataset, key) {
        return dataset.sort((a, b) => {
            const valueA = a[key];
            const valueB = b[key];
            if (valueA < valueB) {
                return -1;
            }
            if (valueA > valueB) {
                return 1;
            }
            return 0;
        });
    }

    const sortedData = sortByKey(agg_result, xAxisValue);

      return sortedData

}