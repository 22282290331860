import React, { useEffect } from 'react';
import { View, StyleSheet, Dimensions, Image } from 'react-native'
import WebFont from 'webfontloader';
import LoginButton from '../Auth0/AuthLogin';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const Login = (props) => {

  const theme = props.theme  
  const aspectRatio = 16/9
  const imageWidth = windowWidth * 0.1
  
  let dynamicButton;

  if (windowWidth < 450) {
    dynamicButton = windowWidth * 0.25
  } else { dynamicButton = windowWidth * 0.10 }

  /*const renderLogo = () => {
    if (themeMode === 'white'){
      return <Image 
      source={require('../Images/Pattern_Blue_TS.png')}
      
      style={{ width: 400, height: 400 }}
    />
    }
    else if (themeMode === 'black'){
      return <Image 
      source={require('../Images/Pattern_Pink_TS.png')}
      
      style={{ width: 400, height: 400 }}
    />
    }
    else if (themeMode === 'light'){
      return <Image 
      source={require('../Images/Pattern_White_TS.png')}
      
      style={{ width: 400, height: 400 }}
    />
    }
    else if (themeMode === 'dark'){
      return <Image 
      source={require('../Images/Pattern_Purple_TS.png')}
      
      style={{ width: 400, height: 400 }}
    />
    }
    else return <Image 
    source={require('../Images/Pattern_Blue_TS.png')}
    
    style={{ width: 400, height: 400 }}
  />
  }
  */
  

  useEffect(() => {
    WebFont.load({
        google: {
            families: ['Droid Sans', 'Electrolize']
        }
    });
}, []);
 

  return (
    <View style={[styles.container, { backgroundColor: theme.backgroundColor, borderWidth: 0,
      borderColor: 'yellow', width: '100%' }]}>
      <Image 
      source={require('../Images/Pattern_Pink_TS.png')}
      
      style={{ width: 400, height: 400,  filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4))',}}
    />
      <LoginButton
        style={{width: dynamicButton, height: 100}}
        theme={theme}
      />

    </View>

  )
}



const styles = StyleSheet.create({
  container: {
    borderWidth: 0,
    borderColor: 'yellow',
    paddingTop: 0,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: windowWidth,
    height: windowHeight,
    flex: 1,
  },
  panel: {
    width: '100%'
  }
})

export default Login