import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Pressable,
    Text
} from 'react-native'
import LineChart from '../ChartsDesktop/LineChart';
import BudgetsText from '../Budgets/BudgetsText';
import FiltersEnhancedSingle from '../ComponentsEnhanced/FiltersEnhancedSingle';
import BudgetArea from '../Budgets/BudgetArea.js'
import Switch from '@mui/material/Switch';
import WebFont from 'webfontloader';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const BudgetPanel = (props) => {

    const dataProp = props.dataProp
    const currentMetrics = props.currentMetrics
    const agg_daily_result = props.dataProp
    const dateLabels = props.dateLabels
    const currentData = agg_daily_result
    const timeFrame = props.timeFrame
    const dataPropBudget = props.dataPropBudget
    const uniqueFilter1 = dataPropBudget.uniqueFilter1
    const uniqueFilter2 = dataPropBudget.uniqueFilter2
    const uniqueFilter3 = dataPropBudget.uniqueFilter3
    const filterParams = props.filterParams
    const [selectedComponent, setSelectedComponent] = useState("Row1_Line1");
    const theme = props.theme
    const handleStateChange = props.handleStateChange
    const propObject = props.propObject
    const filterList = props.filterList
    const setFilterList = props.setFilterList
    const [checked, setChecked] = React.useState(false);
    const [isCumulative, setIsCumulative] = useState(false)

    const calcCumulative = (data) => {
        let cumuTarget = 0, cumuSpend = 0, cumuFtds = 0, cumuBudget = 0;

        return data.map(item => {
            cumuTarget += item.target_daily;
            cumuSpend += item.actual_spend;
            cumuFtds += item.ftd;
            cumuBudget += item.budget

            return {
                calendar_date: item.calendar_date,
                actual_spend: cumuSpend,
                target_daily: cumuTarget,
                ftd: cumuFtds,
                budget: cumuBudget,
                cpa: cumuFtds > 0 ? cumuSpend / cumuFtds : 0
            }
        })
    }

    const cumuData = calcCumulative(currentData)

    let dynamicData;

    if (isCumulative) {
        dynamicData = cumuData
    } else {
        dynamicData = currentData
    }

    const handleChange = (event) => {
        setChecked(event.target.checked);
        setIsCumulative(event.target.checked)

    };

    console.log(agg_daily_result[0])

    const handleComponentClick = (componentName) => {
        setSelectedComponent(componentName);
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    const renderComponentContent = () => {
        if (selectedComponent === 'Row1_Line1') {
            return <Row1Line1 style={panelMain} />;
        } else if (selectedComponent === 'Row1_Line2') {
            return <Row1Line2 style={panelMain} />;
        } else if (selectedComponent === 'Row1_Line3') {
            return <Row1Line3 style={panelMain} />;
        } else {
            return null;
        }
    };

    const panelMain = {

        width: (windowWidth / 3) * 2,
        height: windowHeight * 0.65,
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 40,
        fontFamily: 'Electrolize',
    }

    const panelChild = {
        width: (windowWidth / 3),
        height: '100%',
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 20,
        fontFamily: 'Electrolize',
    }

    let topValue, rightValue;

    if (windowWidth > 1800) {
        topValue = "8%"
        rightValue = "1%"
    } else {
        topValue = "12.5%"
        rightValue = "1%"
    }


    const headerToggleContainer = {
        position: 'absolute',
        right: rightValue,
        top: topValue,
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 100000,
        flexDirection: 'row',
        fontFamily: 'Electrolize',
    }

    const cumuText = {
        fontFamily: 'Electrolize',
    }


    const Row1Line1 = () => {

        return (
            <View style={styles.lineChart}>
                <BudgetArea
                    agg_daily_result={dynamicData}
                    dateLabels={dateLabels}
                    theme={theme}
                    metric1={"actual_spend"}
                    metric2={"budget"}
                    timeFrame={timeFrame}
                    currentMetrics={currentMetrics}
                />
            </View>)
    }

    const Row1Line2 = () => {

        return (
            <View style={styles.lineChart}>
                <LineChart
                    agg_daily_result={dynamicData}
                    dateLabels={dateLabels}
                    theme={theme}
                    metric={"ftd"}
                    timeFrame={timeFrame}
                    currentMetrics={currentMetrics}
                />
            </View>)
    }

    const Row1Line3 = () => {

        return (
            <View style={styles.lineChart}>
                <LineChart
                    agg_daily_result={dynamicData}
                    dateLabels={dateLabels}
                    theme={theme}
                    metric={"cpa"}
                    timeFrame={timeFrame}
                    currentMetrics={currentMetrics}
                />
            </View>)
    }

    return (
        <View style={styles.container}>
            <View style={{ flexDirection: 'row' }}>
                <View style={panelMain}>
                    <View style={{ width: '100%', flexDirection: 'flex-start', fontFamily: 'Electrolize' }}>
                        <FiltersEnhancedSingle
                            style={{
                                height: '10%',
                                borderColor: 'red',
                                borderWidth: 0,
                                width: '100%',
                                fontFamily: 'Electrolize'
                            }}
                            uniqueFilter1={uniqueFilter1}
                            uniqueFilter2={uniqueFilter2}
                            uniqueFilter3={uniqueFilter3}
                            handleStateChange={handleStateChange}
                            propObject={propObject}
                            filterParams={filterParams}
                            theme={theme}
                            setFilterList={setFilterList}
                            filterList={filterList}
                            currentMetrics={currentMetrics}
                        />
                    </View>
                    <View style={headerToggleContainer}>
                        <Text style={cumuText}>Cumulative:</Text>
                        <Switch
                            checked={checked}
                            onChange={handleChange}
                            sx={{
                                color: 'red'
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />

                    </View>
                    {renderComponentContent()}
                </View>
                <BudgetsText
                    style={{ flex: 1 }}
                    theme={theme}
                    dataProp={dataProp}
                />
            </View>
            <View style={{ flexDirection: 'row', flex: 1 }}>
                <Pressable style={panelChild} onPress={() => handleComponentClick('Row1_Line1')}>
                    <View style={styles.lineChart}>
                        <BudgetArea
                            agg_daily_result={dynamicData}
                            dateLabels={dateLabels}
                            theme={theme}
                            metric1={"actual_spend"}
                            metric2={"budget"}
                            timeFrame={timeFrame}
                            currentMetrics={currentMetrics}
                        />
                    </View>
                </Pressable>
                <Pressable style={panelChild} onPress={() => handleComponentClick('Row1_Line2')}>
                    <View style={styles.lineChart}>
                        <LineChart
                            agg_daily_result={dynamicData}
                            dateLabels={dateLabels}
                            theme={theme}
                            metric={"ftd"}
                            timeFrame={timeFrame}
                            currentMetrics={currentMetrics}
                        />
                    </View>
                </Pressable>
                <Pressable style={panelChild} onPress={() => handleComponentClick('Row1_Line3')}>
                    <View style={styles.lineChart}>
                        <LineChart
                            agg_daily_result={dynamicData}
                            dateLabels={dateLabels}
                            theme={theme}
                            metric={"cpa"}
                            timeFrame={timeFrame}
                            currentMetrics={currentMetrics}
                        />
                    </View>
                </Pressable>

            </View>



        </View>

    )

}

export default BudgetPanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: (windowHeight * 0.93),
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',


    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})