import { View } from 'react-native'

const DnDSpace = (props) => {

    const theme = props.theme

    return (
        <View style={{width: '100%', height: '100%', }}>

        </View>
    )

}

export default DnDSpace