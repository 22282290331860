import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions
} from 'react-native'
import WebFont from 'webfontloader';
import moment from 'moment-timezone';
import DocCohortVis from './DocCohortVis.js';
import ParseRunningNGR from '../Cohorts/CubeFunctions/ParseRunningNGR.js';
import ParseCohorts from '../Cohorts//CubeFunctions/ParseCohorts';
import { useCubeQuery } from '@cubejs-client/react';
import LoadingComponent from '../Components/Loader.js';
import DimensionsCube from '../CubeFunctions/Dimensions.js';
import ParseDimensions from '../CubeFunctions/ParseDimensions.js';

const windowHeight = Dimensions.get('window').height;

const DocCohorts = (props) => {

    const theme = props.theme
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayProp = yesterday2.format('YYYY-MM-DD');
    const client = props.client
    const parseProp = props.parseProp;
    const cubeTable = client + "_cohorts";

    const currentDate = new Date();

    // Set the timezone offset to 0 to work with local time
    currentDate.setMinutes(0);
    const previousDate = new Date(currentDate);
    previousDate.setDate(currentDate.getDate() - 1);
    previousDate.setDate(1)

    const filterBase = {
        start: "2022-07-01",
        end: yesterdayProp,
        channel: "Total",
        brand: 'Total',
        kw_type: "Total",
    };

    const start = parseProp.start
    const end = parseProp.end
    const brand = (parseProp.brand === "Total") ? "null" : parseProp.brand;
    const channel = (parseProp.channel === "Total") ? "null" : parseProp.channel;
    const type = (parseProp.kw_type === "Total") ? "null" : parseProp.kw_type;

    const brandOp = (parseProp.brand === "Total") ? "notEquals" : "equals";
    const channelOp = (parseProp.channel === "Total") ? "notEquals" : "equals";
    const typeOp = (parseProp.kw_type === "Total") ? "notEquals" : "equals";

    const propObjectBase = {
        data: [],
        start: "2022-07-01",
        end: yesterdayProp,
        theme: theme,
        brand: "Total",
        channel: "Total",
        kw_type: "Total",
    };


    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    const cubeFilters = [
        {
            "member": `${cubeTable}.brand`,
            "operator": `${brandOp}`,
            "values": [
                `${brand}`
            ]
        },
        {
            "member": `${cubeTable}.channel`,
            "operator": `${channelOp}`,
            "values": [
                `${channel}`
            ]
        },
        {
            "member": `${cubeTable}.kw_type`,
            "operator": `${typeOp}`,
            "values": [
                `${type}`
            ]
        }
    ]


    const cubeQuery = ParseCohorts(cubeFilters, cubeTable, start, end)
    const { resultSet } = useCubeQuery(cubeQuery);
    const dimQuery = DimensionsCube(cubeTable)
    const dimResult = useCubeQuery(dimQuery)

    if (!resultSet) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    if (dimResult.isLoading === true) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    const cubeResponse = resultSet.loadResponse.results[0].data
    const agg_cohorts = ParseRunningNGR(cubeResponse, cubeTable)


    return (
        <View style={styles.container}>
            <View style={styles.cohortVis}>
                <DocCohortVis                    
                    theme={theme}
                    agg_cohorts={agg_cohorts}
                />
            </View>
        </View>

    )

}

export default DocCohorts

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
        alignItems: 'center',
        justifyContent: 'center'
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    },
    cohortVis: {
        marginTop: '1.5%',
        width: '100%',
        alignItems: 'center'
    }
})