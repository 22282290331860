import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Pressable
} from 'react-native'
import FiltersEnhanced from '../../ComponentsEnhanced/FiltersEnhanced';
import WebFont from 'webfontloader';
import BarChart from '../Charts/AttBar';
import AttTable from '../Charts/AttTable';
import AttModelSelect from '../AttModelSelect';
import { current } from '@reduxjs/toolkit';

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

const AttEnhancedContent = (props) => {

    const currentMetrics = props.currentMetrics
    const dataProp = props.dataProp
    const uniqueFilter1 = dataProp.uniqueFilter1
    const uniqueFilter2 = dataProp.uniqueFilter2
    const uniqueFilter3 = dataProp.uniqueFilter3
    const uniqueFilter4 = dataProp.uniqueFilter4
    const filterList = props.filterList
    const setFilterList = props.setFilterList
    const aggResult = dataProp.aggResult
    const models = dataProp.models
    const theme = props.theme
    const handleStateChange = props.handleStateChange
    const propObject = props.propObject
    const dynamicFilter = props.dynamicFilter
    const modelHandler = props.modelHandler
    const setOpen = props.setOpen
    const attModalOpen = props.attModalOpen
    const filterParams = props.filterParams

    function sumField(data, fieldName) {
        return data.reduce((sum, entry) => {
            const fieldValue = entry[fieldName];
            if (fieldValue !== null && !isNaN(fieldValue)) {
                return sum + parseFloat(fieldValue);
            } else {
                return sum;
            }
        }, 0);
    }

    const model1 = models.model1
    const model2 = models.model2
    const model3 = models.model3

    const model1FTDVar = `ftds_${model1}`
    const model1NGRVar = `ngr_${model1}`

    const model2FTDVar = `ftds_${model2}`
    const model2NGRVar = `ngr_${model2}`

    const model3FTDVar = `ftds_${model3}`
    const model3NGRVar = `ngr_${model3}`

    const model1FTDSum = sumField(aggResult, model1FTDVar)
    const model1NGRSum = sumField(aggResult, model1NGRVar)
    const model2FTDSum = sumField(aggResult, model2FTDVar)
    const model2NGRSum = sumField(aggResult, model2NGRVar)
    const model3FTDSum = sumField(aggResult, model3FTDVar)
    const model3NGRSum = sumField(aggResult, model3NGRVar)
    const costSum = sumField(aggResult, 'spend')

    const model1CPA = costSum / model1FTDSum
    const model2CPA = costSum / model2FTDSum
    const model3CPA = costSum / model3FTDSum

    const model1ARPU = model1NGRSum / model1FTDSum
    const model2ARPU = model2NGRSum / model2FTDSum
    const model3ARPU = model3NGRSum / model3FTDSum

    const modelLabels = [model1, model2, model3]
    const ftdValues = [model1FTDSum, model2FTDSum, model3FTDSum]
    const ngrValues = [model1NGRSum, model2NGRSum, model3NGRSum]
    const cpaValues = [model1CPA, model2CPA, model3CPA]
    const arpuValues = [model1ARPU, model2ARPU, model3ARPU]

    

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    let responseHeight;

    if (windowWidth < 1441) {
        responseHeight = windowHeight * 0.11
      } else if (windowWidth > 2000) {
        responseHeight = windowHeight * 0.08
      } else {responseHeight = windowHeight * 0.08}


    const panelMain = {

        width: '100%',
        height: responseHeight,
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 40,
        fontFamily: 'Electrolize',     

        
    }

    const panelChild = {
        flex: 1,
        height: windowHeight * 0.2,
        width: windowWidth,
        borderWidth: 0,
        borderColor: 'grey',

        textAlign: 'center',
        fontSize: 20,
        fontFamily: 'Electrolize',
        flexDirection: 'row',
        
    }

    const lineChart = {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
    }

    const tablePanel = {
        width: '75%',
        height: '100%',
        borderColor: 'blue',
        borderWidth: 0
    }

    const chartPanel = {
        width: '25%',
        height: '100%',
        borderColor: 'blue',
        borderWidth: 0,
        flexDirection: 'column'
    }

    const chartChild = {
        width: '100%',
        height: '33.33333333%',
        borderColor: 'grey',
        borderWidth: 1,
        padding: '1%',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2))',
        
    }


    return (
        <View style={styles.container}>
            <View style={{ flexDirection: 'column' }}>
                <View style={panelMain}>
                    <View style={{ width: '100%', flexDirection: 'flex-start', fontFamily: 'Electrolize' }}>
                        <FiltersEnhanced
                            style={{
                                height: '10%',
                                borderColor: 'red',
                                borderWidth: 0,
                                width: '100%',
                                fontFamily: 'Electrolize'
                            }}
                            uniqueFilter1={uniqueFilter1}
                            uniqueFilter2={uniqueFilter2}
                            uniqueFilter3={uniqueFilter3}
                            uniqueFilter4={uniqueFilter4}
                            handleStateChange={handleStateChange}
                            propObject={propObject}
                            theme={theme}
                            type={dynamicFilter}
                            filterParams={filterParams}
                            setFilterList={setFilterList}
                            filterList={filterList}
                            currentMetrics={currentMetrics}
                        />
                    </View>
                    
                </View>
                    
                

            </View>
                <View style={panelChild}>
                    <View style={tablePanel}>
                            <AttTable
                                dataProp={aggResult}
                                theme={theme}
                                models={models}
                                filterParams={filterParams}
                                dynamicFilter={dynamicFilter}
                                currentMetrics={currentMetrics}
                            />

                    </View>
                    <View style={chartPanel}>
                            <View style={chartChild}>
                                <BarChart 
                                    dataProp={ftdValues}
                                    labels={modelLabels}
                                    theme={theme}
                                    timeFrame={'Total_Conv'}
                                    currentMetrics={currentMetrics}
                                />
                            </View>
                            <View style={chartChild}>
                                <BarChart 
                                    dataProp={cpaValues}
                                    labels={modelLabels}
                                    theme={theme}
                                    timeFrame={'FTDs'}
                                    currentMetrics={currentMetrics}
                                />

                            </View>
                            <View style={chartChild}>
                                <BarChart 
                                    dataProp={arpuValues}
                                    labels={modelLabels}
                                    theme={theme}
                                    timeFrame={'CPA'}
                                    currentMetrics={currentMetrics}
                                />

                            </View>

                    </View>
                </View>
                <AttModelSelect
                    open={attModalOpen}
                    setOpen={setOpen}
                    modelHandler={modelHandler}
                    models={models}
                />
            

        </View>

    )

}

export default AttEnhancedContent

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: (windowHeight * 0.93),
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize'
    }
})