import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Dimensions, View } from 'react-native'
import { useState } from 'react'

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

export default function AttEnhancedLookerModels(props) {
    const open = props.open
    const setOpen = props.setOpen
    const modelHandler = props.modelHandler
    const models = props.models
    const primaryMetric = props.primaryMetric
    const primaryMetricHandler = props.primaryMetricHandler


    const [modelState, setModelState] = useState(models)


    const model1 = modelState.model1
    const model2 = modelState.model2
    const model3 = modelState.model3

    const modelList = ["ft_lifetime",
        "lt_lifetime",
        "ft_28",
        "ft_14",
        "ft_7",
        "ft_1",
        "linear_1",
        "linear_7",
        "linear_14",
        "linear_28",
        "linear_lifetime",
        "td_first_1",
        "td_first_7",
        "td_first_14",
        "td_first_28",
        "td_first_lifetime",
        "td_last_1",
        "td_last_7",
        "td_last_14",
        "td_last_28",
        "td_last_lifetime",
        "pos_1",
        "pos_7",
        "pos_14",
        "pos_28",
        "pos_lifetime",
        "custom_pixel"]

    const metricList = ['clicks', 'spend', 'cpc', model1, 'totalConv', 'costConv', 'ngr', 'netProfit', 'recovered']

    const [age, setAge] = React.useState('');
    const [metricValue, setMetricValue] = useState(primaryMetric)
    const [metricsBase, setMetric] = useState(primaryMetric)

    const handleChange = (event, model) => {
        setAge((event.target.value) || '');

        const modelCopy = models
        modelCopy[model] = event.target.value
        setModelState(modelCopy)


    };

    const handleMetricChange = (event) => {
        setMetricValue((event.target.value) || '');

        const modelCopy = event.target.value
        setMetric(modelCopy)


    };


    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
        console.log(reason)
    };

    function handleSave() {
        modelHandler(modelState);
        primaryMetricHandler(metricsBase)
        setOpen(false);
    }



    const options = modelList.map((item, index) => (
        <MenuItem key={index} value={item}>
            {item}
        </MenuItem>
    ));

    const metrics = metricList.map((item, index) => (
        <MenuItem key={index} value={item}>
            {item}
        </MenuItem>
    ));

    return (
        <View style={{ width: windowWidth * 0.8, borderColor: 'red', borderWidth: 0 }}>
            <div>
                <Dialog disableEscapeKeyDown open={open} onClose={handleClose} maxWidth={"1500"}>
                    <DialogTitle sx={{ fontFamily: 'Electrolize' }}>Select Model / Metric</DialogTitle>
                    <DialogContent>
                        <Box component="form" sx={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}>
                            <FormControl sx={{ m: 1, minWidth: windowWidth * 0.15, fontFamily: 'Electrolize', }}>
                                <InputLabel id="demo-dialog-select-label">Model</InputLabel>
                                <Select
                                    labelId="demo-dialog-select-label"
                                    id="demo-dialog-select"
                                    value={model1}
                                    onChange={(event) => handleChange(event, 'model1')}
                                    input={<OutlinedInput label="Age" />}
                                >
                                    {options}
                                </Select>
                            </FormControl><FormControl sx={{ m: 1, minWidth: windowWidth * 0.15 }}>
                                <InputLabel id="demo-dialog-select-label">Metric</InputLabel>
                                <Select
                                    labelId="demo-dialog-select-label"
                                    id="demo-dialog-select"
                                    value={metricValue}
                                    onChange={(event) => handleMetricChange(event, 'model2')}
                                    input={<OutlinedInput label="Age" />}
                                >
                                    {metrics}
                                </Select>
                            </FormControl>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleSave}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </View>
    );
}