import { memo } from 'react'
import { useDrag } from 'react-dnd'
import { Dimensions } from 'react-native'

const windowWidth = Dimensions.get('window').width;

export const ControlDnDBox = memo(function Box({ name, type, isDropped, theme, currentMetrics }) {

    function boxStyle (type) {
        if (type === 'type') {
            return theme.gradc10
        } else if (type === 'xAxis') {
            return theme.gradc50
        } else if (type === 'metric') {
            return theme.color3
        } else {
            return 'green'
        }
    }

    let formatLabel;

    if (name === 'FTDs') {
      formatLabel = currentMetrics.primaryMetricString
    } else if (name === 'NGR') {
      formatLabel = currentMetrics.primaryRevenueString
    } else if (name === 'Regs') {
      formatLabel = currentMetrics.secondaryMetricString
    } else if (name === 'KW_Type') {
      formatLabel = currentMetrics.primaryDimensionString
    } else {formatLabel = name}

    let dynamicMargin;

    if (windowWidth > 2000) {
      dynamicMargin = '2.5rem'
    } else if (windowWidth >= 1400) {
      dynamicMargin = '1.75rem'
    } else if (windowWidth < 1400) {
      dynamicMargin = '0.75rem'
    }

    let dynamicText;

    if (windowWidth > 2000) {
      dynamicText = windowWidth * 0.01
    } else if (windowWidth >= 1400) {
      dynamicText = windowWidth * 0.01
    } else if (windowWidth < 1400) {
      dynamicText = windowWidth * 0.01
    }
    
    const style = {
        backgroundColor: boxStyle(type),
        color: 'white',
        cursor: 'move',
        width: '48%',
        padding: '0.5rem 0rem',
        borderRadius: 10,
        marginBottom: dynamicMargin,
        cursor: 'move',
        float: 'left',
        textAlign: 'center',
        fontSize: dynamicText,
        border: '1px solid white',
        
      }

  const [{ opacity }, drag] = useDrag(
    () => ({
      type,
      item: { name },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [name, type],
  )
  return (
    <div ref={drag} style={{ ...style, opacity }} data-testid="box">
      {formatLabel}
    </div>
  )
})