import React, {useEffect} from 'react';
import { Dimensions } from 'react-native'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import WebFont from 'webfontloader';

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;


export default function FilterBrand(props) {
  const [age, setAge] = React.useState('');

  const uniqueBrandRaw = props.uniqueBrands
  const total = "Total"
  const uniqueBrands = [total, ...uniqueBrandRaw];
  const handleStateChange = props.handleStateChange
  const propObject = props.propObject
  const brandFilterState = props.brandFilterState
  const theme = props.theme
  const large = props.large

  const brandFilter = propObject.brand

  let filterValue;

  if (brandFilter === 'Total') {
    filterValue = ''
  } else {filterValue = brandFilter}

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Droid Sans', 'Electrolize']
      }
    });
   }, []);


  const handleChange = (event) => {
    setAge(event.target.value);
    handleUpdate(event.target.value)
  };

  const handleUpdate = (prop) =>{
  
  const propUpdate = {
    start: propObject.start,
    end: propObject.end,
    channel: propObject.channel,
    brand: prop,
    kw_type: propObject.kw_type,
  }

  handleStateChange(propUpdate)

}

  const stateColour = brandFilterState ? 'grey' : theme.backgroundColor;

  let responseHeight;

  if (windowWidth < 1441) {
    responseHeight = windowHeight * 0.045
  } else if (windowWidth > 2000 && large === 1) {
    responseHeight = windowHeight * 0.035
  }
   else if (windowWidth > 2000) {
    responseHeight = windowHeight * 0.03
  } else {responseHeight = windowHeight * 0.0415}

  let responseMargin;

  if (windowHeight < 800 && large === 1) {
    responseMargin = '-1%'
  }  else if (windowWidth > 2000) {
    responseMargin = '-0.8%'
  } else if (windowHeight > 800 && large === 1) {
    responseMargin = '-1.2%'
  }  else if (windowHeight > 800) {
    responseMargin = '-1%'
  } else if (windowWidth < 1441 && large === 1) {
    responseMargin = '-3%'
  } else if (windowWidth < 1441) {
    responseMargin = '-1%'
  } else  {responseMargin = '-1%'}

  let responseText;

  if (windowWidth < 1281) {
    responseText = windowHeight * 0.025
  } else if (windowWidth > 2000 && large === 1) {
    responseText = windowHeight * 0.012
  } else if (windowWidth > 2000) {
    responseText = windowHeight * 0.01
  } else {responseText = windowHeight * 0.017}



  return (
    <Box sx={{ 
        width: '100%' , 
        fontFamily : 'Electrolize' , 
        color : theme.color,
        borderWidth : 0.1,
        borderColor: theme.backgroundColor2,
        alignItems: 'center',
        justifyContent: 'center'

        }}>
      <FormControl fullWidth disabled={brandFilterState}
       style={{marginTop: 0, }} >

        <InputLabel id="demo-simple-select-label" 
          style={{
            fontFamily : 'Electrolize', 
            color : theme.color,
            borderWidth : 1,
            borderColor: theme.backgroundColor2,
            fontSize: responseText,
            marginTop: responseMargin,  
                      
            }}>
            Brand
            </InputLabel>

        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={filterValue}          
          onChange={handleChange}
          MenuProps={{
            MenuListProps: {
              disablePadding: true
          }}}
            style={{
            width : '100%' ,
            left : 0, 
            fontFamily : 'Electrolize', 
            color : theme.color , 
            backgroundColor : stateColour,
            border: `1px solid ${theme.backgroundColor2}`,
            height: 45
            }}
        >
          {uniqueBrands.map((option, index) => (
          <MenuItem 
            key={index}
            style={{
              width : '100%',
              fontFamily : 'Electrolize', 
              color : theme.color , 
              backgroundColor : theme.backgroundColor,
              borderWidth : 1,
              border: `1px solid grey`,

              }}
            value={option} >
            {option}
          </MenuItem>
        ))}
        </Select>
      </FormControl>
    </Box>
  );
}