import { Dimensions, View } from 'react-native';
import React, { useState } from 'react';
import MobileAppBar from '../MobileComponents/MobileAppBar';
import MobileBottomNav from '../MobileComponents/MobileBottomNav';
import ChartsPanel from './ChartsPanel';
import moment from 'moment-timezone';
import { useCubeQuery } from '@cubejs-client/react';
import LoadingComponent from '../../Components/Loader';
import ParseDaily from '../../DailySummary/CubeFunctions/ParseDaily';
import CubeChart from '../CubeFunctions/CubeChart.js';
import DimensionsCube from '../../CubeFunctions/Dimensions.js';
import ParseDimensions from '../../CubeFunctions/ParseDimensions.js';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const Charts = (props) => {

    const value = props.value
    const setValue = props.setValue
    const configArray = props.configArray
    const screenHandler = props.screenHandler
    const currentMetrics = props.currentMetrics
    const configHandler = configArray.configHandler
    const clientHandler = configArray.clientHandler
    const clientID = configArray.clientID
    const clientBase = clientID.toLowerCase();
    const theme = props.theme
    const cubeTable = clientBase + "_daily";
    const role = configArray.role
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayProp = yesterday2.format('YYYY-MM-DD');

    const [brandList, setBrandList] = useState('Total')
    const [channelList, setChannelList] = useState('Total')
    const [typeList, setTypeList] = useState('Total')

    const [selectChart, setSelectChart] = useState('Line')
    const [selectAxis, setSelectAxis] = useState('Date')
    const [selectMetric1, setSelectMetric1] = useState('Spend')
    const [selectMetric2, setSelectMetric2] = useState('FTDs')
    

    function graphHandler (prop) {
        //setCurrentGraph(prop)
    }

    const currentDate = new Date();
    currentDate.setMinutes(0);
    const previousDate = new Date(currentDate);
    previousDate.setDate(currentDate.getDate() - 30);
    previousDate.setDate(1)

    const startDateString = previousDate.toISOString().slice(0, 10);

    const baseConfig = {
        chart: 'Line',
        axis: 'Date',
        brand: 'Total',
        channel: 'Total',
        kw_type: 'Total',
        start: startDateString,
        end: yesterdayProp,
        time: 'Day',
        metric1: 'Spend',
        metric2: 'FTDs'
    }

    const [chartConfig, setChartConfig] = useState(baseConfig)

    const start = chartConfig.start
    const end = chartConfig.end  

    let typeFilter;
    let typeOperator;

    if (chartConfig.kw_type === 'Total') {
        typeFilter = ['null']
        typeOperator = ['notEquals']
    } else {
        typeFilter = typeList
        typeOperator = ['equals']
    }

    let brandFilter;
    let brandOperator;

    if (chartConfig.brand === 'Total') {
        brandFilter = ['null']
        brandOperator = ['notEquals']
    } else {
        brandFilter = brandList
        brandOperator = ['equals']
    }

    let channelFilter;
    let channelOperator;

    if (chartConfig.channel === 'Total') {
        channelFilter = ['null']
        channelOperator = ['notEquals']
    } else {
        channelFilter = channelList
        channelOperator = ['equals']
    }

    const cubeFilters = [
        {
            "member": `${cubeTable}.brand`,
            "operator": `${brandOperator}`,
            "values": brandFilter
        },
        {
            "member": `${cubeTable}.channel`,
            "operator": `${channelOperator}`,
            "values": channelFilter
        },
        {
            "member": `${cubeTable}.kw_type`,
            "operator": `${typeOperator}`,
            "values": typeFilter
        }
    ]

    const chartType = chartConfig.chart.toLowerCase()
    const xAxis = chartConfig.axis.toLowerCase()
    const time = chartConfig.time.toLowerCase()

    const cubeQuery = CubeChart(cubeFilters, cubeTable, start, end, chartType, xAxis, time)
    const { resultSet } = useCubeQuery(cubeQuery);
    const dimQuery = DimensionsCube(cubeTable)
    const dimResult = useCubeQuery(dimQuery)

    if (!resultSet) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    if (dimResult.isLoading === true) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    const cubeResponse = resultSet.loadResponse.results[0].data
    const dimData = dimResult.resultSet.loadResponses[0].data

    const uniqueData = ParseDimensions(dimData, cubeTable)

    const updateArray = cubeResponse.map(obj => {
        const newObj = {};
        for (const key in obj) {
            const newKey = key.replace(cubeTable + ".", "");
            newObj[newKey] = obj[key];
        }
        return newObj;
    });

    const agg_result = updateArray.map((obj) => {
        const lowerAxis = selectAxis.toLowerCase();
        let axisValue;
        if (lowerAxis === 'date') {
            axisValue = moment.tz(obj.timestamp, 'Europe/Paris').format('YYYY-MM-DD');
        } else {
            axisValue = obj[lowerAxis]
        }

        const date = moment.tz(obj.timestamp, 'Europe/Paris').format('YYYY-MM-DD');
        const spend = parseFloat(obj.spend)
        const ftds = parseInt(obj.ftds)
        const cpa = (() => {
            const result = spend / ftds;
          
            return isNaN(result) || !isFinite(result) ? 0 : parseFloat(result.toFixed(2));
          })();

        const impressions = parseInt(obj.impressions)
        const clicks = parseInt(obj.clicks)
        const regs = parseInt(obj.regs)
        const cpc = parseFloat((spend / clicks).toFixed(2))
        const ftdConv = ((parseFloat(ftds / clicks))*100).toFixed(2)

        return {
            [lowerAxis] : axisValue,
            spend: Math.round(spend),
            ftds: ftds,
            cpa: Math.round(cpa),
            impressions: impressions,
            clicks: clicks,
            regs: regs,
            cpc: Math.round(cpc),
            cpa: Math.round(cpa),
            clickConv: ftdConv

        };
    });

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
    };

    const dateLabels = getUniqueValues(agg_result, xAxis)
    const uniqueBrands = uniqueData.uniqueBrands;
    const uniqueChannels = uniqueData.uniqueChannels;
    const uniqueTypes = uniqueData.uniqueTypes;

    const container = {
        height: windowHeight,
        width: windowWidth,
        borderColor: theme.backgroundColor2,
        borderWidth: 0,
        backgroundColor: theme.backgroundColor
    }

    const homePanel = {
        height: windowHeight * 0.863,
        width: '100%',
        borderColor: theme.backgroundColor2,
        borderWidth: 0,
        backgroundColor: theme.backgroundColor,
        marginTop: '1%',

    }

    return (
        <View style={container}>
            <MobileAppBar
                theme={theme}
                configHandler={configHandler}
                clientHandler={clientHandler}
                homeTitle={'Charts'}
                role={role}
                screenHandler={screenHandler}
            />
            <View style={homePanel}>

            <ChartsPanel
                theme={theme}
                agg_daily={agg_result}
                dateLabels={dateLabels}
                uniqueBrands={uniqueBrands}
                uniqueChannels={uniqueChannels}
                uniqueTypes={uniqueTypes}
                chartConfig={chartConfig}
                setChartConfig={setChartConfig}
                setBrandList={setBrandList}
                setChannelList={setChannelList}
                setTypeList={setTypeList}
                selectChart={selectChart}
                selectAxis={selectAxis}
                selectMetric1={selectMetric1}
                selectMetric2={selectMetric2}
                setSelectChart={setSelectChart}
                setSelectAxis={setSelectAxis}
                setSelectMetric1={setSelectMetric1}
                setSelectMetric2={setSelectMetric2}
                currentMetrics={currentMetrics}
            />

            <MobileBottomNav
                theme={theme}
                screenHandler={screenHandler}
                graphHandler={graphHandler}
                clientID={clientID}
                value={value}
                setValue={setValue}
            />
            </View>            
        </View>
    )

}

export default Charts