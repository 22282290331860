import React, { useState } from 'react';
import { View, StyleSheet, Dimensions } from 'react-native'
import AppBarRN from '../Components/AppBarRN';
import TemporaryDrawer from "../Components/Drawer";
import LoadingComponent from '../Components/Loader';
import ChartPanelV2 from '../ChartBuilder/ChartPanelV2';
import moment from 'moment-timezone';
import { useCubeQuery } from '@cubejs-client/react';
import CubeQuery from '../ChartBuilder/CubeQueries/CubeQuery';
import DimensionsCube from '../CubeFunctions/Dimensions.js';
import ParseDimensions from '../CubeFunctions/ParseDimensions.js';
import CubeDailyFunction from '../DailySummary/CubeFunctions/CubeDailyFunction.js';
import ParseDimensionsAtt from '../AttributionEnhanced/CubeFunctions/ParseDimensionsAtt.js';

const windowHeight = Dimensions.get('window').height;

const ChartBuilder = (props) => {

  const configArray = props.configArray
  const currentMetrics = configArray.currentMetrics[0]
  const configHandler = configArray.configHandler
  const tooltipArray = configArray.tooltipArray
  const clientHandler = configArray.clientHandler
  const clientID = configArray.clientID
  const client = clientID.toLowerCase();
  const cubeTable = client + "_daily";
  const theme = props.theme
  const homeTitle = "Chart Builder";
  const role = configArray.role
  const currentDate2 = moment.tz('Europe/Malta');
  const yesterday2 = currentDate2.clone().subtract(1, 'day');
  const yesterdayProp = yesterday2.format('YYYY-MM-DD');

  const [chartFilterVisible, setmodalVisible] = useState(false);
  const [modalType, setModalType] = useState('chart')
  const [chartClick, setChartClick] = useState('Daily Line')
  const [axisClick, setAxisClick] = useState('Date')
  const [metric1Click, setMetric1] = useState('Spend')
  const [metric2Click, setMetric2] = useState('FTDs')

  const chartConfig = {
    type: chartClick,
    xAxis: axisClick,
    metric1: metric1Click,
    metric2: metric2Click
  }



  const currentDate = new Date();
  currentDate.setMinutes(0);
  const previousDate = new Date(currentDate);
  previousDate.setDate(currentDate.getDate() - 30);
  previousDate.setDate(1)

  // Convert the start and end dates to strings in YYYY-MM-DD format
  const startDateString = previousDate.toISOString().slice(0, 10);

  const stateBase = {
    top: false,
    left: false,
    bottom: false,
    right: false,
  };

  const [state, setState] = useState(stateBase);
  const anchor = 'left'

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });

  };

  function handleDrawer(anchor) {

    const stateChange = {
      top: false,
      left: true,
      bottom: false,
      right: false,
    };
    setState(stateChange)

  }

  const filter1name = 'brand'
    const filter2name = 'channel'
    const filter3name = 'kw_type'

    const [filter1List, setFilter1List] = useState('Total')
    const [filter2List, setFilter2List] = useState('Total')
    const [filter3List, setFilter3List] = useState('Total')

    const filterValueBase = {
        filter1List : [],
        filter2List : [],
        filter3List : []
      }
    const [filterList, setFilterList] = useState(filterValueBase);

    const filterParams = {
        filter1name,
        filter2name,
        filter3name,
        filter1List,
        filter2List,
        filter3List,
        setFilter1List,
        setFilter2List,
        setFilter3List,
    }

    const filterBase = {
        start: startDateString,
        end: yesterdayProp,
        [filter1name]: "Total",
        [filter2name]: 'Total',
        [filter3name]: "Total",
    };

    const [filters, setFilters] = useState(filterBase);

    const start = filters.start
    const end = filters.end
    let filter1Filter, filter1Operator;

    if (filters[filter1name] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name].length === 0) {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name][0] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else {
        filter1Filter = filter1List
        filter1Operator = ['equals']
    }

    let filter2Filter, filter2Operator;

    if (filters[filter2name] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name].length === 0) {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name][0] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else {
        filter2Filter = filter2List
        filter2Operator = ['equals']
    }

    let filter3Filter, filter3Operator;

    if (filters[filter3name] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filter3name].length === 0) {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filter3name][0] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    }  else {
        filter3Filter = filter3List
        filter3Operator = ['equals']
    }



    const propObjectBase = {
        data: [],
        start: startDateString,
        end: yesterdayProp,
        theme: theme,
        [filter1name]: "Total",
        [filter2name]: 'Total',
        [filter3name]: "Total",
    };

  const [propObject, setpropObject] = useState(propObjectBase);

  const handleStateChange = (prop) => {

    setpropObject(prop);

    const filterUpdate = {
      start: prop.start,
      end: prop.end,
      [filter1name]: prop[filter1name],
      [filter2name]: prop[filter2name],
      [filter3name]: prop[filter3name],
  };

    setFilters(filterUpdate)

  };

  const cubeFilters = [
    {
        "member": `${cubeTable}.${filter1name}`,
        "operator": `${filter1Operator}`,
        "values": filter1Filter
    },
    {
        "member": `${cubeTable}.${filter2name}`,
        "operator": `${filter2Operator}`,
        "values": filter2Filter
    },
    {
        "member": `${cubeTable}.${filter3name}`,
        "operator": `${filter3Operator}`,
        "values": filter3Filter
    }
]


  let xValue;

  if (chartConfig.type === 'Grouped Bar' && chartConfig.xAxis === 'Date') {
    xValue = 'Brand'
  }else if (chartConfig.type === 'Daily Bar' && chartConfig.xAxis === 'Date') {
    xValue = 'Date'
  }else if (chartConfig.type === 'Mixed' && chartConfig.xAxis === 'Date') {
    xValue = 'Date'
  } else { xValue = chartConfig.xAxis }

  const newConfig = {
    type: chartClick,
    xAxis: xValue,
    metric1: metric1Click,
    metric2: metric2Click
  }

  const chartType = newConfig.type
  const xAxis = newConfig.xAxis
  const xAxisLower = xAxis.toLowerCase()

  let timeFrame;

  if (chartType === 'Monthly Line' || chartType === 'Monthly Bar') {
    timeFrame = 'month'
  } else { timeFrame = 'day' }



  const cubeQuery = CubeQuery(cubeFilters, cubeTable, start, end, xAxisLower, timeFrame, chartType)
  const dimQuery = CubeDailyFunction (cubeTable, start, end, filter1name, filter2name, filter3name, cubeFilters)
  const dimResult = useCubeQuery(dimQuery)
  const { resultSet } = useCubeQuery(cubeQuery);

  if (!resultSet) {
    return (
      <LoadingComponent theme={theme} />
    );
  }

  if (dimResult.isLoading === true) {
    return (
      <LoadingComponent theme={theme} />
    );
  }

  const cubeResponse = resultSet.loadResponse.results[0].data
  const dimData = dimResult.resultSet.loadResponses[0].data

  const uniqueFilter1 = ParseDimensionsAtt(dimData, cubeTable, filter1name)
    const uniqueFilter2 = ParseDimensionsAtt(dimData, cubeTable, filter2name)
    const uniqueFilter3 = ParseDimensionsAtt(dimData, cubeTable, filter3name)

  const updateArray = cubeResponse.map(obj => {
    const newObj = {};
    for (const key in obj) {
      const newKey = key.replace(cubeTable + ".", "");
      newObj[newKey] = obj[key];
    }
    return newObj;
  });

  const updateVar = `${cubeTable}.updated_at`
  const lastUpdated = dimData[0][updateVar] || 'loading...'

  const agg_result = updateArray.map((obj) => {
    const date = moment.tz(obj.timestamp, 'Europe/Paris').format('YYYY-MM-DD');
    const mixedDate = moment.tz(obj.date, 'Europe/Paris').format('YYYY-MM-DD');
    const month = moment.tz(obj.timestamp, 'Europe/Paris').format('YYYY-MM');
    const spend = obj.spend
    const ftds = obj.ftds
    const cpa = parseFloat((spend / ftds).toFixed(2))
    const impressions = obj.impressions
    const clicks = obj.clicks
    const regs = obj.regs

    let xAxis;
    let xAxisValue;
    if (chartType === 'Mixed' && xAxisLower === 'date') {
      xAxis = 'date'
      xAxisValue = mixedDate
    } else if (xAxisLower === 'date' && timeFrame === 'day') {
      xAxis = 'date'
      xAxisValue = date
    } else if (xAxisLower === 'date' && timeFrame === 'month') {
      xAxis = 'date'
      xAxisValue = month
    } else {
      xAxis = xAxisLower
      xAxisValue = obj[xAxisLower]
    }


    return {
      [xAxis] : xAxisValue,
      impressions: impressions,
      clicks: clicks,
      spend: spend,
      regs: regs,
      ftds: ftds,
      cpa: cpa

    };
  });

  const uniqueData = ParseDimensions(dimData, cubeTable)

  const uniqueBrands = uniqueData.uniqueBrands;
  const uniqueChannels = uniqueData.uniqueChannels;
  const uniqueTypes = uniqueData.uniqueTypes;

  const dataProp = {
    agg_daily_result: agg_result,
    uniqueFilter1,
    uniqueFilter2,
    uniqueFilter3,
  }




  return (

    <View style={[styles.container, { backgroundColor: theme.backgroundColor }]}>
      <AppBarRN
        handleDrawer={handleDrawer}
        homeTitle={homeTitle}
        theme={theme}
        configHandler={configHandler}
        clientHandler={clientHandler}
        role={role}
        client={clientID}
        lastUpdated={lastUpdated}
      />
      <TemporaryDrawer
        toggleDrawer={toggleDrawer}
        state={state}
        anchor={anchor}
        theme={theme}
        clientID={clientID}
      />
      <ChartPanelV2
        theme={theme}
        dataProp={dataProp}
        propObject={propObject}
        chartConfig={newConfig}
        windowHeight={windowHeight}
        handleStateChange={handleStateChange}
        chartFilterVisible={chartFilterVisible}
        setmodalVisible={setmodalVisible}
        modalType={modalType}
        setModalType={setModalType}
        chartClick={chartType}
        setChartClick={setChartClick}
        axisClick={xAxisLower}
        setAxisClick={setAxisClick}
        metric1Click={metric1Click}
        setMetric1={setMetric1}
        metric2Click={metric2Click}
        setMetric2={setMetric2}
        uniqueBrands={uniqueBrands}
        uniqueChannels={uniqueChannels}
        uniqueTypes={uniqueTypes}
        tooltipArray={tooltipArray}
        currentMetrics={currentMetrics}
        client={client}
        setFilterList={setFilterList}
        filterList={filterList}
        filterParams={filterParams}

      />
    </View>


  )
}



const styles = StyleSheet.create({
  container: {
    borderWidth: 0,
    borderColor: 'yellow',
    paddingTop: 0,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    flex: 1,
  },
  panel: {
    width: '100%'
  }
})

export default ChartBuilder