import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Dimensions, View } from 'react-native'
import { useState } from 'react'

import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const windowWidth = Dimensions.get('window').width;

export default function CumulativeMetricMixedModal(props) {
  const open = props.open
  const setOpen = props.setOpen
  const reportList = props.reportList
  const setMetric = props.setMetric
  const setMetric2 = props.setMetric2
  const currentMetrics = props.currentMetrics

  const excludeRoutes = ['date', 'cpc']


  const filterList = reportList.filter((item) => {
    return !excludeRoutes.includes(item);
  });

  const [textValue, setTextValue] = useState('')
  const [textValue2, setTextValue2] = useState('')

  const textHandler = (value) => {
    let output;
    if (value === 'ftds') {
      output = currentMetrics.primaryMetric
    } else if (value === 'regs') {
      output = currentMetrics.secondaryMetric
    } else if (value === 'ngr') {
      output = currentMetrics.primaryRevenue
    } else {
      output = value
    }
    return output
  }



  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
    console.log(reason)
  };

  function handleSave () {
    setMetric(textValue)
    setMetric2(textValue2)

    setOpen(false);
  }


  const options = filterList.map((item, index) => (
    <MenuItem key={index} value={item}>
      {textHandler(item)}
    </MenuItem>
  ));


  return (
    <View style={{width: windowWidth * 0.8, borderColor: 'red', borderWidth: 0}}>
    <div>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose} maxWidth={1500}>
        <DialogTitle sx={{fontFamily: 'Electrolize'}}>Select Metrics</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ 
                                    display: 'flex', 
                                    flexDirection: 'row',
                                    }}>
              <FormControl sx={{ m: 1, minWidth: windowWidth * 0.15,fontFamily: 'Electrolize',}}>
              <InputLabel id="demo-dialog-select-label">Select Metric 1</InputLabel>
              <Select
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                value={textValue}
                

                onChange={(event) => setTextValue(event.target.value)}
                input={<OutlinedInput label="Select Metric 1" />}
              >
                {options}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: windowWidth * 0.15,fontFamily: 'Electrolize',}}>
              <InputLabel id="demo-dialog-select-label">Select Metric 2</InputLabel>
              <Select
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                value={textValue2}
                

                onChange={(event) => setTextValue2(event.target.value)}
                input={<OutlinedInput label="Select Metric 2" />}
              >
                {options}
              </Select>
            </FormControl>

          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Update</Button>
        </DialogActions>
      </Dialog>
    </div>
    </View>
  );
}