import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import { Dimensions, View } from 'react-native'

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
const responsiveTextSmall = windowWidth * 0.01

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

export default function DashEditorSelect(props) {

    const [isInitialRender, setIsInitialRender] = React.useState(true);
    const currentMetrics = props.currentMetrics
    const theme = props.theme
    const itemList = props.itemList
    const changeHandler = props.changeHandler
    const filters = props.filters
    const filterState = props.filterState
    const type = props.type
    const newArray = itemList.map((label, index) => ({
        key: index,
        label: label
    }));
    const resetChip = {
        key: 99999,
        label: 'RESET'
    }

    function extractLabels(data) {
        return data
            .filter(item => item.label !== 'RESET') // Filter out items with label 'RESET'
            .map(item => item.label); // Map each object to its label property
    }

    const finalArray = [...newArray, resetChip]
    const [chipData, setChipData] = React.useState(finalArray);
    useEffect(() => {

        if (isInitialRender) {
            // Skip the initial render
            setIsInitialRender(false);
            return;
        }

        let updateSetting;
        if (type === 'brands') {
            updateSetting = {
                start: filterState.start,
                end: filterState.end,
                channel: filterState.channel,
                brand: extractLabels(chipData),
                kw_type: filterState.kw_type
            }
            changeHandler(updateSetting)
        } else if (type === 'types') {
            updateSetting = {
                start: filterState.start,
                end: filterState.end,
                channel: filterState.channel,
                brand: filterState.brand,
                kw_type: extractLabels(chipData)
            }
            changeHandler(updateSetting)
        } else if (type === 'channels') {
            updateSetting = {
                start: filterState.start,
                end: filterState.end,
                channel: extractLabels(chipData),
                brand: filterState.brand,
                kw_type: filterState.kw_type
            }
            changeHandler(updateSetting)
        }


    }, [chipData]);

    const handleDelete = (chipToDelete) => () => {
        setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    };

    function handleReset() {
        setChipData(finalArray)
    }

    const nonReset = {
        color: 'white',
        backgroundColor: theme.backgroundColor2,
        fontSize: responsiveTextSmall
    }

    const reset = {
        color: theme.color,
        backgroundColor: theme.backgroundColor,
        borderColor: theme.color,
        borderWidth: 1,
        fontSize: responsiveTextSmall
    }

    const mapContainer = {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: 'grey',
        borderWidth: 1,
        padding: 0,
        flex: 1,
        overflowY: 'auto'
    }

    return (

        <View style={mapContainer}>
            {chipData.map((data) => {

                let convLabel

                if (data.label === 'ctr') {
                    convLabel = `${data.label}%`
                } if (data.label === 'ftds') {
                    convLabel = currentMetrics.primaryMetric
                } else if (data.label === 'ngr') {
                    convLabel = currentMetrics.primaryRevenue
                } else if (data.label === 'regs') {
                    convLabel = currentMetrics.secondaryMetric
                } else { convLabel = data.label }

                let icon;

                if (data.label === 'React') {
                    icon = <TagFacesIcon />;
                }

                return (

                    <ListItem key={data.key}>
                        <Chip
                            icon={icon}
                            label={convLabel}
                            variant={data.label === 'RESET' ? 'outlined' : 'outlined'}
                            onDelete={data.label === 'RESET' ? undefined : handleDelete(data)}
                            onClick={() => data.label === 'RESET' ? handleReset() : undefined}
                            style={data.label === 'RESET' ? reset : nonReset}
                        />
                    </ListItem>
                );
            })}
        </View>
    );
}