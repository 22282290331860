import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Dimensions, View } from 'react-native'
import { useState } from 'react'

import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FlexModalTextBox from './FlexModalTextBox';

const windowWidth = Dimensions.get('window').width;

export default function FlexModalDelete(props) {
    const open = props.open
    const setOpen = props.setOpen
    const reportList = props.reportList
    const theme = props.theme
    const setCurrentReportName = props.setCurrentReportName
    const reportNameFallback = props.reportNameFallback
    const currentReportName = props.currentReportName
    const [reportName, setReportNameBase] = useState(currentReportName)
    const currentSettings = props.currentSettings
    const currentReportConfig = props.currentReportConfig
    const [textValue, setTextValue] = useState('')
    const updateReport = props.updateReport

    const deleteReport = props.deleteReport

    function reportMapping(data) {
        // Initialize an object to hold the result
        let result = {};

        // Iterate over each object in the provided array
        data.forEach((item, index) => {
            // Extract the key (identifier) from each object
            Object.keys(item).forEach(key => {
                // Add the key and its index to the result object
                result[key] = index;
            });
        });

        // Return the result object
        return result;
    }

    const reportMap = reportMapping(currentReportConfig)

    function filterExcludes(inputArray, filterArray) {
        const filterSet = new Set(filterArray);
        return inputArray.filter(item => !filterSet.has(item));
    }

    const excludeDefault = ['default']
    const filteredReportList = filterExcludes(reportList, excludeDefault)

    let deleteList;

    if (filteredReportList.length === 0) {
        deleteList = ['No Templates']
    } else {
        deleteList = filteredReportList
    }

    let deleteLabel;

    if (filteredReportList.length === 0) {
        deleteLabel = ['No Templates']
    } else {
        deleteLabel = 'Current Templates'
    }


    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
        console.log(reason)
    };

    function handleDelete() {
        //localStorage.setItem('lastFlex', textValue)
        const selectedReportIndex = reportMap[textValue]
        const reportConfigCopy = currentReportConfig.filter((item, index) => index !== selectedReportIndex);
        const deleteProps = {
            selectedReportIndex,
            reportConfigCopy,
            textValue
        }

        deleteReport(deleteProps)


        //if (textValue === currentReportName) {
          //  localStorage.setItem('lastFlex', 'default')
            //localStorage.setItem('flexConfig', JSON.stringify(reportConfigCopy))
            //setCurrentReportName('default')
            //setReportNameBase('default')
            //updateReport()
        //} else {

        //}


        setOpen(false);
    }


    const options = deleteList.map((item, index) => (
        <MenuItem key={index} value={item}>
            {item}
        </MenuItem>
    ));


    return (
        <View style={{ width: windowWidth * 0.8, borderColor: 'red', borderWidth: 0 }}>
            <div>
                <Dialog disableEscapeKeyDown open={open} onClose={handleClose} maxWidth={1500}>
                    <DialogTitle sx={{ fontFamily: 'Electrolize', color: 'red', fontWeight: 'bold' }}>Delete Template</DialogTitle>
                    <DialogContent>
                        <Box component="form" sx={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}>
                            <FormControl sx={{ m: 1, minWidth: windowWidth * 0.15, fontFamily: 'Electrolize', }}>
                                <InputLabel id="demo-dialog-select-label">{deleteLabel}</InputLabel>
                                <Select
                                    labelId="demo-dialog-select-label"
                                    id="demo-dialog-select"
                                    value={textValue}


                                    onChange={(event) => setTextValue(event.target.value)}
                                    input={<OutlinedInput label="Age" />}
                                >
                                    {options}
                                </Select>
                            </FormControl>

                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button style={{ color: 'red' }} onClick={handleClose}>Cancel</Button>
                        <Button style={{ color: 'red' }} onClick={handleDelete}>Delete</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </View>
    );
}