import React, { useState } from 'react';
import { View, 
    Text, 
    Pressable, 
    Modal,
    StyleSheet,
    Dimensions } from 'react-native'
import CloseIcon from '@mui/icons-material/Close';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import CanvasContainer from '../ReactDnD/DndCanvas/CanvasContainer';


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
const responsiveTextSmall = windowWidth * 0.02

const DashEditorModify = (props) => {

    const currentMetrics = props.currentMetrics
    const theme = props.theme
    const cubeTable = props.cubeTable
    const visibleModal = props.visibleModal
    const setVisible = props.setViewerVisible
    const viewerVisible = props.viewerVisible
    const setViewerVisible = props.setViewerVisible
    const getLayout = props.getLayout
    const dashConfigs = props.dashConfigs
    const setDashConfigs = props.setDashConfigs
    const currentItem = props.currentItem
    const setCurrentItem = props.setCurrentItem
    const agg_result = props.agg_result
    const dateLabels = props.dateLabels
    const uniqueBrands = props.uniqueBrands
    const uniqueChannels = props.uniqueChannels
    const uniqueTypes = props.uniqueTypes
    const filters = props.filters
    const setFilters = props.setFilters
    const updateHandler = props.updateHandler
    const setUpdateFilters = props.setUpdateFilters
    const startDateString = props.startDateString
    const yesterdayProp = props.yesterdayProp


    const panel = {
        height: '100%',
        width: '100%',
        flexDirection: 'column'
    }

    const filterPanel = {
        height: '0%',
        width: '100'
    }

    const graphPanel = {
        height: '100%',
        width: '100',
        borderColor: 'red',
        borderWidth: 0
    }


    return (
        <Modal
            visible={viewerVisible}
            style={styles.modalParent}
            transparent={true}
            >
        <Pressable 
            style={styles.modalContainer}
            >
            <View style={[styles.modalWindow , {
                backgroundColor : theme.backgroundColor,
                borderColor: theme.color
            }]}>
            <Pressable style={{
                        position: 'absolute',
                        left: '98%',
                        width: 100,
                        height: 45,
                        top: '0%',
                        borderColor: 'red',
                        borderWidth: 0,
                        zIndex: 5000
                       
                    }}
                    onPress={() => setVisible(false)}>
                        <CloseIcon
                    style={{
                        fontSize: responsiveTextSmall,
                        color: theme.backgroundColor,
                        backgroundColor: theme.backgroundColor2,
                        borderRadius: 5,
                        
                    }}
                />
                    </Pressable>
            

                <DndProvider backend={HTML5Backend}>

                    <View style={graphPanel}>
                        <CanvasContainer
                            theme={theme}
                            agg_result={agg_result}
                            dateLabels={dateLabels}
                            uniqueBrands={uniqueBrands}
                            uniqueChannels={uniqueChannels}
                            uniqueTypes={uniqueTypes}
                            cubeTable={cubeTable}
                            currentItem={currentItem}
                            dashConfigs={dashConfigs}
                            filters={filters}
                            setFilters={setFilters}
                            updateHandler={updateHandler}
                            setUpdateFilters={setUpdateFilters}
                            setDashConfigs={setDashConfigs}
                            startDateString={startDateString}
                            yesterdayProp={yesterdayProp}
                            setCurrentItem={setCurrentItem}
                            setViewerVisible={setViewerVisible}
                            currentMetrics={currentMetrics}
                        />


                    </View>
                    
                </DndProvider>
            

            </View>


        </Pressable>


        </Modal>)
    }

export default DashEditorModify

const styles = StyleSheet.create({
    modalParent: {
        padding: 20,
        borderRadius: 0,
    },
    modalContainer: {

        width: windowWidth * 1,
        height: windowHeight * 1,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalWindow: {
        marginTop: '0%',
        height: '100%',
        width: '100%',
        borderColor: 'white',
        borderWidth: 1,
        borderRadius: 0,
        padding: '1%',
        alignItems: 'center',
        justiftContent: 'center',

        }

})