export default function ParseDimensions(dimData, table) {

    if (!dimData || dimData.length === undefined) {
        return null;
    }

    const brand = `${table}.brand`;
    const channel = `${table}.channel`;
    const kw_type = `${table}.kw_type`;

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
      };

    const uniqueBrands = getUniqueValues(dimData, brand)
    const uniqueChannels = getUniqueValues(dimData, channel)
    const uniqueTypes = getUniqueValues(dimData, kw_type)

    return {
        uniqueBrands,
        uniqueChannels,
        uniqueTypes
    }


}