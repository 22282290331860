import React, { useState, useEffect } from "react";
import { View, Text, Dimensions, Pressable } from 'react-native'
import ControlGraph from "../ReactDnD/DndCanvas/ControlGraph";
import GridLayout from "react-grid-layout";

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const DashEditorGrid = (props) => {

  const currentMetrics = props.currentMetrics  
  const config = props.currentItem.gridLayout
    const cubeTable = props.cubeTable
    const chartLayout = props.currentItem.gridChartLayout
    const agg_result = props.agg_result
    const dateLabels = props.dateLabels
    const uniqueBrands = props.uniqueBrands
    const uniqueChannels = props.uniqueChannels
    const uniqueTypes = props.uniqueTypes

    const theme = props.theme

    const container = {
        height: '100%',
        width: windowWidth,
        borderColor: 'red',
        borderWidth: 0
    }

    function sizeHandler (size) {
  
        let responseRow;
      
        if (windowWidth > 2000) {
          responseRow = 1.2
        } else if (windowWidth > 1400) {
          responseRow = 0.7
        } else if (windowWidth <= 1400) {
          responseRow = 0.3
        }else { responseRow = 0.5 }
      
        return parseInt(size * responseRow)
      
        }


    const containerText = {
        color: theme.color,
        fontFamily: 'Electrolize',
        fontSize: 25,
        textAlign: 'center',

      }

      return (
        <View style={container}>
        <GridLayout
          className="layout"
          style={{width: '100%', height: windowHeight, backgroundColor: theme.backgroundColor, borderRadius: 1}}
          layout={config}
          cols={12}
          rowHeight={sizeHandler(39)}
          width={windowWidth * 1}
        >
          {chartLayout.map((item, index) => (
            <div key={item.i} style={{borderRadius: 10,  backgroundColor: theme.backgroundColor, borderColor: theme.color, borderWidth: 1}}>
              <View style={{height: '100%', 
                            borderColor: 'red', 
                            borderWidth: 0,
                            alignItems: 'center',
                            justifyContent: 'center'}}>
                <ControlGraph
                  theme={theme}
                  currentConfig={item}
                  mainAgg={agg_result}
                  dateLabels={dateLabels}
                  uniqueBrands={uniqueBrands}
                  uniqueChannels={uniqueChannels}
                  uniqueTypes={uniqueTypes}
                  cubeTable={cubeTable}
                  currentMetrics={currentMetrics}
                />
              </View>
            </div>
            
          ))}
        </GridLayout>
        </View>
    )

}

export default DashEditorGrid