export default function ParseHome(table) {

    return {
        "measures": [
            `${table}.cpa_diff_dod`,
            `${table}.cpa_diff_mom`,
            `${table}.spend_diff_dod`,
            `${table}.spend_diff_mom`,
            `${table}.ftds_diff_dod`,
            `${table}.ftds_diff_mom`,
        ],
        "dimensions": [
            `${table}.brand`,
            `${table}.channel`,
            `${table}.kw_type`,
        ],
        "limit": 1000,
    }

}