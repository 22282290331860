import React, { useState } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Pressable,
    Text
} from 'react-native'
import Switch from '@mui/material/Switch';
import CumualtiveDataGrid from './CumulativeDataGrid';



const windowWidth = Dimensions.get('window').width;


const CumulativeTable = (props) => {

    const theme = props.theme
    const dynamicData = props.dynamicData
    const setIsCumulative = props.setIsCumulative
    const metricList = props.metricList
    const currentMetrics = props.currentMetrics
    const dateLabels = props.dateLabels
    const client = props.client
    const isCumulative = props.isCumulative
    const totalArray = props.totalArray
    const [checked, setChecked] = React.useState(true);

    const handleChange = (event) => {
        setChecked(event.target.checked);
        setIsCumulative(event.target.checked)
      };

    const headerToggleText = {
        fontFamily: 'Electrolize',
        fontSize: windowWidth * 0.0075,
        fontWeight: 'bold',
        color: theme.color
    }

    let topValue, rightValue;

      if (windowWidth > 1800) {
          topValue = "0%"
          rightValue = "0%"
      } else {
          topValue = "0%"
          rightValue = "1%"
      }

      const headerRow = {
        width: '100%',
        height: '6%',
        borderWidth: 0,
        borderColor: 'grey',
        flexDirection: 'row',
        justifyContent: 'right',
        position: 'absolute',
        right: rightValue,
        top: topValue,
        zIndex: 100000,
    }

      

    return (
        <View style={styles.container}>
            <View style={headerRow}>
                <View style={styles.headerToggle}>
                    <View style={styles.headerToggleText}>
                        <Text style={headerToggleText}>
                            Cumulative:
                        </Text>
                    </View>
                    <View style={styles.headerToggleContainer}>
                    <Switch
                        checked={checked}
                        onChange={handleChange}
                        sx={{
                            color: 'red'
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                        />

                    </View>


                </View>


            </View>
            <View style={styles.graphRow}>
                        <CumualtiveDataGrid
                            currentMetrics={currentMetrics}
                            theme={theme}
                            dataProp={dynamicData}
                            totalArray={totalArray}
                            isCumulative={isCumulative}
                        />


            </View>


        </View>
    )

}

export default CumulativeTable

const styles = StyleSheet.create({
    container: {
        
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
        
    },
    headerRow: {
        width: '100%',
        height: '4%',
        borderWidth: 0,
        borderColor: 'grey',
        flexDirection: 'row',
        justifyContent: 'right',
        borderWidth: 0,
        borderColor: 'grey'
    },
    graphRow: {
        width: '100%',
        height: '96%',
        borderWidth: 0,
        borderColor: 'grey'
    },
    headerToggle: {
        width: '10%',
        height: '100%',
        borderWidth: 0,
        borderColor: 'yellow',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row'
    },
    headerToggleContainer: {
        height: '100%',
        width: '40%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headerToggleText: {
        height: '100%',
        width: '60%',
        borderWidth: 0,
        borderColor: 'red',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headerMetric: {
        width: '10%',
        height: '100%',
        borderWidth: 0,
        borderColor: 'yellow',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0.5%'
    },

})