export default function StatFunctionsMovAv(data, windowSize) {
    const firstObject = data[0];
    const firstObjectKeys = Object.keys(firstObject);
    const excludeFields = ['date'];

    const metricList = firstObjectKeys.filter((item) => {
        return !excludeFields.includes(item); // Exclude non-numeric fields
    });

    const result = data.map((entry, index, arr) => {
        // Skip calculating MA until we have enough data points
        if (index < windowSize - 1) {
            return {
                date: entry.date, // Keep the current date
                ...metricList.reduce((acc, key) => {
                    acc[key] = null;  // Not enough data points for MA yet
                    return acc;
                }, {})
            };
        }

        // Determine the start of the window for the moving average
        const start = index - windowSize + 1;
        const windowElements = arr.slice(start, index + 1);  // The window of data points

        // Calculate the moving average for each required key
        const averages = {
            date: entry.date // Keep the current date
        };

        metricList.forEach(key => {
            const sum = windowElements.reduce((acc, curr) => acc + curr[key], 0);
            averages[key] = sum / windowElements.length; // Average for the window
        });

        return averages;
    });

    return result;
}
