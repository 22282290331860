import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Pressable,
    Text
} from 'react-native'
import FiltersEnhancedSingle from '../ComponentsEnhanced/FiltersEnhancedSingle';
import CumulativeGraph from './CumulativeGraph';
import CumulativeGraphMixed from './CumulativeGraphMixed';
import CumulativeTable from './CumulativeTable';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import WebFont from 'webfontloader';
import TableChartIcon from '@mui/icons-material/TableChart';

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

const CumulativePanels = (props) => {

    const client = props.client
    const agg_daily_result = props.agg_daily
    const agg_brand_result = props.agg_brand
    const dataProp = props.dataProp
    const uniqueFilter1 = dataProp.uniqueFilter1
    const uniqueFilter2 = dataProp.uniqueFilter2
    const uniqueFilter3 = dataProp.uniqueFilter3
    const currentMetrics = props.currentMetrics
    const filterParams = props.filterParams
    const dateLabels = props.dateLabels
    const currentDataBase = props.agg_daily
    const timeFrame = props.timeFrame
    const [selectedComponent, setSelectedComponent] = useState("Row2_Mixed1");
    const theme = props.theme
    const handleStateChange = props.handleStateChange
    const propObject = props.propObject
    const barBrands = props.barBrands
    const filterList = props.filterList
    const setFilterList = props.setFilterList
    const lastUpdated = props.lastUpdated
    const [isCumulative, setIsCumulative] = useState(true)
    const [panelContent, setPanelContent] = useState('graph')

    const calcCumulative = (data) => {
        let cumuImps = 0, cumuClicks = 0, cumuSpend = 0, cumuRegs = 0, cumuFtds = 0, cumuNgr = 0;

        return data.map(item => {
            cumuImps += item.impressions;
            cumuClicks += item.clicks;
            cumuSpend += item.spend;
            cumuRegs += item.regs;
            cumuFtds += item.ftds;
            cumuNgr += item.ngr;

            return {
                date: item.date,
                impressions: cumuImps,
                clicks: cumuClicks,
                spend: cumuSpend,
                regs: cumuRegs,
                ftds: cumuFtds,
                ngr: cumuNgr,
                cpc: cumuImps > 0 ? cumuClicks / cumuImps : 0,
                cpa: cumuFtds > 0 ? cumuSpend / cumuFtds : 0,
                roas: cumuSpend > 0 ? cumuNgr / cumuSpend : 0
            }
        })
    }

    const currentDataCalc = (data) => {
        return data.map(item => {
          return {
            date: item.date,
            impressions: item.impressions,
            clicks: item.clicks,
            spend: item.spend,
            regs: item.regs,
            ftds: item.ftds,
            ngr: item.ngr,
            cpc: item.cpc,
            cpa: item.cpa,
            roas: item.spend > 0 ? item.ngr / item.spend : 0
          }  
        })
    }

    const currentData = currentDataCalc(currentDataBase)

    const cumuData = calcCumulative(currentData)

    const metricList = Object.keys(cumuData[0])
    

    let dynamicData;

    if (isCumulative) {
        dynamicData = cumuData
    } else {
        dynamicData = currentData
    }

    const cumuTotal = cumuData[cumuData.length -1]
    const totalArray = {
        impressions: cumuTotal.impressions,
        clicks: cumuTotal.clicks,
        spend: cumuTotal.spend,
        regs: cumuTotal.regs,
        ftds: cumuTotal.ftds,
        ngr: cumuTotal.ngr,
        cpc: cumuTotal.cpc,
        cpa: cumuTotal.cpa,
        roas: cumuTotal.spend > 0 ? cumuTotal.ngr / cumuTotal.spend : 0
    }
    

    


    const handleComponentClick = (componentName) => {
        setSelectedComponent(componentName);
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    const renderComponentContent = () => {
        if (panelContent === 'graph') {
            return <CumulativeGraph
                theme={theme}
                dynamicData={dynamicData}
                setIsCumulative={setIsCumulative}
                metricList={metricList}
                currentMetrics={currentMetrics}
                client={client}
                dateLabels={dateLabels}
            />;
        } else if (panelContent === 'graph_mixed') {
            return <CumulativeGraphMixed
                theme={theme}
                dynamicData={dynamicData}
                setIsCumulative={setIsCumulative}
                metricList={metricList}
                currentMetrics={currentMetrics}
                client={client}
                dateLabels={dateLabels}
            />;
        } else if (panelContent === 'table') {
            return <CumulativeTable
                theme={theme}
                dynamicData={dynamicData}
                setIsCumulative={setIsCumulative}
                metricList={metricList}
                currentMetrics={currentMetrics}
                client={client}
                dateLabels={dateLabels}
                totalArray={totalArray}
                isCumulative={isCumulative}
            />;
        } else {
            return null;
        }
    };


    const panelMain = {

        width: '100%',
        height: windowHeight * 0.1,
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 40,
        fontFamily: 'Electrolize',     

        
    }

    const panelChild = {
        flex: 1,
        height: '100%',
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 20,
        fontFamily: 'Electrolize',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2))',
    }

    const lineChart = {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
    }

    const chartPanel = {
        width: '15%',
        height: windowHeight * 0.83,
        borderColor: 'blue',
        borderWidth: 0,
        flexDirection: 'column'
    }

    const chartChild = {
        width: '100%',
        height: '20%',
        borderColor: 'grey',
        borderWidth: 1,
        padding: '1%',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2))',
        flexDirection: 'column'
    }

    const chartChildContentSelected = {
        width: '100%',
        height: '80%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.backgroundColor2,
        color: 'white',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2))',
    }

    const chartChildContent = {
        width: '100%',
        height: '80%',
        alignItems: 'center',
        justifyContent: 'center',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2))',
    }

    const iconStyleSelected = {
        color: theme.backgroundColor,
        fontSize: windowWidth * 0.06
    }

    const iconStyle = {
        color: theme.backgroundColor2,
        fontSize: windowWidth * 0.06
    }

    const chartChildTitleSelected = {
        width: '100%',
        height: '20%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.backgroundColor2,
    }

    const chartChildTitle = {
        width: '100%',
        height: '20%',
        alignItems: 'center',
        justifyContent: 'center',
    }

    const titleTextSelected = {
        fontFamily: 'Electrolize',
        color: 'white',
        textTransform: 'capitalize'
    }

    const titleText = {
        fontFamily: 'Electrolize',
        color: theme.color,
        textTransform: 'capitalize'
    }

    const iconStyleDisabled = {
        color: 'grey',
        fontSize: windowWidth * 0.06
    }

    


   
    return (
        <View style={styles.container}>
            <View style={{ flexDirection: 'column' }}>
                <View style={panelMain}>
                    <View style={{ width: '100%', flexDirection: 'flex-start', fontFamily: 'Electrolize' }}>
                        <FiltersEnhancedSingle
                            style={{
                                height: '10%',
                                borderColor: 'red',
                                borderWidth: 0,
                                width: '100%',
                                fontFamily: 'Electrolize'
                            }}
                            uniqueFilter1={uniqueFilter1}
                            uniqueFilter2={uniqueFilter2}
                            uniqueFilter3={uniqueFilter3}
                            handleStateChange={handleStateChange}
                            propObject={propObject}
                            filterParams={filterParams}
                            theme={theme}
                            setFilterList={setFilterList}
                            filterList={filterList}
                            currentMetrics={currentMetrics}
                            large={1}
                        />
                    </View>

                    
                </View>
                <View style={{width: '100%', 'height': windowHeight * 0.83, flexDirection: 'row'}}>
                    <View style={{width: '85%', height: windowHeight * 0.83}}>
                        {renderComponentContent()}

                    </View>
                <View style={chartPanel}>
                        <View style={chartChild}>
                            <Pressable
                                onPress={() => setPanelContent('graph')}
                                style={panelContent === 'graph' ? chartChildContentSelected : chartChildContent}>
                                <ShowChartIcon style={panelContent === 'graph' ? iconStyleSelected : iconStyle} />
                            </Pressable>
                            <View style={panelContent === 'graph' ? chartChildTitleSelected : chartChildTitle}>
                                <Text style={panelContent === 'graph' ? titleTextSelected : titleText}>
                                    Graph - Single
                                </Text>
                            </View>
                        </View>
                        <View style={chartChild}>
                            <Pressable
                                onPress={() => setPanelContent('graph_mixed')}
                                style={panelContent === 'graph_mixed' ? chartChildContentSelected : chartChildContent}>
                                <BarChartIcon style={panelContent === 'graph_mixed' ? iconStyleSelected : iconStyle} />
                            </Pressable>
                            <View style={panelContent === 'graph_mixed' ? chartChildTitleSelected : chartChildTitle}>
                            <Text style={panelContent === 'graph_mixed' ? titleTextSelected : titleText}>
                                    Graph - Mixed
                                </Text>
                            </View>
                        </View>
                        <View style={chartChild}>
                            <Pressable
                                onPress={() => setPanelContent('table')}
                                style={panelContent === 'table' ? chartChildContentSelected : chartChildContent}>
                                <TableChartIcon style={panelContent === 'table' ? iconStyleSelected : iconStyle} />
                            </Pressable>
                            <View style={panelContent === 'table' ? chartChildTitleSelected : chartChildTitle}>
                            <Text style={panelContent === 'table' ? titleTextSelected : titleText}>
                                Table
                                </Text>
                            </View>
                        </View>
                        <View style={chartChild}>
                            <View
                                //onPress={() => setPanelContent('summary')}
                                style={chartChildContent}>
                                <TableChartIcon style={iconStyleDisabled} />
                            </View>
                            <View style={chartChildTitle}>
                                <Text style={titleText}>

                                </Text>
                            </View>
                        </View>
                        <View style={chartChild}>
                            <View
                                //onPress={() => setPanelContent('summary')}
                                style={chartChildContent}>
                                <TableChartIcon style={iconStyleDisabled} />
                            </View>
                            <View style={chartChildTitle}>
                                <Text style={titleText}>

                                </Text>
                            </View>
                        </View>
                        </View>



                    </View>
 
            </View>

        </View>

    )

}

export default CumulativePanels

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: windowHeight * 0.93,
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',


    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize',
        
    }
})