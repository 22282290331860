import React, { useState } from 'react';
import {
    View,
    StyleSheet,
    Dimensions
} from 'react-native'
import FiltersDate from './FiltersDate';
import FiltersDynamicMulti from './FiltersDynamicMulti';
import FilterAutoComplete from './FilterAutoComplete';

const FiltersEnhancedSingle = (props) => {

    const uniqueFilter1 = props.uniqueFilter1
    const uniqueFilter2 = props.uniqueFilter2
    const uniqueFilter3 = props.uniqueFilter3
    const largeProp = props.large
    const type = props.type
    const propObject = props.propObject
    const handleStateChange = props.handleStateChange
    const typeFilterState = props.typeFilterState !== undefined ? props.typeFilterState : false;
    const brandFilterState = props.brandFilterState !== undefined ? props.brandFilterState : false;
    const channelFilterState = props.channelFilterState !== undefined ? props.channelFilterState : false;
    const theme = props.theme
    const filterParams = props.filterParams
    const currentChannel = props.currentChannel
    const currentMetrics = props.currentMetrics
    const [currentFilterValue, setCurrentValue] = useState(currentChannel)
    const [currentFilter2Value, setCurrent2Value] = useState(currentChannel)
    const [currentFilter3Value, setCurrent3Value] = useState(currentChannel)
    const filter1name = filterParams.filter1name
    const filter2name = filterParams.filter2name
    const filter3name = filterParams.filter3name
    const setFilter1List = filterParams.setFilter1List
    const setFilter2List = filterParams.setFilter2List
    const setFilter3List = filterParams.setFilter3List
    const filterList = props.filterList
    const setFilterValueList = props.setFilterList

    const large = largeProp ? true : false;

    return (


        <View style={styles.container}>
            <View style={styles.filterContainer}>
                <FiltersDynamicMulti
                    listValues={uniqueFilter1}
                    propObject={propObject}
                    handleStateChange={handleStateChange}
                    theme={theme}
                    brandFilterState={brandFilterState}
                    large={large}
                    type={filter1name}
                    filterParams={filterParams}
                    setList={setFilter1List}
                    singleModel={true}
                    currentValue={currentChannel}
                    currentFilterValue={currentFilterValue}
                    setCurrentValue={setCurrentValue}
                    filterIndex={0}
                    setFilterList={setFilterValueList}
                    filterList={filterList}
                    currentMetrics={currentMetrics}
                />
            </View>
            <View style={styles.filterContainer}>
                <FiltersDynamicMulti
                        listValues={uniqueFilter2}
                        propObject={propObject}
                        handleStateChange={handleStateChange}
                        theme={theme}
                        brandFilterState={brandFilterState}
                        large={large}
                        type={filter2name}
                        filterParams={filterParams}
                        setList={setFilter2List}
                        singleModel={true}
                        currentValue={currentChannel}
                        currentFilterValue={currentFilter2Value}
                        setCurrentValue={setCurrent2Value}
                        filterIndex={1}
                        setFilterList={setFilterValueList}
                        filterList={filterList}
                        currentMetrics={currentMetrics}
                    />
            </View>
            <View style={styles.filterContainer}>
                <FilterAutoComplete
                        listValues={uniqueFilter3}
                        propObject={propObject}
                        handleStateChange={handleStateChange}
                        theme={theme}
                        brandFilterState={brandFilterState}
                        large={large}
                        type={filter3name}
                        filterParams={filterParams}
                        setList={setFilter3List}
                        singleModel={true}
                        currentValue={currentChannel}
                        currentFilterValue={currentFilter3Value}
                        setCurrentValue={setCurrent3Value}
                        filterIndex={2}
                        setFilterList={setFilterValueList}
                        filterList={filterList}
                        currentMetrics={currentMetrics}

                    />
            </View>
            

            <View style={styles.dateFilterContainer}>
                <FiltersDate
                    propObject={propObject}
                    handleStateChange={handleStateChange}
                    theme={theme}
                    large={large}
                    type={type}
                />
            </View>
        </View>


    )

}

export default FiltersEnhancedSingle

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        marginTop: '0.5%'

    },
    filterContainer: {
        width: '25%',
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 0,
        borderColor: 'blue',
        paddingLeft: '0.5%',
    },
    dateFilterContainer: {

        borderWidth: 0,
        borderColor: 'red',
        width: '25%',
        paddingLeft: '0.5%',
        alignItems: 'center',
        justifyContent: 'center',


    }
})