export default function Dimensions_2 (table, start, end, dim1, dim2) {

    return {
        "order": {
            [`${table}.cost`]: "desc",
        },
        "limit": 1000,
        "dimensions": [
        `${table}.${dim1}`,
        `${table}.${dim2}`,
        `${table}.updated_at`
        ],
        "timeDimensions": [
          {
            "dimension": `${table}.timestamp`,
            "dateRange": [
              start,
              end
          ]
          }
        ]
      }


}