import React, { useState, useEffect } from "react";
import { View, Text, Dimensions, Pressable } from 'react-native'
import GridLayout from "react-grid-layout";
import MetricContainer from "./MetricContainer";
import TuneIcon from '@mui/icons-material/Tune';
import ControlGraph from "./ControlGraph";
import ControlModal from "./ControlModal";
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import DashEditorSettings from "../../DashEditor/DashEditorSettings";
import Fab from '@mui/material/Fab';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Tooltip from '@mui/material/Tooltip';
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./styles.css";

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
const responsiveTextSmall = windowWidth * 0.007

const CanvasContainer = (props) => {

  const currentMetrics = props.currentMetrics
  const theme  = props.theme
  const cubeTable = props.cubeTable
  const agg_result = props.agg_result
  const dateLabels = props.dateLabels
  const uniqueBrands = props.uniqueBrands
  const uniqueChannels = props.uniqueChannels
  const uniqueTypes = props.uniqueTypes
  const currentItem = props.currentItem
  const setCurrentItem = props.setCurrentItem
  const dashConfigs = props.dashConfigs
  const setDashConfigs = props.setDashConfigs
  const filters = props.filters
  const setFilters = props.setFilters
  const updateHandler = props.updateHandler
  const setUpdateFilters = props.setUpdateFilters
  const startDateString = props.startDateString
  const yesterdayProp = props.yesterdayProp
  const setViewerVisible = props.setViewerVisible
  const [controlVisible, setControlVisible] = useState(false)
  const [settingsVisible, setSettingsVisible] = useState(false)
  const [currentConfig, setCurrentConfig] = useState(null)
  const [currentType, setCurrentType] = useState(null)
  const [visibleModal, setVisible] = useState(false)
  const [currentChild, setCurrentChild] = useState(false)
  const parentName = currentItem['gridConfig'];
  const childLayoutName = currentItem['gridLayout']
  const childChartLayoutName = currentItem['gridChartLayout']
  const dashIndex = parseInt(parentName.replace('gridConfig', '')) -1

  function dndHandler (prop) {

    const configsCopy = dashConfigs
    configsCopy[dashIndex][0]['gridLayout'] = layout
    configsCopy[dashIndex][0]['gridChartLayout'] = chartLayout
    
   //localStorage.setItem("gridChartlayout1", JSON.stringify(dashConfigs));
    setVisible(false)
  }

  function sizeHandler (size) {
  
  let responseRow;

  if (windowWidth > 2000) {
    responseRow = 1
  } else if (windowWidth > 1400) {
    responseRow = 0.7
  } else if (windowWidth <= 1400) {
    responseRow = 0.3
  }else { responseRow = 0.5 }

  return parseInt(size * responseRow)

  }

  function configHandler(item, type) {

    setCurrentConfig(item)
    setCurrentType(type)
    setVisible(true)
    //setControlVisible(true)

  }

  function settingsHandler (item, index) {
    setCurrentChild(index)
    setSettingsVisible(true)
  }

  const getStoredLayout = () => {
    const storedConfig = localStorage.getItem("gridConfig");
    const storedLayout = JSON.parse(storedConfig)
    const currentItem = storedLayout[dashIndex][0]['gridLayout']
    return currentItem !== undefined ? currentItem : null;
  };

  const getStoredChartLayout = () => {
    const storedConfig = localStorage.getItem("gridConfig");
    const storedLayout = JSON.parse(storedConfig)
    const currentItem = storedLayout[dashIndex][0]['gridChartLayout']
    return currentItem !== undefined ? currentItem : null;
  };

  const initialLayoutStorage = dashConfigs
  const initalLayout = initialLayoutStorage[dashIndex][0]['gridLayout']


  let [layout, setLayout] = useState(initalLayout);

  const initialChartLayout = initialLayoutStorage[dashIndex][0]['gridChartLayout']


  const [chartLayout, setChartLayout] = useState(initialChartLayout)

  let [key, setKey] = useState(3);
  
  //useEffect(() => {
    //console.log("test");
  //}, [chartLayout]);



  const layoutChange = (layout) => {
    let result = [];

// Iterate through the 'layoutArray'
    for (let i = 0; i < layout.length; i++) {
        // Get the current item from the 'layoutArray'
        let currentItem = layout[i];
        // Find the corresponding item in the 'typeArray' based on the 'i' key
        let correspondingItem = chartLayout.find(item => item.i === currentItem.i);

        // If a corresponding item is found, extract the required values and push them to the result array
        if (correspondingItem) {
            let iValue = currentItem.i
            let xValue = currentItem.x
            let yValue = currentItem.y
            let hValue = currentItem.h
            let maxhValue = currentItem.maxH
            let wValue = currentItem.w; // Get the 'w' value from the 'layoutArray'
            let typeValue = correspondingItem.type; // Get the 'type' value from the 'typeArray'
            let xAxisValue = correspondingItem.xAxis
            let timeValue = correspondingItem.time
            let metric1Value = correspondingItem.metric1
            let metric2Value = correspondingItem.metric2
            let startValue = correspondingItem.start
            let endValue = correspondingItem.end
            let currentFilters = correspondingItem.currentGraphFilters

            // Push the extracted values to the result array
            result.push({ i: iValue, 
                          x: xValue, 
                          y: yValue, 
                          h: hValue, 
                          maxH: maxhValue, 
                          w: wValue, 
                          type: typeValue, 
                          xAxis: xAxisValue, 
                          time: timeValue, 
                          metric1: metric1Value, 
                          metric2: metric2Value,
                          start: startValue,
                          end: endValue,
                          currentGraphFilters: currentFilters  });
        }
    }

    const layoutUpdate = [...layout]
    setLayout(layoutUpdate)
    setChartLayout(result)
    const configCopy = dashConfigs
    configCopy[dashIndex][0]['gridLayout'] = layoutUpdate
    configCopy[dashIndex][0]['gridChartLayout'] = result
    localStorage.setItem("gridConfig", JSON.stringify(configCopy));
    setDashConfigs(configCopy)

    const currentItemBase = {
      gridConfig: configCopy[dashIndex][0]['gridConfig'],
      gridLayout: configCopy[dashIndex][0]['gridLayout'],
      gridChartLayout: configCopy[dashIndex][0]['gridChartLayout'] ,
      reportName: configCopy[dashIndex][0]['reportName'] 
    }
    setCurrentItem(currentItemBase)
  };

  const storedConfig = localStorage.getItem("gridConfig");
  const storedLayout = JSON.parse(storedConfig)
  const storedChartLayout = storedLayout[dashIndex][0]['size']

  const dropHandler = (layout, layoutItem, e) => {
    const chartType = e.dataTransfer.getData("chartType");
    const chartWidth = parseInt(e.dataTransfer.getData("width"));
    const chartHeight = parseInt(e.dataTransfer.getData("height"));

    const storedConfig = localStorage.getItem("gridConfig");
    const storedLayout = JSON.parse(storedConfig)
    const storedSize = storedLayout[dashIndex][0]['size']
    
    const filteredLayout = layout.filter(item => item.i !== "__dropping-elem__");
    const updatedLayout = [...filteredLayout];
    const layoutSize = updatedLayout.length
    const itemKey = 'item' + (storedSize+1)
    let item = { i: itemKey, x: layoutItem.x, y: layoutItem.y, w: chartWidth, h: chartHeight, maxH: 22, type: chartType, xAxis: 'date', time: 'daily', metric1: 'spend', metric2: 'cpa', start: startDateString, end: yesterdayProp, currentGraphFilters : {brand: 'Total', channel: 'Total', kw_type: 'Total', start: startDateString, end: yesterdayProp}};
    
    updatedLayout.push(item);
    setLayout(updatedLayout);    

    const updatedChartLayout = [...chartLayout];
    let chartItem = {i: itemKey, x: layoutItem.x, y: layoutItem.y, w: chartWidth, h: chartHeight, maxH: 22, type: chartType, xAxis: 'date', time: 'daily', metric1: 'spend', metric2: 'cpa', start: startDateString, end: yesterdayProp, currentGraphFilters : {brand: 'Total', channel: 'Total', kw_type: 'Total', start: startDateString, end: yesterdayProp}}
    updatedChartLayout.push(chartItem)

    dropDataHandler(updatedChartLayout)
    const configCopy = JSON.parse(localStorage.getItem("gridConfig"))
    
    configCopy[dashIndex][0]['gridLayout'] = updatedLayout
    configCopy[dashIndex][0]['gridChartLayout'] = updatedChartLayout
    configCopy[dashIndex][0]['size'] = storedSize +1
    localStorage.setItem("gridConfig", JSON.stringify(configCopy));
    setDashConfigs(configCopy)
    const currentItemBase = {
      gridConfig: configCopy[dashIndex][0]['gridConfig'],
      gridLayout: configCopy[dashIndex][0]['gridLayout'],
      gridChartLayout: configCopy[dashIndex][0]['gridChartLayout'] ,
      reportName: configCopy[dashIndex][0]['reportName'] 
    }
    setCurrentItem(currentItemBase)
};


  function dropDataHandler (updatedChartLayout) {
    setChartLayout(updatedChartLayout)

  }

  const removeItemFromLayout = (keyToRemove) => {
    // Filter out the item with the specified index
    const updatedLayout = layout.filter((item, index) => index !== keyToRemove);
    const updatedChartLayout = chartLayout.filter((item, index) => index !== keyToRemove);

    // Update the layout state
    setLayout(updatedLayout);
    setChartLayout(updatedChartLayout);
};


  const save = () => {

    const configCopy = dashConfigs
    configCopy[dashIndex][0]['gridLayout'] = layout
    configCopy[dashIndex][0]['gridChartLayout'] = chartLayout
    localStorage.setItem("gridConfig", JSON.stringify(configCopy));
    setDashConfigs(configCopy)

    const currentItemBase = {
      gridConfig: configCopy[dashIndex][0]['gridConfig'],
      gridLayout: configCopy[dashIndex][0]['gridLayout'],
      gridChartLayout: configCopy[dashIndex][0]['gridChartLayout'] ,
      reportName: configCopy[dashIndex][0]['reportName'] 
    }
    setCurrentItem(currentItemBase)
    setViewerVisible(false)
    
  };

  let responseText;

  if (windowWidth > 2000) {
      responseText = 25
    } else if (windowWidth > 1400) {
      responseText = 20
    } else { responseText = 15 }


  const draggableStyle1 = {
    width: '100%',
    height: '100%',
    backgroundColor: theme.backgroundColor2,
    borderColor: theme.color,
    borderWidth: 2,
    borderRadius: 10,
    margin: '1%',
    alignItems: 'center',
    justifyContent: 'center'
  }

  const draggableStyle2 = {
    width: '100%',
    height: '100%',
    backgroundColor: theme.backgroundColor2,
    borderColor: theme.color,
    borderWidth: 2,
    borderRadius: 10,
    margin: '1%',
    alignItems: 'center',
    justifyContent: 'center'
  }

  const draggableText = {
    color: 'white',
    fontFamily: 'Electrolize',
    fontSize: responseText,
    textAlign: 'center',
  }

  const containerText = {
    color: theme.color,
    fontFamily: 'Electrolize',
    fontSize: 25,
    textAlign: 'center',
    paddingTop: '0%'
  }

  const container = {
    width: windowWidth,
    height: windowHeight * 0.95,
    flexDirection: 'row',
    borderColor: 'red',
    borderWidth: 0,
    padding: '1%'
  }

  const sideBar = {
    width: '15%',
    height: '100%',
    borderColor: 'grey',
    borderWidth: 1,
    padding: '0.5%',
    flexDirection: 'column'

  }

  const panel = {
    width: '85%',
    height: '100%',
    alignItems: 'center',
    borderColor: 'grey',
    borderWidth: 1,

  }

  const bottomPanel = {
    width: '100%',
    height: '20%',
    alignItems: 'center',
    borderColor: 'grey',
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row'
  }

  const button = {
    width: '100%',
    height: '60%',
    backgroundColor: theme.backgroundColor2,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '1%'
  }

  const metricContainer = {
    width: '100%',
    height: '90%',
    borderColor: 'red',
    borderWidth: 0,

  }

  const buttonContainer = {
    width: '100%',
    height: '10%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

  }

  return (
    <View style={container}>
      <View style={sideBar}>
        <View style={metricContainer}>
          <MetricContainer
            styleProp={draggableStyle1}
            textProp={draggableText}
            textValue={'Metric'}
            widthValue={sizeHandler(3)}
            heightValue={sizeHandler(2)}
            itemSize={'Small'}
            currentMetrics={currentMetrics}
          />
          <MetricContainer
            styleProp={draggableStyle1}
            textProp={draggableText}
            textValue={'Space'}
            widthValue={sizeHandler(3)}
            heightValue={sizeHandler(2)}
            itemSize={'Small'}
            currentMetrics={currentMetrics}
          />
          <MetricContainer
            styleProp={draggableStyle2}
            textProp={draggableText}
            textValue={'Bar'}
            widthValue={sizeHandler(5)}
            heightValue={sizeHandler(4)}
            itemSize={'Medium'}
            currentMetrics={currentMetrics}
          />
          <MetricContainer
            styleProp={draggableStyle2}
            textProp={draggableText}
            textValue={'Line'}
            widthValue={sizeHandler(5)}
            heightValue={sizeHandler(4)}
            itemSize={'Medium'}
            currentMetrics={currentMetrics}
          />
          <MetricContainer
            styleProp={draggableStyle2}
            textProp={draggableText}
            textValue={'Mixed'}
            widthValue={sizeHandler(5)}
            heightValue={sizeHandler(4)}
            itemSize={'Medium'}
            currentMetrics={currentMetrics}
          />
          <MetricContainer
            styleProp={draggableStyle2}
            textProp={draggableText}
            textValue={'Table'}
            widthValue={sizeHandler(4)}
            heightValue={sizeHandler(5)}
            itemSize={'Medium'}
            currentMetrics={currentMetrics}
          />


          </View>
          <View style={buttonContainer}>
          <View
            style={{
                borderWidth: 0,
                borderColor: theme.color,
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                padding: '1%',
                height: '80%'
            }}
        >
            <Fab variant="extended"
                onClick={() => { save()}}
                sx={{
                    color: 'white',
                    backgroundColor: theme.backgroundColor2,
                    fontSize: responsiveTextSmall,
                    width: '100%'
                }}>
                <SaveAltIcon sx={{ mr: 1, fontSize: responsiveTextSmall}} />
                Save Report
            </Fab>
        </View>

            
          </View>
      </View>
      <View style={panel}>
        <GridLayout
          className="layout"
          style={{width: '100%', height: windowHeight *0.92, backgroundColor: theme.color+50, border: `2px solid ${theme.color}`, borderRadius: 0}}
          layout={layout}
          onLayoutChange={layoutChange}
          onDrop={dropHandler}
          isDroppable={true}
          cols={12}
          rowHeight={sizeHandler(39)}
          width={windowWidth * 0.825}
          currentMetrics={currentMetrics}
        >
          {chartLayout.map((item, index) => (
            <div key={item.i} style={{borderRadius: 10,  backgroundColor: theme.backgroundColor, borderColor: theme.color, borderWidth: 1}}>
              <View style={{height: '10%', alignItems: 'center', justifyContent: 'center', alignItems: 'center'}}>
                <Text style={containerText}>
                  {item.type}
                </Text>
                

              </View>
              <View style={{
                width: 100,
                height: '10%',
                position: 'absolute', 
                left: '1%',
                top: '0%',
                zIndex: 5000,
                flexDirection: 'row',
                borderColor: 'red',
                borderWidth: 0
              }}>
              
              <Pressable 
                    onPress={() => configHandler(index, item.type)}
                    style={{
                    width: '33.333333%', 
                    height: 50, 
                    //position: 'absolute', 
                    //left: '1%',
                    //top: '0%',
                    borderColor: 'red',
                    borderWidth: 0,
                    }}
                    >
                  <TuneIcon style={{color: theme.color, fontSize: 20}}/>                
                </Pressable>
                <Pressable 
                    onPress={() => settingsHandler(item, index)}
                    style={{
                    width: '33.333333%', 
                    height: 50, 
                    //position: 'absolute', 
                    //left: 25,
                    //top: '0%',
                    borderColor: 'red',
                    borderWidth: 0,
                    zIndex: 5000 }}
                    >
                  <FilterListIcon style={{color: theme.color, fontSize: 20}}/>                
                </Pressable>
                <Pressable 
                    onPress={() => removeItemFromLayout(index)}
                    style={{
                    width: '33.333333%', 
                    height: 50, 
                    //position: 'absolute', 
                    //left: 50,
                    //top: '0%',
                    borderColor: 'red',
                    borderWidth: 0,
                    zIndex: 5000 }}
                    >
                  <DeleteIcon style={{color: theme.color, fontSize: 20}}/>                
                </Pressable>
                </View>
              <View style={{height: '90%', 
                            borderColor: 'red', 
                            borderWidth: 0,
                            alignItems: 'center',
                            justifyContent: 'center'}}>
                <ControlGraph
                  theme={theme}
                  currentConfig={item}
                  mainAgg={agg_result}
                  dateLabels={dateLabels}
                  uniqueBrands={uniqueBrands}
                  uniqueChannels={uniqueChannels}
                  uniqueTypes={uniqueTypes}
                  cubeTable={cubeTable}
                  currentMetrics={currentMetrics}
                />
                
              </View>
            </div>
            
          ))}
        </GridLayout>
        <ControlModal
          theme={theme}
          visibleModal={visibleModal}
          setVisible={setVisible}
          config={chartLayout}
          currentItem={currentConfig}
          dndHandler={dndHandler}
          setChartLayout={setChartLayout}
          currentMetrics={currentMetrics}
            />
        <DashEditorSettings
          theme={theme}
          settingsVisible={settingsVisible}
          setSettingsVisible={setSettingsVisible}
          uniqueBrands={uniqueBrands}
          uniqueChannels={uniqueChannels}
          uniqueTypes={uniqueTypes}
          filters={filters}
          setFilters={setFilters}
          setUpdateFilters={setUpdateFilters}
          updateHandler={updateHandler}
          dashConfigs={dashConfigs}
          setDashConfigs={setDashConfigs}
          currentItem={currentItem}
          currentChild={currentChild}
          setChartLayout={setChartLayout}
          setLayout={setLayout}
          currentMetrics={currentMetrics}
        />

      </View>
    </View>
  );
};

export default CanvasContainer;
