import React, {useEffect} from 'react';
import { View } from 'react-native'
import LineChart from '../../ChartsDesktop/LineChart';
import BarChart from '../../ChartsDesktop/BarChart';
import MixedChart from '../../ChartsDesktop/MixedChart';
import ChartsTable from './ChartsTable';

const ChartsGraphPanel = (props) => {

    const theme = props.theme
    const chartConfig = props.chartConfig
    const agg_daily = props.agg_daily
    const dateLabels = props.dateLabels
    const columnVisibilityModel = props.columnVisibilityModel
    const setColumnVisibilityModel = props.setColumnVisibilityModel
    const currentMetrics = props.currentMetrics

    const type = chartConfig.chart.toLowerCase();
    const metric1 = chartConfig.metric1.toLowerCase();
    const metric2 = chartConfig.metric2.toLowerCase();
    const time = chartConfig.time.toLowerCase();
    const axis = chartConfig.axis.toLowerCase();

    const renderComponentContent = () => {
        if (type === 'bar') {
            return <BarChart
            dataProp={agg_daily}
            labels={dateLabels}
            theme={theme}
            metric={metric1}
            timeFrame={time}
            currentMetrics={currentMetrics}
        />;
        } else if (type === 'line') {
            return <LineChart
            agg_daily_result={agg_daily}
            dateLabels={dateLabels}
            theme={theme}
            metric={metric1}
            timeFrame={time}
            currentMetrics={currentMetrics}
        />
        } else if (type === 'mixed') {
            return <MixedChart
            dataProp={agg_daily}
            labels={dateLabels}
            theme={theme}
            agg_daily_result={agg_daily}
            dateLabels={dateLabels}
            metric1={metric1}
            metric2={metric2}
            timeFrame={time}
            currentMetrics={currentMetrics}
        />
        } else if (type === 'table') {
            return <ChartsTable
            dataProp={agg_daily}
            columnWidth={0.01}
            theme={theme}
            metric1={metric1}
            metric2={metric2}
            columnVisibilityModel={columnVisibilityModel}
            setColumnVisibilityModel={setColumnVisibilityModel}
            axis={axis}
            size={8}
            currentMetrics={currentMetrics}
            />
        } else {
            return null;
        }
    };

    const container = {
        height: '83.5%',
        width: '100%',
        borderColor: theme.color,
        borderWidth: 0,
        flexDirection: 'column',
        padding: '2%',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.3))',
    }

    return (
        <View style={container}>
            {renderComponentContent()}

        </View>
    )

}

export default ChartsGraphPanel