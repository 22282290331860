import React, { useState } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Text,
    Pressable
} from 'react-native'
import DashMetricModal from '../DashMetricModal';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import Tooltip from '@mui/material/Tooltip';

const windowWidth = Dimensions.get('window').width;
const responsiveTextSmall = windowWidth * 0.01

const DashMetrics = (props) => {

    const theme = props.theme
    const currentPanel = props.currentPanel
    const mainGraphHandler = props.mainGraphHandler
    const uniqueMetrics = props.uniqueMetrics
    const uniqueDimensions = props.uniqueDimensions
    const currentReport = props.currentReport
    const panelNumber = props.panelNumber
    const reportIndex = props.reportIndex
    const dashConfig = props.dashConfig
    const currentValue = props.currentValue
    const currentLabel = props.currentLabel
    const currentMetrics = props.currentMetrics
    const [metricVisible, setMetricVisible] = useState(false)

    const responsiveTextLarge = windowWidth * 0.05
    const responsiveTextMedium = windowWidth * 0.02

    let formatLabel;

    if (currentLabel === 'ctr') {
        formatLabel = "ctr%"
    } else if (currentLabel === 'ftds') {
        formatLabel = currentMetrics.primaryMetric
    } else if (currentLabel === 'ngr') {
        formatLabel = currentMetrics.primaryRevenue
    } else if (currentLabel === 'regs') {
        formatLabel = currentMetrics.secondaryMetric
    } else {formatLabel = currentLabel}

    const typeTextLarge = {
        fontSize: responsiveTextLarge,
        color: theme.color2,
        fontFamily: 'Electrolize',
    }

    const typeTextMedium = {
        fontSize: responsiveTextMedium,
        color: theme.color,
        fontFamily: 'Electrolize',
    }

    const text = {
        color: theme.color,
        fontSize: windowWidth * 0.025,
        fontFamily: 'Electrolize',
    }

    const textSub = {
        color: theme.color,
        fontSize: windowWidth * 0.015,
        fontFamily: 'Electrolize',
    }

    function toProperCase(str) {
        return str
          .toLowerCase()
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      }

    const metricProper = toProperCase(currentLabel)

    const panelStyle = {
        borderWidth: 1,
        borderColor: theme.color,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: theme.backgroundColor,
        borderColor: theme.color
    }

    const optionsContainer = {
        width: '5%',
        height: '10%',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        left: '92%',
        top: '85%',
    }

    const optionsButton = {
        color: theme.backgroundColor2,
        fontSize: responsiveTextSmall,
    }

    return (
        <View 
            style={panelStyle}
            onPress={() => setMetricVisible(true)}
            >
            <Text style={text}>
                {currentValue}
            </Text>
            <Text style={textSub}>
                {formatLabel}
            </Text>
            <Tooltip
                    title={"Select metric"}
                    placement="top"
                    componentsProps={{
                        arrow: { sx: { color: theme.backgroundColor2, } },
                        tooltip: {
                            sx: {
                                bgcolor: theme.backgroundColor2,
                                color: "white",
                                fontFamily: 'Electrolize',
                            }
                        },
                    }}
                    arrow
                >
                <Pressable style={optionsContainer}
                    onPress={() => setMetricVisible(true)}>
                    <TuneOutlinedIcon style={optionsButton}/>
                </Pressable>
                </Tooltip>
            <DashMetricModal
                currentPanel={currentPanel}
                panelNumber={panelNumber}
                mainGraphHandler={mainGraphHandler}
                uniqueMetrics={uniqueMetrics}
                uniqueDimensions={uniqueDimensions}
                currentReport={currentReport}
                reportIndex={reportIndex}
                setVisible={setMetricVisible}
                visible={metricVisible}
                dashConfig={dashConfig}
                theme={theme}
                currentMetrics={currentMetrics}
                />

        </View>
    )

}

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'grey',
        flexDirection: 'row',
        justifyContent: 'column',
        width: '100%',
        height: '100%'
    },

})

export default DashMetrics