export default function ExecSummaryMetricsFtdParse (aggResult, brand, country) {

    var dataset = aggResult

    if (!dataset || dataset.length === undefined) {
        return null;
    }

    function filterByBrand(data, brand) {
        return data.filter(item => item.brand === brand);
    }

    function filterByCountries(data, countries) {
        return data.filter(item => countries.includes(item.country));
    }

    function filterByNull(data) {
        return data.filter(item => item.dim !== null);
    }
    


    const brandFiltered = filterByBrand(dataset, brand)
    const countryFiltered  = filterByCountries(brandFiltered, country)
    const nullFiltered = filterByNull(countryFiltered)

    const aggregatedData = nullFiltered.reduce((acc, item) => {
        const dim = item.dim;
        const minus2_day = item.minus2_day;
        const minus3_day = item.minus3_day;
        const mtd = item.mtd;
        const prevMonth = item.prevMonth;
        const prevMonthYear = item.prevMonthYear;
        const prevYTD = item.prevYTD;
        const prevWeek = item.prevWeek;
        const yesterday = item.yesterday;
        const ytd = item.ytd
        

        if (!acc[dim]) {
            acc[dim] = {
                dim: dim,
                minus2_day: parseFloat(minus2_day) || 0,
                minus3_day: parseFloat(minus3_day) || 0,
                mtd: parseFloat(mtd) || 0,
                prevMonth: parseInt(prevMonth) || 0,
                prevMonthYear: parseFloat(prevMonthYear) || 0,
                prevYTD: parseFloat(prevYTD) || 0,
                prevWeek: parseFloat(prevWeek) || 0,
                yesterday: parseFloat(yesterday) || 0,
                ytd: parseFloat(ytd) || 0,
            };
        } else {
            acc[dim].minus2_day += parseFloat(minus2_day) || 0;
            acc[dim].minus3_day += parseFloat(minus3_day) || 0;
            acc[dim].mtd += parseFloat(mtd) || 0;
            acc[dim].prevMonth += parseInt(prevMonth) || 0;
            acc[dim].prevMonthYear += parseFloat(prevMonthYear) || 0;
            acc[dim].prevYTD += parseFloat(prevYTD) || 0;
            acc[dim].prevWeek += parseFloat(prevWeek) || 0;
            acc[dim].yesterday += parseFloat(yesterday) || 0;
            acc[dim].ytd += parseFloat(ytd) || 0;
        }

        return acc;

    }, {});

    

    const flatOutput = Object.values(aggregatedData)


    let dataProp;

    dataProp = flatOutput.map((obj) => {
        const dim = obj.dim
        const minus2_day = obj.minus2_day
        const minus3_day = obj.minus3_day
        const mtd = obj.mtd
        const prevMonth = obj.prevMonth
        const prevMonthYear = obj.prevMonthYear
        const prevYTD = obj.prevYTD
        const prevWeek = obj.prevWeek
        const yesterday = obj.yesterday
        const ytd = obj.ytd
        const mom = (!prevMonth || isNaN(prevMonth) || prevMonth === 0) ? 0 : parseFloat(((mtd - prevMonth) / prevMonth).toFixed(2));
        const mtdYoY = (!prevMonthYear || isNaN(prevMonthYear) || prevMonthYear === 0) ? 0 : parseFloat(((mtd - prevMonthYear) / prevMonthYear).toFixed(2));
        const ytdYoY = (!prevYTD || isNaN(prevYTD) || prevYTD === 0) ? 0 : parseFloat(((ytd - prevYTD) / prevYTD).toFixed(2));

        function checkInfinite (value) {
            let output;
            if (!isFinite(value)) {
                output = 0
            } else { output = value}
            return output
        }

        return {
          metric: dim || "NULL",
          yesterday: yesterday ||0,
          same_day_last_week: prevWeek || 0,
          two_days_ago: minus2_day || 0,
          three_days_ago: minus3_day || 0,
          mtd: mtd || 0,
          mom: checkInfinite(mom) ||0,
          mtd_yoy: checkInfinite(mtdYoY) || 0,
          ytd_yoy: checkInfinite(ytdYoY) || 0

    
        };
      });

    

    return dataProp

}