export default function CubeQuery(cubeFilters, table, start, end, dimension, timeFrame, chartType) {

    if (chartType === 'Daily Line' || chartType === 'Daily Bar') {

    return {
        "timeDimensions": [
            {
                "dimension": `${table}.timestamp`,
                "granularity" : "day",
                "dateRange": [
                    start,
                    end
                ]
            }
        ],
        "order": [
            [
                `${table}.timestamp`,
                "asc"
            ]
        ],
        "measures": [
            `${table}.impressions`,
            `${table}.clicks`,
            `${table}.spend`,
            `${table}.regs`,
            `${table}.ftds`,
        ],
        "limit": 1000,
        "filters": cubeFilters
    }
    }

    if (chartType === 'Monthly Line' || chartType === 'Monthly Bar') {
        return {
            "timeDimensions": [
                {
                    "dimension": `${table}.timestamp`,
                    "granularity" : "month",
                    "dateRange": [
                        start,
                        end
                    ]
                }
            ],
            "order": [
                [
                    `${table}.timestamp`,
                    "asc"
                ]
            ],
            "measures": [
                `${table}.impressions`,
                `${table}.clicks`,
                `${table}.spend`,
                `${table}.regs`,
                `${table}.ftds`,
            ],

            "limit": 1000,
            "filters": cubeFilters
        }
        }

    else return {
        "timeDimensions": [
            {
                "dimension": `${table}.timestamp`,
                "dateRange": [
                    start,
                    end
                ]
            }
        ],
        "order": [
            [
                `${table}.${dimension}`,
                "asc"
            ]
        ],
        "measures": [
            `${table}.impressions`,
            `${table}.clicks`,
            `${table}.spend`,
            `${table}.regs`,
            `${table}.ftds`,
        ],
        "dimensions": [
            `${table}.${dimension}`
        ],
        "limit": 1000,
        "filters": cubeFilters
    }

}