export default function HomeComparison(table, start, end) {

    return {
        "timeDimensions": [
            {
                "dimension": `${table}.timestamp`,
                "granularity" : 'day',
                "dateRange": [
                    start,
                    end
                ]
            }
        ],
        "order": [
            [
                `${table}.timestamp`,
                "asc"
            ]
        ],
        "measures": [
            `${table}.spend`,
            `${table}.ftds`,
            `${table}.clicks`,
            `${table}.ngr`,

        ],


        "limit": 2000,
    }

}