import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Dimensions, View } from 'react-native'
import { useState } from 'react'
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const windowWidth = Dimensions.get('window').width;

export default function ChartBuilderOpenModal(props) {
  const open = props.open
  const setOpen = props.setOpen
  const theme = props.theme
  const openHandler = props.openHandler
  const lastChartUsed = props.lastChartUsed
  const reportList = props.reportList
  const [currentValue, setCurrentValue] = useState(lastChartUsed)

  const options = reportList.map((item, index) => (
    <MenuItem key={index} value={item}>
      {item}
    </MenuItem>
  ));

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
    console.log(reason)
  };

  function handleSave () {
    openHandler(currentValue)
    setOpen(false);
  }




  return (
    <View style={{width: windowWidth * 0.8, borderColor: 'red', borderWidth: 0}}>
    <div>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose} maxWidth={1500}>
        <DialogTitle sx={{fontFamily: 'Electrolize'}}>Open Template</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ 
                                    display: 'flex', 
                                    flexDirection: 'row',
                                    }}>
                <FormControl sx={{ m: 1, minWidth: windowWidth * 0.15,fontFamily: 'Electrolize',}}>
                <InputLabel id="demo-dialog-select-label">Choose Templates</InputLabel>
                <Select
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    value={currentValue}
                    onChange={(event) => setCurrentValue(event.target.value)}
                    input={<OutlinedInput label="Choose Templates" />}
                >
                    {options}
                </Select>
                </FormControl>

          </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Open Template</Button>
        </DialogActions>
      </Dialog>
    </div>
    </View>
  );
}