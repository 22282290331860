import Fab from '@mui/material/Fab';
import { View } from 'react-native'

const HomeFAB = (props) => {

    const buttonText = props.buttonText
    const theme = props.theme
    const selectHandler = props.selectHandler
    const dynamicTextSmall = props.dynamicTextSmall
    const currentMetrics = props.currentMetrics

    let customLabel;

    if (buttonText === 'ftds') {
      customLabel = currentMetrics.primaryMetric
    } else if (buttonText === 'ngr') {
      customLabel = currentMetrics.primaryRevenue
    } else if (buttonText === 'regs') {
        customLabel = currentMetrics.secondaryMetric
      } else {customLabel = buttonText}

    return (
        <View
            style={{
                borderWidth: 0,
                borderColor: theme.color,
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',

            }}
        >
            <Fab variant="extended"
                onClick={() => { selectHandler(buttonText)}}
                sx={{
                    color: 'white',
                    backgroundColor: theme.backgroundColor2,
                    fontSize: dynamicTextSmall * 0.5,
                    width: '100%'
                }}>

                {customLabel}
            </Fab>
        </View>
    )
}

export default HomeFAB