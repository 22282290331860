import React, { useState } from 'react';
import { View, 
    Text, 
    Pressable, 
    Modal,
    StyleSheet,
    Dimensions } from 'react-native'
    import CloseIcon from '@mui/icons-material/Close';
import DashEditorSelect from './DashEditorSelect';    
import DashEditorFAB from './DashEditorFAB';
import DashEditorDate from './DashEditorDate';
import DashEditorText from './DashEditorText';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
const responsiveTextSmall = windowWidth * 0.02


const DashEditorSettings = (props) => {

    const currentMetrics = props.currentMetrics
    const theme = props.theme
    const visibleModal = props.settingsVisible
    const setVisible = props.setSettingsVisible
    const uniqueBrands = props.uniqueBrands
    const uniqueChannels = props.uniqueChannels
    const uniqueTypes = props.uniqueTypes
    const filters = props.filters
    const setFilters = props.setFilters
    const setUpdateFilters = props.setUpdateFilters
    const updateHandler = props.updateHandler
    const dashConfigs = props.dashConfigs
    const setDashConfigs = props.setDashConfigs
    const currentItem = props.currentItem
    const currentChild = props.currentChild
    const [filterState, setFilterState] = useState(filters)
    const [reportName, setReportName] = useState(null)
    const setChartLayout = props.setChartLayout
    const setLayout = props.setLayout
    const endDateProp = filterState.end
    const startDateProp = filterState.start

    function changeHandler(prop) {

        setFilterState(prop)
    }

    function saveSetttings () {
        setUpdateFilters(filterState)
        
        const configCopy = dashConfigs
        const configName = currentItem.gridConfig
        const configIndex = parseInt(configName.replace('gridConfig', ''))
        const currentGraph = configCopy[configIndex -1][0]['gridChartLayout'][currentChild]
        configCopy[configIndex -1][0]['gridChartLayout'][currentChild]['currentGraphFilters'] = {
            brand: filterState.brand,
            channel: filterState.channel,
            kw_type: filterState.kw_type,
            start: filterState.start,
            end: filterState.end

        }
        configCopy[configIndex -1][0]['gridLayout'][currentChild]['currentGraphFilters'] = {
            brand: filterState.brand,
            channel: filterState.channel,
            kw_type: filterState.kw_type,
            start: filterState.start,
            end: filterState.end

        }
        localStorage.setItem("gridConfig", JSON.stringify(configCopy));
        setDashConfigs(configCopy)
        setLayout(configCopy[configIndex -1][0]['gridLayout'])
        setChartLayout(configCopy[configIndex -1][0]['gridChartLayout'])
        //setFilters(filterState)        
        setVisible(false)
    }


    const modalContent = {
        height: '100%',
        width: '100%',
        borderColor: theme.backgroundColor2,
        borderWidth: 1,
        borderRadius: 10,
        flex: 1,
        flexDirection: 'row'
    }

    const chipsPanel = {
        height: '100%',
        width: '70%',
        alignItems: 'center',
        borderColor: theme.backgroundColor2,
        borderWidth: 1,
        borderRadius: 0,
        borderBottomLeftRadius: 10,
        borderTopLeftRadius: 0
    }

    const datePanel = {
        height: '100%',
        width: '30%',
        borderColor: 'grey',
        borderWidth: 0,
        flexDirection: 'column'
    }

    const pickerPanel = {
        height: '82%',
        width: '100%',
        borderColor: 'red',
        borderWidth: 0,
        backgroundColor: theme.backgroundColor,

    }

    const titlePanel = {
        height: '10%',
        width: '100%',
        borderColor: 'red',
        borderWidth: 0,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    }

    const buttonPanel = {
        height: '8%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: 'red',
        borderWidth: 0,
    }

    const chipItemPanel = {
        width: '100%',
        height: '20%',
        margin: 0,
        

    }

    const chipItemPanelLarge = {
        width: '100%',
        height: '60%',
        margin: 0,
        

    }


    const chipTitle = {
        textAlign : 'center', 
        fontSize: 30, 
        backgroundColor: theme.backgroundColor2,
        color: 'white'
    }

return (
    <Modal
        visible={visibleModal}
        style={styles.modalParent}
        transparent={true}
        >
    <Pressable 
        style={styles.modalContainer}
        
        >
        <View style={[styles.modalWindow , {
            backgroundColor : theme.backgroundColor,
            borderColor: theme.color,
        }]}>
            <Pressable style={{
                        position: 'absolute',
                        left: '100%',
                        top: '-4%',
            }}
            onPress={() => setVisible(false)}>
                <CloseIcon
                        style={{
                            
                            fontSize: responsiveTextSmall,
                            color: theme.color,
                            backgroundColor: theme.backgroundColor2,
                            borderRadius: 5,
                            
                        }}
                    />
            </Pressable>
                <View style={modalContent}>
                    <View style={chipsPanel}>
                        <View style={chipItemPanel}>
                        <Text style={chipTitle}>Brand</Text>
                            <DashEditorSelect
                                theme={theme}
                                itemList={uniqueBrands}
                                filters={filters}
                                type={'brands'}
                                changeHandler={changeHandler}
                                filterState={filterState}
                                currentMetrics={currentMetrics}
                                
                            />
                        </View>
                        <View style={chipItemPanel}>
                        <Text style={chipTitle}>Channel</Text>
                            <DashEditorSelect
                                theme={theme}
                                itemList={uniqueChannels}
                                filters={filters}
                                type={'channels'}
                                changeHandler={changeHandler}
                                filterState={filterState}
                                currentMetrics={currentMetrics}
                                
                            />
                        </View>
                        <View style={chipItemPanelLarge}>
                        <Text style={chipTitle}>KW Type</Text>
                            <DashEditorSelect
                                theme={theme}
                                itemList={uniqueTypes}
                                filters={filters}
                                type={'types'}
                                changeHandler={changeHandler}
                                filterState={filterState}
                                currentMetrics={currentMetrics}
                                
                            />
                        </View>
                    </View>
                    <View style={datePanel}>
                    <View style={titlePanel}>
                    <DashEditorText
                        theme={theme}
                        setReportName={setReportName}
                        dashConfigs={dashConfigs}
                        currentItem={currentItem}
                        setDashConfigs={setDashConfigs}
                        currentMetrics={currentMetrics}

                    />
                    </View>
                        <View style={pickerPanel}>
                            <DashEditorDate
                                theme={theme}
                                endDateProp={endDateProp}
                                startDateProp={startDateProp}
                                filterState={filterState}
                                setFilterState={setFilterState}
                                dashConfigs={dashConfigs}
                                currentItem={currentItem}
                            />

                        </View>
                        <View style={buttonPanel}>
                            <DashEditorFAB
                                theme={theme}
                                buttonText={'Save Settings'}
                                saveSettings={saveSetttings}
                                windowWidth={windowWidth}
                            />

                        </View>


                    </View>
                </View>
        </View>


    </Pressable>


    </Modal>)
}


export default DashEditorSettings

const styles = StyleSheet.create({
    modalParent: {
        padding: 20,
        borderRadius: 20,
    },
    modalContainer: {

        width: windowWidth * 1,
        height: windowHeight * 1,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalWindow: {
        margin: 10,
        height: '90%',
        width: '90%',
        borderColor: 'white',
        borderWidth: 1,
        borderRadius: 15,
        padding: '1%',
        alignItems: 'center',
        justiftContent: 'center',

        }

})