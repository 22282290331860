export default function AttEnhancedLooker(cubeFilters, params) {

    const table = params.cubeTable
    const start = params.start
    const end = params.end
    const model1 = params.models.model1
    const model2 = params.models.model2
    const model3 = params.models.model3

    function formatDimensions(table, dimensions) {
        return dimensions.map(dimension => `${table}.${dimension}`);
    }
    const dimensionsInput = params.dimensions
    const dimensions = formatDimensions(table, dimensionsInput)

    return {
        "timeDimensions": [
            {
                "dimension": `${table}.timestamp`,
                "granularity" : 'day',
                "dateRange": [
                    start,
                    end
                ]
            }
        ],
        "order": [
            [
                `${table}.ftds_${model1}`,
                "desc"
            ]
        ],
        "measures": [
            `${table}.impressions`,
            `${table}.clicks`,
            `${table}.spend`,
            `${table}.regs_ft_lifetime`,
            `${table}.ftds_${model1}`,
            `${table}.ngr_${model1}`,
            `${table}.react_${model1}`,
            `${table}.ftds_${model2}`,
            `${table}.ngr_${model2}`,
            `${table}.react_${model2}`,
            `${table}.ftds_${model3}`,
            `${table}.ngr_${model3}`,
            `${table}.react_${model3}`,
            `${table}.ga_ftd_45_day_react`,
        ],
        "dimensions": dimensions,
        "limit": 5000,
        "filters": cubeFilters
    }

}