import React, { useState , useEffect} from 'react';
import { Line } from 'react-chartjs-2';
import WebFont from 'webfontloader';
import { Dimensions } from 'react-native'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Download from '../Components/Download';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const BudgetArea = (props) => {

    const agg_daily_result = props.agg_daily_result || []
    const labels = props.dateLabels    
    const theme = props.theme
    const metric1 = props.metric1 || "actual_spend";
    const metric2 = props.metric2 || "budget";
    const timeFrame = props.timeFrame
    const dailySpend = agg_daily_result.map((obj) => obj[metric1]);
    const dailyBudget = agg_daily_result.map((obj) => obj[metric2]);
    const currentMetrics = props.currentMetrics
    const [contextMenu, setContextMenu] = useState(null);

    const handleContextMenu = (event) => {
      event.preventDefault();
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
            }
          :
            null,
      );
    };

    const handleClose = () => {
      setContextMenu(null);
    };

    let convLabel1, convLabel2

  if (metric1 === 'ctr') {
    convLabel1 = `${metric1}%`
  } else if (metric1 === 'ftds') {
    convLabel1 = currentMetrics.primaryMetric
  } else if (metric1 === 'ftd') {
    convLabel1 = currentMetrics.primaryMetric
  } else if (metric1 === 'ngr') {
    convLabel1 = currentMetrics.primaryRevenue
  } else if (metric1 === 'regs') {
    convLabel1 = currentMetrics.secondaryMetric
  } else if (metric1 === 'reg') {
    convLabel1 = currentMetrics.secondaryMetric
  } else {convLabel1 = metric1}

  if (metric2 === 'ctr') {
    convLabel2 = `${metric2}%`
  } else if (metric2 === 'ftds') {
    convLabel2 = currentMetrics.primaryMetric
  } else if (metric2 === 'ftd') {
    convLabel2 = currentMetrics.primaryMetric
  } else if (metric2 === 'ngr') {
    convLabel2 = currentMetrics.primaryRevenue
  } else if (metric2 === 'regs') {
    convLabel2 = currentMetrics.secondaryMetric
  } else if (metric2 === 'reg') {
    convLabel2 = currentMetrics.secondaryMetric
  } else {convLabel2 = metric2}

    let responseText;

    if (windowWidth < 450) {
      responseText = windowHeight * 0.0125
  }else if (windowWidth < 1281) {
        responseText = windowHeight * 0.025
    } else if (windowWidth > 2000) {
        responseText = windowHeight * 0.01
    } else {responseText = windowHeight * 0.017}

    const axisText = windowWidth * 0.005

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Electrolize']
            }
        });
    }, []);

    function toProperCase(str) {
        return str.replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
      }

      const downloadHandler = () => {
        Download(agg_daily_result)
        handleClose()
      
      }

    const data = {
        labels: labels,
        datasets: [
            {
                label: timeFrame +" " + toProperCase(convLabel2),
                data: dailyBudget,
                borderColor: theme.color, // Color of the line
                fill: false, // Do not fill the area under the line
                type: 'line', // Use 'line' chart type
                lineTension: 0.5,
                radius: 0,
            },
            {
                label: timeFrame +" " + toProperCase(convLabel1),
                data: dailySpend,
                fill: true,
                borderColor: theme.backgroundColor2,
                backgroundColor: theme.backgroundColor2+30,
                lineTension: 0.5,
                radius: 0,
                type: 'line', // Use 'bar' chart type to create an area chart
            },
        ],
    };

    const options = {
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: false, // Remove the gridlines on the y-axis
            },
            ticks: {
              callback: (value, index, values) => {
                if (metric1 === 'budget' || metric1 === 'actual_spend' || metric2 === 'budget' || metric2 === 'actual_spend' || metric2 === 'cpa') {
                    return new Intl.NumberFormat('en-EN', { // Note: Typically, locale would be 'en-US' or other specific locales for Euro.
                        style: 'currency',
                        currency: 'EUR',
                        maximumSignificantDigits: 3
                    }).format(value);
                } else {
                    return value;
                }
            },
              color : theme.color,
              font: {
                size: axisText, // Set the font size for y-axis labels
                weight: 'bold', // Set the font weight for y-axis labels
                family : 'Electrolize',
              },
            },
          },
          x: {
            grid: {
              display: false, // Remove the gridlines on the x-axis
            },
            ticks: {
              color : theme.color,
              font: {
                size: axisText, // Set the font size for x-axis labels
                family : 'Electrolize'
              },
            },
          },
        },
        maintainAspectRatio: false,
        
        plugins: {
          legend: {
            display: true,
            text: 'Chart Title',
            labels: {
              color : theme.color,
              // This more specific font property overrides the global property
              font: {
                  size: responseText,
                  family : 'Electrolize'
              }
          }
          },
          tooltip: {
            enabled: false,
          },
          title: {
            display: false,
            text: 'Chart Title',
            font: {
              size: 24, // Set the font size for the chart title
              weight: 'bold', // Set the font weight for the chart title
      
            },
          },
        },
      };

    return (
      <div onContextMenu={handleContextMenu} style={{ cursor: 'context-menu', height: '100%', width: '100%' }}>
        <Line 
          data={data}
          options={options}
        />
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem 
            style={{fontFamily : 'Electrolize', backgroundColor: theme.backgroundColor}} 
            onClick={downloadHandler}
            >
              Download Data
          </MenuItem>
        </Menu>
      </div>

    )

};

export default BudgetArea;
