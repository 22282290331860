import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Dimensions, View } from 'react-native'
import { useState } from 'react'
import ChartBuilderTextBox from './ChartBuilderTextBox';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const windowWidth = Dimensions.get('window').width;

export default function ChartBuilderSaveModal(props) {
  const open = props.open
  const setOpen = props.setOpen
  const theme = props.theme
  const saveHandler = props.saveHandler
  const reportList = props.reportList
  const [currentValue, setCurrentValue] = useState(null)
  const [textValue, setTextValue] = useState('')

  const updateReportList = [...reportList, 'Create New']
  const isCreateNew = currentValue === 'Create New'




  const options = updateReportList.map((item, index) => (
    <MenuItem key={index} value={item}>
      {item}
    </MenuItem>
  ));

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
    console.log(reason)
  };

  function handleSave () {
    let saveValue;
    if (textValue === '') {
       saveValue =  currentValue
    } else {
        saveValue = textValue
    }
    saveHandler(saveValue)
    setOpen(false);
  }




  return (
    <View style={{width: windowWidth * 0.8, borderColor: 'red', borderWidth: 0}}>
    <div>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose} maxWidth={1500}>
        <DialogTitle sx={{fontFamily: 'Electrolize'}}>Save Chart</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ 
                                    display: 'flex', 
                                    flexDirection: 'row',
                                    }}>
                <FormControl sx={{ m: 1, minWidth: windowWidth * 0.15,fontFamily: 'Electrolize',}}>
                <InputLabel id="demo-dialog-select-label">Current Templates</InputLabel>
                <Select
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    value={currentValue}
                    onChange={(event) => setCurrentValue(event.target.value)}
                    input={<OutlinedInput label="Current Templates" />}
                >
                    {options}
                </Select>
                </FormControl>
                {
                    isCreateNew && <ChartBuilderTextBox
                        theme={theme}
                        setTextValue={setTextValue}
                    />
                }

          </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save Template</Button>
        </DialogActions>
      </Dialog>
    </div>
    </View>
  );
}