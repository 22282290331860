import React, { useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
} from 'react-native'
import FiltersEnhancedSingle from '../ComponentsEnhanced/FiltersEnhancedSingle';
import WebFont from 'webfontloader';
import BudgetDataGrid from '../Budgets/BudgetDataGrid'
import { LicenseInfo } from '@mui/x-license-pro';

const windowWidth = Dimensions.get('window').width;

const BudgetPanelTable = (props) => {

    LicenseInfo.setLicenseKey('6bf1987e6f50457e0ccce5aee1489680Tz04MzczNyxFPTE3Mzg4NjE3NTUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');
    const currentMetrics = props.currentMetrics
    const uniqueBrands = props.uniqueBrands
    const uniqueChannels = props.uniqueChannels
    const uniqueTypes = props.uniqueTypes
    const theme = props.theme
    const handleStateChange = props.handleStateChange
    const propObject = props.propObject
    const currentLevel = props.dataProp
    const dataPropBudget = props.dataPropBudget
    const uniqueFilter1 = dataPropBudget.uniqueFilter1
    const uniqueFilter2 = dataPropBudget.uniqueFilter2
    const uniqueFilter3 = dataPropBudget.uniqueFilter3
    const filterParams = props.filterParams
    const filterList = props.filterList
    const setFilterList = props.setFilterList
    


    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);


    const panelMain = {

        width: windowWidth,
        height: '100%',
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 40,
        fontFamily: 'Electrolize',
        borderRadius: 5
    }

    return (
        <View style={styles.container}>
            <View style={{ flexDirection: 'row' }}>
                <View style={panelMain}>
                    <View style={{ width: '100%', flexDirection: 'flex-start', fontFamily: 'Electrolize', paddingBottom: '0.5%' }}>
                    <FiltersEnhancedSingle
                            style={{
                                height: '10%',
                                borderColor: 'red',
                                borderWidth: 0,
                                width: '100%',
                                fontFamily: 'Electrolize'
                            }}
                            uniqueFilter1={uniqueFilter1}
                            uniqueFilter2={uniqueFilter2}
                            uniqueFilter3={uniqueFilter3}
                            handleStateChange={handleStateChange}
                            propObject={propObject}
                            filterParams={filterParams}
                            theme={theme}
                            large={1}
                            setFilterList={setFilterList}
                            filterList={filterList}
                            currentMetrics={currentMetrics}
                        />
                    </View>
                        <BudgetDataGrid
                            dataProp={currentLevel}
                            theme={theme}
                            currentMetrics={currentMetrics}
                        />


                </View>
            </View>

        </View>

    )

}

export default BudgetPanelTable

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',


    }
})