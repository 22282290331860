export default function ParseCube (props) {

    const data = props.data
    const start = props.start
    const end = props.end
    const xAxis = props.xAxis
    const brand = props.brand
    const channel = props.channel
    const kw_type = props.kw_type

    const filters = {
        start: start,
        end: end,
        channel: channel,
        brand: brand,
        kw_type: kw_type,
    };
    var dataset = data;

    if (!dataset || dataset.length === undefined) {
      return null;
    }
  
        dataset = dataset.filter((obj) => {
          const date = new Date(obj.date);
          const startDate = new Date(start);
          const endDate = new Date(end);
          return (
            date >= startDate &&
            date <= endDate &&
            Object.entries(filters)
      
              .filter(([key, value]) => key !== 'start' && key !== 'end')
              .every(([key, value]) => {
                if (value === 'Total') {
                  return true;
                }
                if (key === 'brand') {
                  return obj[key] === value;
                }
                if (key === 'channel') {
                  return obj[key] === value;
                }
                if (key === 'kw_type') {
                  return obj[key] === value;
                }

      
                return false;
              })
          );
        });

        var agg_result = []

        dataset.reduce(function (res, value) {

            if (!res[value[xAxis]]) {
                res[value[xAxis]] = {
                  [xAxis]: value[xAxis],
                  impressions: 0,
                  clicks: 0,
                  spend: 0,
                  regs: 0,
                  ftds: 0,
                };
                agg_result.push(res[value[xAxis]]);
              }
              res[value[xAxis]].impressions += parseInt(value.impressions) || 0;
              res[value[xAxis]].clicks += parseInt(value.clicks) || 0;
              res[value[xAxis]].spend += parseInt(value.spend) || 0;
              res[value[xAxis]].regs += parseInt(value.regs) || 0;
              res[value[xAxis]].ftds += parseInt(value.ftds) || 0;
          
              return res;
            }, {});

            const agg_result_final = agg_result.map((obj) => {
                const spend = parseFloat(obj.spend)
                const ftds = parseInt(obj.ftds)
                const cpa = spend === 0 || ftds === 0 || isNaN(ftds) ? 0 : spend / ftds;
                const impressions = parseInt(obj.impressions)
                const clicks = parseInt(obj.clicks)
                const cpc = parseFloat((spend / clicks).toFixed(2))
                const ctr = parseFloat((clicks / impressions).toFixed(2))
                const ftdConv = ((parseFloat(ftds / clicks))*100).toFixed(2)
        
                return {
                    ...obj,
                    cpc: cpc,
                    cpa: parseFloat((cpa).toFixed(2)),
                    ctr: ctr,
                    clickConv: ftdConv
        
                };
            })

    return agg_result_final


}