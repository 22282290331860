import React, { useState } from 'react';
import Box from '@mui/material/Box';
import {Dimensions, View} from 'react-native'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

const windowWidth = Dimensions.get('window').width;

export default function DashEditorText(props) {
    const [showPassword, setShowPassword] = React.useState(false);
    
    const theme = props.theme
    const [inputValue, setInputValue] = useState('');
    const setTextValue = props.setTextValue
    const setReportName = props.setReportName
    const reportNameFallback = props.reportNameFallback
    const textValue = props.textValue
    let newTextValue;

    if (textValue === 'Create New') {
      newTextValue = inputValue
    } else {
      newTextValue = textValue
    }


    let themeColor;

      if (theme.color === 'white') {
        themeColor = '#FFFFFF'
      } else {
        themeColor = theme.color
      }


    const handleChange = (event) => {
        setTextValue(event.target.value);
        setReportName(event.target.value);
      //console.log(event.target.value)
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    function clickHandler(prop) {
        
        
        
    }

    

    return (

        <Box sx={{ display: 'flex', flexWrap: 'wrap', maxWidth: '100%', width: '100%' }}>
            <div>

                <FormControl sx={{ m: 1, maxWidth: '90%', width: '100%',  }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-amount">Template Name</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-report"
                        label="Report Name"
                        type={'text'}
                        value={newTextValue || ""}
                        onChange={handleChange}
                        //sx={{height: windowWidth * 0.03}}
                        endAdornment={
                            <InputAdornment position="end">
                                <View
                                    //onPress={() => clickHandler()}
                                    >
                                    <SaveAltIcon
                                        aria-label="toggle password visibility"
                                        //onPress={clickHandler}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        //sx={{color: theme.backgroundColor2}}
                                        
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </SaveAltIcon>
                                </View>
                            </InputAdornment>
                        }
                        
                    />
                </FormControl>

            </div>
        </Box>

    );
}