export default function HomeBudget(table, start, end) {

    return {
        "timeDimensions": [
            {
                "dimension": `${table}.timestamp`,
                "granularity" : 'month',
                "dateRange": [
                    start,
                    end
                ]
            }
        ],
        "order": [
            [
                `${table}.timestamp`,
                "asc"
            ]
        ],
        "measures": [
            `${table}.actual_spend`,
            `${table}.yesterday_spend`,
            `${table}.budget`,
            `${table}.reg`,
            `${table}.ftd`,
            `${table}.ngr`
        ],
        "dimensions": [
            `${table}.current_dom`,
            `${table}.days_in_month`,
            `${table}.days_remaining`,
            `${table}.updated_at`
        ],

        "limit": 2000
    }

}