import React, { useState } from 'react';
import {
    View,
    Text,
    Pressable
} from 'react-native'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import TuneIcon from '@mui/icons-material/Tune';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';

const DashEditorLayout = (props) => {

    const theme = props.theme
    const config = props.config
    const reportName = config.reportName
    const layoutIndex = props.layoutIndex
    const setViewerVisible = props.setViewerVisible
    const setEditorVisible = props.setEditorVisible
    const setCurrentItem = props.setCurrentItem
    const settingsVisible = props.settingsVisible
    const setSettingsVisible = props.setSettingsVisible
    const removeDashboard = props.removeDashboard
    const currentLayout = props.currentLayout
    const getLayout = props.getLayout
    const currentMetrics = props.currentMetrics
     
    function itemHandler(config) {
        const currentItem = getLayout(currentLayout, layoutIndex)
        setCurrentItem(currentItem)
        setViewerVisible(true)

    }

    function settingsHandler (config) {
        setCurrentItem(config)
        setSettingsVisible(true)
        
    }

    function editorHandler (config) {
        setCurrentItem(config)
        setEditorVisible(true)
        
    }

    const container = {
        width: '25%',
        height: '35%',
        borderColor: theme.color,
        borderWidth: 1,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        margin: '1%'
    }

    const layoutColumn = {
        height: '70%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    }

    const buttonColumn = {
        height: '30%',
        width: '100%',
        flexDirection: 'row',
        backgroundColor: theme.backgroundColor2,
        borderWidth: 1,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
    }

    const buttonContainer = {
        width: '33.333333%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    }

    const iconStyle = {
        color: theme.backgroundColor,
        fontSize: 30
    }

    const mainText = {
        color: theme.backgroundColor2,
        fontSize: 30
    }

    return (
        <View style={container}>
            <View style={layoutColumn}>
                <Text style={mainText}>{reportName}</Text>
            </View>
            <View style={buttonColumn}>
            <Tooltip
                    title={"Open Dashboard"}
                    placement="bottom"
                    componentsProps={{
                        arrow: { sx: { color: theme.backgroundColor2, } },
                        tooltip: {
                            sx: {
                                bgcolor: theme.backgroundColor2,
                                color: "white",
                                fontFamily: 'Electrolize',
                                fontSize: 15
                            }
                        },
                    }}
                    arrow
                >
                <Pressable 
                    style={buttonContainer}
                    onPress={() => itemHandler(config)}>

                    <RemoveRedEyeIcon style={iconStyle}/>
                </Pressable>
                </Tooltip>
                <Tooltip
                    title={"Settings"}
                    placement="bottom"
                    componentsProps={{
                        arrow: { sx: { color: theme.backgroundColor2, } },
                        tooltip: {
                            sx: {
                                bgcolor: theme.backgroundColor2,
                                color: "white",
                                fontFamily: 'Electrolize',
                                fontSize: 15
                            }
                        },
                    }}
                    arrow
                >

                <Pressable 
                    style={buttonContainer}
                    onPress={() => editorHandler(config)}
                    >
                    <TuneIcon style={iconStyle}/>
                </Pressable>
                </Tooltip>
                <Tooltip
                    title={"Delete Dashboard"}
                    placement="bottom"
                    componentsProps={{
                        arrow: { sx: { color: theme.backgroundColor2, } },
                        tooltip: {
                            sx: {
                                bgcolor: theme.backgroundColor2,
                                color: "white",
                                fontFamily: 'Electrolize',
                                fontSize: 15
                            }
                        },
                    }}
                    arrow
                >
                <Pressable 
                    style={buttonContainer}
                    onPress={() => removeDashboard(layoutIndex)}
                    >
                    <DeleteIcon style={iconStyle}/>
                </Pressable>
                </Tooltip>

            </View>
        </View>
    )
}

export default DashEditorLayout