import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
} from 'react-native'
import FiltersEnhancedSingle_2 from '../ComponentsEnhanced/FiltersEnhancedSingle_2';
import WebFont from 'webfontloader';
import AllChannelsDataGrid from './AllChannelsDataGrid';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const AllChannelsTable = (props) => {

    const dataProp = props.dataProp
    const currentMetrics = props.currentMetrics
    const dataPropFilter = props.dataPropFilter
    const uniqueFilter1 = dataPropFilter.uniqueFilter1
    const uniqueFilter2 = dataPropFilter.uniqueFilter2
    const filterParams = props.filterParams
    const uniqueBrands = dataProp.uniqueBrands
    const uniqueChannels = dataProp.uniqueChannels
    const uniqueTypes = dataProp.uniqueTypes
    const theme = props.theme
    const handleStateChange = props.handleStateChange
    const propObject = props.propObject
    const currentLevelBase = dataProp.agg_channel_result
    const filterList = props.filterList
    const setFilterList = props.setFilterList
    
    const fallback = [{
        channel: 'No Data',
        new_users: 0,
        sessions: 0,
        pageviews: 0,
        reg_start: 0,
        regs: 0,
        ftds: 0,
        ngr: 0,
        spend: 0,
        cpa: 0,
        month: 'No Data',
        id: 1
    }]

    const currentLevel = currentLevelBase.length > 0 ? currentLevelBase : fallback
    console.log(currentLevel)
    


    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);


    const panelMain = {

        width: '100%',
        height: '99%',
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 40,
        fontFamily: 'Electrolize',
        borderRadius: 5
    }

    return (
        <View style={styles.container}>
            <View style={{ flexDirection: 'row' }}>
                <View style={panelMain}>
                    <View style={{ width: '100%', flexDirection: 'flex-start', fontFamily: 'Electrolize' }}>
                    <FiltersEnhancedSingle_2
                        style={{
                            height: '10%',
                            borderColor: 'red',
                            borderWidth: 0,
                            width: '100%',
                            fontFamily: 'Electrolize'
                        }}
                        uniqueFilter1={uniqueFilter1}
                        uniqueFilter2={uniqueFilter2}
                        handleStateChange={handleStateChange}
                        propObject={propObject}
                        filterParams={filterParams}
                        theme={theme}
                        large={1}
                        setFilterList={setFilterList}
                        filterList={filterList}
                        currentMetrics={currentMetrics}
                    />
                    </View>
                    <View style={{marginTop: '0%', width: '100%'}}>
                        <AllChannelsDataGrid
                            currentMetrics={currentMetrics}
                            dataProp={currentLevel}
                            theme={theme}
                        />
                    </View>

                </View>
            </View>

        </View>

    )

}

export default AllChannelsTable

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'red',
        minHeight: (windowHeight * 0.931),
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',


    }
})