import Fab from '@mui/material/Fab';
import { View } from 'react-native'

const MobileFABUpdate = (props) => {

    const buttonText = props.buttonText
    const theme = props.theme
    const modalHandler = props.setChartConfig
    const setVisible = props.setVisible
    const updateHandler = props.updateHandler

    function clickHandler (prop) {
        setVisible(false)
        updateHandler()
    }

    return (
        <View
            style={{
                borderWidth: 0,
                borderColor: theme.color,
                alignItems: 'center',
                justifyContent: 'center',
                width: '80%',
                padding: '1%'
            }}
        >
            <Fab variant="extended"
                onClick={() => { clickHandler(true)}}
                sx={{
                    color: 'white',
                    backgroundColor: theme.backgroundColor2,
                    fontSize: 20,
                    fontFamily: 'Electrolize',
                    width: '100%'
                }}>

                {buttonText}
            </Fab>
        </View>
    )
}

export default MobileFABUpdate