import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import WebFont from 'webfontloader';
import { Dimensions, Pressable, View } from 'react-native'
import Fab from '@mui/material/Fab';
import LoginIcon from '@mui/icons-material/Login';
import { themes } from "../themes";

const windowWidth = Dimensions.get('window').width;

const LoginButton = (props) => {
  const { loginWithRedirect } = useAuth0();
  const theme = props.theme

  let dynamicButton;

  if (windowWidth < 450) {
    dynamicButton = windowWidth * 0.25
  } else { dynamicButton = windowWidth * 0.10 }

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Droid Sans', 'Electrolize']
      }
    });
  }, []);

  return <Pressable
      onPress={() => loginWithRedirect()}
      style={{
        width: '10%',
        fontFamily: 'Electrolize'
      }}
    >
      <Fab variant="extended" color="primary" 
        style={{
          fontFamily: 'Electrolize',
          fontSize: windowWidth * 0.01,
          backgroundColor: '#E53553',
          fontWeight: 600
          }}>
        <LoginIcon sx={{ mr: 1 }} />
        Login
      </Fab>
    </Pressable>

};

export default LoginButton;