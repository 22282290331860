import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    MenuListProps: {
        disablePadding: true
    },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 250,
    },
    
  },
};


export default function MobileMulitSelect(props) {
  const [personName, setPersonName] = React.useState([]);
  
  const itemList = props.itemList
  const itemTitle = props.itemTitle
  const setList = props.setList
  const theme = props.theme
  const setChartConfig = props.setChartConfig
  const chartConfig = props.chartConfig


  function handleClose (prop) {

    let propUpdate;
    let defaultArray;

    if (personName.length === 0) {
        defaultArray = 'Total'
    } else {defaultArray = personName}

    if (itemTitle === 'Brand') {
        propUpdate = {
        chart: chartConfig.chart,
        axis: chartConfig.axis,
        brand: defaultArray,
        channel: chartConfig.channel,
        kw_type: chartConfig.kw_type,
        start: chartConfig.start,
        end: chartConfig.end,
        time: chartConfig.time,
        metric1: chartConfig.metric1,
        metric2: chartConfig.metric2
    }
    }
    else if (itemTitle === 'Channel') {
        propUpdate = {
        chart: chartConfig.chart,
        axis: chartConfig.axis,
        brand: chartConfig.brand,
        channel: defaultArray,
        kw_type: chartConfig.kw_type,
        start: chartConfig.start,
        end: chartConfig.end,
        time: chartConfig.time,
        metric1: chartConfig.metric1,
        metric2: chartConfig.metric2
    }
    }

    else if (itemTitle === 'Type') {
        propUpdate = {
        chart: chartConfig.chart,
        axis: chartConfig.axis,
        brand: chartConfig.brand,
        channel: chartConfig.channel,
        kw_type: defaultArray,
        start: chartConfig.start,
        end: chartConfig.end,
        time: chartConfig.time,
        metric1: chartConfig.metric1,
        metric2: chartConfig.metric2
    }
    }

    setList(personName)
    setChartConfig(propUpdate)
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <div >
      <FormControl sx={{ width: '100%'}} >
        <InputLabel  id="demo-multiple-checkbox-label"
        style={{
            fontFamily : 'Electrolize', 
            color : theme.color,
            marginTop: '-4%'
            }}
        >{itemTitle}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={personName}
          onChange={handleChange}
          onClose={handleClose}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          style={{height: 45,
          border: `1px solid ${theme.backgroundColor2}`,}}
          

        >
          {itemList.map((name) => (
            <MenuItem key={name} value={name} style={{
                alignItems: 'center', 
                color: theme.backgroundColor2,

                border: `1px solid ${theme.color}`
                }}>
              <Checkbox 
              checked={personName.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}