import React, { useState } from 'react';
import { Modal, 
    View, 
    StyleSheet, 
    Dimensions, 
    Text,
    ScrollView,
    Pressable,
    Image } from 'react-native';
import moment from 'moment';
import ParseCube from '../CubeFunctions/ParseCube';
import LoadingComponent from '../../Components/Loader';
import LineChart from '../../ChartsDesktop/LineChart';
import BarChart from '../../ChartsDesktop/BarChart';
import MixedChart from '../../ChartsDesktop/MixedChart';
import DocTable from '../DocTable';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import DocCommentary from '../DocCommentary';
import DocCohorts from '../DocCohorts';
import logo from '../../Images/Pattern_Pink_TS.png'


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const ModalPreview = (props) => {

    const visible = props.visible
    const setVisible = props.setVisible
    const config = JSON.parse(localStorage.getItem('docConfig')) || props.currentConfig
    const client = props.client
    const theme = props.theme
    const cubeResponse = props.cubeResponse
    const cubeTable = props.cubeTable
    const currentMetrics = props.currentMetrics
    const [pdfGenerating, setPdfGenerating] = useState(false);
    const clientLogo = currentMetrics.clientLogo
    const patternLogo = logo

    const responsiveText = windowHeight * 0.015

    let pptView;
    if (windowWidth > 1400) {
        pptView = '80%'
    } else { pptView = '80%'}

    const generatePdf = async () => {
        setPdfGenerating(true);
    
        // Function to wait for a specified time
        const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    
        const pdf = new jsPDF({ orientation: 'landscape' });
    
        for (let i = 0; i < config.length; i++) {
            await wait(2000); // Wait for 1 second
            const element = document.getElementById(`modalMap-${i}`);
            const canvas = await html2canvas(element);
            const imgData = canvas.toDataURL('image/JPEG', 1);
    
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const canvasAspectRatio = canvas.width / canvas.height;
            const pdfAspectRatio = pdfWidth / pdfHeight;
    
            let imgWidth, imgHeight;
            if (canvasAspectRatio > pdfAspectRatio) {
                // Canvas is wider than PDF
                imgWidth = pdfWidth;
                imgHeight = imgWidth / canvasAspectRatio;
            } else {
                // Canvas is taller than PDF
                imgHeight = pdfHeight;
                imgWidth = imgHeight * canvasAspectRatio;
            }
    
            const x = (pdfWidth - imgWidth) / 2; // Center the image horizontally
            const y = 0; // Center the image vertically
    
            pdf.addImage(imgData, 'JPEG', x, y, imgWidth, imgHeight);

    
            if (i < config.length - 1) {
                pdf.addPage();
            }
        }
    
        pdf.save('download.pdf');
        setPdfGenerating(false);
    };
    
    
    if (config.length === 0) {
        return <LoadingComponent theme={theme}/>
    }
    

    const data = cubeResponse.map(obj => {
        const newObj = {};
        for (const key in obj) {
            const newKey = key.replace(cubeTable + ".", "");
            newObj[newKey] = obj[key];
        }
        return newObj;
    });    

    const agg_result = data.map((obj) => {
        const date = moment.tz(obj.timestamp, 'Europe/Paris').format('YYYY-MM-DD');
        const month = moment.tz(obj.timestamp, 'Europe/Paris').format('YYYY-MM');
        const year = moment.tz(obj.timestamp, 'Europe/Paris').format('YYYY');
        const week = moment.tz(obj.timestamp, 'Europe/Paris').startOf('isoWeek').format('YYYY-MM-DD')
        const spend = parseFloat(obj.spend)
        const ftds = parseInt(obj.ftds)
        const cpa = parseFloat((spend / ftds).toFixed(2))
        const impressions = parseInt(obj.impressions)
        const clicks = parseInt(obj.clicks)
        const regs = parseInt(obj.regs)
        const cpc = parseFloat((spend / clicks).toFixed(2))
        const ftdConv = ((parseFloat(ftds / clicks))*100).toFixed(2)

        return {
            ...obj,
            date: date,
            month: month,
            week: week,
            year: year,
            impressions: impressions,
            clicks: clicks,
            spend: spend,
            cpc: cpc,
            regs: regs,
            ftds: ftds,
            cpa: cpa,
            clickConv: ftdConv

        };
    })

    return (
        <Modal
            visible={visible}
            style={styles.modalParent}
            transparent={false}
            config={config}
            
            >
            <ScrollView >

        <Pressable 
            style={styles.modalContainer}
            onPress={() => setVisible(false)}
            
            
            >

            <View style={[styles.modalWindow , {
                backgroundColor : theme.backgroundColor,
                borderColor: theme.color,
                borderWidth: 2,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
            }]}
            >
            

            <Pressable style={{
                width: pptView, 
                height: '100%',
                
                
                }}
                onPress={() => setVisible(true)}
                >
                {config.map((configItem, index) => (
                <ModalMap
                    key={index}
                    id={`modalMap-${index}`} // Passing the id prop directly to ModalMap
                    theme={theme}
                    config={configItem}
                    data={agg_result}
                    index={index}
                    client={client}
                    currentMetrics={currentMetrics}
                    patternLogo={patternLogo}
                    clientLogo={clientLogo}
                />
            ))}
            </Pressable>

            <Pressable onPress={generatePdf} disabled={pdfGenerating} style={[styles.addSlide , {backgroundColor: theme.backgroundColor2, color: theme.color, borderColor: theme.color}]}>
                <Text style={{color: 'white', fontSize: responsiveText}}>{pdfGenerating ? 'Generating PDF...' : 'Generate PDF'}</Text>
            </Pressable>
            <Pressable onPress={() => setVisible(false)} style={[styles.addSlide2 , {backgroundColor: theme.backgroundColor2, color: theme.color, borderColor: theme.color}]}>
                <Text style={{color: 'white', fontSize: responsiveText}}>Close</Text>
            </Pressable>

            </View>


        </Pressable>

</ScrollView>
        </Modal>
    )

}

const ModalMap = (props) => {

    const theme = props.theme
    const client = props.client
    const config = props.config
    const data = props.data
    const clientLogo = props.clientLogo
    const currentMetrics = props.currentMetrics
    const chartType = config.type
    const start = config.start
    const end = config.end
    const xAxis = config.xAxis
    const brand = config.brand
    const channel = config.channel
    const kw_type = config.kw_type
    const metric1 = config.metric1
    const metric2 = config.metric2
    const index = props.index
    const id = props.id
    const patternLogo = props.patternLogo

    let clientFix;

    if (client === 'Pepperstone') {
        clientFix = 'ps'
    } else (
        clientFix = client
    )

    let timeFrame;
    if (xAxis === 'date') {
        timeFrame = 'Daily'
    } else if (xAxis === 'week') {
        timeFrame = 'Weekly '
    } else if (xAxis === 'month') {
        timeFrame = 'Monthly'
    } else {timeFrame = xAxis}

    const responsiveTextLarge = windowHeight * 0.03
    const responsiveTextMid = windowHeight * 0.0175

    const parseProp = {
        data,
        chartType,
        start,
        end,
        xAxis,
        brand,
        channel,
        kw_type,
        metric1,
        metric2
    }

    const parseData = ParseCube(parseProp)

    const commentary = DocCommentary(parseData, xAxis, metric1, metric2, currentMetrics)
    

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
      };
    
    const labels = getUniqueValues(parseData, xAxis)

    const renderChartLarge = () => {
        if (chartType === 'line') {
            return <LineChart
            agg_daily_result={parseData}
            dateLabels={labels}
            theme={theme}
            metric={metric1}
            timeFrame={timeFrame}
            currentMetrics={currentMetrics}
        />
        } else if  (chartType === 'bar') {
            return <BarChart 
            dataProp={parseData}
            labels={labels}
            theme={theme}
            metric={metric1}
            timeFrame={timeFrame}
            currentMetrics={currentMetrics}
        />
        } else if  (chartType === 'weekly line') {
            return <LineChart
            agg_daily_result={parseData}
            dateLabels={labels}
            theme={theme}
            metric={metric1}
            timeFrame={'Weekly'}
            currentMetrics={currentMetrics}
        />
        } else if  (chartType === 'weekly bar') {
            return <BarChart 
            dataProp={parseData}
            labels={labels}
            theme={theme}
            metric={metric1}
            timeFrame={'Weekly'}
            currentMetrics={currentMetrics}
        />
        } else if  (chartType === 'monthly line') {
            return <LineChart
            agg_daily_result={parseData}
            dateLabels={labels}
            theme={theme}
            metric={metric1}
            timeFrame={'Monthly'}
            currentMetrics={currentMetrics}
        />
        } else if  (chartType === 'monthly bar') {
            return <BarChart 
            dataProp={parseData}
            labels={labels}
            theme={theme}
            metric={metric1}
            timeFrame={'Monthly'}
            currentMetrics={currentMetrics}
        />
        } else if  (chartType === 'grouped bar' && xAxis === 'brand') {
            return <BarChart 
            dataProp={parseData}
            labels={labels}
            theme={theme}
            metric={metric1}
            timeFrame={'Monthly'}
            currentMetrics={currentMetrics}
        />
        } else if  (chartType === 'grouped bar' && xAxis === 'channel') {
            return <BarChart 
            dataProp={parseData}
            labels={labels}
            theme={theme}
            metric={metric1}
            timeFrame={'Monthly'}
            currentMetrics={currentMetrics}
        />
        } else if  (chartType === 'grouped bar' && xAxis === 'kw_type') {
            return <BarChart 
            dataProp={parseData}
            labels={labels}
            theme={theme}
            metric={metric1}
            timeFrame={'Monthly'}
            currentMetrics={currentMetrics}
        />
        } else if  (chartType === 'grouped bar' && xAxis === 'date') {
            return <BarChart 
            dataProp={parseData}
            labels={labels}
            theme={theme}
            metric={metric1}
            timeFrame={'Monthly'}
            currentMetrics={currentMetrics}
        />
        } else if  (chartType === 'grouped bar') {
            return <BarChart 
            dataProp={parseData}
            labels={labels}
            theme={theme}
            metric={metric1}
            timeFrame={'Monthly'}
            currentMetrics={currentMetrics}
        />
        } else if  (chartType === 'mixed') {
            return <MixedChart 
            dataProp={parseData}
            labels={labels}
            theme={theme}
            agg_daily_result={parseData}
            dateLabels={labels}
            metric1={metric1}
            metric2={metric2}
            timeFrame={'Daily'}
            currentMetrics={currentMetrics}
        />
         } else if  (chartType === 'table') {
            return <DocTable 
            dataProp={parseData}
            labels={labels}
            theme={theme}
            agg_daily_result={parseData}
            columnWidth={0.01}
            size={9}
            currentMetrics={currentMetrics}
        />
         } else if  (chartType === 'cohorts') {
            return <DocCohorts 
            theme={theme}
            parseProp={parseProp}
            client={clientFix}
        />
         }
    }

    const titleText = {
        color: theme.color,
        fontSize: responsiveTextLarge * 1.2,
        fontWeight: 600,
        fontFamily: 'Electrolize',
        textAlign: 'right',
        width: '100%',
        paddingRight: '2.5%',
        textTransform: 'capitalize'
    }

    const clientText = {
        color: theme.color,
        fontSize: responsiveTextLarge * 1.2,
        fontWeight: 600,
        fontFamily: 'Electrolize',
        textAlign: 'left',
        width: '100%',
        paddingLeft: '2.5%',
        textTransform: 'capitalize'
    }

    const footerText = {
        color: theme.color,
        fontSize: responsiveTextMid,
        fontFamily: 'Electrolize',
        textAlign: 'center',
        width: '33.333333%',
        paddingRight: '2%',
        borderColor: 'grey',
        borderWidth: 0,
    }

    const commentText = {
        color: theme.color,
        fontSize: responsiveTextLarge,
        fontFamily: 'Electrolize',
        textAlign: 'center',

    }

    const titleContainer = {
        width: '100%',
        height: '7%',
        flexDirection: 'row',
        borderColor: 'grey',
        borderWidth: 0,
        borderBottom: '2px solid grey',
        alignItems: 'center',
        justifyContent: 'center',


    }

    const footerContainer = {
        height: '10%',
        width: '100%',
        flexDirection: 'row',
        borderColor: 'red',
        borderWidth: 0,
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: '2%',
        paddingRight: '2%',
        borderTop: '2px solid grey',
    }

    const logo = {
        width: windowWidth * 0.05,
        height: windowWidth * 0.05,
        resizeMethod: 'auto',
        borderColor: 'red',
        borderWidth: 0,
        justifyContent: 'center'
    }

    

    let slideTitle;
    if (chartType === 'cohorts') {
        slideTitle = `${brand} Cohorts`
    } else if (chartType === 'table') {
        slideTitle = `${brand} ${timeFrame}`
    }  else if (metric1 === 'ftds') {
        slideTitle = `${brand} ${timeFrame} ${currentMetrics.primaryMetricString}`
    } else if (metric1 === 'regs') {
        slideTitle = `${brand} ${timeFrame} ${currentMetrics.secondaryMetricString}`
    } else if (metric1 === 'ngr') {
        slideTitle = `${brand} ${timeFrame} ${currentMetrics.primaryRevenueString}`
    } else {
        slideTitle = `${brand} ${timeFrame} ${metric1}`
    }

    let dynamicCommentary;
    if (chartType === 'cohorts') {
        dynamicCommentary = ""
    } else {
        dynamicCommentary = commentary
    }

    let clientTitle;

    if (client === 'rank') {
        clientTitle = 'Rank International'
    } else if (client === 'fm') {
        clientTitle = 'Fortunate Media'
    } else if (client === 'afun') {
        clientTitle = 'AFUN'
    } else if (client === 'all_clients') {
        clientTitle = 'All Clients'
    } else (clientTitle = client)
    console.log(client)

    return (
        <View style={[styles.container , {backgroundColor: theme.backgroundColor}]} id={id}>
            <View style={titleContainer}>
                <View style={{width: '50%'}}>
                <Text style={clientText}>
                {clientTitle}
                </Text>
                </View>
                <View style={{width: '50%'}}>
                <Text style={titleText}>
                {slideTitle}
                </Text>
                </View>
            </View>
            <View style={styles.graphContainer}>
            {renderChartLarge()}
            </View>
            <View style={styles.commentContainer}>
                <Text style={commentText}>{dynamicCommentary} </Text>
            </View>

            <View style={footerContainer}>
                <View style={{width: '33.33333%',
                                    height: '100%',
                                    borderColor: 'grey',
                                    borderWidth: 0,
                                    }}>
                    <Image
                        style={logo}
                        source={{
                        uri: patternLogo,
                        }}
                    />
                </View>

                <Text style={footerText}>
                    Page {index +1}
                </Text>
                <View style={{width: '33.33333%',
                                height: '100%',
                                borderColor: 'grey',
                                borderWidth: 0,
                                alignItems: 'end',
                                //justifyContent: 'left'
                                }}>
                    <Image
                        style={logo}
                        source={{
                        uri: clientLogo,
                        }}
                    />
                </View>

            </View>
            

        </View>
    )

}





const styles = StyleSheet.create({
    modalParent: {
        padding: 20,
        borderRadius: 20,

    },
    modalContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        width: windowWidth * 1,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        
    },
    modalWindow: {

        width: '90%',
        padding: '0.75%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    container: {
        height: windowHeight * 1.2,
        width: '100%',
        fliexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: 'black',
        borderWidth: 0
    },
    titleContainer: {
        width: '100%',
        height: '5%',
        flexDirection: 'row',
        borderColor: 'grey',
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1%'
    },
    graphContainer: {
        height: '73%',
        width: '100%',
        padding: '3%'
    },
    
    commentContainer: {
        height: '10%',
        padding: '1%',
        flexDirection: 'column',
        borderColor: 'grey',
        borderWidth: 0,
        alignItems: 'center',
        justifyContent: 'center',
    },
    addSlide: {
        margin: '1%',
        borderWidth: 1,
        width: '7.5%',
        height: '1.5%',
        borderRadius: 10,
        position: 'absolute',
        left: '0%',
        top: '0.5%',
        alignItems: 'center',
        justifyContent: 'center'


    },
    addSlide2: {
        margin: '1%',
        borderWidth: 1,
        width: '7.5%',
        height: '1.5%',
        borderRadius: 10,
        position: 'absolute',
        left: '0%',
        top: '2.2%',
        alignItems: 'center',
        justifyContent: 'center'


    },
});


export default ModalPreview