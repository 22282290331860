import React, { useState } from 'react';
import { View, 
    StyleSheet, 
    Dimensions, 
    Text,
    Pressable,
    ScrollView
 } from 'react-native'
import FiltersEnhancedSingle_2 from '../ComponentsEnhanced/FiltersEnhancedSingle_2';
import { useCubeQuery } from '@cubejs-client/react';
import LoadingComponent from '../Components/Loader';
import AttributionCubeSingle from './AttributionCubeSingle';
import AttributionPanelLarge from './AtttributionPanelLarge';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const AttributionPanelSingle = (props) => {

    const theme = props.theme
    const currentMetrics = props.currentMetrics
    const handleStateChange = props.handleStateChange
    const propObject = props.propObject
    const filters = props.filters
    const cubeDimensions = props.cubeDimensions
    const chartType = props.chartType
    const cubeTable = props.cubeTable
    const dataPropFilter = props.dataPropFilter
    const uniqueFilter1 = dataPropFilter.uniqueFilter1
    const uniqueFilter2 = dataPropFilter.uniqueFilter2
    const filterParams = props.filterParams

    const filter1name = filterParams.filter1name
    const filter2name = filterParams.filter2name
    const filter1List = filterParams.filter1List
    const filter2List = filterParams.filter2List

    const filterValueBase = {
      filter1List : [],
      filter2List : [],
      filter3List : []
    }
  const [filterList, setFilterList] = useState(filterValueBase);

    const start = filters.start
    const end = filters.end
    let filter1Filter, filter1Operator;

  if (filters[filter1name] === 'Total') {
    filter1Filter = ['null']
    filter1Operator = ['notEquals']
  } else if (filters[filter1name].length === 0) {
    filter1Filter = ['null']
    filter1Operator = ['notEquals']
  } else if (filters[filter1name][0] === 'Total') {
    filter1Filter = ['null']
    filter1Operator = ['notEquals']
  } else {
    filter1Filter = filter1List
    filter1Operator = ['equals']
  }

  let filter2Filter, filter2Operator;

  if (filters[filter2name] === 'Total') {
    filter2Filter = ['null']
    filter2Operator = ['notEquals']
  } else if (filters[filter2name].length === 0) {
    filter2Filter = ['null']
    filter2Operator = ['notEquals']
  } else if (filters[filter2name][0] === 'Total') {
    filter2Filter = ['null']
    filter2Operator = ['notEquals']
  } else {
    filter2Filter = filter2List
    filter2Operator = ['equals']
  }


    const [chartClick, setChartClick] = useState('Grouped Bar')
    const [axisClick, setAxisClick] = useState('Brand')
    const [metric1Click, setMetric1] = useState('FTDs')
    const [metric2Click, setMetric2] = useState('NGR')



    const chartConfig = {
        type: chartClick,
        xAxis: axisClick,
        metric1: metric1Click,
        metric2: metric2Click
    }

    const cubeFilters = [
        {
          "member": `${cubeTable}.${filter1name}`,
          "operator": `${filter1Operator}`,
          "values": filter1Filter
        },
        {
          "member": `${cubeTable}.${filter2name}`,
          "operator": `${filter2Operator}`,
          "values": filter2Filter
        }
      ]

    const cubeQuery = AttributionCubeSingle(chartConfig , cubeFilters, cubeTable, start, end )
    const { resultSet, isLoading, error, progress } = useCubeQuery(cubeQuery);

    if (!resultSet) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    const cubeResponse = resultSet.loadResponse.results[0].data

    function axisClickHandler(props) {
        setAxisClick(props)
    }

    function metric1Handler(props) {
        setMetric1(props)
    }

    return (
        <View style={[styles.container, { backgroundColor: theme.backgroundColor }]}>
        <View style={{ width: '100%', flexDirection: 'flex-start', fontFamily: 'Electrolize' }}>
        <FiltersEnhancedSingle_2
                style={{
                    height: '10%',
                    borderColor: 'red',
                    borderWidth: 0,
                    width: '100%',
                    fontFamily: 'Electrolize'
                }}
                uniqueFilter1={uniqueFilter1}
                uniqueFilter2={uniqueFilter2}
                handleStateChange={handleStateChange}
                propObject={propObject}
                filterParams={filterParams}
                theme={theme}
                large={1}
                setFilterList={setFilterList}
                filterList={filterList}
                currentMetrics={currentMetrics}
            />
        </View>
            <TopPanel
                theme={theme}
                axisClickHandler={axisClickHandler}
                metric1Handler={metric1Handler}
                cubeDimensions={cubeDimensions}
                cubeResponse={cubeResponse}
                chartType={chartType}
                cubeTable={cubeTable}
                currentMetrics={currentMetrics}

            />
        </View>
    )

}

const DimensionBox = ({cubeDimensions, axisClickHandler, theme, currentMetrics}) => {

    let responseText;

    if (windowWidth < 1281) {
        responseText = windowHeight * 0.025
    } else if (windowWidth > 2000) {
        responseText = windowHeight * 0.01
    } else {responseText = windowHeight * 0.017}


    
    return (
        <ScrollView style={{ flex: 1 }}>
          {cubeDimensions.map((item, index) => {
            let formatValue

            if (item === 'kw_type') {
              formatValue = currentMetrics.primaryDimensionString
            } else {formatValue = item}

            return (
            <Pressable
              key={index}
              style={[[styles.pressable, 
                        index !== 0 && styles.marginTop , 
                            {borderColor : theme.color,
                            backgroundColor: theme.backgroundColor2
                            }]]} // Apply margin to all but the first Pressable
              onPress={() => axisClickHandler(item)}
            >
              <Text
                style={{color: theme.backgroundColor, fontSize:responseText}}
              >{item}</Text>
            </Pressable>
          )})}
        </ScrollView>
      );
    };



const TopPanel = (props) => {

    const theme = props.theme
    const chartConfig = props.chartConfig
    const cubeDimensions = props.cubeDimensions
    const axisClickHandler = props.axisClickHandler
    const metric1Handler = props.metric1Handler
    const cubeResponse = props.cubeResponse
    const chartType = props.chartType
    const cubeTable = props.cubeTable
    const currentMetrics = props.currentMetrics


    return (
        <View style={[styles.topPanelParent, { borderColor: theme.color }]}>
            <View style={styles.topPanelChildLarge}>
            <AttributionPanelLarge
                theme={theme}
                cubeResponse={cubeResponse}
                chartType={chartType}
                cubeTable={cubeTable}
                currentMetrics={currentMetrics}
            />

            </View>
            <View style={styles.topPanelChildSmall}>
            <DimensionBox
                cubeDimensions={cubeDimensions}
                axisClickHandler={axisClickHandler}
                theme={theme}
                currentMetrics={currentMetrics}
                />
            </View>

        </View>
    )
}



const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'yellow',
        paddingTop: 0,
        flexDirection: 'column',
        width: '100%',
        height: windowHeight * 0.931,

    },
    topPanelParent: {
        width: '100%',
        height: '90%',
        borderWidth: 0,
        paddingTop: '1.5%',
        paddingBottom: '1%',
        flexDirection: 'row',
    },
    topPanelChildLarge: {
        width: '81%',
        height: '100%',
        borderWidth: 0,
        borderColor: 'yellow',
    },
    topPanelChildSmall: {
        width: '19%',
        height: '100%',
        borderWidth: 0,
        borderColor: 'yellow',
        flexDirection: 'column',
        padding: '1.5%'
    },
    dimensionBox: {
        width: '100%', 
        height: '40%',
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderRadius: 1,
        paddingBottom: '1%'

    },
    pressable: {
        width: '100%',
        padding: 10, // Add padding for space around text
        backgroundColor: 'white', // Optional background color
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: 'red',
        borderWidth: 1,
        borderRadius: 5,
      },
      marginTop: {
        marginTop: 10, // Adjust the margin as needed
      },
    
})

export default AttributionPanelSingle