import { View, Text, StyleSheet, Dimensions } from 'react-native'
import SyncProblemIcon from '@mui/icons-material/SyncProblem';

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

const NoDataContainer = (props) => {
    const theme = props.theme

    const iconStyle = {
        fontSize : windowWidth * 0.2,
        color: theme.panelBackground
    }

    const textStyle = {
        fontSize : windowWidth * 0.02,
        color: theme.panelBackground,
        fontFamily: 'Electrolize',
    }

    return (
        <View style={styles.container}>
            <SyncProblemIcon
                style={iconStyle}
            />
            <Text style={textStyle}>
            No Data Matching Your Query - Please Refresh and Start Again
            </Text>
            <Text style={textStyle}>
            Reach out to the Pattern team if your problem persists
            </Text>

        </View>
    )

}

export default NoDataContainer

const styles = StyleSheet.create({
    container: {
        borderWidth: 2,
        borderColor: 'grey',
        height: windowHeight * 0.93,
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
        alignItems: 'center',
        justifyContent: 'center'
    },

})