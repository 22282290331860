export default function AttEnhancedTableParse(aggResult, cubeTable, tableConfig) {

    var dataset = aggResult
    var dataset2 = aggResult
    const model1 = tableConfig.model1
    const model2 = tableConfig.model2
    const model3 = tableConfig.model3
    const dimension = tableConfig.dimension
    const metric = tableConfig.metric

    if (!dataset || dataset.length === undefined) {
        return null;
    }

    var agg_result = [];
    var total_result = []

    const aggregatedResults = dataset2.reduce((res, value) => {
        const metrics = [
            { key: 'ftds', model: model1 },
            { key: 'react', model: model1 },
            { key: 'ngr', model: model1 },
            { key: 'ftds', model: model2 },
            { key: 'react', model: model2 },
            { key: 'ngr', model: model2 },
            { key: 'ftds', model: model3 },
            { key: 'react', model: model3 },
            { key: 'ngr', model: model3 },
            { key: 'clicks' },
            { key: 'spend' },
            { key: 'impressions' },
            { key: 'regs_ft_lifetime' }
        ];
    
        metrics.forEach(metric => {
            const metricName = metric.model ? `${metric.key}_${metric.model}` : metric.key;
            res[metricName] = (res[metricName] || 0) + parseInt(value[metricName] || 0);
        });
    
        return res;
    }, {});

    agg_result = dataset.map((obj) => {

        const ftdsBase1 = `ftds_${model1}`
        const reactBase1 = `react_${model1}`
        const ngrBase1 = `ngr_${model1}`
        const ftdsBase2 = `ftds_${model2}`
        const reactBase2 = `react_${model2}`
        const ngrBase2 = `ngr_${model2}`
        const ftdsBase3 = `ftds_${model3}`
        const reactBase3 = `react_${model3}`
        const ngrBase3 = `ngr_${model3}`

        const cpaBase1 = `cpa_${model1}`
        const cpaBase2 = `cpa_${model2}`
        const cpaBase3 = `cpa_${model3}`

        const roasBase1 = `roas_${model1}`
        const roasBase2 = `roas_${model2}`
        const roasBase3 = `roas_${model3}`

        const arpuBase1 = `arpu_${model1}`
        const arpuBase2 = `arpu_${model2}`
        const arpuBase3 = `arpu_${model3}`

        const objDimension = dimension
        const objValue = obj[dimension]
        const clicks = obj.clicks || 0
        const spend = obj.spend || 0;
        const impressions = obj.impressions || 0
        const regs = obj.regs_ft_lifetime || 0
        const ctr = clicks === 0 || impressions === 0 || isNaN(impressions) ? 0 : (clicks / impressions) * 100;
        const cpc = clicks === 0 || spend === 0 || isNaN(spend) ? 0 : (spend / clicks);

        const ftds1 = parseFloat(obj[ftdsBase1]) || 0
        const ftds2 = parseFloat(obj[ftdsBase2]) || 0
        const ftds3 = parseFloat(obj[ftdsBase3]) || 0
        const react1 = parseFloat(obj[reactBase1]) || 0
        const react2 = parseFloat(obj[reactBase2]) || 0
        const react3 = parseFloat(obj[reactBase3]) || 0
        const ngr1 = parseFloat(obj[ngrBase1]) || 0
        const ngr2 = parseFloat(obj[ngrBase2]) || 0
        const ngr3 = parseFloat(obj[ngrBase3]) || 0


        const cpa1 = spend === 0 || ftds1 === 0 || isNaN(ftds1) ? 0 : spend / ftds1;
        const cpa2 = spend === 0 || ftds2 === 0 || isNaN(ftds2) ? 0 : spend / ftds2;
        const cpa3 = spend === 0 || ftds3 === 0 || isNaN(ftds3) ? 0 : spend / ftds3;

        const roas1 = spend === 0 || ngr1 === 0 || isNaN(ngr1) ? 0 : (ngr1 / spend) * 100;
        const roas2 = spend === 0 || ngr2 === 0 || isNaN(ngr2) ? 0 : (ngr2 / spend) * 100;
        const roas3 = spend === 0 || ngr3 === 0 || isNaN(ngr3) ? 0 : (ngr3 / spend) * 100;

        const arpu1 = ngr1 === 0 || ftds1 === 0 || isNaN(ftds1) ? 0 : ngr1 / ftds1;
        const arpu2 = ngr2 === 0 || ftds2 === 0 || isNaN(ftds2) ? 0 : ngr2 / ftds2;
        const arpu3 = ngr3 === 0 || ftds3 === 0 || isNaN(ftds3) ? 0 : ngr3 / ftds3;

        if (metric === 'ftds') {
            return {
                [objDimension]: objValue,
                impressions: parseFloat(impressions.toFixed(2)) || 0,
                clicks: parseFloat(clicks.toFixed(6)) || 0,
                ctr: parseFloat(ctr.toFixed(2)) || 0,
                spend: parseFloat(spend.toFixed(2)) || 0,
                cpc: parseFloat(cpc.toFixed(2)) || 0,
                regs: regs,
                [ftdsBase1]: parseFloat(ftds1.toFixed(2)) || 0,
                [ftdsBase2]: parseFloat(ftds2.toFixed(2)) || 0,
                [ftdsBase3]: parseFloat(ftds3.toFixed(2)) || 0,

            }
        } else if (metric === 'ngr') {
            return {
                [objDimension]: objValue,
                impressions: parseFloat(impressions.toFixed(2)) || 0,
                clicks: parseFloat(clicks.toFixed(2)) || 0,
                ctr: parseFloat(ctr.toFixed(6)) || 0,
                spend: parseFloat(spend.toFixed(2)) || 0,
                cpc: parseFloat(cpc.toFixed(2)) || 0,
                regs: regs,
                [ngrBase1]: parseFloat(ngr1.toFixed(2)) || 0,
                [ngrBase2]: parseFloat(ngr2.toFixed(2)) || 0,
                [ngrBase3]: parseFloat(ngr3.toFixed(2)) || 0,
            }
        } else if (metric === 'cpa') {
            return {
                [objDimension]: objValue,
                impressions: parseFloat(impressions.toFixed(2)) || 0,
                clicks: parseFloat(clicks.toFixed(2)) || 0,
                ctr: parseFloat(ctr.toFixed(6)) || 0,
                spend: parseFloat(spend.toFixed(2)) || 0,
                cpc: parseFloat(cpc.toFixed(2)) || 0,
                regs: regs,
                [cpaBase1]: parseFloat(cpa1.toFixed(2)) || 0,
                [cpaBase2]: parseFloat(cpa2.toFixed(2)) || 0,
                [cpaBase3]: parseFloat(cpa3.toFixed(2)) || 0,
            }
        } else if (metric === 'roas') {
            return {
                [objDimension]: objValue,
                impressions: parseFloat(impressions.toFixed(2)) || 0,
                clicks: parseFloat(clicks.toFixed(2)) || 0,
                ctr: parseFloat(ctr.toFixed(6)) || 0,
                spend: parseFloat(spend.toFixed(2)) || 0,
                cpc: parseFloat(cpc.toFixed(2)) || 0,
                regs: regs,
                [roasBase1]: parseFloat(roas1.toFixed(2)) || 0,
                [roasBase2]: parseFloat(roas2.toFixed(2)) || 0,
                [roasBase3]: parseFloat(roas3.toFixed(2)) || 0,
            }
        } else if (metric === 'arpu') {
            return {
                [objDimension]: objValue,
                impressions: parseFloat(impressions.toFixed(2)) || 0,
                clicks: parseFloat(clicks.toFixed(2)) || 0,
                ctr: parseFloat(ctr.toFixed(6)) || 0,
                spend: parseFloat(spend.toFixed(2)) || 0,
                cpc: parseFloat(cpc.toFixed(2)) || 0,
                regs: regs,
                [arpuBase1]: parseFloat(arpu1.toFixed(2)) || 0,
                [arpuBase2]: parseFloat(arpu2.toFixed(2)) || 0,
                [arpuBase3]: parseFloat(arpu3.toFixed(2)) || 0,
            }
        } else {
            return {
                [objDimension]: objValue,
                impressions: parseFloat(impressions.toFixed(2)) || 0,
                clicks: parseFloat(clicks.toFixed(6)) || 0,
                ctr: parseFloat(ctr.toFixed(2)) || 0,
                spend: parseFloat(spend.toFixed(2)) || 0,
                cpc: parseFloat(cpc.toFixed(2)) || 0,
                regs: regs,
                [ftdsBase1]: parseFloat(ftds1.toFixed(2)) || 0,
                [ftdsBase2]: parseFloat(ftds2.toFixed(2)) || 0,
                [ftdsBase3]: parseFloat(ftds3.toFixed(2)) || 0,

            }
        }
    })

    return {agg_result, aggregatedResults}

}