import React, { useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Text,
    Pressable
} from 'react-native';
import NightlightRoundOutlinedIcon from '@mui/icons-material/NightlightRoundOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import ContrastIcon from '@mui/icons-material/Contrast';

const windowWidth = Dimensions.get('window').width;

const MobileTheme = (props) => {

    const theme = props.theme
    const configHandler = props.configHandler
    const modalHandler = props.modalHandler
    const responsiveIcon = windowWidth * 0.2
    const responsiveText = windowWidth * 0.035

    function clickHandler (props) {
        modalHandler(false)
        configHandler(props)
    }

    const textTheme = {
        color: theme.color2,
        fontSize: responsiveText,
        textAlign: 'center',
        fontFamily: 'Electrolize',
    }

    const rowChild = {
        height: '100%',
        width: '50%',
        flexDirection: 'column',
        borderColor: theme.color2,
        borderWidth: 1,
    }

    return (
        <View style={styles.container}>
            <View style={styles.rowParent}>
                <Pressable 
                    style={[rowChild , {borderTopLeftRadius:10}]}
                    onPress={() => clickHandler("white")}
                    
                    >
                    <View style={styles.childIcon}>
                        <LightModeOutlinedIcon
                            sx={{ color: theme.color2, fontSize: responsiveIcon }}
                        />
                    </View>
                    <View style={styles.childText}>
                        <Text style={textTheme}>
                            Day Mode
                        </Text>
                    </View>
                </Pressable>

                <Pressable 
                    style={[rowChild , {borderTopRightRadius:10}]}
                    onPress={() => clickHandler("dark")}
                    
                    >
                    <View style={styles.childIcon}>
                        <NightlightRoundOutlinedIcon
                            sx={{ color: theme.color2, fontSize: responsiveIcon }}
                        />
                    </View>
                    <View style={styles.childText}>
                        <Text style={textTheme}>
                            Night Mode
                        </Text>
                    </View>
                </Pressable>
            </View>
            <View style={styles.rowParent}>
                <Pressable 
                    style={[rowChild , {borderBottomLeftRadius:10}]}
                    onPress={() => clickHandler("light")}
                    
                    >
                    <View style={styles.childIcon}>
                        <ColorLensIcon
                            sx={{ color: theme.color2, fontSize: responsiveIcon }}
                        />
                    </View>
                    <View style={styles.childText}>
                        <Text style={textTheme}>
                            Colour Mode
                        </Text>
                    </View>
                </Pressable>

                <Pressable 
                    style={[rowChild , {borderBottomRightRadius:10}]}
                    onPress={() => clickHandler("black")}
                    
                    >
                    <View style={styles.childIcon}>
                        <ContrastIcon
                            sx={{ color: theme.color2, fontSize: responsiveIcon }}
                        />
                    </View>
                    <View style={styles.childText}>
                        <Text style={textTheme}>
                            Contrast Mode
                        </Text>
                    </View>
                </Pressable>
            </View>
        </View>
    )

}

const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        borderColor: 'white',
        borderWidth: 1,
        borderRadius: 10,
        flexDirection: 'column',
    },
    rowParent: {
        height: '50%',
        width: '100%',
        flexDirection: 'row'
    },
    childIcon: {
        width: '100%',
        height: '80%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    childText: {
        width: '100%',
        height: '20%',
        justifyContent: 'center',
        alignItems: 'center',
    }
});

export default MobileTheme