import { memo } from 'react'
import { useDrop } from 'react-dnd'
import { View, Text, Dimensions } from 'react-native'

const windowWidth = Dimensions.get('window').width;

export const Dustbin = memo(function Dustbin({
  accept,
  lastDroppedItem,
  onDrop,
  theme,
  currentConfig,
  indexCount,
  chartType
}) {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const style = {
    color: 'white',
    height: windowWidth * 0.08,
    width: windowWidth * 0.08,
    textAlign: 'center',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid white',
    padding: '10%',
  }

  const largeStyle = {
    color: 'white',
    height: windowWidth * 0.11,
    width: windowWidth * 0.11,
    textAlign: 'center',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid white',
    padding: '0%',
  }

  let dynamicText;

  if (chartType === 'main' && windowWidth > 2000) {
    dynamicText = windowWidth * 0.02
  } else if (chartType === 'main' && windowWidth >= 1400) {
    dynamicText = windowWidth * 0.017
  } else if (chartType === 'main' && windowWidth < 1400) {
    dynamicText = windowWidth * 0.02
  } else if (windowWidth > 2000) {
    dynamicText = windowWidth * 0.01
  } else if (windowWidth >= 1400) {
    dynamicText = windowWidth * 0.01
  } else if (windowWidth < 1400) {
    dynamicText = windowWidth * 0.01
  }
    else {dynamicText = 15}


  const textLarge = {
    textAlign: 'center',
    color: 'white',
    fontWeight: 'bold',
    fontFamily: 'Electrolize',
    textTransform: 'capitalize',
    fontSize: dynamicText

  }

  const isActive = isOver && canDrop
  const bucketType = accept[0]
  
  let bucketStyle = indexCount !== 4 ? style : largeStyle;


  let backgroundColor;
  if (isActive) {
    backgroundColor = theme.backgroundColor2
  } else if (canDrop) {
    backgroundColor = '#7DFA00'
  }  else if (accept[0] === 'type') {
    backgroundColor = theme.backgroundColor2
  } else if (bucketType === 'xAxis') {
    backgroundColor = theme.gradc50
  } else if (bucketType === 'shortcut') {
    backgroundColor = theme.color3
  } else {backgroundColor = 'grey' }

  let bucketLabel;
  if (!lastDroppedItem) {
    bucketLabel = currentConfig.shortcuts[indexCount]
  } else {bucketLabel = lastDroppedItem.name}


  return (

    <View ref={drop} style={{ ...bucketStyle, backgroundColor }} data-testid="dustbin">
      <Text style={textLarge}>
      {isActive
        ? 'Release to drop'
        : ""}
      </Text>

        <Text style={textLarge}> {bucketLabel}</Text>

    </View>

  )
})
