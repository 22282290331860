import React from 'react';
import { View, Text } from 'react-native'

const DndMetric = (props) => {

    const currentMetrics = props.currentMetrics
    const mainAgg = props.mainAgg
    const theme = props.theme
    const currentMetric = props.currentMetric
    const currentConfig = props.currentConfig

    const panelHeight = currentConfig.h

    const impressions = mainAgg.map((obj) => obj['impressions']);
    const clicks = mainAgg.map((obj) => obj['clicks']);
    const spend = mainAgg.map((obj) => obj['spend']);
    const regs = mainAgg.map((obj) => obj['regs']);
    const ftds = mainAgg.map((obj) => obj['ftds']);
    const ngr = mainAgg.map((obj) => obj['ngr']);

    function sumArray(arr) {
        // Initialize a variable to store the sum
        let sum = 0;
        
        // Iterate through the array and add each element to the sum
        for (let i = 0; i < arr.length; i++) {
            sum += arr[i];
        }
        
        // Return the sum
        return (parseFloat(sum)).toFixed(2);
    }

    let output;

    if (currentMetric === 'impressions') {
        output = parseFloat((sumArray(impressions))).toFixed(0)
    }

    if (currentMetric === 'clicks') {
        output = parseFloat((sumArray(clicks))).toFixed(0)
    }

    if (currentMetric === 'spend') {
        output = "€"+parseFloat((sumArray(spend))).toFixed(2)
    }

    if (currentMetric === 'regs') {
        output = parseFloat((sumArray(regs))).toFixed(0)
    }

    if (currentMetric === 'ftds') {
        output = parseFloat((sumArray(ftds))).toFixed(0)
    }

    if (currentMetric === 'cpc') {
        output = "€"+parseFloat((sumArray(spend)/sumArray(clicks))).toFixed(2)
    }

    if (currentMetric === 'ctr') {
        output = "€"+parseFloat((sumArray(clicks)/sumArray(impressions))).toFixed(2)
    }

    if (currentMetric === 'cpr') {
        output = "€"+parseFloat((sumArray(spend)/sumArray(regs))).toFixed(2)
    }

    if (currentMetric === 'cpa') {
        output = "€"+parseFloat((sumArray(spend)/sumArray(ftds))).toFixed(2)
    }

    if (currentMetric === 'ngr') {
        output = "€"+parseFloat((sumArray(ngr))).toFixed(0)
    }

    let convLabel

    if (currentMetric === 'ctr') {
        convLabel = `${output}%`
    } if (currentMetric === 'ftds') {
        convLabel = currentMetrics.primaryMetricString
    } else if (currentMetric === 'ngr') {
        convLabel = currentMetrics.primaryRevenueString
    } else if (currentMetric === 'regs') {
        convLabel = currentMetrics.secondaryMetricString
    } else {convLabel = currentMetric}

    const container = {
        width: '100%',
        height: '100%',
        flexDirection: 'column'
    }

    const header = {
        width: '100%',
        height: '20%',
        alignItems: 'center',
        justifyContent: 'center'
    }

    const textPanel = {
        width: '100%',
        height: '80%',
        alignItems: 'center',
        justifyContent: 'center'
    }

    const headerText = {
        fontFamily: 'Electrolize',
        fontSize: 7.5 * panelHeight,
        color: theme.color,
        textTransform: 'capitalize'
    }

    const panelText = {
        fontFamily: 'Electrolize',
        fontSize: 11 * panelHeight,
        color: theme.color
    }

    return (
        <View style={container}>
            <View style={header}>
                <Text style={headerText}>{convLabel}:</Text>
            </View>
            <View style={textPanel}>
                <Text style={panelText}>{output}</Text>
                
            </View>

        </View>
    )

}

export default DndMetric