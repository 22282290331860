import React, { useState } from 'react';
import { Modal, 
    View, 
    StyleSheet, 
    Dimensions, 
    Text,
    Pressable } from 'react-native';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const ModalType = (props) => {

    const visible = props.visible
    const setVisible = props.setVisible
    const config = props.config
    const theme = props.theme
    const currentMetrics = props.currentMetrics
    const updateSlide = props.updateSlide
    const modalState = props.modalState

    const data = modalState.data || []
    const title = modalState.title

    const currentSlide = config.slide

    let updateItem;

    if (title === 'Chart Type') {
        updateItem = 'type'
    } else if (title === 'xAxis') {
        updateItem = 'xAxis'
    } else if (title === 'Filter: Brand') {
        updateItem = 'brand'
    } else if (title === 'Filter: Channel') {
        updateItem = 'channel'
    } else if (title === 'Filter: Type') {
        updateItem = 'kw_type'
    } else if (title === 'Metric 1') {
        updateItem = 'metric1'
    } else if (title === 'Metric 2') {
        updateItem = 'metric2'
    }


    function clickHandler (prop, updateItem) {

        const clickArray = {
            currentSlide: currentSlide -1,
            currentVar : updateItem,
            currentValue: prop
        }

        updateSlide(clickArray)
        setVisible(false)

    }

    const modalPanel = {
        height: '90%',
        width: '100%',
        flexDirection: 'column',
        borderColor: theme.color,
        borderWidth: 1,
        
    }

    const modalPanelRow = {
        height: '25%',
        width: '100%',
        flexDirection: 'row',
    }

    const panelRowItem = {
        height: '100%',
        width: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1%'
    }

    const panelChild = {
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.backgroundColor2,
        borderColor: theme.color,
        borderWidth: 1,
        padding: '0.5%',
        borderRadius: 10
    }

    const panelChildEmpty = {
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.backgroundColor,
        borderColor: theme.backgroundColor,
        borderWidth: 1,
        padding: '0.5%'
    }

    const text = {
        textAlign: 'center',
        fontFamily: 'Electrolize',
        fontSize: 20,
        color: theme.color2,
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
    }

    const textItem = {
 // Each Text component takes up half the container's width
        textAlign: 'center',
        borderColor: 'black',
        borderWidth: 1,
        borderRadius: 10,
        backgroundColor: theme.backgroundColor2,
        fontSize: 15,
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
        color: theme.color2,
        padding: '8%'


        // Additional styling for Text components
    }

    const ScrollableList = ({ data, clickHandler, updateItem, styles, textItem }) => {
        return (
            <div style={styles.mapContainer}>
                <div style={styles.scrollContainer}>
                    {data.map((item, index) => (
                        <div
                            key={index} // Added key here
                            onClick={() => clickHandler(item, updateItem)}
                            style={{ width: '20%', padding: '1%', cursor: 'pointer' }}
                        >
                            <span style={textItem}>
                                {item}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <Modal
            visible={visible}
            style={styles.modalParent}
            transparent={true}
            config={config}
            >
        <Pressable 
            style={styles.modalContainer}
            onPress={() => setVisible(false)}
            >
            <View style={[styles.modalWindow , {
                backgroundColor : theme.backgroundColor,
                borderColor: theme.color,
                borderWidth: 2,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
            }]}>
                <View style={modalPanel} >
                    <View style={modalPanelRow} >
                        <View style={panelRowItem}>
                            <Pressable style={panelChild}>
                                <Text style={text}>
                                {title}
                                </Text>
                            </Pressable>

                        </View>
                        <View style={panelRowItem}>
                            <Pressable 
                                style={panelChildEmpty}>
                                <Text style={text}>

                                </Text>
                            </Pressable>

                        </View>
                    </View>
                    <View 
                        style={{
                            flex: 1,
                            borderColor: 'black',
                            borderWidth: 0
                        }}>
                        <View style={styles.mapContainer}>
                            {data.map((item, index) => {
                                let convLabel

                                if (item === 'ftds') {
                                    convLabel = currentMetrics.primaryMetric
                                } else if (item === 'ngr') {
                                    convLabel = currentMetrics.primaryRevenue
                                } else if (item === 'regs') {
                                    convLabel = currentMetrics.secondaryMetric
                                } else if (item === 'kw_type') {
                                    convLabel = currentMetrics.primaryDimensionString
                                } else {convLabel = item}

                                return (

                                <Pressable 
                                    onPress={() => clickHandler(item, updateItem)}
                                    style={{
                                        width: '20%',
                                        padding: '1%'}}
                                    >
                                <Text key={item} style={textItem}>
                                    {convLabel}
                                </Text>
                                </Pressable>

                            )}
                            )}
        </View>

                    </View>



                </View>

            </View>


        </Pressable>


        </Modal>
    )

}

const ModalChild = (props) => {

}

const styles = StyleSheet.create({
    modalParent: {
        padding: 20,
        borderRadius: 20,
    },
    modalContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        width: windowWidth * 1,
        height: windowHeight * 1,
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        alignItems: 'center',
        justiftContent: 'center',
        
    },
    modalWindow: {
        height: '50%',
        width: '50%',
        borderRadius: 15,
        padding: '1%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    mapContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        borderColor: 'grey',
        borderWidth: 1,
        padding: 0,
        flex: 1,
        overflowY: 'auto', 
    },
    pressableItem: {

        padding: '1%',

    },
    textItem: {
        textAlign: 'center',
        borderColor: 'black',
        borderWidth: 0,
        fontSize: 40,
        // Ensuring text fits within the Pressable item
        flexShrink: 1, 
    },

});


export default ModalType