import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions
} from 'react-native'
import MetricList from '../Components/Checkbox';
import TableMain from '../ChartsDesktop/TableMain.js'
import FiltersEnhancedSingleLarge from '../ComponentsEnhanced/FiltersEnhancedSingleLarge.js';
import WebFont from 'webfontloader';


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const PanelsTable = (props) => {

    const dataProp = props.dataProp
    const agg_result = dataProp.agg_result
    const uniqueBrands = dataProp.uniqueBrands
    const uniqueChannels = dataProp.uniqueChannels
    const uniqueTypes = dataProp.uniqueTypes
    const theme = props.theme
    const handleStateChange = props.handleStateChange
    const propObject = props.propObject
    const uniqueFilter1 = dataProp.uniqueFilter1
    const uniqueFilter2 = dataProp.uniqueFilter2
    const uniqueFilter3 = dataProp.uniqueFilter3
    const currentMetrics = props.currentMetrics
    const filterParams = props.filterParams
    const filterList = props.filterList
    const setFilterList = props.setFilterList
    const [aggregationModel, setAggregationModel] = useState({
        impressions: 'sum',
        clicks: 'sum',
        regs: 'sum',
        spend: 'sum',
        ftds: 'sum',
        cpa: 'cpa',
        cpr:  'cpr',
        cpc: 'cpc',
        ctr : 'ctr',
        brand: 'total',
        channel: 'total',
        kw_type: 'total',
        date: 'total'
    })
    const [currentSettings, setcurrentSettings] = useState(['brand', 'spend', 'ftds', 'cpa']);

    const handleCheckBoxChange = (event) => {
        setcurrentSettings(event)

        const aggregationObject = {};
        event.forEach((item) => {
            if (item === "cpa" ) {
                aggregationObject[item] = 'cpa';
            } else if (item === "cpr") {
                aggregationObject[item] = 'cpr';
            } else if (item === "ctr") {
                aggregationObject[item] = 'ctr';
            } else if (item === "cpc") {
                aggregationObject[item] = 'cpc';
            }  else if (item === "date" || item === "brand" || item === "channel" || item === "kw_type") {
                aggregationObject[item] = "total";
            } else {
                aggregationObject[item] = "sum";
            }
        });
        setAggregationModel(aggregationObject)
      };


      
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    const panelMain = {

        width: windowWidth * 0.85,
        height: '100%',
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 40,
        fontFamily: 'Electrolize',
        borderRadius: 5
    }

    const largeRows = ((windowHeight / 50).toFixed(0))


    const metricList = [
        {'key' : 'date'},
        {'key' : 'brand'},        
        {'key' :  'channel'},
        {'key' :  'kw_type'},
        {'key' :  'impressions'},
        {'key' :  'clicks'},
        {'key' :  'spend'},
        {'key' :  'regs'},
        {'key' :  'ftds'},
        {'key' :  'cpa'},
        {'key' :  'cpr'},
        {'key' :  'ctr'},
        {'key' :  'cpc'}]

    

    return (
        <View style={styles.container}>
            <View style={{ flexDirection: 'row' }}>
                <View style={panelMain}>
                    <View style={{ width: '100%', flexDirection: 'flex-start', fontFamily: 'Electrolize' }}>
                        <FiltersEnhancedSingleLarge
                            style={{
                                height: '10%',
                                borderColor: 'red',
                                borderWidth: 0,
                                width: '100%',
                                fontFamily: 'Electrolize'
                            }}
                            uniqueFilter1={uniqueFilter1}
                            uniqueFilter2={uniqueFilter2}
                            uniqueFilter3={uniqueFilter3}
                            handleStateChange={handleStateChange}
                            propObject={propObject}
                            filterParams={filterParams}
                            theme={theme}
                            setFilterList={setFilterList}
                            filterList={filterList}
                            currentMetrics={currentMetrics}
                        />
                    </View>
                    <View style={{ flexDirection: 'row', flex: 1, width: '98%', borderColor: 'red', borderWidth: 0, marginTop: '1%', minHeight: '30%' }}>
                        <TableMain
                            dataProp={agg_result}
                            columnWidth={0.03}
                            colNum={largeRows}
                            theme={theme}
                            metricList={metricList}
                            currentSettings={currentSettings}
                            aggregationModel={aggregationModel}
                            setAggregationModel={setAggregationModel}
                            currentMetrics={currentMetrics}

                        />
                    </View>
                </View>
                <View style={{ width: '14.9%' , alignItems : 'center' }}>
                
                <MetricList
                    style={{ width: '100%'}}
                    theme={theme}
                    dataProp={dataProp}
                    metricList={metricList}
                    handleCheckBoxChange={handleCheckBoxChange}
                    currentSettings={currentSettings}
                    setAggregationModel={setAggregationModel}
                    currentMetrics={currentMetrics}
                />
                </View>
            </View>
        </View>

    )

}

export default PanelsTable

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        minHeight: windowHeight * 0.9288,
        width: windowWidth,
        flexDirection: 'column',
        fontFamily: 'Electrolize',


    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})