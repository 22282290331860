export default function DocCommentary (data, dimensionKey, metric1, metric2, currentMetrics) {

    const fallback = [{
        brand: "No Data",
        channel: "No Data",
        clicks: 0,
        date: "yesterday",
        ftds: 0,
        impressions: 0,
        kw_type: "No Data",
        regs: 0,
        spend: 0,
        cpc: 0,
        cpa: 0,
        clickConv: 0
    }]

    const dataDefault = data.length > 0 ? data : fallback
    
    console.log(data > 0)
    console.log(dimensionKey)
    const randomTemplate = (templates) => templates[Math.floor(Math.random() * templates.length)];
    const calculateAverageCPA = (dataDefault) => dataDefault.reduce((acc, curr) => acc + (curr.cpa || 0), 0) / dataDefault.length;
    const calculatePercentDifference = (value, average) => ((average - value) / average) * 100;

    // Calculate average CPA
    const filteredData = dataDefault.filter(record => record.spend > 0);
    const averageCPA = calculateAverageCPA(filteredData);

    // Initialize records
    let maxFTDsRecord = dataDefault[0];
    let maxCPAPercentDiffRecord = dataDefault[0];
    let maxCPAPercentDiff = -Infinity;

    // Iterate over data to find the required records
    dataDefault.forEach(record => {
        if (record.ftds > maxFTDsRecord.ftds) {
            maxFTDsRecord = record;
        }

        const percentDiff = calculatePercentDifference(record.cpa, averageCPA);
        if (percentDiff > maxCPAPercentDiff && record.cpa < averageCPA) {
            maxCPAPercentDiff = percentDiff;
            maxCPAPercentDiffRecord = record;
        }
    });

    // Sentence templates
    const ftdsTemplates = [
        `The highest ${currentMetrics.primaryMetricString} were recorded in ${maxFTDsRecord[dimensionKey]} with ${maxFTDsRecord.ftds}.`,
        `${maxFTDsRecord[dimensionKey]} achieved the most ${currentMetrics.primaryMetricString}, totaling ${maxFTDsRecord.ftds}.`,
        `Leading in ${currentMetrics.primaryMetricString} was ${maxFTDsRecord[dimensionKey]}, reaching a high of ${maxFTDsRecord.ftds}.`
    ];

    const cpaTemplates = [
        `${maxCPAPercentDiffRecord[dimensionKey]} had a CPA ${maxCPAPercentDiff.toFixed(2)}% below the average.`,
        `In ${maxCPAPercentDiffRecord[dimensionKey]}, the CPA was lower than average by ${maxCPAPercentDiff.toFixed(2)}%.`,
        `Standing out, ${maxCPAPercentDiffRecord[dimensionKey]} showed a CPA decrease of ${maxCPAPercentDiff.toFixed(2)}% compared to the average.`
    ];

    // Generate commentary using random templates
    const ftdsComment = randomTemplate(ftdsTemplates);
    const cpaComment = randomTemplate(cpaTemplates);

    return `${ftdsComment} ${cpaComment}`;
}