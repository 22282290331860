import React from 'react';
import { View } from 'react-native'
import DndLine from './DnDCharts/DndLine';
import DashBar from '../../DashBuilder/DashScreen/DashBar'
import DashMixed from '../../DashBuilder/DashScreen/DashMixed'
import DashTable from '../../DashBuilder/DashScreen/DashTable'
import DndMetric from './DnDCharts/DndMetric';
import DnDSpace from './DnDCharts/DndSpace';
import DashEditorParse from '../../DashEditor/DashEditorParse';
import moment from 'moment-timezone';
import { LicenseInfo } from '@mui/x-license-pro';

const ControlGraph = (props) => {

    LicenseInfo.setLicenseKey('6bf1987e6f50457e0ccce5aee1489680Tz04MzczNyxFPTE3Mzg4NjE3NTUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');
    const currentMetrics = props.currentMetrics
    const currentConfig = props.currentConfig
    const theme = props.theme
    const mainAggBase = props.mainAgg
    const cubeTable = props.cubeTable
    console.log(currentConfig)

    const currentDate = new Date();

    // Set the timezone offset to 0 to work with local time
    currentDate.setMinutes(0);
    const previousDate = new Date(currentDate);
    previousDate.setDate(currentDate.getDate() - 1);
    previousDate.setDate(1)

    // Convert the start and end dates to strings in YYYY-MM-DD format
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayProp = yesterday2.format('YYYY-MM-DD');

    const currentType = currentConfig.type || 'line'
    const currentAxisbase = currentConfig.xAxis || 'date'
    const currentTime = currentConfig.time || 'daily'
    const currentMetric1 = currentConfig.metric1 || 'ftds'
    const currentMetric2 = currentConfig.metric2 || 'ftds'

    const currentFilters = currentConfig.currentGraphFilters
    const currentStart = currentFilters.start
    const currentEnd = yesterdayProp

    let currentAxis;      

    if (currentAxisbase === 'weekly' || currentAxisbase === 'monthly') {
        currentAxis = 'date'
    } else {currentAxis = currentAxisbase}


    const filteredAgg = mainAggBase.filter((obj) => {
        const date = new Date(obj.date);
        const startDate = moment.tz(currentStart, 'Europe/Paris');
        const endDate = moment.tz(currentEnd, 'Europe/Paris');
        return (
            date >= startDate &&
            date <= endDate &&
            Object.entries(currentFilters)
                .filter(([key, value]) => key !== 'start' && key !== 'end')
                .every(([key, value]) => {
                    if (value === 'Total') {
                        return true;
                    }
                    if (Array.isArray(value)) {
                        return value.includes(obj[key]);
                    }
                    return obj[key] === value;
                })
        );
    });
    const mainAgg = DashEditorParse (filteredAgg, currentAxis, currentMetric1, currentMetric2, cubeTable, currentType, currentStart, currentEnd, currentTime)

    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
        id: false,
        impressions: currentMetric1 === 'impressions' || currentMetric2 === 'impressions',
        clicks: currentMetric1 === 'clicks' || currentMetric2 === 'clicks',
        cpa: currentMetric1 === 'cpa' || currentMetric2 === 'cpa',
        cpc: currentMetric1 === 'cpc' || currentMetric2 === 'cpc',
        cpr: currentMetric1 === 'cpr' || currentMetric2 === 'cpr',
        ctr: currentMetric1 === 'ctr' || currentMetric2 === 'ctr',
        regs: currentMetric1 === 'regs' || currentMetric2 === 'regs',
        ftds: currentMetric1 === 'ftds' || currentMetric2 === 'ftds',
        spend: currentMetric1 === 'spend' || currentMetric2 === 'spend',
        brand: currentAxis === 'brand',
        channel: currentAxis === 'channel',
        kw_type: currentAxis === 'kw_type',

    });

    const visibilityCopy = {
        id: false,
        impressions: currentMetric1 === 'impressions' || currentMetric2 === 'impressions',
        clicks: currentMetric1 === 'clicks' || currentMetric2 === 'clicks',
        cpa: currentMetric1 === 'cpa' || currentMetric2 === 'cpa',
        cpc: currentMetric1 === 'cpc' || currentMetric2 === 'cpc',
        cpr: currentMetric1 === 'cpr' || currentMetric2 === 'cpr',
        ctr: currentMetric1 === 'ctr' || currentMetric2 === 'ctr',
        regs: currentMetric1 === 'regs' || currentMetric2 === 'regs',
        ftds: currentMetric1 === 'ftds' || currentMetric2 === 'ftds',
        spend: currentMetric1 === 'spend' || currentMetric2 === 'spend',
        brand: currentAxis === 'brand',
        channel: currentAxis === 'channel',
        kw_type: currentAxis === 'kw_type',

    }

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
    };

    const labels = getUniqueValues(mainAgg, currentAxis)

    const renderComponentContent = () => {
        if (currentType === 'line') {
        return (<DndLine 
                    style={panelMain}
                    agg_daily_result={mainAgg}
                    metric={currentMetric1}
                    theme={theme}
                    timeFrame={currentTime}
                    labels={labels}
                    currentMetrics={currentMetrics}
                    />);
        } else if (currentType === 'bar') {
            return (<DashBar 
                        style={panelMain}
                        dataProp={mainAgg}
                        metric={currentMetric1}
                        theme={theme}
                        timeFrame={currentTime}
                        labels={labels}
                        currentMetrics={currentMetrics}
                        />);
        } else if (currentType === 'mixed') {
            return (<DashMixed
                    style={panelMain}
                    agg_daily_result={mainAgg}
                    metric1={currentMetric1}
                    metric2={currentMetric2}
                    dateLabels={labels}
                    theme={theme}
                    currentMetrics={currentMetrics}
                    />);
        } else if (currentType === 'metric') {
            return (<DndMetric
                    style={panelMain}
                    mainAgg={mainAgg}
                    currentMetric={currentMetric1}                    
                    theme={theme}
                    currentConfig={currentConfig}
                    currentMetrics={currentMetrics}
                    />);
        } 
        else if (currentType === 'space') {
            return (<DnDSpace
                    style={panelMain}
                    theme={theme}
                    />);
        } else if (currentType === 'table') {
            return (<DashTable
                    style={panelMain}
                    dataProp={mainAgg}
                    columnWidth={0.01}
                    theme={theme}
                    currentMetric1={currentMetric1}
                    currentMetric2={currentMetric2}
                    currentAxis={currentAxis}
                    columnVisibilityModel={visibilityCopy}
                    setColumnVisibilityModel={setColumnVisibilityModel}
                    size={7}
                    currentMetrics={currentMetrics}
                    />);
        } else { return null }
    };

    const container = {
        borderWidth: 0,
        borderColor: 'red',
        height: '82%',
        width: '100%',
        fontFamily: 'Electrolize',
    }

    const panelMain = {
        width: '100%',
        height: '100%'
    }

    return (
        <View style={container}>
            {renderComponentContent()}
        </View>
    )

}

export default ControlGraph