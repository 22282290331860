import React, { useState} from 'react';
import { View, StyleSheet, Dimensions} from 'react-native'
import AppBarRN from '../Components/AppBarRN';
import TemporaryDrawer from "../Components/Drawer";
import LoadingComponent from '../Components/Loader'
import AttributionPanelSingle from '../Attribution/AttributionPanelSingle';
import moment from 'moment-timezone';
import { useCubeMeta } from '@cubejs-client/react';
import { useCubeQuery } from '@cubejs-client/react';
import Dimensions_2 from '../CubeFunctions/Dimensions_2.js';
import ParseDimensionsAtt from '../AttributionEnhanced/CubeFunctions/ParseDimensionsAtt.js';


const windowHeight = Dimensions.get('window').height;

const AttributionDimensions = (props) => {

  const theme = props.theme
  const configArray = props.configArray
  const currentMetrics = configArray.currentMetrics[0]
  const configHandler = configArray.configHandler
  const clientHandler = configArray.clientHandler
  const clientUpper = configArray.clientID
  const role = configArray.role
  const client = clientUpper.toLowerCase();
  const cubeTable = client + "_attribution_export"
  const homeTitle = "Attribution Dimensions";
  const currentDate2 = moment.tz('Europe/Malta');
  const yesterday2 = currentDate2.clone().subtract(1, 'day');
  const yesterdayProp = yesterday2.format('YYYY-MM-DD');
  const cubeMeta = useCubeMeta();
  const chartType = "single"

  const currentDate = new Date();
  currentDate.setMinutes(0);
  const previousDate = new Date(currentDate);
  previousDate.setDate(currentDate.getDate() - 30);
  previousDate.setDate(1)

  // Get the end date (e.g., the end of the current month)
  const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

  // Convert the start and end dates to strings in YYYY-MM-DD format
  const startDateString = previousDate.toISOString().slice(0, 10);
  const endDateString = endDate.toISOString().slice(0, 10);

  const filter1name = 'brand'
  const filter2name = 'channel'

  const [filter1List, setFilter1List] = useState('Total')
  const [filter2List, setFilter2List] = useState('Total')

  const filterParams = {
    filter1name,
    filter2name,
    filter1List,
    filter2List,
    setFilter1List,
    setFilter2List,
  }

  const filterBase = {
    start: "2023-01-01",
    end: yesterdayProp,
    [filter1name]: "Total",
    [filter2name]: 'Total',
  };

  const stateBase = {
    top: false,
    left: false,
    bottom: false,
    right: false,
  };

  const [state, setState] = useState(stateBase);
  const anchor = 'left'

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });

  };

  function handleDrawer(anchor) {

    const stateChange = {
      top: false,
      left: true,
      bottom: false,
      right: false,
    };
    setState(stateChange)

  }

  const [filters, setFilters] = useState(filterBase);

  const start = filters.start
  const end = filters.end
  let filter1Filter, filter1Operator;

  if (filters[filter1name] === 'Total') {
    filter1Filter = ['null']
    filter1Operator = ['notEquals']
  } else if (filters[filter1name].length === 0) {
    filter1Filter = ['null']
    filter1Operator = ['notEquals']
  } else if (filters[filter1name][0] === 'Total') {
    filter1Filter = ['null']
    filter1Operator = ['notEquals']
  } else {
    filter1Filter = filter1List
    filter1Operator = ['equals']
  }

  let filter2Filter, filter2Operator;

  if (filters[filter2name] === 'Total') {
    filter2Filter = ['null']
    filter2Operator = ['notEquals']
  } else if (filters[filter2name].length === 0) {
    filter2Filter = ['null']
    filter2Operator = ['notEquals']
  } else if (filters[filter2name][0] === 'Total') {
    filter2Filter = ['null']
    filter2Operator = ['notEquals']
  } else {
    filter2Filter = filter2List
    filter2Operator = ['equals']
  }

  const propObjectBase = {
    data: [],
    start: "2023-01-01",
    end: yesterdayProp,
    theme: theme,
    [filter1name]: "Total",
    [filter2name]: 'Total',
  };

  const [propObject, setpropObject] = useState(propObjectBase);

  const handleStateChange = (prop) => {

    setpropObject(prop);

    const filterUpdate = {
      start: prop.start,
      end: prop.end,
      [filter1name]: prop[filter1name],
      [filter2name]: prop[filter2name],
    };

    setFilters(filterUpdate)

  };
  
  const dimQuery = Dimensions_2(cubeTable, start, end, filter1name, filter2name);
  const dimResult = useCubeQuery(dimQuery);

  if (!dimResult.resultSet) {
    return (
      <LoadingComponent theme={theme} />
    );
  }

  if (!cubeMeta.response) {
    return (
      <LoadingComponent theme={theme} />
    );
  }

  const dimData = dimResult.resultSet.loadResponses[0].data

  const uniqueFilter1 = ParseDimensionsAtt(dimData, cubeTable, filter1name);
  const uniqueFilter2 = ParseDimensionsAtt(dimData, cubeTable, filter2name);

  const updateVar = `${cubeTable}.updated_at`
  const lastUpdated = dimData[0][updateVar] || 'loading...'

  const cubeMetaData = cubeMeta.response.cubesMap.rank_attribution_export
  const cubeDims = Object.keys(cubeMetaData.dimensions)


  const allowedDimensions = ["channel", "kw_type", "campaign", "adgroup" , "region", "mobile_brand_name", "operating_system_version", "browser"]; // Add the dimension names you want to allow

  const cubeDimensions = cubeDims.map((dimensionName) => {
    const parts = dimensionName.split(".");
    if (parts.length === 2) {
      const dimensionValue = parts[1]; // Get the value after the dot
      if (allowedDimensions.includes(dimensionValue)) {
        return dimensionValue; // Return the dimension if it's in the allowed list
      } else {
        return null; // Return null if the dimension is not in the allowed list
      }
    } else {
      return dimensionName; // Keep the original value if there's no dot
    }
  }).filter((dimension) => dimension !== null);


  const dataPropFilter = {
    uniqueFilter1,
    uniqueFilter2,
  }

  return (
    <View style={[styles.container, { backgroundColor: theme.backgroundColor }]}>
      <AppBarRN
        handleDrawer={handleDrawer}
        homeTitle={homeTitle}
        theme={theme}
        configHandler={configHandler}
        clientHandler={clientHandler}
        role={role}
        client={clientUpper}
        lastUpdated={lastUpdated}
      />
      <TemporaryDrawer
        toggleDrawer={toggleDrawer}
        state={state}
        anchor={anchor}
        theme={theme}
        clientID={clientUpper}
      />
      <AttributionPanelSingle
        theme={theme}
        windowHeight={windowHeight}
        propObject={propObject}
        handleStateChange={handleStateChange}
        filters={filters}
        chartType={chartType}
        cubeTable={cubeTable}
        filterParams={filterParams}
        dataPropFilter={dataPropFilter}
        cubeDimensions={cubeDimensions}
        currentMetrics={currentMetrics}
      />

    </View>

  )
}



const styles = StyleSheet.create({
  container: {
    borderWidth: 0,
    borderColor: 'yellow',
    paddingTop: 0,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    flex: 1,
  },
  panel: {
    width: '100%'
  }
})

export default AttributionDimensions