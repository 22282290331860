export default function CubeAnalytics(cubeFilters, table, start, end) {

    return {
        "timeDimensions": [
            {
                "dimension": `${table}.timestamp`,
                "granularity" : 'month',
                "dateRange": [
                    start,
                    end
                ]
            }
        ],
        "order": [
            [
                `${table}.timestamp`,
                "asc"
            ]
        ],
        "measures": [
            `${table}.pageviews`,
            `${table}.new_users`,
            `${table}.sessions`,
            `${table}.reg_start`,
            `${table}.regs`,
            `${table}.ftds`,
            `${table}.spend`,
            `${table}.ngr`,
            `${table}.cpa`,
        ],
        "dimensions": [
            `${table}.brand`,
            `${table}.channel`,
        ],
        "limit": 2000,
        "filters": cubeFilters
    }

}