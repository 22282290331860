import React from 'react';
import { View, Text } from 'react-native'


const MetricContainer = (props) => {

    const currentMetrics = props.currentMetrics
    const styleProp = props.styleProp
    const textProp = props.textProp
    const textValue = props.textValue
    const widthValue = props.widthValue
    const heightValue = props.heightValue
    const itemSize = props.itemSize


    let panelHeight;

    if (itemSize === 'Large') {
        panelHeight = '15%'
    } else if ( itemSize === 'Medium') {
        panelHeight = '10%'
    } else { panelHeight = '5%'}

    let convLabel

    if (textValue === 'ctr') {
        convLabel = `${textValue}%`
      } if (textValue === 'ftds') {
        convLabel = currentMetrics.primaryMetric
      } else if (textValue === 'ngr') {
        convLabel = currentMetrics.primaryRevenue
      } else if (textValue === 'regs') {
        convLabel = currentMetrics.secondaryMetric
      } else {convLabel = textValue}

    return (
        <div
            style={{padding: '1%', height: panelHeight}}
            draggable={true}
            unselectable="on"
            onDragStart={(e) => {e.dataTransfer.setData("text/plain", ""); 
                                e.dataTransfer.setData("chartType", textValue.toLowerCase()); 
                                e.dataTransfer.setData("width", widthValue);
                                e.dataTransfer.setData("height", heightValue)
                                }}
          >
          <View style={styleProp}>
          <Text
              style={textProp}
              > {textValue} </Text>
          </View>
          </div>

    )

}

export default MetricContainer