import './App.css';
import React, { useState } from 'react';
import { Dimensions } from 'react-native';
import Login from './Screens/Login';
import SignedUp from './Screens/SignedUp';
import MobileScreen from './Mobile/MobileScreen';
import { themes } from './themes'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import AppRoutes from './Routes';
import { useAuth0 } from "@auth0/auth0-react";
import LoadingComponent from './Components/Loader'
import routemap from './routes.json'

const windowWidth = Dimensions.get('window').width;

function App(props) {

  const fmKey = props.fmKey
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [themeType , setTheme] = useState((localStorage.getItem('mode')) ?? 'white')
  const [toolTips , setToolTips] = useState((localStorage.getItem('mode')) ?? true)
  const [clientID, setClientID] = useState((localStorage.getItem('client')))
  
  

  function tootTipHandler (props) {
    localStorage.setItem("toolTips", props)
    setToolTips(false)
  }

  const tooltipArray = {
    toolTips,
    tootTipHandler
  }

  function configHandler (props) {
    localStorage.setItem("mode", props)
    
    setTheme(props)
  }

  function clientHandler (props) {
    localStorage.setItem("client", props)
    setClientID(props)
  }

  const theme = themes[themeType]

  if (isLoading) {
    return <LoadingComponent theme={theme} />;
  }

  if (!isAuthenticated) {
    return <Login theme={theme}/>
  }

  const userProfile = user
  const userRole = userProfile?.stack_user_metadata?.role || 'guest'
  const userClient = userProfile?.stack_user_metadata?.client || 'guest'
  const comparisonURL = userProfile?.stack_user_metadata?.comparison_url || 'guest'

  //const clientIdentifier = userRole === 'admin' ? clientID : userClient;
  const clientIdentifier = clientID || userClient;
  const currentRoutes = routemap[clientIdentifier]['config']
  const currentMetrics = routemap[clientIdentifier]['metricConfig']

  const configArray = {
    role : userRole,
    clientID: clientIdentifier,
    comparison_url: comparisonURL,
    configHandler,
    clientHandler,
    tooltipArray,
    currentMetrics,
    fmKey
  }

  if (clientIdentifier === "guest") {
    return (<SignedUp theme={theme} configArray={configArray} themeType={themeType} />)
  }

  else if (windowWidth < 450) {
    return (
      <MobileScreen theme={theme} configArray={configArray} themeType={themeType} />
    )
  } else { 
    return (
    <Router >
    <Routes>
    {currentRoutes.map((route, index) => {
          const Component = AppRoutes[route.component];
          return (
            <Route 
              key={index}
              path={route.path}
              element={<Component theme={theme} 
                                  configArray={configArray} 
                                  configHandler={configHandler}
                                  clientHandler={clientHandler}
                                  userProfile={userProfile}
                                  themeType={themeType} 
                                  
                                  />}
              exact={route.exact || false}
            />
          );
        })}
    </Routes>
    </Router>
    )
  }
}

export default App;
