import Fab from '@mui/material/Fab';
import { View } from 'react-native'

const SummaryFAB = (props) => {

    const buttonText = props.buttonText
    const theme = props.theme
    const graphHandler = props.graphHandler
    const currentMetrics = props.currentMetrics

    const textHandler = (value) => {
        let output;
        if (value === currentMetrics.primaryMetric) {
          output = 'ftds'
        } else if (value === currentMetrics.secondaryMetric) {
          output = 'regs'
        } else if (value === currentMetrics.primaryRevenue) {
          output = 'ngr'
        } else {
          output = value
        }
        return graphHandler(output)
      }

    return (
        <View
            style={{
                borderWidth: 0,
                borderColor: theme.color,
                alignItems: 'center',
                justifyContent: 'center',
                width: '33.333333%',
                padding: '1%'
            }}
        >
            <Fab variant="extended"
                onClick={() => { textHandler(buttonText)}}
                sx={{
                    color: 'white',
                    backgroundColor: theme.backgroundColor2,
                    fontSize: 10,
                    width: '100%'
                }}>

                {buttonText}
            </Fab>
        </View>
    )
}

export default SummaryFAB