export default function ParseLookerSummary(aggResult, dimension, metric, model) {

    var dataset = aggResult

    if (!dataset || dataset.length === undefined) {
        return null;
    }

    var agg_result = [];
    dataset.reduce(function (res, value) {

        const regs = 'regs_ft_lifetime'
        const ftds = `ftds_${model}`
        const react = `react_${model}`
        const ngr = `ngr_${model}`
        const impressions = 'impressions'
        const clicks = 'clicks'
        const spend = 'spend'
        const xAxis = value[dimension]

        if (!res[xAxis]) {
            res[xAxis] = {
                [dimension]: xAxis,
                impressions: 0,
                clicks: 0,
                spend: 0,
                regs: 0,
                ftds: 0,
                react: 0,
                ngr: 0
            };
            agg_result.push(res[xAxis]);
        }
        res[xAxis].impressions += parseInt(value[impressions]) || 0;
        res[xAxis].clicks += parseInt(value[clicks]) || 0;
        res[xAxis].spend += parseInt(value[spend]) || 0;
        res[xAxis].regs += parseInt(value[regs]) || 0;
        res[xAxis].ftds += parseInt(value[ftds]) || 0;
        res[xAxis].react += parseInt(value[react]) || 0;
        res[xAxis].ngr += parseInt(value[ngr]) || 0;

        return res;
    }, {});

    agg_result = agg_result.map((obj) => {
        //const impressions = obj.impressions
        const objDimension = dimension
        const objValue = obj[dimension]
        const clicks = obj.clicks || 0
        const spend = obj.spend || 0;
        //const regs = obj.regs
        const ftds = obj.ftds || 0;
        const ngr = obj.ngr || 0
        //const ctr = impressions === 0 || clicks === 0 || isNaN(clicks) ? 0 : clicks / impressions;
        const cpc = spend === 0 || clicks === 0 || isNaN(clicks) ? 0 : spend / clicks;
        const cpa = spend === 0 || ftds === 0 || isNaN(ftds) ? 0 : spend / ftds;
        //const cpr = spend === 0 || regs === 0 || isNaN(regs) ? 0 : spend / regs;
        const totalConv = obj.react + obj.ftds || 0
        const netProfit = ngr - spend || 0
        const recovered = (ngr / spend) || 0
        const costConv = spend === 0 || totalConv === 0 || isNaN(totalConv) ? 0 : spend / totalConv;
        return {
            
            [objDimension]: objValue,
            ngr: ngr,
            clicks: clicks,
            ftds: ftds,
            totalConv: totalConv,
            costConv: parseFloat((costConv).toFixed(2)),
            cpa: parseFloat((cpa).toFixed(2)),
            cpc: parseFloat((cpc).toFixed(2)),
            spend: parseFloat((spend).toFixed(2)),
            netProfit: parseFloat((netProfit).toFixed(2)),
            recovered: parseFloat((recovered).toFixed(4)),
        };
    });


    return agg_result

}