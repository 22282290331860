import { memo } from 'react'
import { useDrop } from 'react-dnd'
import { View, Text, Dimensions } from 'react-native'

const windowWidth = Dimensions.get('window').width;

export const Dustbin = memo(function Dustbin({
  accept,
  lastDroppedItem,
  onDrop,
  theme,
  currentConfig,
  indexCount,
  chartType,
  currentMetrics
}) {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const style = {
    color: 'white',
    height: '80%',
    width: '100%',
    textAlign: 'center',
    lineHeight: 'normal',
    float: 'left',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid white',
  }

  const smallStyle = {
    color: 'white',
    height: '20%',
    width: '33.33333%',
    textAlign: 'center',
    lineHeight: 'normal',
    float: 'left',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid white',
  }

  let dynamicText;

  if (chartType === 'main' && windowWidth > 2000) {
    dynamicText = windowWidth * 0.02
  } else if (chartType === 'main' && windowWidth >= 1400) {
    dynamicText = windowWidth * 0.017
  } else if (chartType === 'main' && windowWidth < 1400) {
    dynamicText = windowWidth * 0.02
  } else if (windowWidth > 2000) {
    dynamicText = windowWidth * 0.01
  } else if (windowWidth >= 1400) {
    dynamicText = windowWidth * 0.01
  } else if (windowWidth < 1400) {
    dynamicText = windowWidth * 0.01
  }
  else { dynamicText = 15 }

  const textLarge = {
    textAlign: 'center',
    color: 'white',
    fontWeight: 'bold',
    fontFamily: 'Electrolize',
    textTransform: 'capitalize',
    fontSize: dynamicText

  }

  const isActive = isOver && canDrop
  const bucketType = accept[0]

  let bucketStyle = bucketType === 'type' ? style : smallStyle;
  let bucketComments, bucketDefault;


  if (bucketType === 'type') {
    bucketComments = 'Chart Type : '
  } else if (bucketType === 'xAxis') {
    bucketComments = 'Axis : '
  } else { bucketComments = 'Metric : ' }

  let backgroundColor;
  if (isActive) {
    backgroundColor = theme.backgroundColor2
  } else if (canDrop) {
    backgroundColor = '#7DFA00'
  } else if (indexCount === 3 && `${currentConfig.currentType}` !== 'mixed') {
    backgroundColor = 'grey'
  } else if (accept[0] === 'type') {
    backgroundColor = theme.backgroundColor2
  } else if (bucketType === 'xAxis') {
    backgroundColor = theme.gradc50
  } else if (bucketType === 'metric') {
    backgroundColor = theme.color3
  } else { backgroundColor = 'grey' }

  let bucketLabel;

  if (!lastDroppedItem && chartType.includes('child') && bucketType === 'xAxis') {

    const dynamicPropertyName = "current" + chartType + 'Axis';
    const dynamicPropertyTime = "current" + chartType + 'Time';


    if (currentConfig[dynamicPropertyName] === 'date' && currentConfig[dynamicPropertyTime] === 'daily') {
      bucketLabel = `Axis: ${currentConfig[dynamicPropertyTime]}`
    }
    else if (currentConfig[dynamicPropertyName] === 'date' && currentConfig[dynamicPropertyTime] === 'weekly') {
      bucketLabel = `Axis: ${currentConfig[dynamicPropertyTime]}`
    }
    else if (currentConfig[dynamicPropertyName] === 'date' && currentConfig[dynamicPropertyTime] === 'monthly') {
      bucketLabel = `Axis: ${currentConfig[dynamicPropertyTime]}`
    }
    else bucketLabel = `Axis: ${currentConfig[dynamicPropertyName]}`
  }

  else if (lastDroppedItem && chartType.includes('child') && bucketType === 'xAxis') {
    bucketLabel = `Axis: ${lastDroppedItem.name}`
  }

  else if (!lastDroppedItem && chartType.includes('child') && bucketType === 'type') {
    const dynamicTypeName = "current" + chartType + 'Type';
    bucketLabel = `Chart Type: ${currentConfig[dynamicTypeName]}`
  }

  else if (lastDroppedItem && chartType.includes('child') && bucketType === 'type') {
    bucketLabel = `Chart Type: ${lastDroppedItem.name}`
  }

  else if (!lastDroppedItem && chartType.includes('child') && indexCount === 2) {
    const dynamicTypeName = "current" + chartType + 'Metric1';
    bucketLabel = `Metric: ${currentConfig[dynamicTypeName]}`
  }

  else if (lastDroppedItem && chartType.includes('child') && indexCount === 2) {
    bucketLabel = `Metric: ${lastDroppedItem.name}`
  }

  else if (!lastDroppedItem && chartType.includes('child') && indexCount === 3) {
    const dynamicTypeName = "current" + chartType + 'Metric2';
    bucketLabel = `Metric: ${currentConfig[dynamicTypeName]}`
  }

  else if (lastDroppedItem && chartType.includes('child') && indexCount === 3) {
    bucketLabel = `Metric: ${lastDroppedItem.name}`
  }

  else if (!lastDroppedItem && bucketType === 'xAxis') {
    if (currentConfig.currentAxis === 'date' && currentConfig.currentTime === 'daily') {
      bucketLabel = `Axis: ${currentConfig.currentTime}`
    }

    else if (currentConfig.currentAxis === 'date' && currentConfig.currentTime === 'weekly') {
      bucketLabel = `Axis: ${currentConfig.currentTime}`
    }

    else if (currentConfig.currentAxis === 'date' && currentConfig.currentTime === 'monthly') {
      bucketLabel = `Axis: ${currentConfig.currentTime}`
    }
    else bucketLabel = `Axis: ${currentConfig.currentAxis}`

  }

  else if (lastDroppedItem && bucketType === 'xAxis') {
    const timeVar = "current" + chartType + 'Axis'

    if (timeVar.includes('Date') || timeVar.includes('Weekly') || timeVar.includes('Monthly')) {
      console.log(timeVar)
      bucketLabel = `Axis: ${lastDroppedItem.name}`
    }
    else { bucketLabel = `Axis: ${lastDroppedItem.name}` }
  }

  else if (!lastDroppedItem && bucketType === 'type') {
    bucketLabel = `Chart Type: ${currentConfig.currentType}`
  }

  else if (lastDroppedItem && bucketType === 'type') {
    bucketLabel = `Chart Type: ${lastDroppedItem.name}`
  }

  else if (!lastDroppedItem && indexCount === 2) {
    bucketLabel = `Metric: ${currentConfig.currentMetric1}`
  }

  else if (lastDroppedItem && indexCount === 2) {
    bucketLabel = `Metric: ${lastDroppedItem.name}`
  }

  else if (!lastDroppedItem && indexCount === 3) {
    bucketLabel = `Metric: ${currentConfig.currentMetric2}`
  }

  else if (lastDroppedItem && indexCount === 3) {
    bucketLabel = `Metric: ${lastDroppedItem.name}`
  }

  let finalBucketLabel;

  if (bucketLabel === 'Metric: ftds') {
    finalBucketLabel = `Metric: ${currentMetrics.primaryMetric}`
  } else if (bucketLabel === 'Metric: FTDs') {
    finalBucketLabel = `Metric: ${currentMetrics.primaryMetric}`
  } else if (bucketLabel === 'Metric: ngr') {
    finalBucketLabel = `Metric: ${currentMetrics.primaryRevenue}`
  } else if (bucketLabel === 'Metric: NGR') {
    finalBucketLabel = `Metric: ${currentMetrics.primaryRevenue}`
  } else if (bucketLabel === 'Metric: regs') {
    finalBucketLabel = `Metric: ${currentMetrics.secondaryMetric}`
  } else if (bucketLabel === 'Metric: Regs') {
    finalBucketLabel = `Metric: ${currentMetrics.secondaryMetric}`
  } else if (bucketLabel === 'Axis: KW_Type') {
    finalBucketLabel = `Axis: ${currentMetrics.primaryDimensionString}`
  } else if (bucketLabel === 'Axis: kw_type') {
    finalBucketLabel = `Axis: ${currentMetrics.primaryDimensionString}`
  } else { finalBucketLabel = bucketLabel }



  return (

    <View ref={drop} style={{ ...bucketStyle, backgroundColor }} data-testid="dustbin">
      <Text style={textLarge}>
        {isActive
          ? 'Release to drop'
          : ""}
      </Text>

      <Text style={textLarge}> {finalBucketLabel}</Text>

    </View>

  )
})
