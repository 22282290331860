import React, { useEffect } from 'react';
import {
    View,
    Dimensions,
} from 'react-native'
import CampaignSummaryTable from './CampaignSummaryTable.js';
import BarChart from '../ChartsDesktop/BarChart';
import MixedChart from '../ChartsDesktop/MixedChart';

const windowHeight = Dimensions.get('window').height;

const CampaignSummaryLayout = (props) => {

    const theme = props.theme
    const dataProp = props.dataProp
    const dailyAgg = dataProp.dailyAgg
    const brandAgg = dataProp.brandAgg
    const monthlyAgg = dataProp.monthlyAgg
    const campaignAgg = dataProp.campaignAgg
    const currentMetrics = props.currentMetrics
    const filtered = props.filtered
    const client = props.client


    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
    };

    const dayLabels = getUniqueValues(dailyAgg, 'date')
    const brandLabels = getUniqueValues(brandAgg, 'brand')
    const monthLabels = getUniqueValues(monthlyAgg, 'date')

    const container = {
        height:  windowHeight * 0.85,
        width: '100%',
        borderColor: 'grey',
        borderWidth: 0,
        flexDirection: 'row',
        padding: '1%',
        alignItems: 'center',
        justifyContent: 'center'
    }

    const tablePanel = {
        height: '100%',
        width: '75%',
        borderColor: 'grey',
        borderWidth: 1,
    }

    const graphPanel = {
        height: '100%',
        width: '25%',
        borderColor: 'grey',
        borderWidth: 0,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

    }

    const graphContainer = {
        height: '33.33333%',
        width: '100%',
        borderColor: 'grey',
        borderWidth: 1,
    }

    let newLabels;

    if(client === 'glitnor') {
        newLabels = {
            ftds : 'totalConv',
            cpa : 'cpa'
        }
    } else {
        newLabels = {
            ftds : 'ftds',
            cpa : 'cpa'
        }
    }


    return (
        <View style={container}>
            <View style={tablePanel}>
                <CampaignSummaryTable
                    dataProp={campaignAgg}
                    client={client}
                    theme={theme}
                    currentMetrics={currentMetrics}
                />

            </View>
            <View style={graphPanel}>
                <View style={graphContainer}>
                    <BarChart 
                        dataProp={dailyAgg}
                        labels={dayLabels}
                        theme={theme}
                        metric={'ftds'}
                        timeFrame={"Daily"}
                        newLabels={newLabels}
                        client={client}
                        currentMetrics={currentMetrics}
                    />

                </View>
                <View style={graphContainer}>
                    <BarChart 
                        dataProp={brandAgg}
                        labels={brandLabels}
                        theme={theme}
                        metric={'cpa'}
                        timeFrame={"Daily"}
                        newLabels={newLabels}
                        client={client}
                        currentMetrics={currentMetrics}
                    />
                    
                </View>
                <View style={graphContainer}>
                    <MixedChart 
                        dataProp={monthlyAgg}
                        labels={monthLabels}
                        theme={theme}
                        agg_daily_result={monthlyAgg}
                        dateLabels={monthLabels}
                        metric1={'cpa'}
                        metric2={'ftds'}
                        timeFrame={'Monthly'}
                        newLabels={newLabels}
                        client={client}
                        currentMetrics={currentMetrics}
                    />
                    
                </View>

            </View>

        </View>
    )

}

export default CampaignSummaryLayout