import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { GridToolbarContainer, GridToolbarExport  } from '@mui/x-data-grid';
import { LicenseInfo } from '@mui/x-license-pro';

export default function DataTable(props) {
  const theme = props.theme
  LicenseInfo.setLicenseKey('6bf1987e6f50457e0ccce5aee1489680Tz04MzczNyxFPTE3Mzg4NjE3NTUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');  

  const columns = [
  { field: 'id', headerName: 'ID' },
  ...props.labels.map((label) => ({ field: label, 
                                    headerName: label, 
                                    flex: 1
                                  })),
];
  const rows = props.dataProp.map((row, index) => ({ id: index + 1, ...row , textColor: 'white'}));

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport sx={{color: theme.color}}/>
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ width: '100%', fontSize: 10, backgroundColor: theme.backgroundColor }}>
      <DataGrid
        rows={rows}
        columns={columns}
        slots={{ toolbar: CustomToolbar }}
        autoHeight
        sx={{color: theme.color}}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 20 },
          },
        }}

      />
    </div>
  );
}